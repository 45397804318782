import {ChangeDetectorRef, Component, OnInit} from '@angular/core'
import {TipoProvedor} from '../utils/enums'
import {Beneficio, Topico} from '../model/cct/beneficios/beneficio.model'
import {DomSanitizer} from '@angular/platform-browser'
import {CctService} from '../service/cct.service'
import {MixpanelService} from '../service/mixpanel.service'
import {MixPanelEvents} from '../utils/mixpanel-events'
import {DadosLandingPageUsuarioCCT} from '../model/cct/beneficios/dados-landing-page-usuario-cct.model'
import {ActivatedRoute} from '@angular/router'
import {NavbarOption, PropriedadesMixPanelLP} from '../model/cct/beneficios/landing-page/interfaces'
import {
	CONTEUDO_FOOTER_CLIN,
	CONTEUDO_FOOTER_MELHORAI,
	CONTEUDO_FOOTER_MOBILE_CLIN,
	CONTEUDO_FOOTER_MOBILE_MELHORAI,
	LINTREE_DOWNLOAD_CLINAPP,
} from '../utils/constants'

interface TopicoBeneficioSelecionado {
	topicoSelecionado: Topico
	beneficio: Beneficio
}

interface EventDownloadAppButton {
	linkAcesso: string
	loja: string
	beneficio: Beneficio
}

@Component({
	selector: 'landing-page-usuario',
	templateUrl: './landing-page-usuario.component.html',
	styleUrls: ['./landing-page-usuario.component.scss'],
})
export class LandingPageUsuarioComponent implements OnInit {
	cctInfo: DadosLandingPageUsuarioCCT

	linkBaixarApp = LINTREE_DOWNLOAD_CLINAPP

	conteudoFooterClin = CONTEUDO_FOOTER_CLIN
	conteudoFooterMobileClin = CONTEUDO_FOOTER_MOBILE_CLIN
	conteudoFooterMelhorai = CONTEUDO_FOOTER_MELHORAI
	conteudoFooterMobileMelhorai = CONTEUDO_FOOTER_MOBILE_MELHORAI

	navbarOptions: NavbarOption[] = []

	propsMixpanelLP: PropriedadesMixPanelLP = {
		cnpj: '',
		id: 0,
		razaoSocial: '',
		idCCT: 0,
		nome: '',
	}

	listaBeneficios: Beneficio[] = []
	textoHeaderCCT = ''
	numeroEspecialistaCCT = ''
	ilustracaoCCTUrl = ''

	idCCT: number = null
	idSindicato: number = null

	constructor(
		private sanitizer: DomSanitizer,
		private cctService: CctService,
		private mixpanelService: MixpanelService,
		private route: ActivatedRoute,
		private cd: ChangeDetectorRef,
	) {
		this.configurarConteudoFooterBenificio()
	}

	configurarConteudoFooterBenificio() {
		this.listaBeneficios.map(beneficio => {
			switch (beneficio.tipoProvedor) {
				case TipoProvedor.CLIN:
					beneficio.conteudoFooter = this.sanitizer.bypassSecurityTrustHtml(
						this.conteudoFooterClin,
					) as string
					beneficio.conteudoFooterMobile = this.sanitizer.bypassSecurityTrustHtml(
						this.conteudoFooterMobileClin,
					) as string
					break
				case TipoProvedor.MELHORAI:
					beneficio.conteudoFooter = this.sanitizer.bypassSecurityTrustHtml(
						this.conteudoFooterMelhorai,
					) as string
					beneficio.conteudoFooterMobile = this.sanitizer.bypassSecurityTrustHtml(
						this.conteudoFooterMobileMelhorai,
					) as string
					break
				case TipoProvedor.AGIBEN:
					break
				default:
					break
			}
		})
	}

	async ngOnInit(): Promise<void> {
		this.idCCT = Number(this.route.snapshot.paramMap.get('cct')) || null
		this.idSindicato = Number(this.route.snapshot.paramMap.get('sindicato')) || null

		if (this.idCCT && this.idSindicato) {
			this.carregarDados()
		}
	}

	carregarDados() {
		this.cctService
			.buscarInformacoesCCTPorIdESindicatoId(this.idCCT, this.idSindicato)
			.then((result: DadosLandingPageUsuarioCCT) => {
				this.cctInfo = result

				this.ilustracaoCCTUrl = 'assets/img/logo/novo-banner-agiben.png'

				this.propsMixpanelLP = {
					id: this.cctInfo.sindicato.id,
					nome: this.cctInfo.nome,
					idCCT: this.cctInfo.id,
					razaoSocial: this.cctInfo.sindicato.razaoSocial,
					cnpj: this.cctInfo.sindicato.cnpj,
				}

				this.numeroEspecialistaCCT = this.cctInfo.contatoEspecialista
				const nomeEmpresaSindicato =
					this.cctInfo.sindicato.nomeFantasia || this.cctInfo.sindicato.razaoSocial
				this.textoHeaderCCT = `Estamos felizes em te ter aqui! Você faz parte da ${nomeEmpresaSindicato} e o seu Plano de Assistência Familiar (PAF) está ativo.`

				this.navbarOptions.push({
					id: 'clube-de-beneficios',
					label: 'Clube de benefícios',
				})

				this.listaBeneficios = this.cctInfo.beneficios.map(beneficio => {
					this.navbarOptions.push({
						id: beneficio.tipoProvedor,
						label: beneficio.titulo,
					})

					const topicos = beneficio.topicos
					return {
						...beneficio,
						topicos: topicos.map((topico, index) => {
							return {...topico, ativo: index === 0 ? true : false}
						}),
					}
				})

				this.navbarOptions.push({
					id: 'ATENDIMENTO',
					label: 'Atendimento',
				})
				this.configurarConteudoFooterBenificio()
				this.cd.detectChanges()
			})
	}

	scrollarPara(id: string, type = 'NAVBAR') {
		if (type === 'NAVBAR') {
			this.mixpanelService.track(MixPanelEvents.LP_COLABORADOR_CLIQUE_NAVBAR_OPCAO, {
				...this.propsMixpanelLP,
				localizacao: 'NAVBAR',
				secao: id,
			})
		}

		if (type === 'HEADER') {
			this.mixpanelService.track(MixPanelEvents.LP_COLABORADOR_CLIQUE_QUERO_CONHECER, {
				...this.propsMixpanelLP,
			})
		}

		if (id) {
			document.getElementById(`secao-scroll-${id}`).scrollIntoView({
				behavior: 'smooth',
			})
		}
	}

	baixarApp() {
		this.mixpanelService.track(MixPanelEvents.LP_COLABORADOR_CLIQUE_BAIXAR_APP, {
			...this.propsMixpanelLP,
			localizacao: 'NAVBAR',
		})

		window.open(this.linkBaixarApp, '_blank')
	}

	topicoBeneficioSelecionado(event: TopicoBeneficioSelecionado) {
		this.mixpanelService.track(MixPanelEvents.LP_COLABORADOR_CLIQUE_ABA_BENEFICIO_TOPICO, {
			...this.propsMixpanelLP,
			nomeBeneficio: event.beneficio.titulo,
			idBeneficio: event.beneficio.id,
			nomeTopico: event.topicoSelecionado.titulo,
			idTopico: event.topicoSelecionado.id,
			localizacao: 'SECAO-BENEFICIO',
		})
	}

	clickCardBeneficioStore(event: EventDownloadAppButton) {
		if (event.linkAcesso) {
			this.mixpanelService.track(MixPanelEvents.LP_COLABORADOR_CLIQUE_LOJA, {
				...this.propsMixpanelLP,
				loja: event.loja,
				nomeBeneficio: event.beneficio.titulo,
				idBeneficio: event.beneficio.id,
			})
			window.open(event.linkAcesso, '_blank')
		}
	}

	clicouLogoAgiben(idSecao: string) {
		this.mixpanelService.track(MixPanelEvents.LP_COLABORADOR_CLIQUE_NAVBAR_OPCAO, {
			...this.propsMixpanelLP,
			localizacao: 'NAVBAR',
			secao: idSecao,
		})

		if (idSecao) {
			document.getElementById(idSecao).scrollIntoView({
				behavior: 'smooth',
			})
		}
	}

	falarComEspecialista() {
		this.mixpanelService.track(MixPanelEvents.LP_COLABORADOR_CLIQUE_FALAR_COM_ESPECIALISTA, {
			...this.propsMixpanelLP,
			localizacao: 'SECAO_RODAPE',
			numero: this.numeroEspecialistaCCT,
		})
	}

	clicouAbrirSite() {
		this.mixpanelService.track(MixPanelEvents.LP_COLABORADOR_CLIQUE_ABRIR_SITE, {
			...this.propsMixpanelLP,
			localizacao: 'SECAO_RODAPE',
		})
	}

	clicouAbrirInstagram() {
		this.mixpanelService.track(MixPanelEvents.LP_COLABORADOR_CLIQUE_ABRIR_INSTAGRAM, {
			...this.propsMixpanelLP,
			localizacao: 'SECAO_RODAPE',
		})
	}
}

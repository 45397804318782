import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-progress-popup',
  templateUrl: './progress-popup.component.html',
  styles: [
  ]
})
export class ProgressPopupComponent implements OnInit {

  //#region VARIAVEIS  

  progressTitulo: string;
  progressSubTitulo: string;

  // This property is bound using its original name.
  progressBarValue: number = 0;

  //#endregion

  //#region CONSTRUCTOR
  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, public translateService: TranslateService) { }

  ngOnInit(): void {

  }

  //#endregion

  public setProgress(value: number): void {

    this.progressBarValue = value;

  }

  public close() {

    this.activeModal.close();
    this.progressBarValue = 0;

  }

  public closeDelay() {

    setTimeout(() => {
      this.activeModal.close();
      this.progressBarValue = 0;
    }, 3000);

  }

}

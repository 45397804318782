<div
	[ngClass]="{
		'salesforce-badge-container-success': perfil == 'SUCCESS',
		'salesforce-badge-container-sales-farm': perfil == 'SALES FARM',
		'salesforce-badge-container-field-sales': perfil == 'FIELD SALES',
		'salesforce-badge-container-sales-rep': perfil === 'SALES REP',
		'salesforce-badge-container-account': perfil === 'ACCOUNT',
		'salesforce-badge-container-labor-union': perfil === 'LABORAL',
		'salesforce-badge-container-employee-union': perfil === 'PATRONAL',
		'salesforce-badge-container-produtos-comissoes': perfil === 'COMISSOES',
		'salesforce-badge-container-default': isDefault
	}"
	class="rounded-pill py-2 font-weight-bold">
	<span class="col-md-8">{{ valor || perfil | titlecase }}</span>
</div>

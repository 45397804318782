import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'

interface NavbarOption {
	id: string
	label: string
}

@Component({
	selector: 'navbar-landing-page',
	templateUrl: './navbar-landing-page.component.html',
	styleUrls: ['./navbar-landing-page.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NavbarLandingPageComponent implements OnInit {
	@Input() primaryButtonLabel = ''
	@Input() secondaryButtonLabel = ''
	@Input() bottaoRoteamentoLabel = ''
	@Input() navbarOptions: NavbarOption[] = []
	@Input() typeSelectedLabel = ''

	@Output() onLogoClickEvent: EventEmitter<any> = new EventEmitter<any>()
	@Output() onScrollToEvent: EventEmitter<any> = new EventEmitter<any>()
	@Output() onPrimaryButtonClickEvent: EventEmitter<any> = new EventEmitter<any>()
	@Output() onSecondaryButtonClickEvent: EventEmitter<any> = new EventEmitter<any>()
	@Output() onBottaoRoteamentoEvent: EventEmitter<any> = new EventEmitter<any>()
	@Output() onSelectTypeClickEvent: EventEmitter<any> = new EventEmitter<any>()

	constructor() {}

	ngOnInit(): void {}

	onLogoClick() {
		this.onLogoClickEvent.emit()
	}

	onScrollTo(id) {
		this.onScrollToEvent.emit(id)
	}

	onPrimaryButtonClick() {
		this.onPrimaryButtonClickEvent.emit()
	}

	onSecondaryButtonClick() {
		this.onSecondaryButtonClickEvent.emit()
	}

	onBottaoRoteamento() {
		this.onBottaoRoteamentoEvent.emit()
	}

	onSelectType() {
		this.onSelectTypeClickEvent.emit()
	}
}

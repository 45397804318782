import {
  Component,
  OnInit,
} from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "src/app/app.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: "conclusao-inclusao-vida-popup",
  templateUrl: "./conclusao-popup.component.html",
  styleUrls: [
    "../../../vendor/libs/angular2-ladda/angular2-ladda.scss",
    "../../../vendor/libs/ng-select/ng-select.scss",
    "./conclusao-popup.component.scss",
    "../../../vendor/libs/spinkit/spinkit.scss",
    "../../../vendor/libs/ngb-datepicker/ngb-datepicker.scss",
  ],
})
export class ConclusaoPopupComponent implements OnInit {
  [x: string]: any;

  isRTL: boolean;

  @BlockUI("elementBody") blockUIElementBody: NgBlockUI;
  @BlockUI() blockUIPage: NgBlockUI;
  @BlockUI("element") blockUIElement: NgBlockUI;

  //#endregion

  //#region CONSTRUCTOR
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public translateService: TranslateService,
    private appService: AppService,
  ) {
    this.isRTL = appService.isRTL;
  }

  textoPrincipal: any = '';
  textoAuxiliar: any = '';
  idCompromisso: number = 0;

  ngOnInit(): void {

  }

  async loadAsyncSession() {
    try {
      this.blockUIElementBody.start();

    } catch (error) {
      console.error(error);
    } finally {
      this.blockUIElementBody.stop();
    }
  }

  //#endregion

  delayBlockUI;
  toggleElementBlocking() {
    this.stopToggleElementBlocking();

    this.delayBlockUI = setTimeout(() => {
      this.blockUIElement.start();
    }, 1000);
  }

  stopToggleElementBlocking() {
    if (this.delayBlockUI) {
      clearTimeout(this.delayBlockUI);
      this.blockUIElement.stop();
      this.delayBlockUI = null;
      return;
    }
  }

  close() {
    // this.router.navigate(['/cadastros/compromisso', this.idCompromisso, 'edit']);
    this.activeModal.close(null)
    window.location.reload();
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
} from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { ApiService } from "src/app/service/api.service";
import { Dicionario } from "src/app/model/dicionario";
import { Login } from "src/app/model/login";
import { AppService } from "src/app/app.service";
import { Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { ClipboardService } from "ngx-clipboard";
import { InclusaoVidasArquivosPopup } from "../inclusao-vidas-arquivos-popup/inclusao-vidas-arquivos-popup.component";
// import { InclusaoVidasPopup } from "../inclusao-vidas-popup/inclusao-vidas-popup.component";

@Component({
  selector: "inclusao-vidas-selecao-popup",
  templateUrl: "./inclusao-vidas-selecao-popup.component.html",
  styleUrls: [
    "../../../vendor/libs/angular2-ladda/angular2-ladda.scss",
    "../../../vendor/libs/ng-select/ng-select.scss",
    "./inclusao-vidas-selecao-popup.component.scss",
    "../../../vendor/libs/spinkit/spinkit.scss",
    "../../../vendor/libs/ngb-datepicker/ngb-datepicker.scss",
  ],
})
export class InclusaoVidasSelecaoPopup implements OnInit {
  isRTL: boolean;

  form_inclusao_vida: any;
  loading = [false];
  submitted = false;
  disabled = false;
  numberOfItemsFromEndBeforeFetchingMore = 5;

  typeaheadConta = new EventEmitter<string>();
  typeaheadEmpresa = new EventEmitter<string>();
  existente: boolean = false;

  idCompromisso: any;
  idCliente: any;

  state: any = {
    list: null,
    paging: null,
    is404: [false],
  };

  @BlockUI("elementBody") blockUIElementBody: NgBlockUI;
  @BlockUI() blockUIPage: NgBlockUI;
  @BlockUI("element") blockUIElement: NgBlockUI;
  @Input() atividadeView: any;

  public form: FormGroup;

  get f() {
    return this.form.controls;
  }

  //#endregion

  //#region CONSTRUCTOR
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public translateService: TranslateService,
    private appService: AppService,
    private apiService: ApiService,
    private fb: FormBuilder
  ) {
    this.isRTL = appService.isRTL;
  }

  ngOnInit(): void {
    this.apiService.getUser().then((res: Login) => {
      setTimeout(() => { }, 200);
    });

    // this.form.patchValue(this.form_inclusao_vida);

    this.form = this.fb.group({
      produto_melhorai: [null],
      produto_agiben: [null],
      vida_tipo: [null],
      vida_empresa: [null],
      vida_nome: [null],
      vida_cpf: [null],
      vida_nomemae: [null],
      vida_nascimento: [null],
      vida_sexo: [null],
      vida_matricula: [null],
      vida_telefone: [null],
      vida_email: [null],
      cpf_titular: [null],
      vida_idcompromisso: [null],
      cli_enviar_para_s4E: [null],
      cli_cod_empresa_s4E: [null],
      cco_id_produto_s4: [null, [Validators.required]],
      cli_grauparentesco: [null],
      fk_idempresa: [null],
      fk_idtitular: [null],
      cli_codigoassociados4e: [null],
      cco_valor_produto: [null]
    });
  }

  //#endregion

  delayBlockUI;
  toggleElementBlocking() {
    this.stopToggleElementBlocking();

    this.delayBlockUI = setTimeout(() => {
      this.blockUIElement.start();
    }, 1000);
  }

  stopToggleElementBlocking() {
    if (this.delayBlockUI) {
      clearTimeout(this.delayBlockUI);
      this.blockUIElement.stop();
      this.delayBlockUI = null;
      return;
    }
  }

  //#region CONTAS
  async getContas(term: string = null, offset: number = 0) {
    try {
      this.state.is404[3] = false;
      this.loading[3] = true;

      let params = {
        searchQuery: term,
        status: "A",
        sortBy: "CON_Nome",
        offset,
      };

      let data = await this.apiService.getContas(params);

      this.state.pagingContas = data.paging;

      this.loading[3] = false;

      if (offset == 0) {
        this.state.contas = data;
      } else {
        // Add more row in list
        this.state.contas = this.state.contas.concat(data);
      }
    } catch (error) {
      this.state.contas = null;

      this.loading[3] = false;

      if (error && error.status != 401 && error.status != 404) {
        this.apiService.errorMessageShow(error);
      }

      // Nenhum registro foi cadastrado
      this.state.is404[3] = error.status == 404;
    }
  }

  async onClearConta() {
    this.state.conta = null;

    // reset
    this.form.patchValue({
      conta: null,
    });

    this.getContas();
  }

  async onClickConta() {
    this.getContas();
  }

  async onChangeConta(event) {
    if (!event) {
      return;
    }
  }

  // https://stackblitz.com/run?file=src%2Fvirtual-scroll-example.component.ts
  onScrollConta({ end }, searchTerm) {
    if (
      this.state.contas &&
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.state.contas.length
    ) {
      this.onScrollToEndConta(searchTerm);
    }
  }

  // https://stackblitz.com/edit/ng-select-infinite
  // https://github.com/ng-select/ng-select/issues/683
  // https://github.com/ng-select/ng-select/issues/1596#issuecomment-619442786
  async onScrollToEndConta(searchTerm) {
    if (
      this.loading[3] ||
      !this.state.contas ||
      !this.state.pagingContas ||
      this.state.contas.length >= this.state.pagingContas.total
    ) {
      return;
    }

    var currentOffset = this.state.contas.length;

    await this.getContas(searchTerm, currentOffset); /**/
  }
  //#endregion

  //#region EMPRESA
  async getEmpresa(term: string = null, offset: number = 0) {
    try {
      this.state.is404[4] = false;
      this.loading[4] = true;

      let params = {
        searchQuery: term,
        status: "A",
        sortBy: "emp_nomefantasia",
        offset,
      };

      let data = await this.apiService.getEmpresas(params);

      this.state.pagingEmpresa = data.paging;

      this.loading[4] = false;

      if (offset == 0) {
        this.state.empresa = data;
      } else {
        // Add more row in list
        this.state.empresa = this.state.empresa.concat(data);
      }
    } catch (error) {
      this.state.empresa = null;

      this.loading[4] = false;

      if (error && error.status != 401 && error.status != 404) {
        this.apiService.errorMessageShow(error);
      }

      // Nenhum registro foi cadastrado
      this.state.is404[4] = error.status == 404;
    }
  }

  async onClearEmpresa() {
    this.state.c = null;

    // reset
    this.form.patchValue({
      conta: null,
    });

    this.getEmpresa();
  }

  async onClickEmpresa() {
    this.getEmpresa();
  }

  async onChangeEmpresa(event) {
    if (!event) {
      return;
    }
  }

  // https://stackblitz.com/run?file=src%2Fvirtual-scroll-example.component.ts
  onScrollEmpresa({ end }, searchTerm) {
    if (
      this.state.empresa &&
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.state.contas.length
    ) {
      this.onScrollToEndEmpresa(searchTerm);
    }
  }

  // https://stackblitz.com/edit/ng-select-infinite
  // https://github.com/ng-select/ng-select/issues/683
  // https://github.com/ng-select/ng-select/issues/1596#issuecomment-619442786
  async onScrollToEndEmpresa(searchTerm) {
    if (
      this.loading[3] ||
      !this.state.empresa ||
      !this.state.pagingEmpresa ||
      this.state.empresa.length >= this.state.pagingEmpresa.total
    ) {
      return;
    }

    var currentOffset = this.state.contas.length;

    await this.getEmpresa(searchTerm, currentOffset); /**/
  }
  //#endregion

  async onChangeDdATipotividade(event) {
    if (!event) {
      return;
    }
  }

  async onSubmit(enviarParaS4e) {
    try {

      let valorPlano = 0;
      this.loading[0] = true;

      let o = this.state.list_produtos.find(x => x.idPlano == this.f.cco_id_produto_s4.value)
      //state.list_produtos
      if (this.f.cco_id_produto_s4.value) {
        if (this.form_inclusao_vida.cli_grauparentesco.value === '1') {
          valorPlano = this.state.list_produtos.find(x => x.idPlano == this.f.cco_id_produto_s4.value).valorTitular
        } else {
          valorPlano = this.state.list_produtos.find(x => x.idPlano == this.f.cco_id_produto_s4.value).valorDependente
        }
      }


      this.form.patchValue({
        vida_tipo: this.form_inclusao_vida.vida_tipo.value,
        vida_empresa: this.form_inclusao_vida.vida_empresa.value,
        vida_nome: this.form_inclusao_vida.vida_nome.value,
        vida_cpf: this.form_inclusao_vida.vida_cpf.value,
        vida_nomemae: this.form_inclusao_vida.vida_nomemae.value,
        vida_nascimento: this.form_inclusao_vida.vida_nascimento.value,
        vida_matricula: this.form_inclusao_vida.vida_matricula.value === '' ? null : this.form_inclusao_vida.vida_matricula.value,
        vida_telefone: this.form_inclusao_vida.vida_telefone.value,
        vida_email: this.form_inclusao_vida.vida_email.value === '' ? null : this.form_inclusao_vida.vida_email.value,
        cpf_titular: this.form_inclusao_vida.cpf_titular.value,
        vida_sexo: this.form_inclusao_vida.vida_sexo.value,
        vida_idcompromisso: this.form_inclusao_vida.vida_idcompromisso.value,
        fk_idempresa: this.state.empresa.empresa.pk_idempresa,
        cli_enviar_para_s4E: enviarParaS4e,
        cli_cod_empresa_s4E: this.state?.empresa?.empresa?.emp_codempresa_s4,
        fk_idtitular: this.form_inclusao_vida?.fk_idtitular.value,
        cli_grauparentesco: this.form_inclusao_vida.cli_grauparentesco.value,
        cli_codigoassociados4e: this.form_inclusao_vida.cli_codigoassociados4e.value,
        cco_valor_produto: valorPlano
      });


      // stop here if form is invalid
      if (this.form.invalid) {
        return;
      }

      const create = await this.apiService.inserirVidaNest(this.f);

      if (!create) return;

      let idCliente = create.cliente.pk_idcliente;

      if (enviarParaS4e) {
        this.apiService.showToast(
          null,
          "Tudo certo! Dados sincronizados com sucesso.",
          "warning"
        );
      } else {
        this.apiService.showToast(
          null,
          "Tudo bem! Os dados ficarão armazenados em nosso banco.",
          "warning"
        );
      }
      // close this popup and pass result value

      let ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        windowClass: "modal-md animate nested-modal"
      };

      // let modal = this.modalService.open(InclusaoVidasArquivosPopup, ngbModalOptions);

      // (<InclusaoVidasArquivosPopup>modal.componentInstance).form_inclusao_vida =
      //   this.form_inclusao_vida;

      // (<InclusaoVidasArquivosPopup>modal.componentInstance).form_produtos =
      //   this.f;

      // (<InclusaoVidasArquivosPopup>modal.componentInstance).id = idCliente;

      this.activeModal.close(this.form.value);
    } catch (error) {
      this.loading[0] = false;
      if (error.status == 404) {
        this.apiService.showToast(
          null,
          "Erro ao realizar procedimento.",
          "error"
        );
      } else if (error.status == 400) {

        this.apiService.showToast(null, error.error.errors ? error.error.errors: error.error.message, "error");

      } else if (error.error.code == 406) {
        this.apiService.showToast(null, "Beneficiário já cadastrado", "error");
      } else if (error.error.code == 407) {
        this.apiService.showToast(null, "Matricula Obrigatória", "error");
      } else {
        this.apiService.errorMessageShow(error.errors);
      }
    } finally {
      this.loading[0] = false;
    }
  }

  async returnButton() {
    // let options = { windowClass: "modal-md animate nested-modal" };
    let options: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass: "modal-md animate nested-modal"
    };

    // let modal = this.modalService.open(InclusaoVidasPopup, options);

    // (<InclusaoVidasPopup>modal.componentInstance).form_inclusao_vida =
    //   this.form_inclusao_vida;

    // (<InclusaoVidasPopup>modal.componentInstance).isReturn = true;

    // (<InclusaoVidasPopup>modal.componentInstance).fk_idempresa =
    //   this.form_inclusao_vida.fk_idempresa;

    // (<InclusaoVidasPopup>modal.componentInstance).compromisso =
    //   this.idCompromisso;

    // (<InclusaoVidasPopup>modal.componentInstance).state.list_produtos =
    //   this.state.list_produtos;

    // (<InclusaoVidasSelecaoPopup>modal.componentInstance).state.empresa =
    //   this.state.empresa;

    this.activeModal.close(this.form.value);
  }
}

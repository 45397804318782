<div class="d-flex flex-column">
	<div class="form-label label">
		<span>
			{{ label }}
		</span>
	</div>
</div>

<div
	[ngClass]="{'flex-row': orientation === 'row', 'flex-column': orientation === 'column'}"
	class="d-flex align-items-center w-100">
	<label class="custom-control custom-radio mt-1 mr-3" *ngFor="let o of radioOptions">
		<input
			(click)="onClick(o)"
			type="radio"
			class="custom-control-input"
			[name]="name"
			[value]="o.value"
			[formControl]="valueFormControl" />
		<span *ngIf="!o.description" class="custom-control-label">{{ o.label }}</span>
		<span *ngIf="o.description" class="custom-control-label font-weight-bold"
			>{{ o.label }}
			<span class="font-weight-regular">{{ o.description }}</span>
		</span>
	</label>
</div>

import {Component} from '@angular/core'
import {
	Router,
	Event as RouterEvent,
	NavigationStart,
	NavigationEnd,
	NavigationCancel,
	NavigationError,
} from '@angular/router'
import {AppService} from './app.service'
import {LayoutService} from './layout/layout.service'
import {TranslateService} from '@ngx-translate/core'
import {MixpanelService} from './service/mixpanel.service'
import {pegarSistemaOperacional} from './utils/util'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styles: [':host { display: block; }'],
})
export class AppComponent {
	constructor(
		private router: Router,
		private appService: AppService,
		private layoutService: LayoutService,
		translate: TranslateService,
		private mixpanelService: MixpanelService,
	) {
		const {client, sistemaOperacional} = pegarSistemaOperacional()
		this.mixpanelService.init()
		this.mixpanelService.setup({cliente: client, sistemaOperacional})

		// Subscribe to router events to handle page transition
		this.router.events.subscribe(this.navigationInterceptor.bind(this))

		// Disable animations and transitions in IE10 to increase performance
		if (typeof (document as any).documentMode === 'number' && (document as any).documentMode < 11) {
			const style = document.createElement('style')
			style.textContent = `
        * {
          -ms-animation: none !important;
          animation: none !important;
          -ms-transition: none !important;
          transition: none !important;
        }`
			document.head.appendChild(style)
		}

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang('pt-BR')

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use('pt-BR')
	}

	private navigationInterceptor(e: RouterEvent) {
		if (e instanceof NavigationStart) {
			// Set loading state
			document.body.classList.add('app-loading')
		}

		if (e instanceof NavigationEnd) {
			// Scroll to top of the page
			this.appService.scrollTop(0, 0)
		}

		if (
			e instanceof NavigationEnd ||
			e instanceof NavigationCancel ||
			e instanceof NavigationError
		) {
			// On small screens collapse sidenav
			if (this.layoutService.isSmallScreen() && !this.layoutService.isCollapsed()) {
				setTimeout(() => this.layoutService.setCollapsed(true, true), 10)
			}

			// Remove loading state
			document.body.classList.remove('app-loading')

			// Remove initial splash screen
			const splashScreen = document.querySelector('.app-splash-screen')
			if (splashScreen) {
				;(splashScreen as any).style.opacity = 0
				setTimeout(
					() =>
						splashScreen &&
						splashScreen.parentNode &&
						splashScreen.parentNode.removeChild(splashScreen),
					300,
				)
			}
		}
	}
}

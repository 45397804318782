import { NumericInputDirective } from './directives/only-number-input-directive'
import { NgModule } from '@angular/core'
// import ngx-translate and the http loader
// https://github.com/ngx-translate/core
//
// Esse resolveu o translate: -> https://medium.com/@lopesgon/translate-angular-4-with-ngx-translate-and-multiple-modules-7d9f0252f139
// https://stackblitz.com/github/ngx-translate/example?file=src%2Fapp%2Fapp.component.ts
import { TranslateModule } from '@ngx-translate/core'

// https://github.com/KingMario/packages/tree/master/projects/ngx-upper-case-directive
// import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
// https://github.com/KingMario/packages/tree/master/projects/ngx-lower-case-directive
// import { NgxLowerCaseDirectiveModule } from 'ngx-lower-case-directive';
// https://github.com/KingMario/packages/tree/master/projects/ngx-trim-directive
// import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { TagInputModule } from 'ngx-chips'
// https://github.com/ng-select/ng-select
import { NgSelectModule } from '@ng-select/ng-select'

import { LaddaModule } from 'angular2-ladda'

import { ReactiveFormsModule, FormsModule } from '@angular/forms'

import { BlockUIModule } from 'ng-block-ui'
import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive'
import { NgxLowerCaseDirectiveModule } from 'ngx-lower-case-directive'
import { NumericDirective } from './directives/numeric-directive'
import { DisableControlDirective } from './directives/disable-control-directive '
import { DateMaskDirective } from './directives/date-mask-directive'
import { TimeMaskDirective } from './directives/time-mask-directive'
import { ProgressPopupComponent } from './others-component/progress-popup/progress-popup.component'
import { AuditViewComponent } from './audit/audit-view/audit-view.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { OnlyNumberDirective } from './directives/only-number-directive'
import { EmailPopupComponent } from './others-component/email-popup/email-popup.component'

import { NgxImageGalleryModule } from 'ngx-image-gallery'
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2'
import { TrackCapsDirective } from './directives/TrackCapsDirective'
import { UsuarioViewComponent } from './others-component/usuario-view-component/usuario-view-component'
import { TimeagoModule } from 'ngx-timeago'
import { UploadPopupComponent } from './others-component/upload-popup/upload-popup.component'
import { DropzoneModule } from 'ngx-dropzone-wrapper'
import { ImageCropperModule } from 'ngx-image-cropper'
import { WebcamModule } from 'ngx-webcam'
import { VisualizarCampanhaPopupComponent } from './others-component/visualizar-campanha-popup/visualizar-campanha-popup.component'
import { TipoAtividadePipe } from './pipes/tipoAtividade.pipe'
import { TipoCargoPipe } from './pipes/tipoCargo.pipe'
import { TipoStatusAtividade } from './pipes/tipoStatusAtividade.pipe'
import { DataIsoToPtbr } from './pipes/dataIsoToPtbr.pipe'
import { NumeroCompromisso } from './pipes/numeroCompromisso.pipe'
import { TipoStatusCompromisso } from './pipes/tipoStatusCompromisso.pipe'
import { InclusaoVidasPopup } from './others-component/inclusao-vidas-popup/inclusao-vidas-popup.component'
import { InclusaoVidasSelecaoPopup } from './others-component/inclusao-vidas-selecao-popup/inclusao-vidas-selecao-popup.component'
import { InclusaoVidasArquivosPopup } from './others-component/inclusao-vidas-arquivos-popup/inclusao-vidas-arquivos-popup.component'
import { PhonePipe } from './pipes/telefoneBr.pipe'
import { SimNaoPipe } from './pipes/booleanoBr.pipe'
import { TipoClientePipe } from './pipes/tipoTitular.pipe'
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { TipoStatusVida } from './pipes/tipoStatusVida.pipe'
import { ConclusaoPopupComponent } from './others-component/conclusao-popup/conclusao-popup.component'
import { NomesMaiusculosPipe } from './pipes/nomesMaiusculos.pipe'
import { ItemAbaAtividadesComponent } from './others-component/abas/item-aba/item-aba-atividades/item-aba-atividades.component'
import { DataPtBrComHoraPipe } from './pipes/dataPtBrComHora.pipe'
import { DefaultTabsModule } from './others-component/tabs/default-tabs/default-tabs.module'
import { ItemAbaHistoricoComponent } from './others-component/abas/item-aba/item-aba-historico/item-aba-historico.component'
import { ItemAbaVisaoGeralComponent } from './others-component/abas/item-aba/item-aba-visao-geral/item-aba-visao-geral.component'
import { ItemAbaDadosDeConversaoComponent } from './others-component/abas/item-aba/item-aba-dados-de-conversao/item-aba-dados-de-conversao.component'
import { ItemAbaPropostaComponent } from './others-component/abas/item-aba/item-aba-proposta/item-aba-proposta.component'
import { ItemAbaContratoComponent } from './others-component/abas/item-aba/item-aba-contrato/item-aba-contrato.component'
import { SelecaoProdutoCardComponent } from './others-component/cards/selecao-produto-card/selecao-produto-card.component'
import { splitVariaveisCamelCasePipe } from './pipes/splitVariaveisCamelCase.pipe'
import { IconFileCardComponent } from './others-component/cards/icon-file-card/icon-file-card.component'
import { TipoStatusPropostaPipe } from './pipes/tipoStatusProposta.pipe'
import { FormatarBytesPipe } from './pipes/formatarBytes.pipe'
import { ListagemHistoricoEmpresaComponent } from './others-component/listagem-historico-empresa/listagem-historico-empresa.component'
import { ListagemDeArquivosEmpresaComponent } from './others-component/listagem-de-arquivos-empresa/listagem-de-arquivos-empresa.component'
import { InformarObservacaoPopupComponent } from './others-component/informar-observacao-popup/informar-observacao-popup.component'
import { AtividadeAdmPopupComponent } from './others-component/popups/atividade-adm-popup/atividade-adm-popup.component'
import { BadgeComponent } from './others-component/badge/badge.component'
import { ProdutosSelecionadosComponent } from './others-component/cards/produtos-selecionados/produtos-selecionados.component'
import { CalendarioMesSemanaDiaComponent } from './others-component/calendario/calendario-mes-semana-dia/calendario-mes-semana-dia.component'
import { CalendarModule } from 'angular-calendar'
import { CalendarioMesSemanaDiaHeaderComponent } from './others-component/calendario/calendario-mes-semana-dia/calendario-mes-semana-dia-header/calendario-mes-semana-dia-header.component'
import { CalendarioViewDiaComponent } from './others-component/calendario/calendario-mes-semana-dia/calendario-view-dia/calendario-view-dia.component'
import { CalendarioViewMesComponent } from './others-component/calendario/calendario-mes-semana-dia/calendario-view-mes/calendario-view-mes.component'
import { CalendarioViewSemanaComponent } from './others-component/calendario/calendario-mes-semana-dia/calendario-view-semana/calendario-view-semana.component'
import { MultiToggleButtonsComponent } from './others-component/multi-toggle-buttons/multi-toggle-buttons.component'
import { DefaultButtonComponent } from './others-component/default-button/default-button.component'
import { ModalWithOneButtonComponent } from './others-component/modal-with-one-button/modal-with-one-button.component'
import { EditCardHeaderComponent } from './others-component/edit-card-header/edit-card-header.component'
import { EditCardComponent } from './others-component/edit-card/edit-card.component'
import { PesquisarComponent } from './others-component/pesquisar/pesquisar.component'
import { SimpleSelectComponent } from './others-component/selects/simple-select/simple-select.component'
import { CheckboxSelectComponent } from './others-component/selects/checkbox-select/checkbox-select.component'
import { InputComponent } from './others-component/input/input.component'
import { RadioGroupComponent } from './others-component/radio-group/radio-group.component'
import { HeaderUserComponent } from './others-component/header-user/header-user.component'
import { AlertCardComponent } from './others-component/alert-card/alert-card.component'
import { CctActStepsBreadcrumbComponent } from './others-component/breadcrumbs/cct-act-steps-breadcrumb/cct-act-steps-breadcrumb.component'
import { AgisalesDatepickerComponent } from './others-component/datepicker/agisales-datepicker/agisales-datepicker.component'
import { AgisalesTextAreaComponent } from './others-component/agisales-text-area/agisales-text-area.component'
import { DefaultSwalModalComponent } from './others-component/default-swal-modal/default-swal-modal.component'
import { RouterModule } from '@angular/router'
import { UserNameCircleComponent } from './others-component/user-name-circle/user-name-circle.component'
import { ArquivosNecessariosComponent } from './others-component/arquivos-necessarios/arquivos-necessarios.component'
import { ModalGenericoComponent } from './others-component/modal-generico/modal-generico.component'
import { CardResumoTaxasComponent } from './others-component/cards/card-resumo-taxas/card-resumo-taxas.component'
import { AcordeonComponent } from './others-component/acordeon/acordeon.component'
import { GenericBannerComponent } from './others-component/generic-banner/generic-banner.component'
import { AgiStepperModule } from './others-component/stepper/agi-stepper.module'
import { DragNDropDirective } from './directives/drag-n-drop.directive'
import { UploadBoxDragNDropComponent } from './others-component/upload-box-drag-n-drop/upload-box-drag-n-drop.component'
import { AgiButtonComponent } from './others-component/buttons/agi-button/agi-button.component'
import { AgibenDefaultBreadcrumbsComponent } from './others-component/breadcrumbs/agiben-default-breadcrumbs/agiben-default-breadcrumb.component'
import { InclusaoVidasContinuaPopupComponent } from './others-component/inclusao-vidas-continua-popup/inclusao-vidas-continua-popup.component'
import { QuillModule } from 'ngx-quill'
import { EditorQuillComponent } from './others-component/editor-quill/editor-quill.component'
import { AgibenBannerAlertComponent } from './others-component/banners/agiben-banner-alert/agiben-banner-alert.component'
import { AgibenKpiCardComponent } from './others-component/cards/card-kpi/agiben-kpi-card/agiben-kpi-card.component'
import { ModalGrupoCctComponent } from './others-component/popups/modal-grupo-cct/modal-grupo-cct.component'

import { AgibenSimpleTooltipComponent } from './others-component/agiben-simple-tooltip/agiben-simple-tooltip.component'
import { RadioGroupV2Component } from './others-component/radio-group-v2/radio-group-v2.component'
import { CheckboxSelectV2Component } from './others-component/selects/checkbox-select-v2/checkbox-select-v2.component'
import { AgisalesInputV2Component } from './others-component/agisales-input-v2/agisales-input-v2.component'
import { SimpleSelectV2Component } from './others-component/selects/simple-select-v2/simple-select-v2.component'
import { AcordeonV2Component } from './others-component/acordeon-v2/acordeon-v2.component'
import { MigracaoPlanoPopupComponent } from './others-component/migracao-plano-popup/migracao-plano-popup.component'
import { ListagemDeArquivosEnvioComponent } from './others-component/listagem-de-arquivos-envio/listagem-de-arquivos-envio.component'
import { ListagemSignatariosComponent } from './others-component/listagem-signatarios/listagem-signatarios.component'
import { StatusContratacaoComponent } from './others-component/status-contratacao/status-contratacao.component'
import { DataPtBrSemHoraPipe } from './pipes/dataPtBrSemHora.pipe'
import { HeaderModalContentComponent } from './others-component/header-modal-title/header-modal-content.component'
import {AgibenPaginacaoTabelaComponent} from './others-component/pagination/agiben-paginacao-tabela/agiben-paginacao-tabela.component'

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null
const SHARED_MODULE = [
	TranslateModule,
	NgbModule,
	CommonModule,
	HttpClientModule,
	NgbModule,
	InfiniteScrollModule,
	FormsModule,
	ReactiveFormsModule,
	// https://github.com/ng-select/ng-select
	NgSelectModule,
	TagInputModule,
	LaddaModule,
	NgxImageGalleryModule,
	SweetAlert2Module,
	BlockUIModule,
	TimeagoModule,
	DropzoneModule,
	ImageCropperModule,
	WebcamModule,
	NgxMaskModule,
	DefaultTabsModule,
	CalendarModule,
	RouterModule,
	AgiStepperModule,
	QuillModule,
]

@NgModule({
	declarations: [
		BadgeComponent,
		AtividadeAdmPopupComponent,
		NumericDirective,
		OnlyNumberDirective,
		NumericInputDirective,
		DisableControlDirective,
		DateMaskDirective,
		TimeMaskDirective,
		ProgressPopupComponent,
		UploadPopupComponent,
		AuditViewComponent, // Add modal component to "declarations"
		EmailPopupComponent, // Add modal component to "declarations"
		VisualizarCampanhaPopupComponent,
		TrackCapsDirective,
		UsuarioViewComponent, // Add modal component to "declarations"
		TipoAtividadePipe,
		TipoCargoPipe,
		TipoStatusAtividade,
		DataIsoToPtbr,
		NumeroCompromisso,
		TipoStatusCompromisso,
		InclusaoVidasPopup,
		ConclusaoPopupComponent,
		InclusaoVidasSelecaoPopup,
		InclusaoVidasArquivosPopup,
		PhonePipe,
		SimNaoPipe,
		TipoClientePipe,
		TipoStatusVida,
		NomesMaiusculosPipe,
		ItemAbaAtividadesComponent,
		DataPtBrComHoraPipe,
		DataPtBrSemHoraPipe,
		ItemAbaHistoricoComponent,
		ItemAbaVisaoGeralComponent,
		ItemAbaDadosDeConversaoComponent,
		ItemAbaPropostaComponent,
		ItemAbaContratoComponent,
		SelecaoProdutoCardComponent,
		ListagemDeArquivosEmpresaComponent,
		StatusContratacaoComponent,
		ListagemDeArquivosEnvioComponent,
		ListagemSignatariosComponent,
		splitVariaveisCamelCasePipe,
		IconFileCardComponent,
		TipoStatusPropostaPipe,
		FormatarBytesPipe,
		ListagemHistoricoEmpresaComponent,
		InformarObservacaoPopupComponent,
		ProdutosSelecionadosComponent,
		CalendarioMesSemanaDiaComponent,
		CalendarioMesSemanaDiaHeaderComponent,
		CalendarioViewDiaComponent,
		CalendarioViewMesComponent,
		CalendarioViewSemanaComponent,
		MultiToggleButtonsComponent,
		DefaultButtonComponent,
		ModalWithOneButtonComponent,
		ModalGrupoCctComponent,
		EditCardHeaderComponent,
		EditCardComponent,
		PesquisarComponent,
		SimpleSelectComponent,
		CheckboxSelectComponent,
		InputComponent,
		RadioGroupComponent,
		HeaderUserComponent,
		AlertCardComponent,
		CctActStepsBreadcrumbComponent,
		AgisalesDatepickerComponent,
		AgisalesTextAreaComponent,
		DefaultSwalModalComponent,
		UserNameCircleComponent,
		ArquivosNecessariosComponent,
		ModalGenericoComponent,
		CardResumoTaxasComponent,
		AcordeonComponent,
		AcordeonV2Component,
		GenericBannerComponent,
		DragNDropDirective,
		UploadBoxDragNDropComponent,
		AgiButtonComponent,
		AgibenDefaultBreadcrumbsComponent,
		InclusaoVidasContinuaPopupComponent,
		EditorQuillComponent,
		AgibenBannerAlertComponent,
		AgibenKpiCardComponent,
		RadioGroupV2Component,
		AgibenSimpleTooltipComponent,
		AgisalesInputV2Component,
		AgibenPaginacaoTabelaComponent,
		CheckboxSelectV2Component,
		SimpleSelectV2Component,
		MigracaoPlanoPopupComponent,
		HeaderModalContentComponent,
	],
	exports: [
		BadgeComponent,
		...SHARED_MODULE,
		// NgxUpperCaseDirectiveModule,
		// NgxLowerCaseDirectiveModule,
		// NgxTrimDirectiveModule,
		TranslateModule,
		NgxUpperCaseDirectiveModule,
		NgxLowerCaseDirectiveModule,
		NumericDirective,
		OnlyNumberDirective,
		NumericInputDirective,
		DisableControlDirective,
		DateMaskDirective,
		TimeMaskDirective,
		TrackCapsDirective,
		ProgressPopupComponent,
		VisualizarCampanhaPopupComponent,
		AuditViewComponent, // Add modal component to "declarations"
		EmailPopupComponent, // Add modal component to "declarations"
		UsuarioViewComponent, // Add modal component to "declarations"
		TipoAtividadePipe,
		TipoCargoPipe,
		TipoStatusAtividade,
		DataIsoToPtbr,
		NumeroCompromisso,
		TipoStatusCompromisso,
		PhonePipe,
		SimNaoPipe,
		TipoClientePipe,
		TipoStatusVida,
		NomesMaiusculosPipe,
		ItemAbaAtividadesComponent,
		DataPtBrComHoraPipe,
		DataPtBrSemHoraPipe,
		ItemAbaHistoricoComponent,
		ItemAbaVisaoGeralComponent,
		ItemAbaDadosDeConversaoComponent,
		ItemAbaPropostaComponent,
		ItemAbaContratoComponent,
		SelecaoProdutoCardComponent,
		StatusContratacaoComponent,
		ListagemDeArquivosEmpresaComponent,
		ListagemDeArquivosEnvioComponent,
		ListagemSignatariosComponent,
		splitVariaveisCamelCasePipe,
		IconFileCardComponent,
		TipoStatusPropostaPipe,
		FormatarBytesPipe,
		ListagemHistoricoEmpresaComponent,
		InformarObservacaoPopupComponent,
		ProdutosSelecionadosComponent,
		CalendarioMesSemanaDiaComponent,
		CalendarioMesSemanaDiaHeaderComponent,
		CalendarioViewDiaComponent,
		CalendarioViewMesComponent,
		CalendarioViewSemanaComponent,
		MultiToggleButtonsComponent,
		EditCardHeaderComponent,
		EditCardComponent,
		PesquisarComponent,
		SimpleSelectComponent,
		CheckboxSelectComponent,
		InputComponent,
		RadioGroupComponent,
		AlertCardComponent,
		DefaultSwalModalComponent,
		HeaderUserComponent,
		AlertCardComponent,
		CctActStepsBreadcrumbComponent,
		DefaultButtonComponent,
		AgisalesDatepickerComponent,
		AgisalesTextAreaComponent,
		UserNameCircleComponent,
		ArquivosNecessariosComponent,
		ModalGenericoComponent,
		CardResumoTaxasComponent,
		AcordeonComponent,
		AcordeonV2Component,
		GenericBannerComponent,
		DragNDropDirective,
		UploadBoxDragNDropComponent,
		AgiButtonComponent,
		AgibenDefaultBreadcrumbsComponent,
		EditorQuillComponent,
		AgibenBannerAlertComponent,
		AgibenKpiCardComponent,
		RadioGroupV2Component,
		AgibenSimpleTooltipComponent,
		AgisalesInputV2Component,
		CheckboxSelectV2Component,
		SimpleSelectV2Component,
		HeaderModalContentComponent,
		AgibenPaginacaoTabelaComponent,
	],
	entryComponents: [
		InclusaoVidasPopup,
		InclusaoVidasSelecaoPopup,
		InclusaoVidasArquivosPopup,
		ConclusaoPopupComponent,
		ModalWithOneButtonComponent,
		ModalGrupoCctComponent,
		BadgeComponent,
		ModalGenericoComponent,
		UploadPopupComponent,
		InclusaoVidasContinuaPopupComponent,
		MigracaoPlanoPopupComponent,
	],
	imports: SHARED_MODULE,
})
export class SharedModule { }

<!-- Element blocking -->
<ng-template #elementBlockTemplate>
  <div class="block-ui-template">
    <div class="sk-wave sk-primary mx-auto">
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
    </div>
  </div>
</ng-template>

<div class="modal-header">
  <button type="button" class="close" (click)="activeModal.close(null)">
    &times;
  </button>
</div>
<div class="card text-center">
  <div class="card-body">
    <div class="mt-2">
      <svg
        width="95"
        height="95"
        viewBox="0 0 127 127"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="63.5"
          cy="63.5"
          r="61.5"
          stroke="#FAAB00"
          stroke-width="4"
        />
        <path
          d="M55.634 75.087 45.309 64.762l-3.442 3.442L55.634 81.97l29.5-29.5-3.442-3.441-26.058 26.058Z"
          fill="#FAAB00"
        />
      </svg>
    </div>
    <div class="mt-4">
      <h2 class="card-text">{{ textoPrincipal }}</h2>
    </div>
    <div *ngIf="textoAuxiliar" class="mt-2">
      <p class="text-muted">{{ textoAuxiliar }}</p>
    </div>
    <div class="mt-4">
      <button class="btn btn-primary" style="width: 40%" (click)="close()"
        >OK</button
      >
    </div>
  </div>
</div>

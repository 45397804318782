import {NgModule} from '@angular/core'
import {Routes, RouterModule} from '@angular/router'
import {NotFoundComponent} from './not-found/not-found.component'

// *******************************************************************************
// Layouts

import {Layout2Component} from './layout/layout-2/layout-2.component'
import {Layout2FlexComponent} from './layout/layout-2-flex/layout-2-flex.component'
import {LayoutBlankComponent} from './layout/layout-blank/layout-blank.component'
import {LayoutBlankTotemComponent} from './layout/layout-blank-totem/layout-blank-totem.component'
import {LandingPageComponent} from './landing-page-cct/landing-page.component'
import {LandingPageUsuarioComponent} from './landing-page-usuario/landing-page-usuario.component'
import {SecRecifeComponent} from './agiben/paf/secrecife/secrecife.component'
import {IndexAgibenComponent} from './agiben/index-agiben/index-agiben.component'
import {BeneficiosAgibenComponent} from './agiben/beneficios-agiben/beneficios-agiben.component'
import {PafGenericoComponent} from './agiben/paf/paf-generico/paf-generico.component'
import {PafSintibrefComponent} from './agiben/paf/paf-sintibref/paf-sintibref.component'
import {ManutencaoComponent} from './manutencao/manutencao.component'
import {RedirecionamentoGrupoCctComponent} from './agiben/redirecionamento-grupo-cct/redirecionamento-grupo-cct.component'

// *******************************************************************************
// Routes

/* tslint:disable */
const routes: Routes = [
	// Default
	//{ path: '', redirectTo: 'search', pathMatch: 'full' },

	// Página principal do site da agiben
	{
		path: '',
		component: IndexAgibenComponent,
	},

	// EXEMPLO DE ROTA PARA REDIRECIONAMENTO DE CCT COM MAIS DE UM PRODUTO (Pós implementação de um caso real remover este exemplo)
	// {
	// 	path: 'paf-group-cct',
	// 	component: RedirecionamentoGrupoCctComponent,
	// 	data: [
	// 		{
	// 			label: 'Sindicato Teste 1',
	// 			link: 'paf-sindicato-1',
	// 		},
	// 		{
	// 			label: 'Sindicato Teste 2',
	// 			link: 'paf-sindicato-2',
	// 		},
	// 	],
	// },

	// Página beneficios do site da agiben
	{
		path: 'beneficios',
		component: BeneficiosAgibenComponent,
	},

	// Search
	{
		path: 'search',
		component: Layout2Component,
		loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
	},

	// People
	{
		path: 'people',
		component: Layout2Component,
		loadChildren: () => import('./people/people.module').then(m => m.PeopleModule),
	},

	// Configuracoes
	{
		path: 'config',
		component: Layout2Component,
		loadChildren: () => import('./config/config.module').then(m => m.ConfigModule),
	},

	// Marketing
	{
		path: 'marketing',
		component: Layout2Component,
		loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule),
	},

	// Leads
	{
		path: 'leads',
		component: Layout2Component,
		loadChildren: () => import('./leads/leads.module').then(m => m.LeadsModule),
	},

	{
		path: 'assinaturas',
		component: Layout2Component,
		loadChildren: () => import('./assinaturas/assinaturas.module').then(m => m.AssinaturasModule),
	},

	// Atividades
	{
		path: 'atividades',
		component: Layout2Component,
		loadChildren: () => import('./atividades/atividades.module').then(m => m.AtividadesModule),
	},

	// Autorizacoes
	{
		path: 'autorizacoes',
		component: Layout2Component,
		loadChildren: () =>
			import('./autorizacoes/autorizacoes.module').then(m => m.AutorizacoesModule),
	},

	// Autorizacoes
	{
		path: 'autorizacoes',
		component: Layout2Component,
		loadChildren: () =>
			import('./autorizacoes/autorizacoes.module').then(m => m.AutorizacoesModule),
	},

	// Despesas
	{
		path: 'despesas',
		component: Layout2Component,
		loadChildren: () => import('./despesas/despesa.module').then(m => m.DespesaModule),
	},

	// Cadastros
	{
		path: 'cadastros',
		component: Layout2Component,
		loadChildren: () => import('./cadastros/cadastros.module').then(m => m.CadastrosModule),
	},

	//  Gestao de carteira
	{
		path: 'gestao-de-carteira',
		component: Layout2Component,
		loadChildren: () =>
			import('./gestao-de-carteira/gestao-de-carteira.module').then(m => m.GestaoDeCarteiraModule),
	},

	// Relatorios
	{
		path: 'reports',
		component: Layout2Component,
		loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
	},

	// pages
	{
		path: 'pages',
		component: Layout2FlexComponent,
		loadChildren: () => import('./+pages/pages.module').then(m => m.PagesModule),
	},

	// Login
	{
		path: 'login',
		component: LayoutBlankComponent,
		loadChildren: () => import('./login/login-blank.module').then(m => m.LoginBlankModule),
	},

	{
		path: 'dashboards',
		component: Layout2Component,
		loadChildren: () => import('./+dashboards/dashboards.module').then(m => m.DashboardsModule),
	},
	{
		path: 'layouts',
		loadChildren: () => import('./+layouts/layouts.module').then(m => m.LayoutsModule),
	},

	// Help
	{
		path: 'help',
		component: Layout2Component,
		loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
	},

	{
		path: 'landing-page-cct/cct/:cct',
		component: LandingPageComponent,
	},

	{
		path: 'landing-page-usuario/cct/:cct/sindicato/:sindicato',
		component: LandingPageUsuarioComponent,
	},

	{
		path: 'redirect/index.html',
		component: LayoutBlankComponent,
		loadChildren: () =>
			import('./agiben/redirecionamento-agiben/redirecionamento-agiben.module').then(
				m => m.RedirecionamentoAgibenModule,
			),
	},

	{
		path: 'cadastro-empresa-sindicato',
		loadChildren: () =>
			import('./agiben/fluxo-cadastro-empresa/fluxo-cadastro-empresa.module').then(
				m => m.FluxoCadastroEmpresaModule,
			),
	},

	{
		path: 'paf-secrecife',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'SINDHORTIFRUTI',
					link: 'PAF-SECRECIFE-SINDHORTIFRUTI',
				},
				{
					label: 'SINDFERRAGENS',
					link: 'PAF-SECRECIFE-SINDFERRAGENS',
				},
				{
					label: 'SINCOPECAS',
					link: 'PAF-SECRECIFE-SINCOPECAS',
				},
				{
					label: 'SINCOMCAPE',
					link: 'PAF-SECRECIFE-SINCOMCAPE',
				},
				{
					label: 'SINDCAMEPE',
					link: 'PAF-SECRECIFE-SINDCAMEPE',
				},
				{
					label: 'SINDAGAPE',
					link: 'PAF-SECRECIFE-SINDAGAPE',
				},
			],
		},
	},

	{
		path: 'paf-seco',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'SINCOPECAS',
					link: 'PAF-SECO-SINCOPECAS',
				},
				{
					label: 'SINDNORTE',
					link: 'PAF-SECO-SINDNORTE',
				},
			],
		},
	},

	{path: 'paf-sintibref', component: PafSintibrefComponent},

	{path: 'manutencao/:paf', component: ManutencaoComponent},

	{
		path: 'paf-comerciarioscamaragibe',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'SINCOPEÇAS-PE',
					link: 'PAF-COMERCIARIOSCAMARAGIBE-SINCOPECAS',
				},
				{
					label: 'SINCOMCAPE',
					link: 'PAF-COMERCIARIOSCAMARAGIBE-SINCOMCAPE',
				},
			],
		},
	},
	{
		path: 'paf-comerciarios-juazeiro',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'Juazeiro',
					link: 'PAF-COMERCIARIOS-JUAZEIRO--SINDILOJASJUAZEIRO',
				},
				{
					label: 'Casa Nova',
					link: 'PAF-COMERCIARIOS-JUAZEIRO-SINDLOJASBA-CASANOVA',
				},
				{
					label: 'Sobradinho',
					link: 'PAF-COMERCIARIOS-JUAZEIRO-SINDLOJASBA-SOBRADINHO',
				},
				{
					label: 'Campo Alegre de Lourdes, Pilão Arcado, Remanso e Sento Sé',
					link: 'PAF-COMERCIARIOS-JUAZEIRO-SINDLOJASBA-CAMPOA-PILAO-REMANSO-SENTOSE',
				},
				{
					label: 'Caém, Caldeirão Grande, Mirangaba, Saúde e Umburanas',
					link: 'PAF-COMERCIARIOS-JUAZEIRO-SINDLOJASBA-CAEM-CALDEIRAO-MIRANGABA-SAUDE-UMUBURANAS',
				},
				{
					label: 'Campo Formoso',
					link: 'PAF-COMERCIARIOS-CAMPOFORMOSO',
				},
				{
					label: 'Andorinha',
					link: 'PAF-COMERCIARIOS-ANDORINHA',
				},
			],
		},
	},
	{
		path: 'paf-comerciarios-vitoria',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'Sincopeças - PE',
					link: 'PAF-COMERCIARIOS-VITORIA-SINCOPECAS',
				},
				{
					label: 'Sincomcape',
					link: 'PAF-COMERCIARIOS-VITORIA-SINCOMCAPE',
				},
				{
					label: 'Sinconferpe',
					link: 'PAF-COMERCIARIOS-VITORIA-SINCONFERPE',
				},
				{
					label: 'Sindagape',
					link: 'PAF-COMERCIARIOS-VITORIA-SINDAGAPE',
				},
			],
		},
	},
	{
		path: 'paf-comerciarios-gravata',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'Sincopeças - PE',
					link: 'PAF-COMERCIARIOS-GRAVATA-SINCOPECAS',
				},
				{
					label: 'Sindagape',
					link: 'PAF-COMERCIARIOS-GRAVATA-SINDAGA',
				},
				{
					label: 'Sincomcape',
					link: 'PAF-COMERCIARIOS-GRAVATA-SINCOMCAPE',
				},
				{
					label: 'Sincomferpe',
					link: 'PAF-COMERCIARIOS-GRAVATA-SINCOMFERPE',
				},
			],
		},
	},
	{
		path: 'paf-comerciarios-sirinhaem',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'Sincopeças',
					link: 'PAF-COMERCIARIOS-SIRINHAEM-SINCOPECAS',
				},
				{
					label: 'Sindagape',
					link: 'PAF-COMERCIARIOS-SIRINHAEM-SINDAGA',
				},
				{
					label: 'Sincomcape',
					link: 'PAF-COMERCIARIOS-SIRINHAEM-SINCOMCAPE',
				},
			],
		},
	},
	{
		path: 'pafcomerciarios-arcoverde',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'Sincopeças',
					link: 'PAFCOMERCIARIOS-ARCOVERDE-SINCOPECAS',
				},
				{
					label: 'Sincomcape',
					link: 'PAFCOMERCIARIOS-ARCOVERDE-SINCOMCAPE',
				},
			],
		},
	},
	{
		path: 'paf-comerciarios-carpinaelimoeiro',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'Sindaga',
					link: 'PAF-COMERCIARIOS-CARPINAELIMOEIRO-SINDAGA',
				},
				{
					label: 'Sincomcape',
					link: 'PAF-COMERCIARIOS-CARPINAELIMOEIRO-SINCOMCAPE',
				},
				{
					label: 'Sincopecas',
					link: 'PAF-COMERCIARIOS-CARPINAELIMOEIRO-SINCOPECAS',
				},
			],
		},
	},
	{
		path: 'paf-comerciarios-petrolina',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'SINCOMFERPE - Ferragens',
					link: 'PAF-COMERCIARIOS-PETROLINA-SINCOFERPE',
				},
				{
					label: 'SINCOMCAPE - Calçados',
					link: 'PAF-COMERCIARIOS-PETROLINA-SINCOMCAPE',
				},
				{
					label: 'SINDLOJAS - Comércio',
					link: 'PAF-COMERCIARIOS-PETROLINA-SINDLOJAS',
				},
				{
					label: 'SINDAGA - Alimentos',
					link: 'PAF-COMERCIARIOS-PETROLINA-SINDAGA',
				},
				{
					label: 'SINCOPEÇAS - Peças',
					link: 'PAF-COMERCIARIOS-PETROLINA-SINCOPECAS',
				},
				{
					label: 'SINDFRUTAS - Frutas',
					link: 'PAF-COMERCIARIOS-PETROLINA-SINDFRUTAS',
				},
			],
		},
	},
	{
		path: 'paf-comerciarios-ipojuca',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'SINCOMCAPE - Calçados',
					link: 'PAF-COMERCIARIOS-IPOJUCA-SINCOMCAPE',
				},
				{
					label: 'SINCOPEÇAS - Peças',
					link: 'PAF-COMERCIARIOS-IPOJUCA-SINCOPECAS',
				},
			],
		},
	},
	{
		path: 'paf-sindecomjg',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'SINDAGA',
					link: 'PAF-SINDECOMJG-SINDAGA',
				},
				{
					label: 'FECOMÉRCIO',
					link: 'PAF-SINDECOMJG-FECOMERCIO',
				},
				{
					label: 'SINDFERRAGENS',
					link: 'PAF-SINDECOMJG-SINDFERRAGENS',
				},
				{
					label: 'SINDLOJASJG',
					link: 'PAF-SINDECOMJG-SINDLOJASJG',
				},
				{
					label: 'SINCOPECAS',
					link: 'PAF-SINDECOMJG-SINCOPECAS',
				},
				{
					label: 'SINCOMCAPE',
					link: 'PAF-SINDECOMJG-SINCOMCAPE',
				},
			],
		},
	},
	{
		path: 'paf-comerciarios-santacruz',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: false,
			rotasCCT: [
				{
					label: 'SINCOMCAPE',
					link: 'PAF-COMERCIARIOS-SANTACRUZ-SINCOMCAPE',
				},
				{
					label: 'SINCOPECAS',
					link: 'PAF-COMERCIARIOS-SANTACRUZ-SINCOPECAS',
				},
				{
					label: 'SINDAGAPE',
					link: 'PAF-COMERCIARIOS-SANTACRUZ-SINDAGAPE',
				},
			],
		},
	},
	{
		path: 'paf-comerciarios-paulista',
		component: RedirecionamentoGrupoCctComponent,
		data: {
			adesaoRepis: true,
			rotasCCT: [
				{
					label: 'SINCOPEÇAS',
					link: 'PAF-COMERCIARIOS-PAULISTA-SINCOPEÇAS',
				},
				{
					label: 'SINDNORTE',
					link: 'PAF-COMERCIARIOS-PAULISTA-SINDNORTE',
				},
			],
		},
	},
	{path: ':paf', component: LandingPageComponent},
]
/* tslint:enable */

// *******************************************************************************
//
// https://stackoverflow.com/questions/50907736/refreshing-the-page-results-in-404-error-angular-6
@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: false})],
	exports: [RouterModule],
})
export class AppRoutingModule {}

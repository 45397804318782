import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {FormControl, FormControlName, FormGroup, FormGroupDirective} from '@angular/forms'

@Component({
	selector: 'agisales-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
	@ViewChild('inputElement') inputElement: ElementRef

	@Input() label?: string
	@Input() placeholder = ''
	@Input() mask = ''
	@Input() description = ''
	@Input() formControlName?: string
	@Input() iconSrc = ''
	@Input() id?: string
	@Input() autofocus = false
	@Input() leadZeroDateTime = false
	@Input() dropSpecialCharacters = true
	@Input() thousandSeparator = ''
	@Input() decimalMarker = ''
	@Input() hasIconSuffix = false
	@Input() flagBootStrap = false
	@Input() preffixLabel = ''

	@Output() OnSearch: EventEmitter<any> = new EventEmitter()
	@Output() OnClear: EventEmitter<any> = new EventEmitter()

	valueFormGroup: FormGroup
	valueFormControl: FormControl

	value: any
	@Input() disabled = false
	onChange: any = () => {}
	onTouched: any = () => {}

	constructor(
		private formGroupDirective: FormGroupDirective,
		private formControlNameDirective: FormControlName,
	) {}

	ngOnInit(): void {
		this.valueFormGroup = this.formGroupDirective.form
		this.valueFormControl = this.formGroupDirective.getControl(this.formControlNameDirective)
	}

	clickClearSearchInput(control: string) {
		this.inputElement.nativeElement.focus()
		this.OnClear.emit()
	}

	clickSearchInput() {
		this.inputElement.nativeElement.focus()
		this.OnSearch.emit()
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataIsoToPtbr',
  pure: true
})


export class DataIsoToPtbr implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if(value)
    value = value.slice(8,10)+"/"+value.slice(5,7)+"/"+value.slice(0,4)+" "+value.slice(11,16)
    return value

  }
}

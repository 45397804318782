import {Injectable} from '@angular/core'
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap'
import {InclusaoVidasPopup} from '../../others-component/inclusao-vidas-popup/inclusao-vidas-popup.component'
import {
	IInclusaoVidaContinuaPopup,
	IInclusaoVidaDependentePopup,
	IInclusaoVidaExistentePopup,
	IInclusaoVidaPopup,
} from '../../model/interfaces/inclusao-vida-popup'
import {OptionsUpload} from '../../model/interfaces/options-upload'
import {UploadPopupComponent} from '../../others-component/upload-popup/upload-popup.component'
import {InclusaoVidasContinuaPopupComponent} from '../../others-component/inclusao-vidas-continua-popup/inclusao-vidas-continua-popup.component'
import {MigracaoPlanoPopupComponent} from 'src/app/others-component/migracao-plano-popup/migracao-plano-popup.component'
import {MigracaoPlanoPopupInfo} from 'src/app/model/interfaces/migracao-plano'

@Injectable({
	providedIn: 'root',
})
export class CustomModalService {
	constructor(private ngbModal: NgbModal) {}

	openInclusaoVidasPopup(
		body: IInclusaoVidaPopup,
		options: NgbModalOptions,
		cbOnClose?: (e: any) => void,
		cbOnDismiss?: (e: any) => void,
	) {
		const {nomeEmpresa, compromisso, listaProdutos, empresa} = body

		const modal = this.ngbModal.open(InclusaoVidasPopup, options)

		modal.componentInstance.nomeEmpresa = nomeEmpresa
		modal.componentInstance.compromisso = compromisso
		modal.componentInstance.state.list_produtos = listaProdutos
		modal.componentInstance.state.empresa = empresa

		modal.result.then(
			result => {
				if (cbOnClose) {
					cbOnClose(result)
				}
			},
			dismiss => {
				if (cbOnDismiss) {
					cbOnDismiss(dismiss)
				}
			},
		)
	}

	openInclusaoDependente(
		body: IInclusaoVidaDependentePopup,
		options: NgbModalOptions,
		cbOnClose?: (e: any) => void,
		cbOnDismiss?: (e: any) => void,
	) {
		const {nomeEmpresa, compromisso, listaProdutos, empresa, infoTitular, tipoVida} = body

		const modal = this.ngbModal.open(InclusaoVidasPopup, options)

		modal.componentInstance.nomeEmpresa = nomeEmpresa
		modal.componentInstance.compromisso = compromisso
		modal.componentInstance.state.list_produtos = listaProdutos
		modal.componentInstance.state.empresa = empresa
		modal.componentInstance.infoTitular = infoTitular
		modal.componentInstance.tipoVida = tipoVida

		modal.result.then(
			result => {
				if (cbOnClose) {
					cbOnClose(result)
				}
			},
			dismiss => {
				if (cbOnDismiss) {
					cbOnDismiss(dismiss)
				}
			},
		)
	}

	openVidaExistentePopup(
		body: IInclusaoVidaExistentePopup,
		options: NgbModalOptions,
		cbOnClose?: (e: any) => void,
		cbOnDismiss?: (e: any) => void,
	) {
		const {nomeEmpresa, compromisso, listaProdutos, empresa, existente, id} = body

		const modal = this.ngbModal.open(InclusaoVidasPopup, options)

		modal.componentInstance.nomeEmpresa = nomeEmpresa
		modal.componentInstance.compromisso = compromisso
		modal.componentInstance.state.list_produtos = listaProdutos
		modal.componentInstance.state.empresa = empresa
		modal.componentInstance.existente = existente
		modal.componentInstance.id = id

		modal.result.then(
			result => {
				if (cbOnClose) {
					cbOnClose(result)
				}
			},
			dismiss => {
				if (cbOnDismiss) {
					cbOnDismiss(dismiss)
				}
			},
		)
	}

	openUploadPopup(
		optionsUpload: OptionsUpload,
		options: NgbModalOptions,
		cbOnClose?: (e: any) => void,
		cbOnDismiss?: (e: any) => void,
	) {
		const modal = this.ngbModal.open(UploadPopupComponent, options)
		modal.componentInstance.setOptionsUpload(optionsUpload)

		modal.result.then(
			result => {
				if (cbOnClose) {
					cbOnClose(result)
				}
			},
			dismiss => {
				if (cbOnDismiss) {
					cbOnDismiss(dismiss)
				}
			},
		)
	}

	openInclusaoVidaContinua(
		body: IInclusaoVidaContinuaPopup,
		options,
		cbOnClose?: (e: any) => void,
		cbOnDismiss?: (e: any) => void,
	) {
		const {
			nomeEmpresa,
			idEmpresa,
			idEmpresaS4E,
			idCompromisso,
			listaProdutos,
			tipoVida,
			exigeMatricula,
			idTitular,
			codAssociadoS4E,
			nomeTitular,
		} = body

		const modal = this.ngbModal.open(InclusaoVidasContinuaPopupComponent, options)

		modal.componentInstance.nomeEmpresa = nomeEmpresa
		modal.componentInstance.idEmpresa = idEmpresa
		modal.componentInstance.idEmpresaS4E = idEmpresaS4E
		modal.componentInstance.idCompromisso = idCompromisso
		modal.componentInstance.listaProdutos = listaProdutos
		modal.componentInstance.tipoVida = tipoVida
		modal.componentInstance.exigeMatricula = exigeMatricula
		modal.componentInstance.idTitular = idTitular
		modal.componentInstance.nomeTitular = nomeTitular
		modal.componentInstance.codAssociadoS4E = codAssociadoS4E

		modal.result.then(
			result => {
				if (cbOnClose) {
					cbOnClose(result)
				}
			},
			dismiss => {
				if (cbOnDismiss) {
					cbOnDismiss(dismiss)
				}
			},
		)
	}

	openMigracaoPlano(
		bodyModal: MigracaoPlanoPopupInfo,
		options: NgbModalOptions,
		cbOnClose?: () => void,
	) {
		const {idEmpresa, idEmpresaS4E, listaProdutos, idCompromisso = null} = bodyModal

		const modal = this.ngbModal.open(MigracaoPlanoPopupComponent, options)

		modal.componentInstance.idEmpresa = idEmpresa
		modal.componentInstance.idEmpresaS4E = idEmpresaS4E
		modal.componentInstance.listaProdutos = listaProdutos
		modal.componentInstance.idCompromisso = idCompromisso

		modal.result.then(
			_ => {
				if (cbOnClose) {
					cbOnClose()
				}
			},
			_ => {
				if (cbOnClose) {
					cbOnClose()
				}
			},
		)
	}
}

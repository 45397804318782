import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core'
import {AgibenButtonStyle} from 'src/app/model/interfaces/aginben-button-style'

@Component({
	selector: 'agi-button',
	templateUrl: './agi-button.component.html',
	styleUrls: ['./agi-button.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AgiButtonComponent implements OnInit {
	@Input() label: string
	@Input() disabled = false
	@Input() type = 'button'
	@Input() loading = false

	@Input() cssClass: AgibenButtonStyle = 'PRIMARY'

	@Output() onClick = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}

	click() {
		if (!this.disabled) {
			this.onClick.emit()
		}
	}
}

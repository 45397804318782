import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
} from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { ApiService } from "src/app/service/api.service";
import { Dicionario } from "src/app/model/dicionario";
import { Login } from "src/app/model/login";
import { AppService } from "src/app/app.service";
import { Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { ClipboardService } from "ngx-clipboard";
import { TypeFile } from "src/app/others-component/upload-popup/type-file";
import { UploadPopupComponent } from "../upload-popup/upload-popup.component";
import { GALLERY_CONF, NgxImageGalleryComponent } from "ngx-image-gallery";
import { ConclusaoPopupComponent } from "../conclusao-popup/conclusao-popup.component";

@Component({
  selector: "inclusao-vidas-selecao-popup",
  templateUrl: "./inclusao-vidas-arquivos-popup.component.html",
  styleUrls: [
    "../../../vendor/libs/angular2-ladda/angular2-ladda.scss",
    "../../../vendor/libs/ng-select/ng-select.scss",
    "./inclusao-vidas-arquivos-popup.component.scss",
    "../../../vendor/libs/spinkit/spinkit.scss",
    "../../../vendor/libs/ngb-datepicker/ngb-datepicker.scss",
  ],
})
export class InclusaoVidasArquivosPopup implements OnInit {
  isRTL: boolean;
  form_inclusao_vida: any;
  form_produtos: any;
  loading = [false];
  submitted = false;
  disabled = false;
  numberOfItemsFromEndBeforeFetchingMore = 5;
  img: string;
  id: number = 0;
  linkGerado;

  typeaheadConta = new EventEmitter<string>();
  typeaheadEmpresa = new EventEmitter<string>();

  state: any = {
    list: null,
    paging: null,
    is404: [false],
  };

  images = [];
  conf: GALLERY_CONF = {
    imageOffset: "0px",
    imageBorderRadius: "0",
    showDeleteControl: false,
    showImageTitle: false,
  };
  @ViewChild("galleryContainer", { static: false }) galleryContainer: any;
  @ViewChild(NgxImageGalleryComponent, { static: false })
  gallery: NgxImageGalleryComponent;

  @BlockUI("elementBody") blockUIElementBody: NgBlockUI;
  @BlockUI() blockUIPage: NgBlockUI;
  @BlockUI("element") blockUIElement: NgBlockUI;
  @Input() atividadeView: any;

  public form: FormGroup;

  get f() {
    return this.form.controls;
  }

  //#endregion

  //#region CONSTRUCTOR
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public translateService: TranslateService,
    private appService: AppService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    private sanitizer: DomSanitizer,
    private _clipboardService: ClipboardService,
    private toastr: ToastrService
  ) {
    this.isRTL = appService.isRTL;
  }

  ngOnInit(): void {
    this.apiService.getUser().then((res: Login) => {
      setTimeout(() => { }, 200);
    });

    this.form = this.fb.group({
      arquivos: [null, [Validators.required]],
    });
  }

  //#endregion

  delayBlockUI;
  toggleElementBlocking() {
    this.stopToggleElementBlocking();

    this.delayBlockUI = setTimeout(() => {
      this.blockUIElement.start();
    }, 1000);
  }

  stopToggleElementBlocking() {
    if (this.delayBlockUI) {
      clearTimeout(this.delayBlockUI);
      this.blockUIElement.stop();
      this.delayBlockUI = null;
      return;
    }
  }

  //#region CONTAS
  async getContas(term: string = null, offset: number = 0) {
    try {
      this.state.is404[3] = false;
      this.loading[3] = true;

      let params = {
        searchQuery: term,
        status: "A",
        sortBy: "CON_Nome",
        offset,
      };

      let data = await this.apiService.getContas(params);

      this.state.pagingContas = data.paging;

      this.loading[3] = false;

      if (offset == 0) {
        this.state.contas = data;
      } else {
        // Add more row in list
        this.state.contas = this.state.contas.concat(data);
      }
    } catch (error) {
      this.state.contas = null;

      this.loading[3] = false;

      if (error && error.status != 401 && error.status != 404) {
        this.apiService.errorMessageShow(error);
      }

      // Nenhum registro foi cadastrado
      this.state.is404[3] = error.status == 404;
    }
  }

  async onClearConta() {
    this.state.conta = null;

    // reset
    this.form.patchValue({
      conta: null,
    });

    this.getContas();
  }

  async onClickConta() {
    this.getContas();
  }

  async onChangeConta(event) {
    if (!event) {
      return;
    }
  }

  // https://stackblitz.com/run?file=src%2Fvirtual-scroll-example.component.ts
  onScrollConta({ end }, searchTerm) {
    if (
      this.state.contas &&
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.state.contas.length
    ) {
      this.onScrollToEndConta(searchTerm);
    }
  }

  // https://stackblitz.com/edit/ng-select-infinite
  // https://github.com/ng-select/ng-select/issues/683
  // https://github.com/ng-select/ng-select/issues/1596#issuecomment-619442786
  async onScrollToEndConta(searchTerm) {
    if (
      this.loading[3] ||
      !this.state.contas ||
      !this.state.pagingContas ||
      this.state.contas.length >= this.state.pagingContas.total
    ) {
      return;
    }

    var currentOffset = this.state.contas.length;

    await this.getContas(searchTerm, currentOffset); /**/
  }
  //#endregion

  //#region EMPRESA
  async getEmpresa(term: string = null, offset: number = 0) {
    try {
      this.state.is404[4] = false;
      this.loading[4] = true;

      let params = {
        searchQuery: term,
        status: "A",
        sortBy: "emp_nomefantasia",
        offset,
      };

      let data = await this.apiService.getEmpresas(params);

      this.state.pagingEmpresa = data.paging;

      this.loading[4] = false;

      if (offset == 0) {
        this.state.empresa = data;
      } else {
        // Add more row in list
        this.state.empresa = this.state.empresa.concat(data);
      }
    } catch (error) {
      this.state.empresa = null;

      this.loading[4] = false;

      if (error && error.status != 401 && error.status != 404) {
        this.apiService.errorMessageShow(error);
      }

      // Nenhum registro foi cadastrado
      this.state.is404[4] = error.status == 404;
    }
  }

  async onClearEmpresa() {
    this.state.c = null;

    // reset
    this.form.patchValue({
      conta: null,
    });

    this.getEmpresa();
  }

  async onClickEmpresa() {
    this.getEmpresa();
  }

  async onChangeEmpresa(event) {
    if (!event) {
      return;
    }
  }

  // https://stackblitz.com/run?file=src%2Fvirtual-scroll-example.component.ts
  onScrollEmpresa({ end }, searchTerm) {
    if (
      this.state.empresa &&
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.state.contas.length
    ) {
      this.onScrollToEndEmpresa(searchTerm);
    }
  }

  // https://stackblitz.com/edit/ng-select-infinite
  // https://github.com/ng-select/ng-select/issues/683
  // https://github.com/ng-select/ng-select/issues/1596#issuecomment-619442786
  async onScrollToEndEmpresa(searchTerm) {
    if (
      this.loading[3] ||
      !this.state.empresa ||
      !this.state.pagingEmpresa ||
      this.state.empresa.length >= this.state.pagingEmpresa.total
    ) {
      return;
    }

    var currentOffset = this.state.contas.length;

    await this.getEmpresa(searchTerm, currentOffset); /**/
  }
  //#endregion

  async onChangeDdATipotividade(event) {
    if (!event) {
      return;
    }
  }

  async onSubmit() {
    try {
      let options = { windowClass: "modal-md animate nested-modal" };

      let modal = this.modalService.open(ConclusaoPopupComponent, options);

      (<ConclusaoPopupComponent>modal.componentInstance).textoPrincipal =
        "Vida inclusa com sucesso!";
      (<ConclusaoPopupComponent>modal.componentInstance).idCompromisso = this.form_inclusao_vida.vida_idcompromisso.value;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading[0] = false;
      this.activeModal.close(null);
    }
  }
  openWebcam() {
    let optionsUpload: any = {
      // Definindo a pasta e nome do arquivo para upload
      folders: "clientes/fichas",
      fileName: `${this.id}`,
      // Definindo tipo do arquivo
      typeFile: TypeFile.ALL,
      // Definindo a quantidade de arquivos para upload
      maxFiles: 1,
      // Tamanho máximo do arquivo em MB
      maxFilesize: 30,
      isCroppedImage: true,
      maintainAspectRatio: false,
      // aspectRatio: 4 / 4,
      showWebcam: true,
    };
    this.openUploadPopup(optionsUpload);
  }

  openUploadPopup(optionsUpload) {
    let options = { windowClass: "modal-lg animate" };

    let modal = this.modalService.open(UploadPopupComponent, options); // Render modal with modal component

    // https://stackoverflow.com/questions/42287304/pass-variable-to-custom-component
    (<UploadPopupComponent>modal.componentInstance).setOptionsUpload(
      optionsUpload
    );

    modal.result.then(
      (result) => {
        let paths3 = `/${optionsUpload.folders}/${optionsUpload.fileName}`;
        this.linkGerado = this.apiService.getUrlImage(paths3, true);
        const create = this.apiService.updateLinkVidaPorIdNest(
          this.linkGerado,
          this.id
        );
      },
      (reason) => {
        let paths3 = `/${optionsUpload.folders}/${optionsUpload.fileName}`;
        this.linkGerado = this.apiService.getUrlImage(paths3, true);
        const create = this.apiService.updateLinkVidaPorIdNest(
          this.linkGerado,
          this.id
        );
      }
    );
  }
  openDragdrop() {
    let optionsUpload: any = {
      // Definindo a pasta e nome do arquivo para upload
      folders: "clientes/fichas",
      fileName: `${this.id}`,
      // Definindo tipo do arquivo
      typeFile: TypeFile.ALL,
      // Definindo a quantidade de arquivos para upload
      maxFiles: 1,
      // Tamanho máximo do arquivo em MB
      maxFilesize: 30,
      isCroppedImage: true,
      maintainAspectRatio: false,
      // aspectRatio: 4 / 4,
      showDragdrop: true,
    };

    this.openUploadPopup(optionsUpload);
  }
}

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Dicionario } from 'src/app/model/dicionario';
import { ApiService } from 'src/app/service/api.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Login } from 'src/app/model/login';
import { AppService } from 'src/app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from 'src/app/service/export.service';
import { ProgressPopupComponent } from 'src/app/others-component/progress-popup/progress-popup.component';

@Component({
  selector: 'app-audit-view',
  templateUrl: './audit-view.component.html',
  styles: [
  ]
})
export class AuditViewComponent implements OnInit {

  //#region  VARIABLE
  isRTL: boolean;

  public form: FormGroup;
  private user: Login;
  canceled: boolean = false;
  loading = [false];
  isLoading: boolean = false;  
  progressComponent: any;

  public state: any = {
    nome: null,
    list: null,
    paging: null,
    is404: false
  }

  get f() {
    return this.form.controls;
  }

  //#endregion

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, public translateService: TranslateService, 
    private appService: AppService, private fb: FormBuilder, public apiService: ApiService, private exportService: ExportService) {
    this.isRTL = appService.isRTL;

    this.apiService.getUser().then((res: Login) => {
      this.user = res;
    });

    // https://angular.io/api/forms/FormGroup
    this.form = this.fb.group({

      fk_iddicionariotabela: [null],
      aud_idtabela: [null],
      sortBy: ['aud_datahora'],
      sortDesc: [true],
      offset: [0]

    });

  }

  ngOnInit(): void {

  }

  setSort(key) {

    if (this.form.value.sortBy !== key) {

      this.form.patchValue({
        sortBy: key,
        sortDesc: false
      });

    } else {
      this.form.patchValue({
        sortDesc: !this.form.value.sortDesc
      });
    }

    this.onSubmit();

  }

  async onSubmit(offset: number = 0) {

    try {

      this.state.is404 = false;
      this.loading[0] = true;

      this.form.patchValue({
        offset: offset
      });

      if (offset == 0) {
        this.clearList();
      }

      let data = await this.apiService.getAuditoriaPorDicionarioEId(this.form.value);

      this.loading[0] = false;

      

      this.state.paging = {
        total: data[0].full_count
      }

      if (offset == 0) {
        this.state.list = data
      } else {
        // Add more row in list
        Array.prototype.push.apply(this.state.list, data);
      }

    } catch (error) {

      this.loading[0] = false;

      if (error && error.status != 401 && error.status != 404) {
        this.apiService.errorMessageShow(error);
      }

      this.state.is404 = error.status == 404;

    }

  }

  async onScroll() {

    if (this.isLoading || !this.state.list || !this.state.paging || this.state.list.length >= this.state.paging.total) {
      return;
    }

    this.isLoading = true;

    console.log("onScroll...");

    var currentOffset = this.state.list.length;

    await this.onSubmit(currentOffset);/**/

    this.isLoading = false;

  }

  clearList() {

    this.state.list = null;
    this.state.paging = null;

  }

  public setDicionario(nome: string, fk_iddicionariotabela: number, aud_idtabela: number): void {

    this.state.nome = nome;

    this.form.patchValue({
      fk_iddicionariotabela: fk_iddicionariotabela,
      aud_idtabela: aud_idtabela
    })

    this.onSubmit();

  }

  //#region EXPORT

  async loadExport() {

    this.exportService.clear();

    let filters = this.getAllFilters();

    this.exportService.addFilters(filters);

    // Table
    var table = await this.getAllRecord();

    this.exportService.addTable(table);

  }

  async exportPdf() {

    try {

      this.openProgressPopup();

      this.progressComponent.progressSubTitulo = this.translateService.instant('relatorio.pdf');

      await this.loadExport();

      if (!this.canceled) {

        this.exportService.exportPdf(
          "portrait",
          this.translateService.instant('auditoria.relatorio.titulo'),
          this.user.nomeCliente,
          this.translateService.instant('auditoria.relatorio.name_pdf')
        );

      }

    } catch (error) {
      console.error(error);
      this.progressComponent.close();
    }

  }
 
  async openProgressPopup() {

    let options = { windowClass: 'modal-lg nested-modal' };

    let modal = this.modalService.open(ProgressPopupComponent, options);

    modal.result.then((result) => {

    }, (reason) => {
      // modal foi cancelado/fechado
      console.log(`Dismissed ${reason}`);
      this.canceled = true;

    });

    this.progressComponent = modal.componentInstance;

  }

  async getAllRecord(): Promise<any> {

    this.canceled = false;

    this.progressComponent.progressTitulo = this.translateService.instant('relatorio.relatorio');

    // number total of tuple at database
    let totalRecord: number = 0;

    // Table list rows
    let tableRows = [];

    // get filters selected by user
    let formFilters = this.form.value;

    // set first row tuple
    formFilters.offset = 0;

    while (!this.canceled && (totalRecord == 0 || tableRows.length < totalRecord)) {

      // call api
      let data = await this.apiService.getAuditoriaPorDicionarioEId(formFilters);

      // get number of tuple
      totalRecord = data.paging.total;

      // get rows
      let list = data

      list.forEach(row => {

        let dataRow = [
          row.con_nome,
          row.con_email,
          row.gru_nome,
          row.aud_alteracao,
          this.apiService.dateIsoToString(row.aud_datahora, 'short')
        ];

        tableRows.push(dataRow);

      });

      // set next tuple
      formFilters.offset = tableRows.length;

      // set progress status
      this.progressComponent.progressBarValue = (formFilters.offset / totalRecord) * 100;

    }

    // Columns selected
    var tableColumns = [
      this.translateService.instant('auditoria.list.operador'),
      this.translateService.instant('auditoria.list.email'),
      this.translateService.instant('auditoria.list.grupo'),
      this.translateService.instant('auditoria.list.tipo_operacao'),
      this.translateService.instant('auditoria.list.data')
    ]

    const table = {
      title: this.translateService.instant('auditoria.view.sub_titulo'),
      columns: tableColumns,
      rows: tableRows,
      widths: ['25%', '30%', '15%', '15%', '15%']
    }

    this.progressComponent.closeDelay();

    return table;

  }
  
  getAllFilters(): any {

    var filterHeaders = [];

    filterHeaders.push(this.translateService.instant('filtro.pesquisar_por'));
    filterHeaders.push(this.translateService.instant('auditoria.list.id'));


    return {
      columns: filterHeaders,
      rows: [this.state.nome, this.form.value.aud_idtabela],
      countColumns: 2,
      title: this.translateService.instant('filtro.titulo')
    }

  }


  //#endregion

  getActionCrud(o) {
    let ret

    let crud = o.aud_operacao

    switch (crud) {
      case 'C':
        ret = 'Cadastrado'
        break;
      case 'U':
        ret = 'Alterado'
        break;
      case 'D':
        ret = 'Inativado'
        break;
      default:
        break;
    }

    return ret

  }

}

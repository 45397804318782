import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {environment} from 'src/environments/environment'

@Component({
	selector: 'app-beneficios-agiben',
	templateUrl: './beneficios-agiben.component.html',
	styleUrls: ['./beneficios-agiben.component.scss'],
})
export class BeneficiosAgibenComponent implements OnInit {
	public linkDocumentoOdontologia = `${environment.API_URL_NEST}site/pdf?arquivo=Plano odontológico (Clin).pdf`
	public linkDocumentoMedicina = `${environment.API_URL_NEST}site/pdf?arquivo=Telemedicina (Melhor.ai).pdf`
	public linkDocumentoPsicologia = `${environment.API_URL_NEST}site/pdf?arquivo=Atendimento psicológico (Melhor.ai).pdf`
	public linkDocumentoSeguros = `${environment.API_URL_NEST}site/pdf?arquivo=Seguros (Allianz).pdf`

	isExpanded3 = false

	constructor(
		private modalService: NgbModal,
		private router: Router,
		private route: ActivatedRoute,
	) {}

	ngOnInit(): void {
		this.route.fragment.subscribe(fragment => {
			document.getElementById(fragment)?.scrollIntoView({behavior: 'smooth', block: 'start'})
		})
	}

	open(content, options = {}) {
		this.modalService.open(content, options).result.then(
			result => {
				console.log(`Closed with: ${result}`)
			},
			reason => {
				console.log(`Dismissed ${this.getDismissReason(reason)}`)
			},
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop'
		} else {
			return `with: ${reason}`
		}
	}

	scrollToSection(nomeDiv: any, page: any) {
		if (page === 'Home') {
			nomeDiv ? this.router.navigate(['/'], {fragment: nomeDiv}) : this.router.navigate(['/'])
		} else if (page === 'Beneficios') {
			document.getElementById(nomeDiv)?.scrollIntoView({behavior: 'smooth', block: 'start'})
		}
	}
}

export enum MixPanelEvents {
	LP_SINDICATO_CLIQUE_CADASTRAR_MINHA_EMPRESA = 'LP_SINDICATO_CLIQUE_CADASTRAR_MINHA_EMPRESA',
	LP_SINDICATO_CLIQUE_ABA_BENEFICIO_TOPICO = 'LP_SINDICATO_CLIQUE_ABA_BENEFICIO_TOPICO',
	LP_SINDICATO_CLIQUE_BAIXAR_CCT = 'LP_SINDICATO_CLIQUE_BAIXAR_CCT',
	LP_SINDICATO_CLIQUE_FALAR_COM_ESPECIALISTA = 'LP_SINDICATO_CLIQUE_FALAR_COM_ESPECIALISTA',
	LP_SINDICATO_CLIQUE_ABRIR_SITE = 'LP_SINDICATO_CLIQUE_ABRIR_SITE',
	LP_SINDICATO_CLIQUE_ABRIR_INSTAGRAM = 'LP_SINDICATO_CLIQUE_ABRIR_INSTAGRAM',
	LP_SINDICATO_CLIQUE_ABRIR_MODAL_SELECIONAR_SINDICATO = 'LP_SINDICATO_CLIQUE_ABRIR_MODAL_SELECIONAR_SINDICATO',
	LP_SINDICATO_MODAL_SELECAO_SINDICATOS_CLIQUE_SELECIONAR_SINDICATO = 'LP_SINDICATO_MODAL_SELECAO_SINDICATOS_CLIQUE_SELECIONAR_SINDICATO',
	LP_SINDICATO_CLIQUE_NAVBAR_OPCAO = 'LP_SINDICATO_CLIQUE_NAVBAR_OPCAO',

	LP_COLABORADOR_CLIQUE_FALAR_COM_ESPECIALISTA = 'LP_COLABORADOR_CLIQUE_FALAR_COM_ESPECIALISTA',
	LP_COLABORADOR_CLIQUE_ABRIR_SITE = 'LP_COLABORADOR_CLIQUE_ABRIR_SITE',
	LP_COLABORADOR_CLIQUE_ABRIR_INSTAGRAM = 'LP_COLABORADOR_CLIQUE_ABRIR_INSTAGRAM',
	LP_COLABORADOR_CLIQUE_ABA_BENEFICIO_TOPICO = 'LP_COLABORADOR_CLIQUE_ABA_BENEFICIO_TOPICO',
	LP_COLABORADOR_CLIQUE_QUERO_CONHECER = 'LP_COLABORADOR_CLIQUE_QUERO_CONHECER',
	LP_COLABORADOR_CLIQUE_BAIXAR_APP = 'LP_COLABORADOR_CLIQUE_BAIXAR_APP',
	LP_COLABORADOR_CLIQUE_LOJA = 'LP_COLABORADOR_CLIQUE_LOJA',
	LP_COLABORADOR_CLIQUE_NAVBAR_OPCAO = 'LP_COLABORADOR_CLIQUE_NAVBAR_OPCAO',

	GESTAO_CARTEIRA_CLIQUE_ABA_CARTEIRA_USUARIO = 'GESTAO_CARTEIRA_CLIQUE_ABA_CARTEIRA_USUARIO',
	GESTAO_CARTEIRA_USUARIO_CLIQUE_PESQUISAR_USUARIOS = 'GESTAO_CARTEIRA_USUARIO_CLIQUE_PESQUISAR_USUARIOS',
	GESTAO_CARTEIRA_USUARIO_CLIQUE_NO_USUARIO = 'GESTAO_CARTEIRA_USUARIO_CLIQUE_NO_USUARIO',
	GESTAO_CARTEIRA_USUARIO_CLIQUE_BREADCUMB_GESTAO_CARTEIRA = 'GESTAO_CARTEIRA_USUARIO_CLIQUE_BREADCUMB_GESTAO_CARTEIRA',
	GESTAO_CARTEIRA_USUARIO_CLIQUE_TRANSFERIR_EMPRESAS = 'GESTAO_CARTEIRA_USUARIO_CLIQUE_TRANSFERIR_EMPRESAS',
	GESTAO_CARTEIRA_USUARIO_MODAL_TRANSFERIR_EMPRESAS_CLIQUE_FECHAR = 'GESTAO_CARTEIRA_USUARIO_MODAL_TRANSFERIR_EMPRESAS_CLIQUE_FECHAR',
	GESTAO_CARTEIRA_USUARIO_MODAL_TRANSFERIR_EMPRESAS_CLIQUE_TRANSFERIR_EMPRESAS = 'GESTAO_CARTEIRA_USUARIO_MODAL_TRANSFERIR_EMPRESAS_CLIQUE_TRANSFERIR_EMPRESAS',

	GESTAO_CARTEIRA_CLIQUE_ABA_CARTEIRA_EMPRESA = 'GESTAO_CARTEIRA_CLIQUE_ABA_CARTEIRA_EMPRESA',
	GESTAO_CARTEIRA_EMPRESA_CLIQUE_PESQUISAR_EMPRESAS = 'GESTAO_CARTEIRA_EMPRESA_CLIQUE_PESQUISAR_EMPRESAS',
	GESTAO_CARTEIRA_EMPRESA_CLIQUE_NA_EMPRESA = 'GESTAO_CARTEIRA_EMPRESA_CLIQUE_NA_EMPRESA',
	GESTAO_CARTEIRA_EMPRESA_CLIQUE_BREADCUMB_GESTAO_CARTEIRA = 'GESTAO_CARTEIRA_EMPRESA_CLIQUE_BREADCUMB_GESTAO_CARTEIRA',
	GESTAO_CARTEIRA_EMPRESA_CLIQUE_TRANSFERIR_OU_ADICIONAR_EMPRESA = 'GESTAO_CARTEIRA_EMPRESA_CLIQUE_TRANSFERIR_OU_ADICIONAR_EMPRESA',
	GESTAO_CARTEIRA_EMPRESA_MODAL_TRANSFERIR_OU_ADICIONAR_EMPRESA_CLIQUE_FECHAR = 'GESTAO_CARTEIRA_EMPRESA_MODAL_TRANSFERIR_OU_ADICIONAR_EMPRESA_CLIQUE_FECHAR',
	GESTAO_CARTEIRA_EMPRESA_MODAL_TRANSFERIR_OU_ADICIONAR_EMPRESA_CLIQUE_RADIO_TRANSFERIR = 'GESTAO_CARTEIRA_EMPRESA_MODAL_TRANSFERIR_OU_ADICIONAR_EMPRESA_CLIQUE_RADIO_TRANSFERIR',
	GESTAO_CARTEIRA_EMPRESA_MODAL_TRANSFERIR_OU_ADICIONAR_EMPRESA_CLIQUE_RADIO_ADICIONAR = 'GESTAO_CARTEIRA_EMPRESA_MODAL_TRANSFERIR_OU_ADICIONAR_EMPRESA_CLIQUE_RADIO_ADICIONAR',
	GESTAO_CARTEIRA_EMPRESA_MODAL_TRANSFERIR_OU_ADICIONAR_EMPRESA_FLUXO_ADICIONAR_CLIQUE_MOVIMENTAR = 'GESTAO_CARTEIRA_EMPRESA_MODAL_TRANSFERIR_OU_ADICIONAR_EMPRESA_FLUXO_ADICIONAR_CLIQUE_MOVIMENTAR',
	GESTAO_CARTEIRA_EMPRESA_MODAL_TRANSFERIR_OU_ADICIONAR_EMPRESA_FLUXO_TRANSFERIR_CLIQUE_MOVIMENTAR = 'GESTAO_CARTEIRA_EMPRESA_MODAL_TRANSFERIR_OU_ADICIONAR_EMPRESA_FLUXO_TRANSFERIR_CLIQUE_MOVIMENTAR',
}

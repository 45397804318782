import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { PosicaoEfeito } from "src/app/utils/enums";

@Component({
  selector: "card-imagem-landing-page",
  templateUrl: "./card-imagem-landing-page.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./card-imagem-landing-page.component.scss'],
})
export class CardImagemLandingPageComponent implements OnInit {

  @Input() imgUrl: string;
  @Input() posicaoEfeito: 'esq' | 'central' | 'dir';

  posicaoEfeitoEnum = PosicaoEfeito;

  constructor() {}

  ngOnInit(): void {

  }
}

import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
// import custom validator to validate that password and confirm password fields match
import { MustMatch } from './must-match.validator';

@Component({
  selector: 'app-new-password-popup',
  templateUrl: './new-password-popup.component.html',
  styleUrls: [
    '../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
  ]
})
export class NewPasswordPopupComponent implements OnInit {

  //#region VARIABLE
  // https://gist.github.com/ux-powered/c47614ec346517a6c17306733574ecdb
  disabled = false;

  loading = [false];

  @BlockUI() blockUIPage: NgBlockUI;
  @BlockUI('elementNewPassword') blockUIElement: NgBlockUI;

  public form: FormGroup;

  get f() {
    return this.form.controls;
  }

  //#endregion

  //#region CONSTRUCTOR
  constructor(public modalService: NgbActiveModal, public translateService: TranslateService, private apiService: ApiService, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.form = this.fb.group({

      pk_idconta: [null, [Validators.required]],
      con_senha: [null, [Validators.required]],
      senha_antiga: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(15)]],
      nova_senha: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(15)]],
      repetir_nova_senha: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(15)]]

    }, {
      // https://offering.solutions/blog/articles/2020/05/03/cross-field-validation-using-angular-reactive-forms/
      validators: [
        MustMatch('con_senha', 'senha_antiga'),
        MustMatch('nova_senha', 'repetir_nova_senha'),
      ]

    });

    this.toggleElementBlocking();

  }

  //#endregion

  //#region CRUD

  async onSubmit() {
    try {
      this.loading[0] = true;

      let body = {
        con_senha: this.form.value.nova_senha,
        con_resetarsenha: false
      }

      const data = await this.apiService.patchConta(this.form.value.pk_idconta, body);

      this.apiService.showToast(null, 'conta.edit.senha_alterada', 'success');

      // retorna a nova senha
      this.modalService.close(this.form.value.nova_senha);

    } catch (error) {

      this.apiService.errorMessageShow(error);

    } finally {
      this.loading[0] = false;
    }
  }

  //#endregion


  //#region OTHERS

  public add(parameters: any) {

    this.form.patchValue({
      pk_idconta: parameters.pk_idconta,
      con_senha: parameters.con_senha
    });

    this.toggleElementBlocking();

  }

  delayBlockUI;
  toggleElementBlocking() {

    if (this.delayBlockUI) {
      clearTimeout(this.delayBlockUI);
      this.blockUIElement.stop();
      this.delayBlockUI = null;
      return;
    }

    this.delayBlockUI = setTimeout(() => {

      this.blockUIElement.start();

    }, 100);

  }

  //#endregion
}

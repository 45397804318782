<div class="modal-header">
	<h5 [innerHtml]="title" class="modal-title bold width-100 text-center"></h5>
</div>
<div class="modal-body">
	<div id="bannerCctRepis" *ngIf="adesaoRepis">
		<ngb-alert
			class="alert alert-warning"
			[ngStyle]="{'background-color': '#FFF3DC'}"
			[dismissible]="false"
			type="danger">
			<div class="d-flex flex-row justify-content-between align-items-center">
				<svg
					class="mr-3"
					width="60"
					height="auto"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M9.9915 1.66663C5.3915 1.66663 1.6665 5.39996 1.6665 9.99996C1.6665 14.6 5.3915 18.3333 9.9915 18.3333C14.5998 18.3333 18.3332 14.6 18.3332 9.99996C18.3332 5.39996 14.5998 1.66663 9.9915 1.66663ZM10.8331 5.83326H9.16648V10.8333H10.8331V5.83326ZM10.8331 12.4999H9.16648V14.1666H10.8331V12.4999ZM3.33316 9.99995C3.33316 13.6833 6.31649 16.6666 9.99983 16.6666C13.6832 16.6666 16.6665 13.6833 16.6665 9.99995C16.6665 6.31662 13.6832 3.33328 9.99983 3.33328C6.31649 3.33328 3.33316 6.31662 3.33316 9.99995Z"
						fill="#F2AB27" />
				</svg>

				<span [innerHTML]="repisContent"></span>
			</div>
		</ngb-alert>
	</div>

	<p class="description text-center width-100 form-label text-big">
		Selecione qual a categoria que representa a sua empresa
	</p>
	<form [formGroup]="formSindicato">
		<div class="form-row mt-2">
			<div class="d-flex flex-column col-md-12 mt-1">
				<div *ngFor="let item of dataCctGroup" class="mb-2 list-group">
					<a class="list-group-item list-group-item-action flex-column align-items-start">
						<div class="d-flex justify-content-between w-100">
							<label class="custom-control custom-radio mt-2">
								<input
									name="sindicato"
									type="radio"
									formControlName="sindicato"
									[value]="item.link"
									class="custom-control-input" />
								<span class="custom-control-label text-big ml-2">
									<strong>{{ item.label }}</strong>
								</span>
							</label>
						</div>
					</a>
				</div>
			</div>
		</div>
		<div class="d-grid w-100 mt-4">
			<button
				[disabled]="!formSindicato.valid"
				class="btn btn-primary w-100 text-big button-text-color"
				type="button"
				(click)="closeModal()">
				<strong>Selecionar sindicato</strong>
			</button>
		</div>
	</form>
</div>

import {AfterViewInit, Component} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap'
import {ModalGroupCCT} from 'src/app/model/modal-group-cct/modal-group-cct'
import {ModalGrupoCctComponent} from 'src/app/others-component/popups/modal-grupo-cct/modal-grupo-cct.component'

@Component({
	selector: 'app-redirecionamento-grupo-cct',
	templateUrl: './redirecionamento-grupo-cct.component.html',
	styles: [],
})
export class RedirecionamentoGrupoCctComponent implements AfterViewInit {
	isExpanded3 = false
	dataGroupCct: ModalGroupCCT[] = []
	adesaoRepis = false

	constructor(
		private modalService: NgbModal,
		private router: Router,
		private route: ActivatedRoute,
	) {}

	ngAfterViewInit(): void {
		this.route.fragment.subscribe(fragment => {
			document.getElementById(fragment)?.scrollIntoView({behavior: 'smooth', block: 'start'})
		})

		const rotasCCT = this.route.snapshot.data.rotasCCT
		if (rotasCCT.length) {
			this.dataGroupCct = rotasCCT
		}

		this.adesaoRepis = this.route.snapshot.data.adesaoRepis || false

		this.abrirModalSindicatos()
	}

	abrirModalSindicatos = () => {
		const options: NgbModalOptions = {
			windowClass: 'modal-md animate nested-modal',
			backdrop: 'static',
			keyboard: false,
		}

		const modal = this.modalService.open(ModalGrupoCctComponent, options)
		modal.componentInstance.title = 'Grupo de Sindicatos da CCT'
		modal.componentInstance.dataCctGroup = this.dataGroupCct
		modal.componentInstance.adesaoRepis = this.adesaoRepis

		modal.result.then(
			result => {
				this.router.navigate([`/${result}`])
			},
			reason => {},
		)
	}
}

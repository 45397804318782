<div class="modal-header">
	<h5 [innerHtml]="title" class="modal-title bold width-100 text-center"></h5>
</div>
<div class="modal-body">
	<p class="description text-center width-100 form-label text-big">
		Selecione qual a categoria que representa a sua empresa
	</p>
	<form [formGroup]="formSindicato">
		<div class="form-row mt-2">
			<div class="d-flex flex-column col-md-12 mt-1">
				<div class="flex-row">
					<div *ngFor="let sindicato of sindicatos" class="mb-2 list-group">
						<a class="list-group-item list-group-item-action flex-column align-items-start">
							<div class="d-flex justify-content-between w-100">
								<!-- <label
                  class="custom-control custom-radio ms-3 me-3 mt-3 d-flex flex-row justify-content-center align-items-center">
                  <div style="height: 100%" class="d-flex justify-content-center align-items-center">
                    <input type="radio" class="custom-control-input align-center" formControlName="sindicato"
                      name="sindicato" [value]="sindicato.id" />
                  </div>
                  <div>
                    <span class="custom-control-label mt-1">{{sindicato.razaoSocial}}</span> <br />
                    <span class="custom-control-label mt-1">{{sindicato.nomeFantasia}}</span>
                  </div>
                </label> -->
								<label class="custom-control custom-radio mt-2">
									<input
										name="sindicato"
										type="radio"
										formControlName="sindicato"
										[value]="sindicato.id"
										class="custom-control-input" />
									<span class="custom-control-label text-big ml-2"
										><strong>{{ sindicato.razaoSocial }}</strong
										><br /><span style="font-size: 100% !important" class="ml-2">{{
											sindicato.nomeFantasia
										}}</span></span
									>
								</label>
							</div>
						</a>
						<!-- <p>{{sindicato.cnpj}}</p>
            <p>{{sindicato}} a</p> -->
						<!-- <div class="card-sindicato-container d-flex justify-content-start align-items-center">
              <label class="custom-control custom-radio ms-3 me-3 mt-3 d-flex flex-row justify-content-center align-items-center">
                <div style="height: 100%" class="d-flex justify-content-center align-items-center">
                  <input type="radio" class="custom-control-input align-center" formControlName="sindicato"
                    name="sindicato" [value]="sindicato.id" />
                </div>
                <div>
                  <span class="custom-control-label mt-1">{{sindicato.razaoSocial}}</span> <br />
                  <span class="custom-control-label mt-1">{{sindicato.nomeFantasia}}</span>
                </div>
              </label>

            </div> -->
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<!-- <div class="container d-flex"> -->
	<div class="d-grid w-100">
		<!-- <default-button class="d-flex" label="Selecionar sindicato"></default-button> -->
		<button
			[disabled]="!formSindicato.valid"
			class="btn btn-primary w-100 text-big button-text-color"
			type="button"
			(click)="closeModal()">
			<strong>Selecionar sindicato</strong>
		</button>
	</div>
</div>

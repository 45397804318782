<!-- Element blocking -->
<ng-template #elementBlockTemplateCampanha>
  <div class="block-ui-template">
    <div class="sk-wave sk-primary mx-auto">
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
    </div>
  </div>
</ng-template>

<div class="modal-header">


  <h5 class="modal-title">
    Informe os campos abaixo
  </h5>

  <button type="button" class="close" (click)="activeModal.close(null)">&times;</button>
</div>

<div class="modal-body">
  <!-- <div *ngIf=" this.qtdListaAtivas == 0" class="mb-5"> -->
  <div class="table-responsive " style="overflow-x: hidden;">

    <form [formGroup]="form" #ngForm="ngForm" novalidate>
      <div class="row mb-4 ">
        <div class="col-md-6 mb-2">
          <label class="form-label">Código Plataforma</label>
          <code>*</code>
          <div class="input-group">
            <input formControlName="cod_plataforma" id="cod_plataforma" type="text" class="form-control"
              [ngClass]="{ 'is-invalid': (submitted || f.cod_plataforma.touched) && f.cod_plataforma.invalid }"
              upperCase autofocus onlyNumber>
            <span class="input-group-append">
              <button [ladda]="loading[4]" (click)="carregarPlataforma(4)" data-style="expand-left"
                class="btn btn-primary" type="button">Validar</button>
            </span>
          </div>

          <div *ngIf="(submitted || f.cod_plataforma.touched) && f.cod_plataforma.invalid" class="text-danger">
            <div *ngIf="f.cod_plataforma.errors.required">{{ 'validation_form.required' | translate}}</div>
            <div *ngIf="f.cod_plataforma.errors.minlength">
              {{ 'validation_form.min_character' | translate:{'value':'1'} }}
            </div>
            <div *ngIf="f.cod_plataforma.errors.maxlength">
              {{ 'validation_form.max_character_vender_Agora' | translate:{'value':'8'} }}
            </div>
          </div>

        </div>
        <!-- *ngIf="!this.user.conta.con_cods4_corretor > 0" -->
        <div class="col-md-6 mb-2">
          <label class="form-label">Código do vendedor</label>
          <code>*</code>

          <div class="input-group">
            <input formControlName="cod_vendedor" id="cod_vendedor" type="text" class="form-control"
              [ngClass]="{ 'is-invalid': (submitted || f.cod_vendedor.touched) && f.cod_vendedor.invalid }" upperCase
              autofocus onlyNumber>
            <span class="input-group-append">
              <button [ladda]="loading[3]" (click)="carregarVendedor(3)" data-style="expand-left"
                class="btn btn-primary" type="button">Validar</button>
            </span>
          </div>
          <div *ngIf="(submitted || f.cod_vendedor.touched) && f.cod_vendedor.invalid" class="text-danger">
            <div *ngIf="f.cod_vendedor.errors.required">{{ 'validation_form.required' | translate}}</div>
            <div *ngIf="f.cod_vendedor.errors.minlength">
              {{ 'validation_form.min_character' | translate:{'value':'1'} }}
            </div>
            <div *ngIf="f.cod_vendedor.errors.maxlength">
              {{ 'validation_form.max_character_vender_Agora' | translate:{'value':'8'} }}
            </div>
          </div>
        </div>
        <!-- *ngIf="!this.nome_plataforma === ''" -->

        <div class="col-md-6" *ngIf="this.mostraNome">

          <!-- <label class="form-label">Nome da plataforma</label> -->
          <p class="form-control">{{this.nome_plataforma}} </p>
        </div>
        <div class="col-md-6" *ngIf="this.mostraNome">

          <!-- <label class="form-label">Nome do vendedor</label> -->
          <p class="form-control">{{this.nome_vendedor}} </p>
        </div>
      </div>

      <ngb-tabset class="nav-tabs-top">
        <ngb-tab title="Campanhas Pessoa física">

          <ng-template ngbTabContent>
            <br />
            <div class="text-center mt-2" *ngIf=" this.qtdListaAtivas > 0">
              <div class="col-lg-6 mx-auto">
                <p class="lead mb-4">Confira abaixo as campanhas disponíveis para o mercado de pessoa física e
                  turbine as suas vendas!
                </p>
              </div>
            </div>
            <div class="demo-vertical-spacing w-100" style="padding: 20px ; overflow-x: hidden;">
              <div *ngIf=" this.qtdListaAtivas > 0">
                <!-- 3D coverflow effect -->
                <div [swiper]="swiper3dCoverflowEffect" class="swiper-container w-100" id="swiper-3d-coverflow-effect">
                  <div class="swiper-wrapper">

                    <!-- [style.background-image]=" obj.img" -->
                    <div *ngFor="let obj of state.list_ativas" class="swiper-slide">

                      <div style="padding: 20px ;">
                        {{ obj.cam_nome }}

                        <!-- <div class="description" [innerHTML]="obj.cam_sobre"></div> -->

                      </div>
                      <button class="btn btn-md btn-default" type="button" [ladda]="loading[0]" data-style="zoom-in"
                        (click)="copyLinkVenda(obj)">
                        <i class="fa fa-copy"></i> Copiar Link
                      </button>

                      <button type="button" (click)="redirectTelaDeVendas(obj)" [ladda]="loading[0]"
                        data-style="zoom-in" class="btn btn-md btn-primary"><i class="ion ion-logo-usd"></i>&nbsp;
                        Vender </button>
                    </div>
                  </div>
                  <div class="swiper-pagination"></div>
                </div>
              </div>
              <div *ngIf="this.qtdListaAtivas == 0">
                <div class="p-4">
                  <div class=" text-center">
                    <div class="mt-4">
                      <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 50" xml:space="preserve" width="50"
                        height="50">
                        <g id="SVGRepo_bgCarrier" stroke-width="0" />
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                        <g id="SVGRepo_iconCarrier">
                          <g>
                            <path
                              d="M49.545 17.146c-0.002 -0.003 -0.004 -0.006 -0.006 -0.009l-5.431 -6.761a1.288 1.288 0 0 0 -0.203 -0.2c-0.234 -0.277 -0.58 -0.457 -0.97 -0.457H7.066c-0.391 0 -0.736 0.18 -0.97 0.457a1.287 1.287 0 0 0 -0.204 0.2l-5.43 6.761c-0.002 0.003 -0.004 0.006 -0.006 0.009C0.179 17.38 0 17.725 0 18.114v20.893c0 0.703 0.57 1.274 1.274 1.274h47.453c0.703 0 1.274 -0.57 1.274 -1.274V18.114c0 -0.39 -0.179 -0.735 -0.455 -0.968zm-5.337 -2.578 1.826 2.273h-1.826v-2.273zm-2.548 -2.302v4.575H32.123c-0.272 0 -0.536 0.087 -0.755 0.248l-6.367 4.686 -6.368 -4.686a1.278 1.278 0 0 0 -0.755 -0.247H8.339v-4.575h33.322zM5.792 16.841H3.966l1.826 -2.273v2.273zm41.661 20.893H2.547V19.388h14.912l6.786 4.994a1.275 1.275 0 0 0 1.51 -0.001l6.785 -4.994h14.912V37.734z" />
                          </g>
                        </g>
                      </svg>
                      <p class="lead mt-3 ">Você ainda não ativou <b>nenhuma</b> campanha.</p><br>
                      <button [routerLink]="['/dashboards/dashboard-2']" (click)="activeModal.close(null)"
                        class="btn btn-primary btn-md md-btn-flat" type="button">Ativar campanha</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </ng-template>
        </ngb-tab>
        <ngb-tab title="Servidor público">
          <ng-template ngbTabContent>
            <div class="card-body p-4">
              <div class="px-4 py-5 my-5 text-center">
                <div class="col-lg-10 mx-auto">
                  <p class="lead mb-4">Acesse agora as campanhas exclusivas para o mercado de servidores públicos de
                    <strong>Pernambuco</strong> e turbine as suas vendas!

                  </p>

                  <div class="alert alert-warning mt-3">
                    <div class="text-center">Região Metropolitana Recife</div>
                    <a class="d-none d-xl-block" href="{{this.url_seridor}}">{{this.url_seridor}}</a>
                    <hr>
                    <div class="d-grid gap-2 d-md-flex justify-content-sm-center">
                      <button class="btn btn-md btn-default" style="background-color: white" type="button"
                        [ladda]="loading[0]" data-style="zoom-in" (click)="copyLinkVendaServidor(obj)">
                        <i class="fa fa-copy"></i> Copiar Link
                      </button>
                      <a href="{{this.url_seridor}}" target="_blank" class="btn btn-md btn-primary"><i
                          class="ion ion-logo-usd"></i>Vender</a>
                      <br />
                    </div>
                  </div>

                  <div class="alert alert-warning mt-3">
                    <div class="text-center">Região Agreste</div>
                    <a class="d-none d-xl-block" href="{{this.url_agreste}}">{{this.url_agreste}}</a>
                    <hr>
                    <div class="d-grid gap-2 d-md-flex justify-content-sm-center">
                      <button class="btn btn-md btn-default" style="background-color: white" type="button"
                        [ladda]="loading[0]" data-style="zoom-in" (click)="copyLinkVendaAgreste(obj)">
                        <i class="fa fa-copy"></i> Copiar Link
                      </button>
                      <a href="{{this.url_agreste}}" target="_blank" class="btn btn-md btn-primary"><i
                          class="ion ion-logo-usd"></i>Vender</a>
                      <br />
                    </div>
                  </div>



                  <div class="alert alert-warning mt-3">
                    <div class="text-center">Sertão de Pernambuco</div>
                    <a class="d-none d-xl-block" href="{{this.url_seridor_sertao}}">{{this.url_seridor_sertao}}</a>
                    <hr>

                    <div class="d-grid gap-2 d-md-flex justify-content-sm-center">

                      <button class="btn btn-md btn-default" style="background-color: white" type="button"
                        [ladda]="loading[0]" data-style="zoom-in" (click)="copyLinkVendaServidorSertao(obj)">
                        <i class="fa fa-copy"></i> Copiar Link
                      </button>


                      <a href="{{this.url_seridor_sertao}}" target="_blank" class="btn btn-md btn-primary"><i
                          class="ion ion-logo-usd"></i>Vender</a>
                      <br />
                    </div>
                  </div>




                </div>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="PME">
          <ng-template ngbTabContent>
            <div class="card-body p-4">
              <div class="px-4 py-5 my-5 text-center">
                <div class="col-lg-6 mx-auto">
                  <p class="lead mb-4">Confira abaixo as campanhas disponíveis para o mercado de pessoa jurídica de
                    médio porte (PME) e turbine as suas vendas!

                  </p>
                  <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">

                    <button class="btn btn-md btn-default" type="button" [ladda]="loading[0]" data-style="zoom-in"
                      (click)="copyLinkVendaPME(obj)">
                      <i class="fa fa-copy"></i> Copiar Link
                    </button>
                    <a href="{{this.url_PME}}" target="_blank" class="btn btn-md btn-primary"><i
                        class="ion ion-logo-usd"></i>Vender</a>

                    <!-- <a target="_blank" class="btn btn-outline-secondary btn-lg px-4"
                      href="https://portal2.planoclin.com.br/Vendas/SelecionarRegiao/"> Vender agora para PME
                    </a> -->

                    <!-- <button type="button" (click)="redirectRelatorio()"
                      class="btn btn-outline-secondary btn-lg px-4">Definir
                      relatório padrão</button> -->
                  </div>
                </div>
              </div>


            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>

    </form>

    <table class="table table-hover card-table " style="display: none">
      <!-- <thead>
        <tr>
         
          <th class="text-nowrap cursor-pointer" (click)="setSort('con_nome')">
            {{ 'conta.list.nome' | translate }}
            <i class="ion text-muted ml-2" *ngIf="form.value.sortBy=='con_nome'"
              [ngClass]="'ion-md-arrow-' + (form.value.sortDesc ? 'down' : 'up')"></i>
          </th>
          <th class="text-nowrap cursor-pointer" (click)="setSort('con_email')">
            {{ 'conta.list.email' | translate }}
            <i class="ion text-muted ml-2" *ngIf="form.value.sortBy=='con_email'"
              [ngClass]="'ion-md-arrow-' + (form.value.sortDesc ? 'down' : 'up')"></i>
          </th>
        
        </tr>
      </thead> -->
      <tbody>
        <tr *ngFor="let obj of state.list_ativas">

          <td class="align-middle">
            <span *ngIf="obj.con_status == 'I'" class="badge font-weight-normal badge-danger">{{ 'status.inativo' |
              translate}}</span>
            <a href="javascript:void(0)" [ngClass]="{ 'text-danger': obj.con_status == 'I' }">{{ obj.cam_nome }}
            </a>
          </td>

          <td class="align-middle cell-fit">

            <button class="btn btn-md btn-default" type="button" [ladda]="loading[0]" data-style="zoom-in"
              (click)="copyLinkVenda(obj)">
              <i class="fa fa-copy"></i> Copiar Link
            </button>

            <button type="button" (click)="redirectTelaDeVendas(obj)" [ladda]="loading[0]" data-style="zoom-in"
              class="btn btn-md btn-primary"><i class="ion ion-logo-usd"></i>&nbsp;
              Vender </button>

            <!-- <div ngbDropdown [placement]="isRTL ? 'bottom-right' : 'bottom-right'" class="d-inline-flex">
              <button type="button" class="btn btn-sm btn-default" ngbDropdownToggle [disabled]="false">
               Opção</button>
              <div ngbDropdownMenu [class.dropdown-menu-right]="isRTL">
                <a class="dropdown-item" href="javascript:void(0)">{{
                  'conta.edit.resetar_senha' | translate }}</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="openEmailPopup()">{{
                    'conta.edit.receber_email' | translate }}</a>
                  <a class="dropdown-item" href="javascript:void(0)" (click)="openSmsPopup()">{{
                    'conta.edit.receber_sms' | translate }}</a>
              </div>
            </div> -->

          </td>

          <!-- <td class="align-middle cell-fit">

          </td> -->

        </tr>

      </tbody>
    </table>





  </div>

  <div *ngFor="let obj of state.list_ativas" style="display: none" class="card ">
    <div class="card-header with-elements  px-4 py-2 ">
      <div class="card-header-title ">
        <b>{{obj.cam_nome}}</b>
      </div>
      <div class="card-header-elements ml-auto">
        <!-- <small>Expira em: {{obj.cam_termino | date: 'short' : '' : translateService.currentLang}}</small> -->
        <a class="badge badge-success ml-2" href="javascript:void(0)">Ativa</a>
      </div>
    </div>
    <div class="row no-gutters " style="display: none">
      <!-- GRID TABVIEW -->
      <div class="col-xl-12 ">
        <ngb-tabset class="nav-tabs-top">
          <ngb-tab title="Planos">
            <ng-template ngbTabContent>

              <ul class="list-group">
                <div *ngFor="let produtos of obj.plano_campanhas_json">
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <div _ngcontent-cfx-c21=""><a _ngcontent-cfx-c21=""
                        href="javascript:void(0)">{{produtos.pla_nome}}</a>
                    </div>
                    <div _ngcontent-cfx-c21="" class="text-muted">{{produtos.fpa_nome}}</div>
                  </li>
                </div>
              </ul>

            </ng-template>
          </ngb-tab>
          <ngb-tab title="Região">
            <ng-template ngbTabContent>
              <ul class="list-group ">
                <div *ngFor="let regiao of obj.regiao_campanhas_json">
                  <li class="list-group-item d-flex justify-content-between align-items-center">


                    <div _ngcontent-cfx-c21=""><a _ngcontent-cfx-c21="" href="javascript:void(0)">
                        {{regiao.reg_nome}}</a>
                    </div>
                    <div _ngcontent-cfx-c21="" class="text-muted">{{regiao.est_nome}}</div>

                  </li>
                </div>
              </ul>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Sobre">
            <ng-template ngbTabContent>
              <div style="padding: 20px">
                Empresa: <b>{{obj.emp_nomefantasia}}</b>
                <hr>
                <div [innerHTML]="obj.cam_sobre"></div>

                <small class="pull-right mb-4">Expira em: {{obj.cam_termino | date: 'short' : '' :
                  translateService.currentLang}}</small>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
      <!-- / Sales -->
    </div>




    <div _ngcontent-uml-c6="" class="card-footer mt-2" style="display: none">


    </div>
  </div>


</div>

<div class="modal-footer">




</div>
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core'
import {FormControl, FormControlName, FormGroup, FormGroupDirective} from '@angular/forms'
import {RadioOption} from 'src/app/model/interfaces/radio-option'

@Component({
	selector: 'radio-group',
	templateUrl: './radio-group.component.html',
	styleUrls: ['./radio-group.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class RadioGroupComponent implements OnInit {
	@Input() name = ''
	@Input() radioOptions: RadioOption[] = []
	@Input() label = ''	
	@Input() orientation = 'row';

	@Output() selectedChange: EventEmitter<RadioOption> = new EventEmitter<RadioOption>()

	valueFormGroup?: FormGroup
	valueFormControl?: FormControl

	constructor(
		private formGroupDirective: FormGroupDirective,
		private formControlNameDirective: FormControlName,
	) {}

	ngOnInit(): void {
		this.valueFormGroup = this.formGroupDirective.form
		this.valueFormControl = this.formGroupDirective.getControl(this.formControlNameDirective)
	}

	onClick(option: RadioOption) {
		this.selectedChange.emit(option)
	}
}

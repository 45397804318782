import {CurrencyPipe} from '@angular/common'
import {Component, OnInit, ViewChild} from '@angular/core'
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms'
import {NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap'
import {ValidateBrService} from 'angular-validate-br'
import {BehaviorSubject} from 'rxjs'
import {debounceTime, distinctUntilChanged} from 'rxjs/operators'
import {CompromissoService} from 'src/app/service/compromisso.service'
import {
	GrauParentesco,
	Sexo,
	TipoTitularInclusaoVidas,
	TipoValidoOuInvalido,
} from 'src/app/utils/enums'
import {InclusaoVidaNestDTO} from '../../model/cliente-compromisso/inclusao-vida-nest.dto'
import {DatePickerType} from '../../model/datepicker-type'
import {Dicionario} from '../../model/dicionario'
import {OptionsUpload} from '../../model/interfaces/options-upload'
import {RadioOption} from '../../model/interfaces/radio-option'
import {Login} from '../../model/login'
import {ApiService} from '../../service/api.service'
import {retornarMsgErroPorStatus} from '../../utils/util'
import {DefaultSwalModalComponent} from '../default-swal-modal/default-swal-modal.component'
import {TypeFile} from '../upload-popup/type-file'
import {UploadPopupComponent} from '../upload-popup/upload-popup.component'
import {PlanoEmpresa} from '../../model/interfaces/inclusao-vida-popup'

interface FileUploadPopup {
	size: number
	filename: string
}
interface TipoVidaSelect {
	value: string
	descricao: string
}

interface TitularSelect {
	value: number
	descricao: string
	cpf: string
}

enum InclusaoVidaPopupSteps {
	STEP_FORM_INCLUSAO_VIDA = 1,
	STEP_SELECAO_PRODUTO = 2,
	STEP_ENVIO_FOTO = 3,
	STEP_NOVA_INCLUSAO = 4,
	STEP_FORM_NOVA_INCLUSAO_VIDA = 5,
	STEP_NOVA_SELECAO_PRODUTO = 6,
}

@Component({
	selector: 'inclusao-vidas-continua-popup',
	templateUrl: './inclusao-vidas-continua-popup.component.html',
	styleUrls: [
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../vendor/libs/ng-select/ng-select.scss',
		'./inclusao-vidas-continua-popup.component.scss',
		'../../../vendor/libs/spinkit/spinkit.scss',
		'../../../vendor/libs/ngb-datepicker/ngb-datepicker.scss',
	],
	providers: [CurrencyPipe],
})
export class InclusaoVidasContinuaPopupComponent implements OnInit {
	user: Login
	loading = [false]
	submitted = false
	disabled = false
	numberOfItemsFromEndBeforeFetchingMore = 5

	tituloModal = ''
	labelAvancar = ''
	labelVoltar = ''

	idEmpresa = 0
	idEmpresaS4E = 0
	nomeEmpresa = ''
	nomeArquivo = ''

	idCompromisso: number

	id = 0
	tipoVida = ''
	nomeTitular: string
	idTitular: number
	codAssociadoS4E: number

	exigeMatricula = false
	uploadImagemRealizado = false
	urlFicha = null
	imagemEnviada
	arquivosEnviados: FileUploadPopup[] = []
	cpfTitularSelecionado: string

	steps = [
		InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA,
		InclusaoVidaPopupSteps.STEP_SELECAO_PRODUTO,
		InclusaoVidaPopupSteps.STEP_ENVIO_FOTO,
		InclusaoVidaPopupSteps.STEP_NOVA_INCLUSAO,
		InclusaoVidaPopupSteps.STEP_FORM_NOVA_INCLUSAO_VIDA,
		InclusaoVidaPopupSteps.STEP_NOVA_SELECAO_PRODUTO,
	]

	currentStep = new BehaviorSubject<InclusaoVidaPopupSteps>(
		InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA,
	)
	inclusaoVidaPopupStepEnum = InclusaoVidaPopupSteps

	isReturn = false
	removerVida = false
	dadosCompromisso: any

	state = {
		list: null,
		paging: null,
		listaProdutos: [],
		empresa: null,
		is404: [false],
	}

	listaProdutos: PlanoEmpresa[]

	opcoesTipoVida = [
		{value: TipoTitularInclusaoVidas.TITULAR, descricao: 'Titular'},
		{value: TipoTitularInclusaoVidas.DEPENDENTE, descricao: 'Dependente'},
	]

	opcoesTipoSexo = [
		{value: Sexo.MASCULINO, descricao: 'Masculino'},
		{value: Sexo.FEMININO, descricao: 'Feminino'},
	]

	opcoesGrauParentesco = [
		{value: GrauParentesco.CONJUGE_OU_COMPANHEIRO, descricao: 'CONJUGE/COMPANHEIRO'},
		{value: GrauParentesco.FILHO, descricao: 'FILHO'},
		{value: GrauParentesco.ENTEADO, descricao: 'ENTEADO'},
		{value: GrauParentesco.PAI_OU_MAE, descricao: 'PAI/MAE'},
		{value: GrauParentesco.AGREGADOS_OU_OUTROS, descricao: 'AGREGADOS/OUTROS'},
	]

	opcoesTitulares: []

	opcoesPlanos: []

	patternCaracteresEspeciaiseEspaco = new RegExp(/\.|\-|\(|\)|\s/g)

	@ViewChild('swalBeneficiarioJaCadastrado')
	swalBeneficiarioJaCadastrado: DefaultSwalModalComponent

	@ViewChild('swalVidaImplantadaComSucesso')
	swalVidaImplantadaComSucesso: DefaultSwalModalComponent

	@ViewChild('swalVidasNaoImplantadas')
	swalVidasNaoImplantadas: DefaultSwalModalComponent

	tipoVidaEnum = TipoTitularInclusaoVidas

	opcoesPlanoRadioGroup: RadioOption[] = []

	dataNascimentoMinima = new Date(1900, 0, 1)
	buscandoCPF = false
	buscandoColaboradoresTitulares = false
	beneficiarioJaCadastrado = false
	desabilitarBotaoAvancar: () => {}

	labelStepNovaInclusao = 'Deseja incluir um dependente para'

	formPrimeiraInclusao = new FormGroup({
		vida_tipo: new FormControl(null, Validators.required),
		vida_empresa: new FormControl(null, [Validators.required]),
		vida_nome: new FormControl(null, [Validators.required, Validators.minLength(10)]),
		vida_cpf: new FormControl(null, [Validators.required, this.validateBrService.cpf]),
		vida_nomemae: new FormControl(null, [Validators.required]),
		vida_nascimento: new FormControl(null, [Validators.required]),
		vida_sexo: new FormControl(null, [Validators.required]),
		vida_telefone: new FormControl(null, [Validators.required]),
		vida_email: new FormControl(null, [Validators.email]),
		vida_idcompromisso: new FormControl(null, [Validators.required]),
		vida_matricula: new FormControl(null),
		cli_grauparentesco: new FormControl(this.opcoesTipoVida[0], [Validators.required]),
		cli_codigoassociados4e: new FormControl(null),
		fk_idtitular: new FormControl(null),
		cli_cpf_titular: new FormControl(null),
	})

	get getFormPrimeiraInclusao() {
		return this.formPrimeiraInclusao.controls
	}

	formSelecaoProdutos = new FormGroup({
		cco_id_produto_s4_melhorai: new FormControl(null),
		cco_id_produto_s4_agiben: new FormControl(null),
		cco_id_produto_s4: new FormControl(null, [Validators.required]),
	})

	get getFormSelecaoProdutos() {
		return this.formSelecaoProdutos.controls
	}

	maxDate: DatePickerType
	minDate: DatePickerType
	startDate: DatePickerType

	formSegundaInclusao = new FormGroup({
		vida_tipo: new FormControl({value: null, disabled: true}, Validators.required),
		vida_empresa: new FormControl({value: null, disabled: true}, [Validators.required]),
		vida_nome: new FormControl(null, [Validators.required, Validators.minLength(10)]),
		vida_cpf: new FormControl(null, [Validators.required, this.validateBrService.cpf]),
		vida_nomemae: new FormControl(null, [Validators.required]),
		vida_nascimento: new FormControl(null, [Validators.required]),
		vida_sexo: new FormControl(null, [Validators.required]),
		vida_telefone: new FormControl(null, [Validators.required]),
		vida_email: new FormControl(null, [Validators.email]),
		vida_idcompromisso: new FormControl(null, [Validators.required]),
		vida_matricula: new FormControl(null),
		cli_grauparentesco: new FormControl(null, [Validators.required]),
		cli_codigoassociados4e: new FormControl({value: null, disabled: true}),
		nome_associado_s4e: new FormControl({value: null, disabled: true}),
		fk_idtitular: new FormControl(null),
		cli_cpf_titular: new FormControl(null),
	})

	get getFormSegundaInclusao() {
		return this.formSegundaInclusao.controls
	}

	formSegundaSelecaoProdutos = new FormGroup({
		cco_id_produto_s4_melhorai: new FormControl(null),
		cco_id_produto_s4_agiben: new FormControl(null),
		cco_id_produto_s4: new FormControl(null, [Validators.required]),
	})

	get getFormSegundaSelecaoProdutos() {
		return this.formSegundaSelecaoProdutos.controls
	}

	constructor(
		private currencyPipe: CurrencyPipe,
		private validateBrService: ValidateBrService,
		private apiService: ApiService,
		private activeModal: NgbActiveModal,
		private ngbModal: NgbModal,
		public compromissoService: CompromissoService,
	) {
		this.maxDate = {year: new Date().getUTCFullYear(), month: 12, day: 31}
		this.minDate = {year: new Date().getUTCFullYear() - 100, month: 12, day: 31}
		this.startDate = {
			year: new Date().getUTCFullYear() - 20,
			month: new Date().getUTCMonth(),
			day: 1,
		}
	}
	formatarData(data: string): string {
		return !data ? '' : data.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$3-$2-$1')
	}

	ngOnInit(): void {
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())

		this.formPrimeiraInclusao.patchValue({
			vida_idcompromisso: this.idCompromisso,
			vida_empresa: this.nomeEmpresa,
		})

		if (this.exigeMatricula) {
			this.getFormPrimeiraInclusao.vida_matricula.setValidators([Validators.required])
		}

		this.apiService.getUser().then((res: Login) => {
			this.user = res
			this.verificarPermissaoExclusao()
		})

		this.opcoesPlanoRadioGroup = this.listaProdutos.map((produto: any) => ({
			value: produto.idPlano,
			label: produto.plano.nome,
			description: `(Titular: R$ ${this.currencyPipe.transform(
				produto.valorTitular,
				'BRL',
			)} | Dependente: R$ ${this.currencyPipe.transform(produto.valorDependente, 'BRL')})`,
		}))

		this.getFormPrimeiraInclusao.vida_cpf.statusChanges
			.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe(status => {
				if (status === TipoValidoOuInvalido.VALID) {
					const formatarCpf = this.getFormPrimeiraInclusao.vida_cpf.value.replace(
						this.patternCaracteresEspeciaiseEspaco,
						'',
					)
					this.buscarBeneficiarioCadastradoS4E(this.idEmpresaS4E, formatarCpf)
				}
			})

		this.getFormSegundaInclusao.vida_cpf.statusChanges
			.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe(status => {
				if (status === TipoValidoOuInvalido.VALID) {
					const formatarCpf = this.getFormSegundaInclusao.vida_cpf.value.replace(
						this.patternCaracteresEspeciaiseEspaco,
						'',
					)
					this.buscarBeneficiarioCadastradoS4E(this.idEmpresaS4E, formatarCpf)
				}
			})

		this.verificarFluxoInclusao()
	}

	produtoSelecionado(produtoSelecionado: RadioOption) {
		this.formSelecaoProdutos.patchValue({
			cco_id_produto_s4: produtoSelecionado.value,
		})
	}

	verificarPermissaoExclusao() {
		if (this.user.temPermissao(Dicionario.SALES_CLIENTE, 'D')) {
			this.removerVida = true
		}
	}

	async verificarFluxoInclusao() {
		if (
			this.tipoVida === TipoTitularInclusaoVidas.TITULAR ||
			this.tipoVida === TipoTitularInclusaoVidas.DEPENDENTE
		) {
			this.currentStep.next(InclusaoVidaPopupSteps.STEP_NOVA_INCLUSAO)
			this.resetarValoresFormSegundaInclusao(this.codAssociadoS4E, this.idTitular)
		}
	}

	avancarStep(step?: InclusaoVidaPopupSteps) {
		if (!step) {
			this.currentStep.next(this.currentStep.getValue() + 1)
		} else {
			this.currentStep.next(step)
		}
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
	}

	voltarStep() {
		this.currentStep.next(this.currentStep.getValue() - 1)
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
	}

	configurarLabelsEValidacaoStep(step: InclusaoVidaPopupSteps) {
		switch (step) {
			case InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA:
				this.tituloModal = 'Incluir vida'
				this.labelAvancar = 'Avançar'
				this.labelVoltar = 'Voltar'

				this.desabilitarBotaoAvancar = () =>
					(this.formPrimeiraInclusao.invalid && !this.beneficiarioJaCadastrado) || this.buscandoCPF
				break
			case InclusaoVidaPopupSteps.STEP_SELECAO_PRODUTO:
				this.tituloModal = 'Selecione um plano de cada produto'
				this.labelAvancar = 'Avançar'
				this.labelVoltar = 'Voltar'

				this.desabilitarBotaoAvancar = () => this.formSelecaoProdutos.invalid
				break
			case InclusaoVidaPopupSteps.STEP_ENVIO_FOTO:
				this.tituloModal = 'Enviar arquivos'
				this.labelAvancar = 'Salvar e incluir vida'
				this.labelVoltar = 'Voltar'

				const nomeVida =
					this.formSegundaInclusao.get('vida_nome')?.value ||
					this.formPrimeiraInclusao.get('vida_nome')?.value
				this.nomeArquivo = `${this.idCompromisso}-${nomeVida}`

				this.desabilitarBotaoAvancar = () =>
					!(this.imagemEnviada && this.uploadImagemRealizado && !this.loading[0])
				break
			case InclusaoVidaPopupSteps.STEP_NOVA_INCLUSAO:
				this.tituloModal = 'Incluir vida'
				break
			case InclusaoVidaPopupSteps.STEP_FORM_NOVA_INCLUSAO_VIDA:
				this.tituloModal = 'Incluir vida'
				this.labelAvancar = 'Avançar'
				this.labelVoltar = 'Voltar'

				this.desabilitarBotaoAvancar = () =>
					(this.formSegundaInclusao.invalid && !this.beneficiarioJaCadastrado) || this.buscandoCPF
				break
			case InclusaoVidaPopupSteps.STEP_NOVA_SELECAO_PRODUTO:
				this.tituloModal = 'Selecione um plano de cada produto'
				this.labelAvancar = 'Salvar e incluir vida'
				this.labelVoltar = 'Voltar'
				this.desabilitarBotaoAvancar = () =>
					this.formSegundaSelecaoProdutos.invalid || this.loading[0]
				break
		}
	}

	dismiss() {
		this.activeModal.dismiss()
	}

	async salvarInclusaoVida() {
		let valorPlano = 0
		this.loading[0] = true

		let formInclusaoVida: FormGroup
		let formSelecaoProdutos: FormGroup

		if (this.currentStep.getValue() === InclusaoVidaPopupSteps.STEP_ENVIO_FOTO) {
			formInclusaoVida = this.formPrimeiraInclusao
			formSelecaoProdutos = this.formSelecaoProdutos
		} else {
			formInclusaoVida = this.formSegundaInclusao
			formSelecaoProdutos = this.formSegundaSelecaoProdutos
		}

		formInclusaoVida.get('cli_cpf_titular').setValue(this.cpfTitularSelecionado)
		const idProdutoSelecionado = formSelecaoProdutos.get('cco_id_produto_s4')?.value
		if (idProdutoSelecionado) {
			const produtoSelecionado: any = this.listaProdutos.find(
				(produto: any) => produto.idPlano === Number(idProdutoSelecionado),
			)
			const grauParentescoSelecionado = formInclusaoVida.get('cli_grauparentesco')?.value
			if (grauParentescoSelecionado.value === TipoTitularInclusaoVidas.TITULAR) {
				valorPlano = produtoSelecionado.valorTitular
			} else {
				valorPlano = produtoSelecionado.valorDependente
			}
		}

		const {
			vida_tipo,
			vida_nome,
			vida_cpf,
			vida_nomemae,
			vida_nascimento,
			vida_sexo,
			vida_telefone,
			vida_email,
			vida_idcompromisso,
			vida_matricula,
			cli_grauparentesco,
			fk_idtitular,
			cli_codigoassociados4e,
			cli_cpf_titular,
		} = formInclusaoVida.getRawValue()

		const {cco_id_produto_s4} = formSelecaoProdutos.getRawValue()

		const body: InclusaoVidaNestDTO = {
			cli_nome: vida_nome,
			cli_cpf: vida_cpf.replace(this.patternCaracteresEspeciaiseEspaco, ''),
			cli_nomemae: vida_nomemae,
			cli_datanascimento: this.formatarData(vida_nascimento),
			cli_sexo: vida_sexo.value,
			cli_telefonecelular: vida_telefone.replace(this.patternCaracteresEspeciaiseEspaco, ''),
			cli_email: vida_email,
			cli_grauparentesco: cli_grauparentesco.value,
			cli_matricula: vida_matricula,
			cli_idplano: cco_id_produto_s4,
			fk_idtitular,
			cli_tipo: vida_tipo.value,
			cli_status: 'A',
			cli_titular: vida_tipo.descricao === 'Titular',
			fk_idempresa: this.idEmpresa,
			cli_enviar_para_s4E: true,
			cli_cod_empresa_s4E: this.idEmpresaS4E,
			cli_codigoassociados4e: cli_codigoassociados4e ? cli_codigoassociados4e.value : null,
			cli_cpf_titular,
			clienteCompromisso: {
				fk_idprodutoempresa: null,
				cco_id_produto_s4,
				fk_idcompromisso: vida_idcompromisso,
				cco_valor_produto: valorPlano,
			},
			cli_anexos: this.urlFicha,
		}

		try {
			const {cliente, clienteS4E} = await this.compromissoService.inserirVidaNestNovo(body)

			if (!cliente) {
				this.apiService.showToast(
					null,
					'Ocorreu um erro inesperado, entre em contato com o administrador',
					'error',
				)
				return
			}

			if (vida_tipo?.value === this.tipoVidaEnum.TITULAR) {
				this.nomeTitular = vida_nome
				this.idTitular = cliente.pk_idcliente
			}

			if (clienteS4E) {
				const {titular, dependentes} = clienteS4E
				this.resetarValoresFormSegundaInclusao(
					titular?.associado?.id || dependentes[0]?.idAssociado,
					this.idTitular,
				)
				this.swalVidaImplantadaComSucesso.openModal()
			} else {
				this.resetarValoresFormSegundaInclusao(null, this.idTitular)
				this.swalVidasNaoImplantadas.openModal()
			}
		} catch (e) {
			this.apiService.showToast(
				null,
				'Ocorreu um erro inesperado, entre em contato com o administrador',
				'error',
			)
		} finally {
			this.loading[0] = false
		}
	}

	finalizarInclusao() {
		this.activeModal.close()
	}

	novaInclusaoDependente() {
		this.avancarStep(InclusaoVidaPopupSteps.STEP_FORM_NOVA_INCLUSAO_VIDA)
	}

	openWebcam() {
		const optionsUpload: OptionsUpload = {
			folders: 'contas',
			fileName: `${this.nomeArquivo}.jpg`,
			typeFile: TypeFile.PHOTO_JPG_PNG,
			maxFiles: 1,
			maxFilesize: 2,
			isCroppedImage: true,
			maintainAspectRatio: false,
			// aspectRatio: 4 / 4,
			showWebcam: true,
		}

		this.openUploadPopup(optionsUpload)
	}

	openDragdrop() {
		const optionsUpload: OptionsUpload = {
			folders: 'contas',
			fileName: `${this.nomeArquivo}.jpg`,
			typeFile: TypeFile.PHOTO_JPG_PNG,
			maxFiles: 1,
			maxFilesize: 2,
			isCroppedImage: true,
			maintainAspectRatio: false,
			// aspectRatio: 4 / 4,
			showDragdrop: true,
		}

		this.openUploadPopup(optionsUpload)
	}

	openUploadPopup(optionsUpload: OptionsUpload) {
		const options: NgbModalOptions = {windowClass: 'modal-lg animate'}

		const modal = this.ngbModal.open(UploadPopupComponent, options)
		modal.componentInstance.setOptionsUpload(optionsUpload)

		modal.result.then(result => {
			if (result && result.dataURL) {
				this.arquivosEnviados = [{filename: optionsUpload.fileName, size: result?.size}]
				this.imagemEnviada = result.dataURL
				this.uploadImagemRealizado = true
				this.urlFicha = `https://dest-clinfluencer-imagens.s3.amazonaws.com/${optionsUpload.folders}/${optionsUpload.fileName}`
			}
		})
	}

	tipoVidaSelecionado(item: TipoVidaSelect) {
		this.tipoVida = item.value

		const formResetValues = {
			vida_tipo: item,
			vida_idcompromisso: this.idCompromisso,
			vida_empresa: this.nomeEmpresa,
			cli_grauparentesco:
				item.value === TipoTitularInclusaoVidas.TITULAR ? this.opcoesTipoVida[0] : null,
		}

		this.formPrimeiraInclusao.reset(formResetValues)

		this.setValidators(
			'cli_codigoassociados4e',
			this.tipoVida === TipoTitularInclusaoVidas.DEPENDENTE ? [Validators.required] : [],
		)
		this.setValidators(
			'vida_matricula',
			this.tipoVida === TipoTitularInclusaoVidas.TITULAR && this.exigeMatricula
				? [Validators.required]
				: [],
		)
	}

	private setValidators(controlName: string, validators: ValidatorFn[]) {
		const control = this.formPrimeiraInclusao?.get(controlName)
		if (control) {
			control.setValidators(validators)
			control.updateValueAndValidity()
		}
	}

	nomeTitularSelecionado(item: TitularSelect) {
		this.nomeTitular = item.descricao
		this.cpfTitularSelecionado = item.cpf
	}

	async buscarBeneficiarioCadastradoS4E(codEmpresaS4E: number, cpf: string) {
		this.buscandoCPF = true
		const {count = 0} =
			await this.compromissoService.listarBeneficiariosAtivosPorFiltroeIdEmpresaS4E(
				codEmpresaS4E,
				cpf,
			)
		if (count) {
			this.swalBeneficiarioJaCadastrado.openModal()
		} else {
			this.beneficiarioJaCadastrado = false
		}
		this.buscandoCPF = false
	}

	fecharSwalBeneficiarioJaCadastrado() {
		this.beneficiarioJaCadastrado = true
		this.getFormPrimeiraInclusao.vida_cpf.setValue(null)
		this.getFormSegundaInclusao.vida_cpf.setValue(null)
	}

	fecharSwalVidaImplantadaComSucessoOuNao() {
		if (this.currentStep.getValue() === InclusaoVidaPopupSteps.STEP_NOVA_SELECAO_PRODUTO) {
			this.labelStepNovaInclusao = 'Deseja incluir mais um dependente para'
			this.avancarStep(InclusaoVidaPopupSteps.STEP_NOVA_INCLUSAO)
		} else {
			this.avancarStep()
		}
	}

	listarBeneficiariosTitularesPorNomeEIdEmpresaS4E = async (filtro: any) => {
		try {
			this.buscandoColaboradoresTitulares = true
			const data = await this.compromissoService.listarBeneficiariosAtivosPorFiltroeIdEmpresaS4E(
				this.idEmpresaS4E,
				filtro,
			)
			this.opcoesTitulares = data.rows
				.filter(cliente => cliente.vinculo === 'Colaborador')
				.map(titular => ({
					value: titular.codigoAssociado,
					descricao: titular.nome,
					cpf: titular.cpf,
				}))
		} catch (e) {
			this.apiService.showToast(null, retornarMsgErroPorStatus(e), 'error')
		} finally {
			this.buscandoColaboradoresTitulares = false
		}
	}

	async resetarValoresFormSegundaInclusao(codigoAssociados4e: any, idTitular: number) {
		let associado
		if (codigoAssociados4e?.value) {
			associado = this.opcoesTitulares.find(
				(titular: TitularSelect) => titular.value === codigoAssociados4e,
			)
		} else if (codigoAssociados4e) {
			associado = {value: codigoAssociados4e, descricao: this.nomeTitular}
		}

		await this.listarBeneficiariosTitularesPorNomeEIdEmpresaS4E(this.nomeTitular)

		this.formSegundaInclusao.enable()
		this.formSegundaInclusao.reset({
			vida_tipo: this.opcoesTipoVida[1],
			cli_codigoassociados4e: associado,
			nome_associado_s4e: this.nomeTitular,
			fk_idtitular: idTitular,
			vida_empresa: this.nomeEmpresa,
			vida_idcompromisso: this.idCompromisso,
		})
		this.formSegundaInclusao.get('vida_tipo')?.disable()
		this.formSegundaInclusao.get('cli_codigoassociados4e')?.disable()
		this.formSegundaInclusao.get('nome_associado_s4e')?.disable()
		this.formSegundaSelecaoProdutos.reset()
	}
}

<div style="overflow: hidden; background-color: white">
	<!-- NAVBAR -->
	<section id="navbar">
		<div class="page-margin">
			<navbar-landing-page
				primaryButtonLabel="Cadastrar minha empresa"
				secondaryButtonLabel="Baixar CCT"
				[typeSelectedLabel]="combinacaoSindicatoSelecionadoLabel"
				(onSelectTypeClickEvent)="clicouSelecionarSindicatoPatronal()"
				(onLogoClickEvent)="clicouLogoAgiben('banner')"
				(onSecondaryButtonClickEvent)="baixarCCT('NAVBAR')"
				(onPrimaryButtonClickEvent)="cadastrarMinhaEmpresa('NAVBAR')"
				[bottaoRoteamentoLabel]="'Página do trabalhador'"
				(onBottaoRoteamentoEvent)="navegarParaPaginaDoTrabalhador()"></navbar-landing-page>
		</div>

		<div class="navbar-horizontal-line">
			<hr class="w-100 my-0" />
		</div>
	</section>

	<!-- Banner -->
	<!-- justify-content-between d-none d-md-block -->
	<section id="banner" style="background-color: #ffffff">
		<div class="d-lg-flex d-none flex-row justify-content-between page-margin">
			<div class="d-flex align-items-center w-50">
				<landing-page-info-card
					[iconUrl]="logoCCTUrl"
					[title]="sindicatoInfo.laboral?.nomeFantasia"
					[subtitle]="sindicatoInfo.nomeFantasia"
					[description]="informacoesBanner"
					primaryButtonLabel="Cadastrar minha empresa"
					(primaryEventEmitter)="cadastrarMinhaEmpresa('SECAO_BANNER')"
					secondaryButtonLabel="Baixar CCT"
					(secondaryEventEmitter)="baixarCCT('SECAO_BANNER')">
				</landing-page-info-card>
			</div>

			<div class="d-flex w-50 justify-content-end">
				<card-imagem-landing-page
					[imgUrl]="ilustracaoCCTUrl"
					posicaoEfeito="esq"></card-imagem-landing-page>
				<!-- <img [src]="ilustracaoCCTUrl" alt="" class="app-brand-logo img-fluid" /> -->
			</div>
		</div>

		<div class="d-flex d-lg-none flex-column justify-content-between page-margin">
			<div class="d-flex justify-content-center">
				<landing-page-info-card
					[iconUrl]="logoCCTUrl"
					[title]="sindicatoInfo.laboral?.nomeFantasia"
					[subtitle]="sindicatoInfo.nomeFantasia"
					[description]="informacoesBanner"
					primaryButtonLabel="Cadastrar minha empresa"
					(primaryEventEmitter)="cadastrarMinhaEmpresa('SECAO_BANNER')"
					secondaryButtonLabel="Baixar CCT"
					(secondaryEventEmitter)="baixarCCT('SECAO_BANNER')"
					[imageUrl]="ilustracaoCCTUrl"
					imagePos="dir">
				</landing-page-info-card>
			</div>
		</div>
	</section>

	<!-- Section preço -->

	<section id="PAFInformacao" class="py-5 text-center" style="background: #fff7e0 !important">
		<div class="d-lg-flex d-none flex-row py-lg-5 justify-content-around">
			<div class="card card-paf d-lg-flex flex-column">
				<p class="card-title container py-2 mt-4 text-big font-weight-bold">
					PAF: Plano de assistência Familiar
				</p>

				<div class="d-flex flex-row justify-content-around">
					<div class="d-flex flex-row pl-2 pt-3">
						<span class="label-valor moeda pt-2">R$</span>
						<span class="label-valor valor-inteiro">{{ valorPAFTitularInteiro }}</span>
						<span class="label-valor valor-decimal pt-5">{{
							valorPAFTitularDecimal ? ',' + valorPAFTitularDecimal : ''
						}}</span>
					</div>

					<div class="d-flex flex-column justify-content-center">
						<div
							class="d-flex flex-row align-items-center my-1"
							*ngFor="let benificio of beneficiosCardPAF">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<circle opacity="0.2" cx="12" cy="12" r="12" fill="#FAAB00" />
								<path
									d="M12.0013 14.7109L15.2991 17.1109L14.0369 13.2353L17.3346 10.8887H13.2902L12.0013 6.88867L10.7124 10.8887H6.66797L9.96575 13.2353L8.70352 17.1109L12.0013 14.7109Z"
									fill="#FAAB00" />
							</svg>

							<span class="label-beneficio ml-2"
								><strong>{{ benificio }}</strong></span
							>
						</div>
					</div>
				</div>
			</div>

			<landing-page-info-card
				subtitle="Tranquilidade e apoio<br>em todos os momentos"
				description="O nosso Plano de Assistência Familiar pode<br>fazer a diferença na sua empresa e na vida dos<br> seus colaboradores."
				primaryButtonLabel="Cadastrar minha empresa"
				secondaryButtonLabel="Baixar CCT"
				(primaryEventEmitter)="cadastrarMinhaEmpresa('SECAO_PAF')"
				(secondaryEventEmitter)="baixarCCT('SECAO_PAF')">
			</landing-page-info-card>
		</div>

		<div class="d-lg-none d-flex flex-column page-margin">
			<div class="card card-paf w-100 d-lg-flex flex-column p-3 mb-4">
				<div>
					<p class="card-title container py-2 mt-2 text-big">
						<strong>PAF: Plano de assistência Familiar</strong>
					</p>
				</div>

				<div class="d-flex flex-row justify-content-around">
					<div class="d-flex flex-row align-items-center">
						<span style="font-size: 1rem" class="mb-5"><strong>R$</strong></span>
						<span style="font-size: 4rem"
							><strong>{{ valorPAFTitularInteiro }}</strong></span
						>
						<span style="font-size: 2rem" class="mt-1"
							><strong>,{{ valorPAFTitularDecimal }}</strong></span
						>
					</div>

					<div class="d-flex flex-column justify-content-center">
						<div class="d-flex flex-column benefits">
							<div
								class="d-flex flex-row"
								[ngClass]="{'mt-1': i !== 0}"
								*ngFor="let benificio of beneficiosCardPAF; let i = index">
								<svg
									class="icon-detail-benefit-paf"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<circle opacity="0.2" cx="12" cy="12" r="12" fill="#FAAB00" />
									<path
										d="M12.0013 14.7109L15.2991 17.1109L14.0369 13.2353L17.3346 10.8887H13.2902L12.0013 6.88867L10.7124 10.8887H6.66797L9.96575 13.2353L8.70352 17.1109L12.0013 14.7109Z"
										fill="#FAAB00" />
								</svg>

								<span class="ml-2 label-benificio-detalhe" style="color: #4e5155"
									><strong>{{ benificio }}</strong></span
								>
							</div>
						</div>
					</div>
				</div>
			</div>

			<landing-page-info-card
				subtitle="Tranquilidade e apoio em todos<br> os momentos"
				description="O nosso Plano de Assistência Familiar pode fazer a<br>diferença na sua empresa e na vida dos seus colaboradores."
				primaryButtonLabel="Cadastrar minha empresa"
				secondaryButtonLabel="Baixar CCT"
				(primaryEventEmitter)="cadastrarMinhaEmpresa('SECAO_PAF')"
				(secondaryEventEmitter)="baixarCCT('SECAO_PAF')">
			</landing-page-info-card>
		</div>
	</section>

	<!-- Card Clube de Benefícios -->
	<div class="card-clube-beneficios">
		<card-clube-beneficios></card-clube-beneficios>
	</div>

	<!-- Section platform -->
	<section id="infoPlatatormaRH" style="background-color: #f8f8f8 !important" class="py-3">
		<div class="page-margin">
			<div class="d-lg-flex d-none flex-row">
				<landing-page-info-card
					class="col-lg-6 col-sm-12"
					title="Plataforma de gestão para o RH"
					subtitle="&nbsp;Gerencie os benefícios<br>em um só lugar&nbsp;<br><br>"
					[backgroundSubtitle]="true"
					description="Simplifique a gestão de benefícios para sua empresa com nossa plataforma exclusiva, completa e eficiente.<br/><br/> Sem burocracia, oferecemos agilidade e facilidade para que sua empresa tenha uma melhor e mais rápida gestão no dia-a-dia."
					primaryButtonLabel="Cadastrar minha empresa"
					(primaryEventEmitter)="cadastrarMinhaEmpresa('SECAO_RH')"></landing-page-info-card>

				<card-imagem-landing-page
					imgUrl="../../assets/img/device-macbook.svg"
					posicaoEfeito="central"></card-imagem-landing-page>
			</div>

			<div class="d-flex d-lg-none flex-column">
				<landing-page-info-card
					class="col-lg-6 col-sm-12"
					title="Plataforma de gestão para o RH"
					subtitle="&nbsp;Gerencie os benefícios<br>em um só lugar&nbsp;<br>"
					[backgroundSubtitle]="true"
					description="Simplifique a gestão de benefícios para sua empresa com nossa plataforma exclusiva, completa e eficiente.<br/><br/> Sem burocracia, oferecemos agilidade e facilidade para que sua empresa tenha uma melhor e mais rápida gestão no dia-a-dia."
					primaryButtonLabel="Cadastrar minha empresa"
					(primaryEventEmitter)="cadastrarMinhaEmpresa('SECAO_RH')"></landing-page-info-card>

				<card-imagem-landing-page
					class="mt-5"
					imgUrl="../../assets/img/device-macbook.svg"
					posicaoEfeito="central"></card-imagem-landing-page>
			</div>
		</div>
	</section>

	<!-- CLIN | MELHORAI | AGIBEN -->
	<section id="secao-scroll-{{ beneficio.tipoProvedor }}" *ngFor="let beneficio of listaBeneficios">
		<div *ngIf="beneficio.topicos.length" class="page-margin">
			<card-beneficios-cct
				class="p-4"
				[beneficio]="beneficio"
				(onTopicSelectedEvent)="topicoBeneficioSelecionado($event)"
				[linkDinamico]="linkDinamicoCCT"
				(onDynamicLinkClickEvent)="clickEmLinkDinamicoCCT($event)"></card-beneficios-cct>
		</div>
	</section>

	<section id="footer">
		<div class="d-flex flex-column justify-content-around page-margin">
			<div class="d-lg-flex d-none flex-row">
				<div class="d-flex flex-column w-50">
					<div class="px-1 py-2">
						<svg
							width="102"
							height="50"
							viewBox="0 0 102 50"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_5729_927)">
								<path
									d="M43.7188 8.0432V6.02976C43.7188 5.89642 43.7453 5.76575 43.7959 5.64308C43.8464 5.5204 43.921 5.41106 44.0141 5.31505C44.1099 5.21905 44.2189 5.14705 44.3413 5.09638C44.4637 5.04571 44.5941 5.01904 44.7271 5.01904H46.8556C46.9887 5.01904 47.1191 5.04571 47.2415 5.09638C47.3638 5.14705 47.4729 5.22172 47.5687 5.31505C47.6645 5.41106 47.7363 5.5204 47.7869 5.64308C47.8374 5.76575 47.864 5.89642 47.864 6.02976V8.0432C47.864 8.17654 47.8374 8.30722 47.7869 8.42989C47.7363 8.55256 47.6618 8.6619 47.5687 8.75791C47.4729 8.85391 47.3638 8.92592 47.2415 8.97659C47.1191 9.02726 46.9887 9.05392 46.8556 9.05392H44.7271C44.5941 9.05392 44.4637 9.02726 44.3413 8.97659C44.2189 8.92592 44.1099 8.85124 44.0141 8.75791C43.9183 8.66457 43.8464 8.55256 43.7959 8.42989C43.7453 8.30722 43.7188 8.17654 43.7188 8.0432Z"
									fill="#F2AB27" />
								<path
									d="M43.7188 30.2203V11.7446C43.7188 11.6113 43.7453 11.4806 43.7959 11.3579C43.8464 11.2352 43.921 11.1259 44.0141 11.0299C44.1099 10.9339 44.2189 10.8619 44.3413 10.8112C44.4637 10.7606 44.5941 10.7339 44.7271 10.7339H46.8556C46.9887 10.7339 47.1191 10.7606 47.2415 10.8112C47.3638 10.8619 47.4729 10.9366 47.5687 11.0299C47.6645 11.1259 47.7363 11.2352 47.7869 11.3579C47.8374 11.4806 47.864 11.6113 47.864 11.7446V30.2203C47.864 30.3536 47.8374 30.4843 47.7869 30.6069C47.7363 30.7296 47.6618 30.839 47.5687 30.935C47.4756 31.031 47.3638 31.103 47.2415 31.1536C47.1191 31.2043 46.9887 31.231 46.8556 31.231H44.7271C44.5941 31.231 44.4637 31.2043 44.3413 31.1536C44.2189 31.103 44.1099 31.0283 44.0141 30.935C43.9183 30.8416 43.8464 30.7296 43.7959 30.6069C43.7453 30.4843 43.7188 30.3563 43.7188 30.2203Z"
									fill="white" />
								<path
									d="M62.9647 21.2624C62.9647 19.065 62.5895 17.3769 61.8419 16.2035C61.1927 15.1554 60.32 14.6301 59.2238 14.6301C58.6757 14.6301 57.9839 14.8248 57.1485 15.2114C56.4673 15.5261 55.874 15.8968 55.3685 16.3182C55.1556 16.4968 55.0359 16.7635 55.0359 17.0409V25.6787C55.0359 25.9533 55.153 26.2147 55.3579 26.3934C56.5099 27.3988 57.5981 27.9001 58.6251 27.9001C59.7958 27.9001 60.7802 27.3747 61.5784 26.3267C62.5043 25.124 62.9647 23.4359 62.9647 21.2624ZM66.8545 21.3371C66.8545 24.2599 66.2319 26.6414 64.9841 28.4788C63.7362 30.3162 62.0175 31.2336 59.8224 31.2336C58.5506 31.2336 57.5529 31.0949 56.8318 30.8203C56.1081 30.5456 55.286 29.9456 54.3627 29.0202L53.2639 30.5109C53.0856 30.7536 52.8036 30.8949 52.5029 30.8949H51.311C50.707 30.8949 50.2574 30.3376 50.3851 29.7455L50.9997 26.8841V5.98161C50.9997 5.45625 51.4227 5.03223 51.9469 5.03223H53.9796C54.5011 5.03223 54.9268 5.45625 54.9268 5.98161V13.5047C55.6505 13.03 56.3609 12.5553 57.058 12.0806C57.9546 11.5552 58.7528 11.2939 59.4526 11.2939C61.645 11.2939 63.4276 12.2433 64.8005 14.142C66.1681 16.0408 66.8545 18.4383 66.8545 21.3371Z"
									fill="white" />
								<path
									d="M79.7147 17.3555C80.3319 17.3555 80.7816 16.7741 80.6326 16.1714C80.1058 14.038 78.8393 12.9686 76.8359 12.9686C75.7636 12.9686 74.8217 13.3606 74.0129 14.15C73.4781 14.67 73.071 15.3127 72.7916 16.0781C72.5655 16.6968 73.0231 17.3528 73.6803 17.3528H79.7147V17.3555ZM83.8174 20.617H72.3101C72.5096 22.4917 73.1828 23.9532 74.3295 25.0012C75.3512 25.9506 76.5724 26.4253 77.9932 26.4253C79.595 26.4253 81.1514 25.8359 82.66 24.6545C83.2108 24.2252 84.0196 24.5212 84.1659 25.2066L84.4719 26.6386C84.5571 27.0413 84.3762 27.4547 84.0196 27.6574C81.7075 28.9854 79.4513 29.6495 77.2456 29.6495C74.702 29.6495 72.6081 28.7641 70.9638 26.9933C69.2184 25.0972 68.3457 22.5904 68.3457 19.4729C68.3457 16.4541 69.2423 14.0353 71.0383 12.2139C72.6826 10.5924 74.6647 9.78174 76.9848 9.78174C79.1027 9.78174 80.8987 10.4804 82.37 11.8805C83.9637 13.4299 84.7619 15.478 84.7619 18.0275V19.6649C84.7646 20.193 84.3416 20.617 83.8174 20.617Z"
									fill="white" />
								<path
									d="M101.054 29.6496H99.021C98.4968 29.6496 98.0738 29.2256 98.0738 28.7002V18.0677C98.0738 14.8942 97.2756 13.3074 95.6819 13.3074C94.011 13.3074 92.1778 13.9821 90.1849 15.3315V28.7002C90.1849 29.2256 89.7619 29.6496 89.2378 29.6496H87.205C86.6809 29.6496 86.2578 29.2256 86.2578 28.7002V14.2061V11.6887C86.2578 10.9873 86.8245 10.4193 87.5243 10.4193C87.8835 10.4193 88.2294 10.5739 88.4688 10.8433L89.6608 12.182C92.8775 10.7579 95.1577 10.0459 96.504 10.0459C100.168 10.0459 102.001 12.7074 102.001 18.0303V28.7002C102.001 29.2256 101.578 29.6496 101.054 29.6496Z"
									fill="white" />
								<path
									d="M14.0023 17.5343V14.2434C14.0023 13.7101 13.6352 13.246 13.119 13.126C12.0574 12.886 11.0437 12.7633 10.0752 12.7633C8.35906 12.7633 7.02607 13.0807 6.07888 13.7127C5.13168 14.3474 4.65543 15.1928 4.65543 16.2489C4.65543 17.3049 5.10774 18.1583 6.0097 18.8063C6.91432 19.457 8.04246 19.7797 9.39674 19.7797C11.1581 19.7797 12.5656 19.3184 13.6165 18.3903C13.864 18.177 14.0023 17.8623 14.0023 17.5343ZM18.3232 23.6733H17.0541C16.6975 23.6733 16.3597 23.5052 16.1442 23.2199L14.7712 21.4091C12.9035 22.4945 11.7754 23.1159 11.3842 23.2652C10.1497 23.8093 8.86991 24.0786 7.5449 24.0786C5.31527 24.0786 3.52467 23.3852 2.17039 21.9958C0.725658 20.5478 0.00195312 18.585 0.00195312 16.1102C0.00195312 13.9687 0.829405 12.27 2.48433 11.0193C4.13926 9.76585 6.32365 9.14182 9.0322 9.14182C10.6579 9.14182 12.3128 9.33916 13.9996 9.73118V9.18715C13.9996 5.74697 12.6747 4.02688 10.0246 4.02688C9.12535 4.02688 7.21232 4.39757 4.29093 5.13894C3.74283 5.27761 3.17611 4.99493 2.95794 4.4749L2.2449 2.78681C1.96819 2.13078 2.35132 1.38141 3.04309 1.22406C6.59773 0.408021 9.30095 0 11.1554 0C14.1354 0 16.1974 0.858712 17.3441 2.5788C18.2487 3.93621 18.6984 6.12566 18.6984 9.14182V18.8277L19.4407 22.2838C19.5924 23.0012 19.0496 23.6733 18.3232 23.6733Z"
									fill="white" />
								<path
									d="M34.9515 18.5878V21.8786C34.9515 22.412 34.5844 22.876 34.0682 22.996C33.0066 23.2361 31.9929 23.3587 31.0244 23.3587C29.3083 23.3587 27.9753 23.0414 27.0281 22.4067C26.0809 21.772 25.6047 20.9293 25.6047 19.8705C25.6047 18.8145 26.057 17.9611 26.9589 17.3131C27.8635 16.6624 28.9917 16.3397 30.346 16.3397C32.1073 16.3397 33.5148 16.801 34.5657 17.7291C34.8105 17.9451 34.9515 18.2598 34.9515 18.5878ZM39.2724 12.4488H38.0033C37.6468 12.4488 37.3089 12.6168 37.0934 12.9022L35.7205 14.7129C33.8527 13.6275 32.7246 13.0062 32.3335 12.8568C31.0989 12.3128 29.8191 12.0435 28.4941 12.0435C26.2645 12.0435 24.4739 12.7368 23.1196 14.1262C21.6749 15.5743 20.9512 17.5371 20.9512 20.0119C20.9512 22.1533 21.7786 23.8521 23.4362 25.1055C25.0911 26.3562 27.2755 26.9829 29.9841 26.9829C31.6097 26.9829 33.2647 26.7856 34.9515 26.3936V26.9376C34.9515 30.3778 33.6265 32.0978 30.9765 32.0978C30.0772 32.0978 28.1642 31.7272 25.2428 30.9858C24.6947 30.8471 24.128 31.1298 23.9098 31.6498L23.1968 33.3379C22.9201 33.994 23.3032 34.7433 23.995 34.9007C27.5496 35.7167 30.2528 36.1247 32.1073 36.1247C35.0872 36.1247 37.1493 35.266 38.296 33.5459C39.198 32.1885 39.6503 29.9991 39.6503 26.9829V17.2971L40.3926 13.8409C40.5416 13.1208 39.9988 12.4488 39.2724 12.4488Z"
									fill="white" />
								<path
									d="M1.52835 38.6979C2.04186 38.2712 2.79215 38.2952 3.28171 38.7485C7.38976 42.5594 12.9825 44.4662 20.0651 44.4662C23.8512 44.4662 27.0573 44.0448 29.686 43.2021C32.4717 42.3247 34.9807 40.8846 37.2077 38.8845C37.6786 38.4632 38.3811 38.4312 38.8866 38.8072L39.8764 39.5432C40.5176 40.0206 40.6001 40.9513 40.052 41.53C37.3913 44.3515 34.0415 46.5249 30.0027 48.0504C26.5332 49.3518 23.2207 49.9998 20.0651 49.9998C16.7712 49.9998 13.3523 49.2798 9.81363 47.8397C6.11798 46.3143 2.97576 44.2128 0.378964 41.53C-0.163809 40.97 -0.118574 40.0659 0.482733 39.5672L1.52835 38.6979Z"
									fill="white" />
							</g>
							<defs>
								<clipPath id="clip0_5729_927">
									<rect width="102" height="50" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>

					<span class="title font-weight-bold p-1 py-3">Agiben LTDA</span>
					<span class="subtitle p-1">CNPJ: 46.731.059/0001-50</span>
					<span class="subtitle p-1"
						>Rua Silveira Lobo, 32 - Poço da Panela - Recife/PE - Brasil</span
					>
				</div>

				<div class="d-flex flex-column w-50 justify-content-between">
					<div class="d-flex flex-column justify-content-start">
						<span class="title font-weight-bold">Precisa de ajuda?</span>
						<span class="subtitle pt-1 pb-2">Fale agora mesmo com um dos nossos especialistas</span>
					</div>

					<div class="d-flex flex-row my-4">
						<button
							class="btn btn-primary font-weight-bold d-flex flex-row align-items-center"
							(click)="falarComEspecialista()">
							<span class="mr-2">Falar com especialista</span>
							<i class="fa fa-arrow-right"></i>
						</button>

						<button class="btn btn-white font-weight-bold ml-4" (click)="baixarCCT('SECAO_RODAPE')">
							<span class="mr-2">Baixar CCT</span>
						</button>
					</div>

					<div class="d-flex flex-row pt-2">
						<div class="d-flex flex-row align-items-center">
							<i class="fa fa-globe-americas subtitle"></i>
							<a
								(click)="clicouAbrirSite()"
								href="https://www.agiben.com.br/"
								target="_blank"
								class="subtitle ml-1"
								>www.agiben.com.br</a
							>
						</div>

						<div class="d-flex flex-row align-items-center pl-4">
							<i class="fab fa-instagram subtitle"></i>
							<a
								href="https://www.instagram.com/agibenbeneficios/"
								(click)="clicouAbrirInstagram()"
								target="_blank"
								class="subtitle ml-1"
								>@agibenbeneficios</a
							>
						</div>
					</div>
				</div>
			</div>

			<div class="d-lg-none d-flex flex-column">
				<div class="d-flex flex-column justify-content-start">
					<div class="px-1 py-2">
						<svg
							width="102"
							height="50"
							viewBox="0 0 102 50"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_5729_927)">
								<path
									d="M43.7188 8.0432V6.02976C43.7188 5.89642 43.7453 5.76575 43.7959 5.64308C43.8464 5.5204 43.921 5.41106 44.0141 5.31505C44.1099 5.21905 44.2189 5.14705 44.3413 5.09638C44.4637 5.04571 44.5941 5.01904 44.7271 5.01904H46.8556C46.9887 5.01904 47.1191 5.04571 47.2415 5.09638C47.3638 5.14705 47.4729 5.22172 47.5687 5.31505C47.6645 5.41106 47.7363 5.5204 47.7869 5.64308C47.8374 5.76575 47.864 5.89642 47.864 6.02976V8.0432C47.864 8.17654 47.8374 8.30722 47.7869 8.42989C47.7363 8.55256 47.6618 8.6619 47.5687 8.75791C47.4729 8.85391 47.3638 8.92592 47.2415 8.97659C47.1191 9.02726 46.9887 9.05392 46.8556 9.05392H44.7271C44.5941 9.05392 44.4637 9.02726 44.3413 8.97659C44.2189 8.92592 44.1099 8.85124 44.0141 8.75791C43.9183 8.66457 43.8464 8.55256 43.7959 8.42989C43.7453 8.30722 43.7188 8.17654 43.7188 8.0432Z"
									fill="#F2AB27" />
								<path
									d="M43.7188 30.2203V11.7446C43.7188 11.6113 43.7453 11.4806 43.7959 11.3579C43.8464 11.2352 43.921 11.1259 44.0141 11.0299C44.1099 10.9339 44.2189 10.8619 44.3413 10.8112C44.4637 10.7606 44.5941 10.7339 44.7271 10.7339H46.8556C46.9887 10.7339 47.1191 10.7606 47.2415 10.8112C47.3638 10.8619 47.4729 10.9366 47.5687 11.0299C47.6645 11.1259 47.7363 11.2352 47.7869 11.3579C47.8374 11.4806 47.864 11.6113 47.864 11.7446V30.2203C47.864 30.3536 47.8374 30.4843 47.7869 30.6069C47.7363 30.7296 47.6618 30.839 47.5687 30.935C47.4756 31.031 47.3638 31.103 47.2415 31.1536C47.1191 31.2043 46.9887 31.231 46.8556 31.231H44.7271C44.5941 31.231 44.4637 31.2043 44.3413 31.1536C44.2189 31.103 44.1099 31.0283 44.0141 30.935C43.9183 30.8416 43.8464 30.7296 43.7959 30.6069C43.7453 30.4843 43.7188 30.3563 43.7188 30.2203Z"
									fill="white" />
								<path
									d="M62.9647 21.2624C62.9647 19.065 62.5895 17.3769 61.8419 16.2035C61.1927 15.1554 60.32 14.6301 59.2238 14.6301C58.6757 14.6301 57.9839 14.8248 57.1485 15.2114C56.4673 15.5261 55.874 15.8968 55.3685 16.3182C55.1556 16.4968 55.0359 16.7635 55.0359 17.0409V25.6787C55.0359 25.9533 55.153 26.2147 55.3579 26.3934C56.5099 27.3988 57.5981 27.9001 58.6251 27.9001C59.7958 27.9001 60.7802 27.3747 61.5784 26.3267C62.5043 25.124 62.9647 23.4359 62.9647 21.2624ZM66.8545 21.3371C66.8545 24.2599 66.2319 26.6414 64.9841 28.4788C63.7362 30.3162 62.0175 31.2336 59.8224 31.2336C58.5506 31.2336 57.5529 31.0949 56.8318 30.8203C56.1081 30.5456 55.286 29.9456 54.3627 29.0202L53.2639 30.5109C53.0856 30.7536 52.8036 30.8949 52.5029 30.8949H51.311C50.707 30.8949 50.2574 30.3376 50.3851 29.7455L50.9997 26.8841V5.98161C50.9997 5.45625 51.4227 5.03223 51.9469 5.03223H53.9796C54.5011 5.03223 54.9268 5.45625 54.9268 5.98161V13.5047C55.6505 13.03 56.3609 12.5553 57.058 12.0806C57.9546 11.5552 58.7528 11.2939 59.4526 11.2939C61.645 11.2939 63.4276 12.2433 64.8005 14.142C66.1681 16.0408 66.8545 18.4383 66.8545 21.3371Z"
									fill="white" />
								<path
									d="M79.7147 17.3555C80.3319 17.3555 80.7816 16.7741 80.6326 16.1714C80.1058 14.038 78.8393 12.9686 76.8359 12.9686C75.7636 12.9686 74.8217 13.3606 74.0129 14.15C73.4781 14.67 73.071 15.3127 72.7916 16.0781C72.5655 16.6968 73.0231 17.3528 73.6803 17.3528H79.7147V17.3555ZM83.8174 20.617H72.3101C72.5096 22.4917 73.1828 23.9532 74.3295 25.0012C75.3512 25.9506 76.5724 26.4253 77.9932 26.4253C79.595 26.4253 81.1514 25.8359 82.66 24.6545C83.2108 24.2252 84.0196 24.5212 84.1659 25.2066L84.4719 26.6386C84.5571 27.0413 84.3762 27.4547 84.0196 27.6574C81.7075 28.9854 79.4513 29.6495 77.2456 29.6495C74.702 29.6495 72.6081 28.7641 70.9638 26.9933C69.2184 25.0972 68.3457 22.5904 68.3457 19.4729C68.3457 16.4541 69.2423 14.0353 71.0383 12.2139C72.6826 10.5924 74.6647 9.78174 76.9848 9.78174C79.1027 9.78174 80.8987 10.4804 82.37 11.8805C83.9637 13.4299 84.7619 15.478 84.7619 18.0275V19.6649C84.7646 20.193 84.3416 20.617 83.8174 20.617Z"
									fill="white" />
								<path
									d="M101.054 29.6496H99.021C98.4968 29.6496 98.0738 29.2256 98.0738 28.7002V18.0677C98.0738 14.8942 97.2756 13.3074 95.6819 13.3074C94.011 13.3074 92.1778 13.9821 90.1849 15.3315V28.7002C90.1849 29.2256 89.7619 29.6496 89.2378 29.6496H87.205C86.6809 29.6496 86.2578 29.2256 86.2578 28.7002V14.2061V11.6887C86.2578 10.9873 86.8245 10.4193 87.5243 10.4193C87.8835 10.4193 88.2294 10.5739 88.4688 10.8433L89.6608 12.182C92.8775 10.7579 95.1577 10.0459 96.504 10.0459C100.168 10.0459 102.001 12.7074 102.001 18.0303V28.7002C102.001 29.2256 101.578 29.6496 101.054 29.6496Z"
									fill="white" />
								<path
									d="M14.0023 17.5343V14.2434C14.0023 13.7101 13.6352 13.246 13.119 13.126C12.0574 12.886 11.0437 12.7633 10.0752 12.7633C8.35906 12.7633 7.02607 13.0807 6.07888 13.7127C5.13168 14.3474 4.65543 15.1928 4.65543 16.2489C4.65543 17.3049 5.10774 18.1583 6.0097 18.8063C6.91432 19.457 8.04246 19.7797 9.39674 19.7797C11.1581 19.7797 12.5656 19.3184 13.6165 18.3903C13.864 18.177 14.0023 17.8623 14.0023 17.5343ZM18.3232 23.6733H17.0541C16.6975 23.6733 16.3597 23.5052 16.1442 23.2199L14.7712 21.4091C12.9035 22.4945 11.7754 23.1159 11.3842 23.2652C10.1497 23.8093 8.86991 24.0786 7.5449 24.0786C5.31527 24.0786 3.52467 23.3852 2.17039 21.9958C0.725658 20.5478 0.00195312 18.585 0.00195312 16.1102C0.00195312 13.9687 0.829405 12.27 2.48433 11.0193C4.13926 9.76585 6.32365 9.14182 9.0322 9.14182C10.6579 9.14182 12.3128 9.33916 13.9996 9.73118V9.18715C13.9996 5.74697 12.6747 4.02688 10.0246 4.02688C9.12535 4.02688 7.21232 4.39757 4.29093 5.13894C3.74283 5.27761 3.17611 4.99493 2.95794 4.4749L2.2449 2.78681C1.96819 2.13078 2.35132 1.38141 3.04309 1.22406C6.59773 0.408021 9.30095 0 11.1554 0C14.1354 0 16.1974 0.858712 17.3441 2.5788C18.2487 3.93621 18.6984 6.12566 18.6984 9.14182V18.8277L19.4407 22.2838C19.5924 23.0012 19.0496 23.6733 18.3232 23.6733Z"
									fill="white" />
								<path
									d="M34.9515 18.5878V21.8786C34.9515 22.412 34.5844 22.876 34.0682 22.996C33.0066 23.2361 31.9929 23.3587 31.0244 23.3587C29.3083 23.3587 27.9753 23.0414 27.0281 22.4067C26.0809 21.772 25.6047 20.9293 25.6047 19.8705C25.6047 18.8145 26.057 17.9611 26.9589 17.3131C27.8635 16.6624 28.9917 16.3397 30.346 16.3397C32.1073 16.3397 33.5148 16.801 34.5657 17.7291C34.8105 17.9451 34.9515 18.2598 34.9515 18.5878ZM39.2724 12.4488H38.0033C37.6468 12.4488 37.3089 12.6168 37.0934 12.9022L35.7205 14.7129C33.8527 13.6275 32.7246 13.0062 32.3335 12.8568C31.0989 12.3128 29.8191 12.0435 28.4941 12.0435C26.2645 12.0435 24.4739 12.7368 23.1196 14.1262C21.6749 15.5743 20.9512 17.5371 20.9512 20.0119C20.9512 22.1533 21.7786 23.8521 23.4362 25.1055C25.0911 26.3562 27.2755 26.9829 29.9841 26.9829C31.6097 26.9829 33.2647 26.7856 34.9515 26.3936V26.9376C34.9515 30.3778 33.6265 32.0978 30.9765 32.0978C30.0772 32.0978 28.1642 31.7272 25.2428 30.9858C24.6947 30.8471 24.128 31.1298 23.9098 31.6498L23.1968 33.3379C22.9201 33.994 23.3032 34.7433 23.995 34.9007C27.5496 35.7167 30.2528 36.1247 32.1073 36.1247C35.0872 36.1247 37.1493 35.266 38.296 33.5459C39.198 32.1885 39.6503 29.9991 39.6503 26.9829V17.2971L40.3926 13.8409C40.5416 13.1208 39.9988 12.4488 39.2724 12.4488Z"
									fill="white" />
								<path
									d="M1.52835 38.6979C2.04186 38.2712 2.79215 38.2952 3.28171 38.7485C7.38976 42.5594 12.9825 44.4662 20.0651 44.4662C23.8512 44.4662 27.0573 44.0448 29.686 43.2021C32.4717 42.3247 34.9807 40.8846 37.2077 38.8845C37.6786 38.4632 38.3811 38.4312 38.8866 38.8072L39.8764 39.5432C40.5176 40.0206 40.6001 40.9513 40.052 41.53C37.3913 44.3515 34.0415 46.5249 30.0027 48.0504C26.5332 49.3518 23.2207 49.9998 20.0651 49.9998C16.7712 49.9998 13.3523 49.2798 9.81363 47.8397C6.11798 46.3143 2.97576 44.2128 0.378964 41.53C-0.163809 40.97 -0.118574 40.0659 0.482733 39.5672L1.52835 38.6979Z"
									fill="white" />
							</g>
							<defs>
								<clipPath id="clip0_5729_927">
									<rect width="102" height="50" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>

					<span class="title font-weight-bold p-1 py-3">Agiben LTDA</span>
					<span class="subtitle p-1">CNPJ: 46.731.059/0001-50</span>
					<span class="subtitle p-1"
						>Rua Silveira Lobo, 32 - Poço da Panela - Recife/PE - Brasil</span
					>
				</div>

				<div class="d-flex flex-column justify-content-start mt-5">
					<div class="d-flex flex-column justify-content-start">
						<span class="title font-weight-bold title">Precisa de ajuda?</span>
						<span class="subtitle pt-1 pb-2">Fale agora mesmo com um dos nossos especialistas</span>
					</div>

					<div class="d-flex flex-row my-4">
						<button
							class="btn btn-primary font-weight-bold d-flex flex-row align-items-center"
							(click)="falarComEspecialista()">
							<span class="mr-2">Falar com especialista</span>
							<i class="fa fa-arrow-right"></i>
						</button>

						<button class="btn btn-white font-weight-bold ml-4" (click)="baixarCCT('SECAO_RODAPE')">
							<span>Baixar CCT</span>
						</button>
					</div>

					<div class="d-flex flex-row pt-2">
						<div class="d-flex flex-row align-items-center">
							<i class="fa fa-globe-americas text-white"></i>
							<a
								(click)="clicouAbrirSite()"
								href="https://www.agiben.com.br/"
								target="_blank"
								class="subtitle ml-1"
								>www.agiben.com.br</a
							>
						</div>

						<div class="d-flex flex-row align-items-center pl-4">
							<i class="fab fa-instagram text-white"></i>
							<a
								href="https://www.instagram.com/agibenbeneficios/"
								(click)="clicouAbrirInstagram()"
								target="_blank"
								class="subtitle ml-1"
								>@agibenbeneficios</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="footer-horizontal-line">
			<hr class="w-100" />
		</div>

		<div class="page-margin py-3">
			<span class="copyright">© Copyright Agiben Assistências. All rights reserved</span>
		</div>
	</section>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrls: ["./badge.component.scss"]
})
export class BadgeComponent implements OnInit {
  @Input() perfil: string = null
  @Input() valor: string = null;


  validsProfiles = ["SALES REP", "SALES FARM", "FIELD SALES", "ACCOUNT", "SUCCESS"]

  isDefault = false

  constructor(
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.isDefault = !this.validsProfiles.find(item => item === this.perfil)
  }

}

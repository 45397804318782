import { Component, OnInit, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneDirective } from 'ngx-dropzone-wrapper';
import { TypeFile } from './type-file';
import { TranslateService } from '@ngx-translate/core';

import { ApiService } from 'src/app/service/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ProgressPopupComponent } from 'src/app/others-component/progress-popup/progress-popup.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { WebcamInitError, WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-upload-popup',
  templateUrl: './upload-popup.component.html',
  styleUrls: [
    '../../../vendor/libs/ngx-dropzone-wrapper/ngx-dropzone-wrapper.scss',
    '../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
    './upload-popup.scss'
  ]
})
export class UploadPopupComponent implements OnInit, AfterViewInit {

  //#region VARIABLES
  // https://gist.github.com/ux-powered/c47614ec346517a6c17306733574ecdb

  isRTL: boolean;

  state: any = {
    titulo: null,
    sub_titulo: null,
    descricao_titulo: null,
    progressValue: 0,
    dispositivos: null
  }

  optionsUpload: any = {
    // Diretorio para salvar o(s) arquivo(s)
    folders: null,
    // Nome do arquivo - Somente quando for apenas um
    fileName: null,
    // JPG, PNG, PDF, XLSX, DOC, ETC...
    typeFile: null,
    // 'mimeTypes' => array('image/jpeg', 'image/png','image/jpg','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/docx','application/pdf','text/plain','application/msword','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),  
    acceptedFiles: null,
    isCroppedImage: false,
    maintainAspectRatio: false,
    // https://github.com/Mawi137/ngx-image-cropper/issues/88#issuecomment-422695542
    // aspectRatio: 4 / 4,
    modeloPlanilha: null,
    showWebcam: false,
    showDragdrop: false,
    showCameraIP: false,
    showLeitorFacial: false,
    showWebcamLocal: false

  };

  progressFiles = [];

  loading: any = [false];

  snapshot: boolean = false;

  public form: FormGroup;
  get f() {
    return this.form.controls;
  }

  //@ViewChild("progressPopup") progressComponent: ProgressPopupComponent;

  progressBarValue: number = 0;
  progressTitulo: string;
  progressSubTitulo: string;

  croppedImage: any = null;
  imageBase64: any = null;
  showCropped: boolean = false;

  images: any = null;


  //#endregion



  //#region CONSTRUCTOR
  constructor(private appService: AppService, public modalService: NgbActiveModal,
    private http: HttpClient,
    public translateService: TranslateService, public apiService: ApiService, private fb: FormBuilder,
    private zone: NgZone) {
    this.isRTL = appService.isRTL;
  }

  ngOnInit(): void {

    this.form = this.fb.group({
      name: [null],
      filesSource: [null]

    });

  }

  ngAfterViewInit() {

    if (!this.dropzoneInstance)
      return;

    var component = this;
    const modalService = this.modalService;

    this.dropzoneInstance.dropzone().uploadFiles = function (files) {

      let filesSource = component.form.value.filesSource;

      if (!filesSource)
        filesSource = [];

      files.forEach(f => {
        filesSource.push(f);

      });

      component.form.patchValue({
        filesSource: filesSource
      });

      component.setImage();

      // Se for importacao em excel, vai retornar para a tela processar o excel. - return result
      // O excel não vai para o backend para evitar o uso excessivo de memória no servidor.
      // O Excel é tratado no frontend no PC do cliente e convertido para JSON para envio ao backend e posteriormente enviado a procedure para inserir 
      // os dados no banco de dados.
      //modalService.close(files);

    }



  }



  //#endregion

  //#region POPUP

  clearUploadCanceled() {
    let files = this.form.value.filesSource;

    if (!files)
      return;

    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      if (file.status == "canceled") {
        files.splice(index, 1);
      }

    }
  }

  onSending(data): void {

    let f = data[0];
    let filesSource = this.form.value.filesSource;

    if (!filesSource)
      filesSource = [];

    filesSource.push(f);

    this.form.patchValue({
      filesSource: filesSource
    });

    this.setImage();
  }


  //#endregion

  //#region DROPZONE
  //
  // ngx-dropzone-wrapper
  // https://www.npmjs.com/package/ngx-dropzone-wrapper
  //
  @ViewChild(DropzoneDirective, { static: false }) dropzoneInstance: DropzoneDirective;

  dropzoneConfig;

  initDropzone() {

    // https://www.dropzonejs.com/#configuration
    this.dropzoneConfig = {
      url: '127.0.0.1', // https://github.com/zefoy/ngx-dropzone-wrapper/issues/88#issuecomment-376081571
      autoProcessQueue: true,
      acceptedFiles: this.optionsUpload.acceptedFiles,
      uploadMultiple: false, // https://github.com/zefoy/ngx-dropzone-wrapper/issues/88#issuecomment-376081571
      parallelUploads: this.optionsUpload.maxFiles, // count files upload
      maxFilesize: this.optionsUpload.maxFilesize, // MB
      filesizeBase: 1000,
      addRemoveLinks: true,
      previewTemplate: `
<div class="dz-preview dz-file-preview">
  <div class="dz-details">
    <div class="dz-thumbnail">
      <img data-dz-thumbnail>
      <span class="dz-nopreview">No preview</span>
      <div class="dz-success-mark"></div>
      <div class="dz-error-mark"></div>
      <div class="dz-error-message"><span data-dz-errormessage></span></div>
      <div class="progress">
        <div class="progress-bar progress-bar-primary"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          data-dz-uploadprogress></div>
      </div>
    </div>
    <div class="dz-filename" data-dz-name></div>
    <div class="dz-size" data-dz-size></div>
  </div>
</div>`
    };


  }

  //#endregion

  //#region CRUD
  progress: number = 0;

  onSubmit() {

    this.clearUploadCanceled();

    let filesSource = this.form.value.filesSource;

    if ((this.optionsUpload.isCroppedImage == true && !this.croppedImage) && (!filesSource || filesSource.length == 0)) {
      // https://stackoverflow.com/questions/43553544/how-can-i-manually-set-an-angular-form-field-as-invalid
      this.form.controls['filesSource'].setErrors({ 'incorrect': true });
      return;
    }

    if (!this.optionsUpload.folders) {

      if (this.optionsUpload.isCroppedImage) {
        // close this popup and pass result value
        this.modalService.close({ dataURL: this.croppedImage });
      } else {
        // close this popup and pass result value
        this.modalService.close(filesSource[0]);
      }

      return;
    }

    this.uploadAPI();

  }


  private uploadAPI() {

    let filesSource = this.form.value.filesSource;

    let file;

    if (this.optionsUpload.isCroppedImage == true) {
      // https://ourcodeworld.com/articles/read/322/how-to-convert-a-base64-image-into-a-image-file-and-upload-it-with-an-asynchronous-form-using-jquery
      // Split the base64 string in data and contentType
      var block = this.croppedImage.split(";");
      // Get the content type of the image
      var contentType = block[0].split(":")[1];// In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

      // Convert it to a blob to upload
      file = this.b64toBlob(realData, contentType, 512);
      filesSource = [];
      filesSource[0] = file;
    } else {
      file = filesSource[0];
    }

    this.loading[0] = 0;

    /*this.progressComponent.open().then((result) => {

      if (result != null) {
        // progress canceled 
        console.log(`Closed with: ${result}`);
      }

    });/**/

    // calculando a barra de progresso de acordo com a quantidade de arquivos
    this.state.countFiles = filesSource.length;
    this.apiService.uploadFile(this.optionsUpload.folders, this.optionsUpload.fileName, file).subscribe(
      (res) => {

        // a barra de progresso se baseará na quantidade de arquivos a enviar e o seu tamanho.
        // o envio dos arquivos devem ser feitos um por um.

        if (res.status == "progress") {          
          let currentProgress = 0;
          if (res.message > 0) {
            /*
              regra de tres:
              (100% * Qtd.)	  (100% <- Current progress of file)
              100%	          x

              x * 400% = 100% * %100
              x =  (100% * 100%) / 400%
              x = 
            */
            currentProgress = res.message * 100;
            currentProgress = currentProgress / (this.state.countFiles * 100);
            this.progressFiles[filesSource.length - 1] = currentProgress;

            // sum all progress
            let sumProgress: number = 0;
            for (let index = 0; index < this.progressFiles.length; index++) {
              const p = this.progressFiles[index];
              if (p) {
                sumProgress += p;
              }
            }

            // progressBarValue ladda
            this.loading[0] = sumProgress;
          }
        } else if (res.status == "OK") {          
          // remove first item array
          filesSource.splice(0, 1);

          // update form value
          this.form.patchValue({
            filesSource: filesSource
          })

          if (filesSource.length > 0) {
            // recursive call
            this.uploadAPI();
          } else {
            // upload complete
            // this.apiService.showToast(null, 'upload.enviado', 'success');

            if (this.optionsUpload.isCroppedImage) {
              // close this popup and pass result value
              this.modalService.close({size: file?.size, dataURL: this.croppedImage})
            } else {
              // close this popup and pass result value
              this.modalService.close(file);
            }

            this.loading[0] = false;
          }

        }
      },
      (error) => {
        this.apiService.errorMessageShow(error);
        this.loading[0] = false;
      }
    );


  }


  //#endregion

  //#region OTHERS
  private setTypeUpload() {

    this.state.titulo = this.translateService.instant('upload.titulo');

    // 'mimeTypes' => array('image/jpeg', 'image/png','image/jpg','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/docx','application/pdf','text/plain','application/msword','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),  
    // https://stackoverflow.com/questions/28337198/dropzone-js-doesnt-upload-doc-xls-xlsx-file-formats

    // https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc
    switch (this.optionsUpload.typeFile) {
      case TypeFile.ALL:
        this.state.sub_titulo = this.translateService.instant('upload.any.titulo');
        this.state.descricao_titulo = this.translateService.instant('upload.any.descricao');
        break;
      case TypeFile.PHOTO_JPG_PNG:
        this.state.sub_titulo = this.translateService.instant('upload.foto.titulo');
        this.state.descricao_titulo = this.translateService.instant('upload.foto.descricao');
        this.optionsUpload.acceptedFiles = 'image/jpeg,image/png';
        this.progressSubTitulo = this.translateService.instant('upload.foto.titulo');
        break;
      case TypeFile.PHOTO_JPG:
        this.state.sub_titulo = this.translateService.instant('upload.foto.titulo');
        this.state.descricao_titulo = this.translateService.instant('upload.foto.descricao');
        this.optionsUpload.acceptedFiles = 'image/jpeg';
        this.progressSubTitulo = this.translateService.instant('upload.foto.titulo');
        break;
      case TypeFile.PHOTO_PNG:
        this.state.sub_titulo = this.translateService.instant('upload.foto.titulo');
        this.state.descricao_titulo = this.translateService.instant('upload.foto.descricao');
        this.optionsUpload.acceptedFiles = 'image/png';
        this.progressSubTitulo = this.translateService.instant('upload.foto.titulo');
        break;
      case TypeFile.EXCEL:
        this.state.sub_titulo = this.translateService.instant('upload.excel.titulo');
        this.state.descricao_titulo = this.translateService.instant('upload.excel.descricao');
        //this.optionsUpload.acceptedFiles = 'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        this.optionsUpload.acceptedFiles = null;
        this.progressSubTitulo = this.translateService.instant('upload.excel.titulo');
        break;
      case TypeFile.PDF:
        this.state.sub_titulo = this.translateService.instant('upload.pdf.titulo');
        this.state.descricao_titulo = this.translateService.instant('upload.pdf.descricao');
        this.optionsUpload.acceptedFiles = 'application/pdf';
        this.progressSubTitulo = this.translateService.instant('upload.pdf.titulo');
        break;
      case TypeFile.WORD:
        this.state.sub_titulo = this.translateService.instant('upload.word.titulo');
        this.state.descricao_titulo = this.translateService.instant('upload.word.descricao');
        this.optionsUpload.acceptedFiles = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        this.progressSubTitulo = this.translateService.instant('upload.word.titulo');
        break;
    }
  }

  public setOptionsUpload(optionsUpload) {
    this.optionsUpload = optionsUpload;
    this.setTypeUpload();
    this.initDropzone();

    if (this.optionsUpload.showCameraIP) {
      this.startLiveCameraIP(true);
    }

  }
  //#endregion

  //#region ngx-image-cropper    

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  setImage() {

    if (this.optionsUpload.isCroppedImage) {
      this.clearUploadCanceled();
      let files = this.form.value.filesSource;

      this.showCropped = false;
      this.imageBase64 = null;
      setTimeout(() => {
        this.imageBase64 = files[0].dataURL;
        this.showCropped = true;
      }, 200);
    }
  }

  trocarFoto() {
    this.showCropped = false;
    if (this.optionsUpload.showCameraIP) {
      // restart camera ip live
      this.startLiveCameraIP(true);
    } else if (this.optionsUpload.showWebcamLocal) {
      // restart webcam local live
      this.connectWebcamLocal(true);
    }

  }

  /**
 * Convert a base64 string in a Blob according to the data and contentType.
 * 
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  //#endregion

  //#region WEBCAM
  // latest snapshot
  public webcamImage: WebcamImage = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  public errors: WebcamInitError[] = [];


  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");

    } else if (error.message == "Requested device not found") {
      console.warn(error);
    } else {
      console.error(error);
    }

    this.errors.push(error);

    // Tentando conectar a Webcam pelo sistema que roda no PC localmente
    this.connectWebcamLocal(true)
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public triggerSnapshot(): void {
    this.trigger.next();

    // passando snapshot da webcam para o cropper
    this.imageBase64 = this.webcamImage.imageAsDataUrl;
    this.showCropped = true;

  }

  async connectWebcamLocal(startSnapshot: boolean = false) {

    try {

      if (startSnapshot) {
        this.snapshot = startSnapshot;
        this.optionsUpload.showWebcam = false
        this.optionsUpload.showWebcamLocal = true
      }

      if (!this.snapshot) {
        return;
      }

      // serviço do windows local - no pc cliente
      var url = "http://localhost:9001/webcam";

      let headers = {
        'Content-Type': 'application/json'
      }

      let retorno = await this.http.get<any>(url, { headers }).toPromise();

      let img = retorno.response || retorno.Response

      if (img.code) {
        this.apiService.errorMessageShow(img.code.toString())
        return;
      }

      this.imageBase64 = "data:image/jpeg;base64," + img;

      if (this.snapshot) {
        // Captura novamente, loop infinito
        setTimeout(() => {
          this.connectWebcamLocal();
        }, 100);
      }

    } catch (error) {
      this.snapshot = false;
      console.log("connectWebcamLocal: ")
      console.log(error)
    }


  }
  //#endregion

  //#region CAMERA_IP

  async startLiveCameraIP(startSnapshot: boolean = false) {

    try {

      if (startSnapshot) {
        this.snapshot = startSnapshot;
      }

      if (!this.snapshot) {
        return;
      }

      // nodejs local - no pc cliente
      var url = "http://localhost:3001";

      let headers = {
        'Content-Type': 'application/json'
      }

      let retorno = await this.http.get<any>(url, { headers }).toPromise();

      this.imageBase64 = "data:image/jpeg;base64," + retorno.base64;

      if (this.snapshot) {
        // Captura novamente, loop infinito
        setTimeout(() => {
          this.startLiveCameraIP();
        }, 100);
      }

    } catch (error) {
      this.snapshot = false;
      console.error(error);
    }

  }

  async triggerSnapshotCameraIP() {
    // passando snapshot da camera IP para o cropper
    this.snapshot = false;
    this.showCropped = true;
  }

  //#endregion

}

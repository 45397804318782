import { Component, Injectable } from '@angular/core';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 * https://stackblitz.com/run?file=src%2Fapp%2Fdatepicker-adapter.ts
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

    readonly DELIMITER = '-';

    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);

            return {
                day: parseInt(date[2], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[0], 10)
            };
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {

        let retorno = null;

        if (date) {
            // https://stackoverflow.com/questions/40776255/ng-bootstrap-datepicker-format
            retorno = [
                date.year,
                (date.month < 10 ? ('0' + date.month) : date.month),
                (date.day < 10 ? ('0' + date.day) : date.day)                
            ].join(this.DELIMITER);
        }

        return retorno;

        //return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : null;
    }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 * https://github.com/ng-bootstrap/ng-bootstrap/issues/2072#issuecomment-432239089
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    readonly DT_FORMAT = 'DD/MM/YYYY';

    parse(value: string): NgbDateStruct {
        if (value) {
            const mdt = moment(value.trim(), this.DT_FORMAT);
            const d = { day: mdt.date(), month: mdt.months() + 1, year: mdt.year() };
            return d;
        }
        return null;
    }
    format(date: NgbDateStruct): string {
        if (!date) {
            return '';
        }

        const mdt = moment([date.year, date.month - 1, date.day]);
        return mdt.isValid() ? mdt.format(this.DT_FORMAT) : '';
    }

}

import {Component, Input, OnInit} from '@angular/core'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'
import {ModalGroupCCT} from 'src/app/model/modal-group-cct/modal-group-cct'
import {Sindicato} from 'src/app/service/cct.service'

@Component({
	selector: 'app-modal-grupo-cct',
	templateUrl: './modal-grupo-cct.component.html',
	styles: [],
})
export class ModalGrupoCctComponent implements OnInit {
	@Input() title = 'Modal title'
	@Input() description: string
	@Input() icon: string
	@Input() buttonLabel = 'Voltar'
	@Input() dataCctGroup: ModalGroupCCT[] = []
	@Input() adesaoRepis = false

	repisContent = `<strong>Atenção:</strong> se a sua empresa possui o certificado de adesão ao REPIS, entre em contato
          através do <strong>sac@agiben.com.br</strong> para solicitar o desconto, logo após a conclusão do cadastro aqui no site.`

	public formSindicato: FormGroup

	constructor(
		private activeModal: NgbActiveModal,
		private fb: FormBuilder,
	) {}

	async ngOnInit() {
		this.formSindicato = this.fb.group({
			sindicato: [null, [Validators.required]],
		})
	}

	get getFormSindicato() {
		return this.formSindicato.controls
	}

	closeModal() {
		this.activeModal.close(this.formSindicato.get('sindicato').value)
	}
}

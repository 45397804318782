<div class="landing-page-info-card">
	<div *ngIf="iconUrl" class="d-lg-flex d-none flex-column w-75">
		<img class="icon-logo" [src]="iconUrl" />
		<p
			*ngIf="title"
			[ngClass]="{'background-subtitle font-weight-bold': backgroundTitle, title: !backgroundTitle}"
			class="text-left"
			[innerHTML]="title">
			<br />
		</p>
		<p
			*ngIf="subtitle"
			class="text-left font-weight-bold"
			[ngClass]="{'background-subtitle': backgroundSubtitle, subtitle: !backgroundSubtitle}"
			[innerHTML]="subtitle"></p>
		<br />
	</div>

	<div *ngIf="iconUrl" class="d-lg-none flex-row align-items center">
		<img class="icon-logo" [src]="iconUrl" />
		<div class="d-flex flex-column">
			<p
				*ngIf="title"
				[ngClass]="{
					'background-subtitle font-weight-bold': backgroundTitle,
					title: !backgroundTitle
				}"
				class="text-left"
				[innerHTML]="title">
				<br />
			</p>
			<p
				*ngIf="subtitle"
				class="text-left font-weight-bold"
				[ngClass]="{'background-subtitle': backgroundSubtitle, subtitle: !backgroundSubtitle}"
				[innerHTML]="subtitle"></p>
		</div>
	</div>

	<div *ngIf="!iconUrl">
		<p
			*ngIf="title"
			[ngClass]="{'background-subtitle font-weight-bold': backgroundTitle, title: !backgroundTitle}"
			class="text-left"
			[innerHTML]="title">
			<br />
		</p>
		<p
			*ngIf="subtitle"
			class="text-left font-weight-bold"
			[ngClass]="{'background-subtitle': backgroundSubtitle, subtitle: !backgroundSubtitle}"
			[innerHTML]="subtitle"></p>
		<br />
	</div>

	<card-imagem-landing-page
		*ngIf="imagePos && imageUrl"
		[posicaoEfeito]="imagePos"
		[imgUrl]="imageUrl"></card-imagem-landing-page>
	<p
		*ngIf="secondSubtitle"
		class="d-inline background-subtitle text-left w-65"
		[innerHTML]="secondSubtitle">
		<br />
	</p>
	<p *ngIf="description" class="description text-left" [innerHTML]="description"><br /></p>
	<p *ngIf="secondDescription" class="description text-left" [innerHTML]="secondDescription">
		<br />
	</p>
	<div class="d-flex mt-4">
		<button
			*ngIf="primaryButtonLabel"
			class="btn btn-primary text-left font-weight-bold"
			(click)="emitEvent(primaryEventEmitter)">
			{{ primaryButtonLabel }}
			<i *ngIf="showIconPrimary" class="fa fa-arrow-right"></i>
		</button>
		<button
			*ngIf="secondaryButtonLabel"
			class="btn btn-white text-left font-weight-bold ml-3"
			(click)="emitEvent(secondaryEventEmitter)">
			{{ secondaryButtonLabel }}
		</button>
	</div>
</div>

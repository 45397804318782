import { Component, Input, HostBinding, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AppService } from '../../app.service';
import { LayoutService } from '../../layout/layout.service';
import { ApiService } from 'src/app/service/api.service';
import { Login } from 'src/app/model/login';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewPasswordPopupComponent } from 'src/app/others-component/new-password-popup/new-password-popup.component';
import { debounceTime, switchMap, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-layout-navbar',
  templateUrl: './layout-navbar.component.html',
  styles: [':host { display: block; }'],
  styleUrls: [
    '../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
    '../../../vendor/libs/ng-select/ng-select.scss'
  ]
})
export class LayoutNavbarComponent {
  isExpanded = false;
  isRTL: boolean;
  img: string = null;
  private delayRefresh; // Timer get dispositivos
  loading = [false];
  offline: boolean = true;
  searching = false;

  qtdMensagem = 0;
  user: Login;

  @Input() sidenavToggle = true;

  state: any = {
    searchQuery: '',
    list: null,
    listFilterDevices: null,
    paging: null,
    usuarios: null,
    pagingUsuarios: null,
    is404: [false]
  }

  public form: FormGroup;
  public formDevice: FormGroup;


  get f() {
    return this.form.controls;
  }

  // https://stackblitz.com/run?file=src%2Fapp%2Ftypeahead-http.ts
  // https://ng-bootstrap.github.io/#/components/typeahead/examples
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => this.getUsuariosPorSetorNomeEPerfil(term)),
      tap(() => this.searching = false)
    )

  @HostBinding('class.layout-navbar') hostClassMain = true;

  constructor(private appService: AppService, private layoutService: LayoutService, public apiService: ApiService,
    public translateService: TranslateService, private modalService: NgbModal,
    private cd: ChangeDetectorRef, private fb: FormBuilder, private router: Router) {
    this.isRTL = appService.isRTL;

    this.apiService.getUser().then((res: Login) => {
      this.user = res;

      if (this.user) {
        // Set img foto
        let imagePath = `/contas/${this.user.idConta}.jpg`
        this.img = this.apiService.getUrlImage(imagePath, true)

        setTimeout(() => {
          // this.getMensagens()

        }, 100);


      }

    })

  }

  async getMensagens() {

    try {


      this.state.is404[1] = false;
      this.loading[1] = true;

      let idConta = this.user.idConta

      let body = {
        pesq: null,
        vencidas: false,
        idorigem: 0,
        iddestino: idConta,
        status: 'A'
      }

      let data_compromisso = await this.apiService.getCompromissoPorIDConta(idConta);

      this.state.list_mensagem_grupo = []
      this.state.list_compromisso = []


      if (data_compromisso) {
        this.state.list_compromisso = data_compromisso;
        this.qtdMensagem += this.state.list_compromisso.length
      }
      
      let data = await this.apiService.getMensagens(body);
      if (data) {
        
        data.forEach(o => {          

          //SE TIVER NULL O GRUPO QUER DIZER QUE É UMA MENSAGEM PARA TODOS
          if (o.grupos_json == null) {
            this.replaceMensagem(o)
          }
          else if (o.grupos_json != null) {

            let estaNoGrupo = o.grupos_json.find(x => x.fk_idgrupo == this.user.conta.fk_idgrupo)

            if (estaNoGrupo) {
              this.replaceMensagem(o)
            }
          }
        });
      }

    } catch (error) {
      
      if (error && error.status != 401 && error.status != 404) {
        this.apiService.errorMessageShow(error);
      }

      this.state.is404[1] = error.status == 404;

    } finally {
      this.loading[1] = false;
    }

  }


  async replaceMensagem(o: any) {


    if (o.men_texto) {
      if (o.men_texto.indexOf("@CODIGOPLATAFORMA") > 0) {
        if (!this.user.conta.con_cods4_plataforma && this.user.conta.isAdm == 0) {
          throw 'Apenas usuários tipo plataforma pode visualizar esse relatório.'
        }
        o.men_texto = o.men_texto.replace('@CODIGOPLATAFORMA', this.user.conta.con_cods4_plataforma)
      }

      //VALIDA SE O USUÁRIO É TIPO VENDEDOR
      if (o.men_texto.indexOf("@CODIGOVENDEDOR") > 0 && this.user.conta.con_cods4_corretor) {
        o.men_texto = o.men_texto.replace('@CODIGOVENDEDOR', this.user.conta.con_cods4_corretor)
      }
      
      if (o.men_texto.indexOf("@NOMEUSUARIO") > 0 && this.user.conta.con_nome) {
        o.men_texto = o.men_texto.replace('@NOMEUSUARIO', this.user.conta.con_nome)
      }

      if (o.men_texto.indexOf("@ID_CONTA") > 0 && this.user.conta.pk_idconta) {
        o.men_texto = o.men_texto.replace('@ID_CONTA', this.user.conta.pk_idconta)
      }

      if (o.men_texto.indexOf("@ID_CONTA") > 0 && this.user.conta.pk_idconta) {
        o.men_texto = o.men_texto.replace('@ID_CONTA', this.user.conta.pk_idconta)
      }

      if (o.men_texto.indexOf("@CODS4") > 0 && this.user.conta.con_cods4) {
        o.men_texto = o.men_texto.replace('@CODS4', this.user.conta.con_cods4)
      }

      if (o.men_texto.indexOf("@CODSUPERVISOR") > 0 && this.user.conta.con_cods4_supervisor) {
        o.men_texto = o.men_texto.replace('@CODSUPERVISOR', this.user.conta.con_cods4_supervisor)
      }

      this.state.list_mensagem_grupo.push(o);
      this.qtdMensagem += this.state.list_mensagem_grupo.length
    }
  }


  isMaiorQueUm(): boolean {
    return this.qtdMensagem > 1;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.form = this.fb.group({
      search_term: [null],
    });

    this.formDevice = this.fb.group({
      searchTerm: [null]
    })

  }

  ngOnDestroy() {
    clearTimeout(this.delayRefresh);
    this.delayRefresh = null;

  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  logout() {

    this.apiService.logout(true);

  }

  //#region USERS  
  get getNomeUsuario() {
    let retorno = '';

    if (this.user) {
      retorno = this.apiService.getFirstWord(this.user.conta.con_nome);
    }

    return retorno;
  }

  get getNomeCliente() {
    let retorno = '';

    if (this.user) {
      retorno = this.apiService.getFirstWord(this.user.conta.con_nome);
    }

    return retorno;
  }


  //#endregion

  //#region NEW_PASSWORD
  openNewPasswordPopup() {

    let options = { windowClass: 'modal-top modal-lg animate' };

    let modal = this.modalService.open(NewPasswordPopupComponent, options); // Render modal with modal component    

    setTimeout(() => {

      let parameters = {
        pk_idconta: this.user.idConta,
        con_senha: this.user.conta.con_senha
      };

      (<NewPasswordPopupComponent>modal.componentInstance).add(parameters);

    }, 200);

    modal.result.then((result) => {
      console.log(`Closed with: ${result}`);

      if (result) {
        // return a new password from popup
        this.user.conta.con_senha = result;


      }

    }, (reason) => {

    });

  }

  //#endregion

  //#region USUARIO
  async getUsuariosPorSetorNomeEPerfil(term: string = null, offset: number = 0) {

    try {

      this.state.is404[2] = false;
      this.loading[2] = true;

      if (!term || term.length <= 2)
        return;

      let params = {
        searchQuery: term,
        empresa: 0,
        setor: 0,
        offset,
        idperfisdeacesso: null, // perfil: Morador e Funcionario, este ultimo depende de configuracao
        rowLimit: 5
      }

      let data /*= await this.apiService.getUsuariosPorSetorNomeEPerfil(params);*/

      this.state.pagingUsuarios = data.paging;

      this.loading[2] = false;

      if (offset == 0) {
        this.state.usuarios = data
      } else {
        // Add more row in list
        this.state.usuarios = this.state.usuarios.concat(data);
      }

    } catch (error) {

      this.state.usuarios = null;

      this.loading[2] = false;

      if (error && error.status != 401 && error.status != 404) {
        this.apiService.errorMessageShow(error);
      }

      // Nenhum registro foi cadastrado
      this.state.is404[2] = error.status == 404;

    }

    return this.state.usuarios;

  }

  formatter = (x: { usu_nome: string }) => x.usu_nome;

  selectedItem(item) {

    let obj = item.item;
    // redirecionando de acordo com o perfil da pessoa selecionada
    // redirect to view 
    // https://www.digitalocean.com/community/tutorials/angular-query-parameters

    this.router.navigate(['/people/person', obj.pk_idusuario, 'edit'], { queryParams: { refresh: new Date().getTime(), perfil: obj.fk_idperfisdeacesso } });


  }

  clear() {
    this.form.patchValue({
      search_term: null
    })
  }

  openAccount() {

    if (this.user) {
      // redirect person to new id
      this.router.navigate(['/people/user', this.user.idConta, 'edit']);
    }

  }

  //#endregion



}

<div class="custom-input-container form-col">
	<div class="d-flex flex-row">
		<div class="icon-label-container d-flex flex-row align-items-center mb-2" *ngIf="iconSrc">
			<img class="icon-label" [attr.tipo]="iconSrc" src="" alt="" />
			<label class="form-label my-0 ml-2">{{ label }}</label>
		</div>

		<label *ngIf="label && !iconSrc" class="form-label pb-1">
			{{ label }}
		</label>
		<span class="description" *ngIf="description">&nbsp;{{ description }}</span>
	</div>
	<input
		*ngIf="!hasIconSuffix && !flagBootStrap"
		[formControl]="valueFormControl"
		[placeholder]="placeholder"
		[id]="id"
		[ngClass]="{'is-invalid': valueFormControl?.errors && valueFormControl?.touched}"
		[mask]="mask ? mask : null"
		[thousandSeparator]="mask && thousandSeparator ? thousandSeparator : null"
		[decimalMarker]="mask && decimalMarker ? decimalMarker : null"
		[autofocus]="autofocus"
		[leadZeroDateTime]="leadZeroDateTime"
		[dropSpecialCharacters]="dropSpecialCharacters"
		class="form-control"
		type="text" />

	<div class="input-group" *ngIf="hasIconSuffix">
		<input
			#inputElement
			[formControl]="valueFormControl"
			[placeholder]="placeholder"
			[id]="id"
			[ngClass]="{'is-invalid': valueFormControl?.errors && valueFormControl?.touched}"
			[mask]="mask ? mask : null"
			[thousandSeparator]="mask && thousandSeparator ? thousandSeparator : null"
			[decimalMarker]="mask && decimalMarker ? decimalMarker : null"
			[autofocus]="autofocus"
			[leadZeroDateTime]="leadZeroDateTime"
			[dropSpecialCharacters]="dropSpecialCharacters"
			class="form-control border-right-0"
			type="text" />
		<div class="input-group-append">
			<button
				*ngIf="inputElement?.value; else searchIconTemp"
				(click)="clickClearSearchInput()"
				class="input-group-text border-left-0"
				type="button"
				[ngClass]="{'is-invalid': valueFormControl?.touched && valueFormControl?.invalid}">
				<span
					*ngIf="inputElement?.value; else searchIconTemp"
					class="ion ion-md-close"
					[ngClass]="{'is-invalid': valueFormControl?.touched && valueFormControl?.invalid}"
					style="cursor: pointer"></span>
			</button>

			<ng-template #searchIconTemp>
				<button
					(click)="clickSearchInput()"
					class="input-group-text border-left-0"
					type="button"
					[ngClass]="{'is-invalid': valueFormControl?.touched && valueFormControl?.invalid}">
					<span
						class="ion ion-md-search"
						[ngClass]="{'is-invalid': valueFormControl?.touched && valueFormControl?.invalid}"
						style="cursor: pointer"></span>
				</button>
			</ng-template>
		</div>
	</div>

	<div class="input-group mb-3" *ngIf="flagBootStrap">
		<span
			[ngClass]="{
				'is-invalid': valueFormControl?.errors && valueFormControl?.touched,
				formDisable: valueFormControl?.disabled
			}"
			class="input-group-text"
			>{{ preffixLabel }}</span
		>
		<input
			type="text"
			class="form-control"
			[formControl]="valueFormControl"
			[placeholder]="placeholder"
			[id]="id"
			[ngClass]="{'is-invalid': valueFormControl?.errors && valueFormControl?.touched}"
			[mask]="mask ? mask : null"
			[thousandSeparator]="mask && thousandSeparator ? thousandSeparator : null"
			[decimalMarker]="mask && decimalMarker ? decimalMarker : null"
			[autofocus]="autofocus"
			[leadZeroDateTime]="leadZeroDateTime"
			[dropSpecialCharacters]="dropSpecialCharacters"
			aria-label="Amount (to the nearest dollar)" />
	</div>

	<div *ngIf="valueFormControl?.touched && valueFormControl.errors?.required" class="mt-2 error">
		Campo obrigatório
	</div>
</div>

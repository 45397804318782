<div class="custom-simple-select">
	<div class="icon-label-container d-flex flex-row align-items-center mb-2" *ngIf="iconSrc">
		<img class="icon-label" [attr.tipo]="iconSrc" src="" />
		<label class="form-label my-0 ml-2">{{ selectUpperLabel }}</label>
	</div>
	<label *ngIf="!iconSrc" class="form-label">{{ selectUpperLabel }}</label>
	<ng-select
		#simpleSelect
		[loadingText]="loadingText"
		[placeholder]="placeholder"
		[notFoundText]="notFoundText"
		[clearAllText]="clearAllText"
		[typeToSearchText]="typeToSearchText"
		[multiple]="false"
		[closeOnSelect]="closeOnSelect"
		[searchable]="searchable"
		[formControl]="valueFormControl"
		[hideSelected]="hideSelected"
		[clearable]="clearable"
		[virtualScroll]="virtualScroll"
		[id]="name"
		[ngClass]="{'is-invalid': (submitted || valueFormControl.touched) && valueFormControl?.errors}"
		[bindLabel]="bindLabel"
		[bindValue]="bindValue"
		[items]="items"
		[loading]="loading"
		[readonly]="isReadonly"
		[typeahead]="selectTypeahead"
		(focus)="simpleSelect.open()"
		(clear)="clear()"
		(scrollToEnd)="scrollToEnd(simpleSelect.searchTerm)"
		(change)="changedItem($event)"
		[compareWith]="compareWith"
		(scroll)="scroll($event, simpleSelect.searchTerm)">
		<ng-template ng-option-tmp let-item="item"> {{ item[bindLabel] }} </ng-template>
	</ng-select>
</div>

import {Injectable} from '@angular/core'
import * as mixpanel from 'mixpanel-browser'
import {environment} from 'src/environments/environment'
import {Client} from '../model/client'
import {SistemaOperacional} from '../model/sistema-operacional'

interface SuperPropriedades {
	cliente: Client
	sistemaOperacional: SistemaOperacional
}

@Injectable({
	providedIn: 'root',
})
export class MixpanelService {
	init(): void {
		mixpanel.init(environment.MIXPANEL_TOKEN, {ignore_dnt: true})
	}

	setup(props: SuperPropriedades) {
		this.setSuperProperties(props)
	}

	setSuperProperties(properties: SuperPropriedades) {
		mixpanel.register(properties)
	}

	register(props: any[]): void {
		mixpanel.register(props)
	}

	unregister(prop: string): void {
		mixpanel.unregister(prop)
	}

	track(id: string, action: any = {}): void {
		mixpanel.track(id, action)
	}

	identify(userId?: string): void {
		mixpanel.identify(userId)
	}

	createUserProfile(user: any) {
		mixpanel.people.set(user)
	}

	clearLoggedUser() {
		mixpanel.reset()
	}
}

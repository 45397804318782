import {Component, HostBinding, VERSION} from '@angular/core'
import {version} from 'package.json'
import {AppService} from '../../app.service'

@Component({
	selector: 'app-layout-footer',
	templateUrl: './layout-footer.component.html',
	styles: [':host { display: block; }'],
})
export class LayoutFooterComponent {
	version = VERSION.full
	@HostBinding('class.layout-footer') hostClassMain = true
	appVersion: string

	constructor(private appService: AppService) {
		this.getVersion()
	}

	currentBg() {
		return `bg-${this.appService.layoutFooterBg}`
	}

	async getVersion() {
		this.appVersion = version
	}
}

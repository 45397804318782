import {
	Component,
	Input,
	AfterViewInit,
	HostBinding,
	ViewChild,
	ViewEncapsulation,
	OnInit,
	HostListener,
} from '@angular/core'
import {Router} from '@angular/router'
import {ViewportScroller} from '@angular/common'
import {AppService} from '../../app.service'
import {LayoutService} from '../layout.service'
import {Login} from 'src/app/model/login'
import {Dicionario} from 'src/app/model/dicionario'
import {ApiService} from 'src/app/service/api.service'
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap'
import {VenderCampanhaPopupComponent} from 'src/app/others-component/vender-campanha-popup/vender-campanha-popup.component'
import {PopoverService} from 'src/app/service/popover.service'

@Component({
	selector: 'app-layout-sidenav',
	templateUrl: './layout-sidenav.component.html',
	styles: [':host { display: block; }'],
	styleUrls: ['./layout-sidenav.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class LayoutSidenavComponent implements OnInit, AfterViewInit {
	@ViewChild('inicial') popoverInicial: NgbPopover
	@ViewChild('step2') popoverStep2: NgbPopover

	@Input() orientation = 'vertical'

	@HostBinding('class.layout-sidenav') hostClassVertical = false
	@HostBinding('class.layout-sidenav-horizontal') hostClassHorizontal = false
	@HostBinding('class.flex-grow-0') hostClassFlex = false
	primeiroAcessoPresidente = true
	showPopoverInicial = false
	showPopoverStep2 = false
	isMobile = false
	user: Login
	tipoLogin = false

	// allows you to use ENUM Class in template
	Dicionario = Dicionario

	constructor(
		private router: Router,
		private appService: AppService,
		private apiService: ApiService,
		private layoutService: LayoutService,
		private modalService: NgbModal,
		private popoverService: PopoverService,
		private viewportScroller: ViewportScroller,
	) {
		// Set host classes
		this.hostClassVertical = this.orientation !== 'horizontal'
		this.hostClassHorizontal = !this.hostClassVertical
		this.hostClassFlex = this.hostClassHorizontal

		this.apiService.getUser().then((res: Login) => {
			this.user = res
		})
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any): void {
		this.checkScreenSize()
	}

	ngOnInit() {
		this.checkScreenSize()
		const login = JSON.parse(localStorage.getItem('login'))
		if (login.gru_nome === 'SINDICATO PATRONAL' || login.gru_nome === 'SINDICATO LABORAL') {
			this.tipoLogin = true
		}
		const verificacaoPrimeiroPopover = JSON.parse(localStorage.getItem('hidePrimeiroPopover'))
		const verificacaoSegundoPopover = JSON.parse(localStorage.getItem('segundoPopover'))

		if (!login.con_primeiro_acesso && !verificacaoPrimeiroPopover && this.tipoLogin && !this.isMobile) {
			this.showPopoverInicial = true
		}

		if (verificacaoSegundoPopover) {
			this.showPopoverStep2 = true
		}
	}

	checkScreenSize(): void {
		const screenWidth = window.innerWidth

		if (screenWidth < 768) {
			this.isMobile = true
		}
	}

	async openVisualizarCampanhaPopup() {
		try {
			// let options = { windowClass: 'modal-lg animate nested-modal' };
			const options = {windowClass: 'modal-xl animate nested-modal'}

			const modal = this.modalService.open(VenderCampanhaPopupComponent, options) // Render modal with modal component

			//  (<VenderCampanhaPopupComponent>modal.componentInstance).campanha = conta_campanha_atual;
		} catch (error) {
			// this.loading[0] = false;

			if (error && error.status !== 401 && error.status !== 404) {
				this.apiService.errorMessageShow(error)
			}
		} finally {
			///  this.loading[0] = false;
		}
	}

	ngAfterViewInit() {
		this.layoutService._redrawLayoutSidenav()
		if (this.showPopoverInicial) {
			this.popoverInicial.open()
		}

		if (this.showPopoverStep2) {
			this.popoverStep2.open()

			setTimeout(() => {
				const retorno = document.getElementById('meusRelatorios')
				const wrapper = document.getElementById('sidenav')
				const count = retorno.offsetTop - wrapper.scrollTop
				wrapper.scroll({top: count, left: 0})
			}, 1000)
		}
	}

	getClasses() {
		let bg = this.appService.layoutSidenavBg

		if (
			this.orientation === 'horizontal' &&
			(bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)
		) {
			bg = bg
				.replace(' sidenav-dark', '')
				.replace(' sidenav-light', '')
				.replace('-darker', '')
				.replace('-dark', '')
		}

		return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`
	}

	isActive(url) {
		// by appwork
		return this.router.isActive(url, true)
	}

	isActive2(url, url_2 = null) {
		// by denes

		return (
			this.router.url &&
			(this.router.url.indexOf(url) >= 0 || (url_2 && this.router.url.indexOf(url_2) >= 0))
		)
	}

	isMenuActive(url) {
		return this.router.isActive(url, false)
	}

	isMenuOpen(url) {
		return this.router.isActive(url, false) && this.orientation !== 'horizontal'
	}

	toggleSidenav() {
		this.layoutService.toggleCollapsed()
	}

	//#region PERMISSOES

	hasDic(dicEnum: Dicionario | Dicionario[]) {
		return this.apiService.hasDic(dicEnum)
	}

	isShowConfiguracoes() {
		return this.hasDic([
			Dicionario.PLANO,
			Dicionario.QUADRO_AVISO,
			Dicionario.GRUPO,
			Dicionario.MODELOEMAILMARKETING,
			Dicionario.EMPRESA,
			Dicionario.CONFIGURACAO_SISTEMA,
		])
	}

	isShowMarketing() {
		return this.hasDic([Dicionario.CAMPANHA])
	}

	isShowCentralRelatorio() {
		return this.hasDic([Dicionario.CENTRAL_RELATORIO])
	}

	isShowRelatorios() {
		return this.hasDic([Dicionario.RELATORIO_CADASTRAL])
	}

	isShowCampanha() {
		return this.hasDic([Dicionario.CAMPANHA])
	}

	isShowEmpresaEmpresa() {
		return this.hasDic([Dicionario.EMPRESA])
	}

	isShowEmpresaSindicato() {
		return this.hasDic([Dicionario.SINDICATO])
	}

	isShowPessoas() {
		return this.hasDic([
			Dicionario.CONTA_DE_USUARIO,
			Dicionario.CADASTRO_VENDEDOR,
			Dicionario.CLIENTE,
		])
	}

	isShowMinhasAdesoesSales() {
		return this.hasDic([Dicionario.SALES_ADESAO])
	}

	isShowMinhasEmpresasSales() {
		return this.hasDic([Dicionario.SALES_EMPRESA])
	}

	nextPopoverInicial() {
		this.popoverInicial.close()
		this.showPopoverInicial = false
		localStorage.setItem('hidePrimeiroPopover', 'true')
		localStorage.setItem('segundoPopover', 'true')
		this.router.navigate(['/search/central_relatorio/list'])
	}

	nextPopoverStep2() {
		this.popoverStep2.close()
		localStorage.setItem('segundoPopover', 'false')
		localStorage.setItem('terceiroPopover', 'true')
		this.popoverService.send(true)
	}

	//#endregion
}

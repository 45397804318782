import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[dateMask]'
})

export class DateMaskDirective {

  // Example:  locale="pt-BR"
  @Input('locale') locale: string = "pt-BR";


  private specialKeys = [
    'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', '/'
  ];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    let current: string = this.el.nativeElement.value;

    let next: string = current.concat(event.key);

    const pattern = /[0-9]/;

    if (!pattern.test(event.key)) {
      // invalid character, prevent input
      event.preventDefault();
      return;
    }

    if (next.length == 3 || next.length == 6) {
      this.el.nativeElement.value = current + "/";
    }    

  }


}
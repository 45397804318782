<nav class="navbar navbar-expand-lg bg-light">
	<div class="container" style="padding-top: 10px; padding-bottom: 10px">
		<a class="navbar-brand" href="javascript:void(0)"
			><img
				src="assets/agiben-site/img/agiben.png"
				style="height: 40px"
				class="navbar-brand-img"
				alt="..."
		/></a>
		<button class="navbar-toggler" type="button" (click)="isExpanded3 = !isExpanded3">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="navbar-collapse" [ngbCollapse]="!isExpanded3">
			<div class="navbar-nav mr-auto ml-4">
				<a class="nav-item nav-link" href="/">Home</a>
				<a
					class="nav-item nav-link"
					href="javascript:void(0)"
					(click)="open(defaultModal, {windowClass: 'modal-xl animate'})"
					>Sobre</a
				>
				<a
					class="nav-item nav-link active"
					href="javascript:void(0)"
					(click)="scrollToSection('', 'Beneficios')"
					>Benefícios</a
				>
				<a
					class="nav-item nav-link"
					href="javascript:void(0)"
					(click)="scrollToSection('sectionParceiros', 'Home')"
					>Parceiros</a
				>
			</div>
			<form class="form-inline">
				<a
					class="btn btn-primary mr-2"
					href="https://api.whatsapp.com/send?phone=5508199991009&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20a%20Agiben"
					target="_blank"
					>Quero uma cotação</a
				>
				<a class="btn btn-outline-primary" target="_blank" href="https://rh.agiben.com.br/#/login"
					>Área do cliente</a
				>
			</form>
		</div>
	</div>
</nav>

<ng-template #defaultModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h5 class="modal-title">
			<span class="font-weight-light">Sobre Agiben</span><br />
			<!-- <small class="text-muted">We need payment information to process your order.</small> -->
		</h5>
		<button type="button" class="close" (click)="d('Cross click')">&times;</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div
				class="col-lg-6"
				style="
					background-image: url('./assets/agiben-site/img/covers/cover-9.jpg');
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					height: 400px;
				">
				<!-- cover-9. -->
			</div>
			<div class="col-lg-6" style="padding: 5rem 3rem">
				<h3 class="text-center text-muted">
					A Agiben surgiu para revolucionar o mercado de benefícios corporativos, mediante às
					demandas e necessidades das variadas empresas e associações.
				</h3>

				<p class="mb-6 text-center text-muted">
					Somos uma gestora de soluções flexíveis com a missão de levar facilidade na oferta de
					benefícios corporativos. Unimos tudo em um só lugar, sem burocracia e com agilidade.
				</p>

				<p class="mb-6 text-center text-muted">
					Ajudamos a sua empresa a reduzir o absenteísmo e o turnover, deixando o seu colaborador
					mais saudável, seguro, engajado e confiante.
				</p>
				<!-- Text -->
				<p class="mb-0 fs-sm text-center text-muted">
					Deseja uma cotação?
					<a
						target="_blank"
						href="https://api.whatsapp.com/send?phone=5508199991009&amp;text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20a%20Agiben"
						>Clique aqui</a
					>.
				</p>
			</div>
		</div>
	</div>
</ng-template>

<section
	#sectionBeneficios
	id="sectionBeneficios"
	class="overlay overlay-dark overlay-10"
	style="
		background-image: url('./assets/agiben-site/img/covers/BeneficiosHome.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	">
	<div class="container">
		<div class="row align-items-center justify-content-center min-vh-100 py-8 py-md-11">
			<div class="col-12 mt-auto"></div>
		</div>
	</div>
</section>

<!-- Todos os beneficios em um so lugar -->
<section class="py-8 py-md-12 sectionAgiben" id="sectionTwo">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-12 col-lg-8 text-center">
				<h2 class="" style="font-size: 35px">
					Saúde, segurança e os melhores<br />
					sorrisos sempre disponíveis
				</h2>
				<p>
					Não precisa ir longe para ter mais. Com apenas uma plataforma, tenha acesso a vários
					benefícios.<br />
					Unimos tudo em um só lugar, sem burocracia e com agilidade.
				</p>
				<br />
				<hr class="mb-4" />
			</div>
		</div>
	</div>
</section>

<!-- Section odontologia -->
<section class="pt-md-8 pb-8 pb-md-11" #sectionOdontologia id="sectionOdontologia">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-10 col-lg-9 col-xl-8">
				<h2 class="fw-bold">Odontologia</h2>
				<!-- Fugure -->
				<figure class="figure">
					<!-- Image -->
					<img
						class="figure-img img-fluid rounded lift lift-lg"
						src="./assets/agiben-site/img/photos/Odontologia.jpg"
						alt="..." />
				</figure>
				<!-- Text -->
				<h3 class="fw-bold">Plano odontológico (Clin)</h3>
				<p class="mt-5">
					Aqui você não fica na mão! Com apenas alguns cliques, solicite a marcação de consultas no
					dia e horário que quiser, visualize a rede de clínicas credenciadas, acesse a carteira
					digital e inclua dependentes no seu plano.
				</p>
				<p class="">
					São mais de 270 procedimentos contidos no rol da ANS para garantir os melhores sorrisos. A
					saúde bucal que o seu time merece:
				</p>
				<div class="row mb-2 mt-4">
					<div class="col-12 col-lg-6">
						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<!-- Text -->
							<p class="text-primary">Urgência</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<p class="text-primary">Limpeza e aplicação de flúor (profilaxia)</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<p class="text-primary mb-lg-0">Tratamento de gengiva (periodontia)</p>
						</div>
					</div>
					<div class="col-12 col-lg-6 mb-6 mb-md-0">
						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>

							<!-- Text -->
							<p class="text-primary">Restauração (dentística)</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>

							<!-- Text -->
							<p class="text-primary">Endodontia (tratamento de canal)</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>

							<!-- Text -->
							<p class="text-primary">Cirurgias</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft me-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<!-- Text -->
							<p class="text-primary mb-0">Extrações (exodontia)</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- / .row -->
	</div>
	<!-- / .container -->

	<!-- <a class="btn btn-outline-warning mt-8" target="_blank" href="https://agiben.com.br/Pdf/Plano%20odontol%C3%B3gico%20(Clin).pdf">Download Material</a>       -->
	<div class="row mt-10">
		<div class="col-12">
			<div class="text-center">
				<a
					class="btn btn-primary btn-lg mb-1 text-center ms-auto"
					[href]="linkDocumentoOdontologia">
					Download Material
				</a>
			</div>
		</div>
	</div>
</section>

<!-- Section Telemedicina -->
<section class="pt-md-8 pb-8 pb-md-11 mt-10" #sectionMedicina id="sectionMedicina">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-10 col-lg-9 col-xl-8">
				<h2 class="fw-bold">Medicina</h2>
				<!-- Fugure -->
				<figure class="figure">
					<!-- Image -->
					<img
						class="figure-img img-fluid rounded lift lift-lg"
						src="./assets/agiben-site/img/photos/telemedicina.jpg"
						alt="..." />
				</figure>
				<!-- Text -->
				<h3 class="fw-bold">Telemedicina (Melhor.ai)</h3>
				<p class="mt-5">
					Tenha acesso à uma plataforma completa de saúde e conecte-se a profissionais para realizar
					exames, tratamentos preventivos e de cura.
				</p>
				<p class="">
					Escolha o melhor dia e horário. Você precisa apenas de um aparelho com acesso à internet.
					As consultas são realizadas sem barreiras geográficas. A qualquer hora, em qualquer lugar.
				</p>

				<div class="row mb-2 mt-4">
					<div class="col-lg-6">
						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<!-- Text -->
							<p class="text-primary">Clínica geral</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<p class="text-primary">Endocrinologia</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<p class="text-primary mb-lg-0">Pediatria</p>
						</div>
					</div>
					<div class="col-lg-6">
						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>

							<!-- Text -->
							<p class="text-primary">Dermatologia</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>

							<!-- Text -->
							<p class="text-primary">Cardiologia</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- / .row -->
	</div>
	<!-- / .container -->

	<!-- <a class="btn btn-outline-warning mt-8" target="_blank" href="https://agiben.com.br/Pdf/Plano%20odontol%C3%B3gico%20(Clin).pdf">Download Material</a>       -->
	<div class="row mt-10">
		<div class="col-12">
			<div class="text-center">
				<a class="btn btn-primary btn-lg mb-1 text-center ms-auto" [href]="linkDocumentoMedicina">
					Download Material
				</a>
			</div>
		</div>
	</div>
</section>

<!-- Section Psicologia -->
<section class="pt-md-8 pb-8 pb-md-11 mt-10" #sectionPsicologia id="sectionPsicologia">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-10 col-lg-9 col-xl-8">
				<h2 class="fw-bold">Psicologia</h2>
				<!-- Fugure -->
				<figure class="figure">
					<!-- Image -->
					<img
						class="figure-img img-fluid rounded lift lift-lg"
						src="./assets/agiben-site/img/photos/psicologia.jpg"
						alt="..." />
				</figure>
				<!-- Text -->
				<h3 class="fw-bold">Atendimento psicológico (Melhor.ai)</h3>
				<p class="mt-5">
					A saúde mental ganhou visibilidade nos últimos anos e se tornou alvo de interesse por
					muitas empresas e organizações.
				</p>
				<p class="">
					Tenha acesso a profissionais especialistas que vão ajudar com tratamentos humanizados para
					garantir o bem-estar da sua equipe.
				</p>

				<div class="row mb-2 mt-4">
					<div class="col-lg-6">
						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<!-- Text -->
							<p class="text-primary">Triagens virtuais</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<p class="text-primary">Diagnósticos</p>
						</div>
					</div>
					<div class="col-lg-6">
						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>

							<!-- Text -->
							<p class="text-primary">Tratamentos preventivos</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<p class="text-primary mb-lg-0">Terapias</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- / .row -->
	</div>
	<!-- / .container -->

	<!-- <a class="btn btn-outline-warning mt-8" target="_blank" href="https://agiben.com.br/Pdf/Plano%20odontol%C3%B3gico%20(Clin).pdf">Download Material</a>       -->
	<div class="row mt-10">
		<div class="col-12">
			<div class="text-center">
				<a class="btn btn-primary btn-lg mb-1 text-center ms-auto" [href]="linkDocumentoPsicologia">
					Download Material
				</a>
			</div>
		</div>
	</div>
</section>

<!-- Section Seguros -->
<section class="pt-md-8 pb-8 pb-md-11 mt-10">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-10 col-lg-9 col-xl-8">
				<h2 class="fw-bold">Seguros</h2>
				<!-- Fugure -->
				<figure class="figure">
					<!-- Image -->
					<img
						class="figure-img img-fluid rounded lift lift-lg"
						src="./assets/agiben-site/img/photos/seguros.jpg"
						alt="..." />
				</figure>
				<!-- Text -->
				<h3 class="fw-bold">Seguros (Allianz)</h3>
				<p class="mt-5">
					Proteção é sempre bem-vinda! Oferecemos um pacote completo para garantir a segurança da
					sua Organização.
				</p>

				<div class="row mb-2 mt-4" #sectionSeguros id="sectionSeguros">
					<div class="col-lg-6">
						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<!-- Text -->
							<p class="text-primary">Seguro de vida (morte acidental ou morte natural)</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<p class="text-primary">Seguro funeral (traslado, sepultamento e cremação)</p>
						</div>
					</div>
					<div class="col-lg-6">
						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>

							<!-- Text -->
							<p class="text-primary">Manutenção, limpeza e assistências</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<p class="text-primary mb-lg-0">Proteção contra incêndios, roubos e alagamentos.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row mt-10">
		<div class="col-12">
			<div class="text-center">
				<a class="btn btn-primary btn-lg mb-1 text-center ms-auto" [href]="linkDocumentoSeguros">
					Download Material
				</a>
			</div>
		</div>
	</div>
</section>

<section
	data-jarallax=""
	data-speed=".8"
	class="py-14 py-lg-16 jarallax mt-10"
	style="
		background-image: url('https://agiben.com.br/assets/img/covers/cover-7.jpg');
		height: 450px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	"></section>

<nav class="footer bg-light pt-4">
	<div class="container" style="padding: 60px 60px">
		<div class="row">
			<div class="col-12 col-md-4 col-lg-3">
				<!-- Brand -->
				<img src="./assets/agiben-site/img/agiben.png" alt="..." style="height: 60px" class="" />

				<!-- Text -->
				<p class="text-gray-700 mb-2">Uma plataforma, vários benefícios.</p>

				<!-- Social -->
				<ul class="list-unstyled list-inline list-social mb-6 mb-md-0">
					<li class="list-inline-item list-social-item me-3">
						<a
							href="https://www.instagram.com/agibenbeneficios/"
							target="_blank"
							class="text-decoration-none">
							<i
								class="d-block fab fa-instagram"
								ng-reflect-klass="d-block"
								ng-reflect-ng-class="fab fa-instagram"></i>
						</a>
					</li>
				</ul>
			</div>
			<div class="col-6 col-md-4 col-lg-2" style="visibility: hidden">
				<!-- Heading -->
				<h6 class="fw-bold text-uppercase text-gray-700">Parceiros</h6>

				<!-- List -->
				<ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
					<li class="mb-3">
						<a href="https://planoclin.com.br/" target="_blank" class="text-reset"> Clin </a>
					</li>
					<li class="mb-3">
						<a href="https://institucional.melhor.ai/" target="_blank" class="text-reset">
							Melhor.ai
						</a>
					</li>

					<li class="mb-3">
						<a href="https://www.allianz.com.br/" target="_blank" class="text-reset"> Allianz </a>
					</li>
				</ul>
			</div>
			<div class="col-6 col-md-4 col-lg-2">
				<!-- Heading -->
				<h6 class="fw-bold text-uppercase text-gray-700" style="display: none">Contatos</h6>
			</div>
			<div class="col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0">
				<div>
					<!-- Heading -->
					<h6 class="fw-bold text-uppercase text-gray-700">Parceriros</h6>

					<!-- List -->
					<ul class="list-unstyled text-muted mb-0">
						<li class="mb-3">
							<a href="https://planoclin.com.br/" target="_blank" class="text-reset"> Clin </a>
						</li>
						<li class="mb-3">
							<a href="https://institucional.melhor.ai/" target="_blank" class="text-reset">
								Melhor.ai
							</a>
						</li>

						<li class="mb-3">
							<a href="https://www.allianz.com.br/" target="_blank" class="text-reset"> Allianz </a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-6 col-md-4 col-lg-2">
				<!-- Heading -->
				<h6 class="fw-bold text-uppercase text-gray-700">Produtos</h6>

				<!-- List -->
				<ul class="list-unstyled text-muted mb-0">
					<li class="mb-3">
						<a
							style="cursor: pointer"
							(click)="scrollToSection('sectionMedicina', 'Beneficios')"
							class="text-reset">
							Telemedicina
						</a>
					</li>
					<li class="mb-3">
						<a
							style="cursor: pointer"
							(click)="scrollToSection('sectionOdontologia', 'Beneficios')"
							class="text-reset">
							Odontologia
						</a>
					</li>
					<li class="mb-3">
						<a
							style="cursor: pointer"
							(click)="scrollToSection('sectionPsicologia', 'Beneficios')"
							class="text-reset">
							Psicologia
						</a>
					</li>
					<li class="mb-3">
						<a
							style="cursor: pointer"
							(click)="scrollToSection('sectionSeguros', 'Beneficios')"
							class="text-reset">
							Seguros
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>

import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
	name: 'tipoStatusCompromisso',
	pure: true,
})
export class TipoStatusCompromisso implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		switch (value) {
			case '1': {
				return 'Planejada'
			}

			case '2': {
				return 'Trabalhando'
			}

			case '4': {
				return 'Finalizada'
			}

			case '5': {
				return 'Cancelado'
			}

			default: {
				return 'Indisponível'
			}
		}
	}
}

import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core'
import {PosicaoEfeito} from 'src/app/utils/enums'

@Component({
	selector: 'card-atendimento-landing-page',
	templateUrl: './card-atendimento-landing-page.component.html',
	styleUrls: ['./card-atendimento-landing-page.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CardAtendimentoLandingPageComponent implements OnInit {
	@Input() titulo: string
	@Input() conteudo: string
	@Input() tipoLogo: 'CLIN' | 'AGIBEN' | 'MELHORAI'
	@Input() descricaoFooter: string
	@Input() mostrarIcon = false

	@Input() tipoBorda: 'esq' | 'dir' | 'central'
	tipoBordaEnum = PosicaoEfeito

	constructor() {}

	ngOnInit(): void {}
}

<div style="overflow: hidden; background-color: white">
	<section id="navbar">
		<div class="page-margin">
			<navbar-landing-page></navbar-landing-page>
		</div>
		<div class="navbar-horizontal-line">
			<hr class="w-100 my-0" />
		</div>
	</section>
	<div class="container">
		<div class="item">
			<landing-page-info-card
				[showIconPrimary]="true"
				[subtitle]="'Opa, atualização no ar'"
				primaryButtonLabel="Falar com especialista"
				(primaryEventEmitter)="falarComEspecialista()"
				[description]="
					'Esta página está sendo atualizada e ficará disponível em breve. Até lá, caso precise de alguma informação ou ajuda, fale com um dos nossos especialistas. Pedimos desculpas pelo transtorno.'
				">
			</landing-page-info-card>
		</div>
		<div class="item"><img src="../../assets/img/manutencao.svg" alt="Descrição da imagem" /></div>
	</div>
</div>

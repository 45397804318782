<div class="modal-header">
  <h5 class="modal-title">
    {{ state.titulo }} <span class="font-weight-light">{{ state.sub_titulo }}</span><br>
    <small class="text-muted">{{ state.descricao_titulo }}</small>
  </h5>
  <button type="button" class="close" (click)="modalService.dismiss(null)">&times;</button>
</div>
<!-- Form -->
<form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="modal-body">

    <!-- Drag and Drop -->

    <div class="form-row" *ngIf="optionsUpload.showDragdrop && !showCropped">
      <div class="form-group col-md-12">
        <div class="dropzone" [dropzone]="dropzoneConfig" (sending)="onSending($event)">
          <div class="dz-message needsclick">
            {{ 'upload.drop_files' | translate }}
          </div>
          <div class="fallback">
            <input name="file" type="file" multiple>
          </div>
        </div>

      </div>
    </div>

    <!-- Fim Drag and Drop -->

    <!-- Camera local - Ip ou webcam -->
    <div class="form-row" *ngIf="(optionsUpload.showCameraIP || optionsUpload.showWebcamLocal) && !showCropped">

      <div class="form-group col-md-12">

        <img [src]="imageBase64" style="height: 375px; border: 1px dotted black" />

      </div>
      <div class="form-group col-md-12">
        <button type="button" class="btn btn-outline-primary" (click)="triggerSnapshotCameraIP()">{{ 'upload.tirar_foto'
          | translate }}</button>
      </div>
    </div>

    <!-- FIM - Camera local - Ip ou webcam -->

    <!-- Webcam -->

    <div class="form-row" *ngIf="optionsUpload.showWebcam && !showCropped">

      <div class="form-group col-md-12">
        <webcam [height]="375" (imageCapture)="handleImage($event)" (initError)="handleInitError($event)"
          [trigger]="triggerObservable"></webcam>
      </div>
      <div class="form-group col-md-12">
        <button type="button" class="btn btn-outline-primary" (click)="triggerSnapshot()">{{ 'upload.tirar_foto' |
          translate }}</button>
      </div>

    </div>

    <!-- Fim Webcam-->

    <!-- Leitor Facial -->
    <div class="form-row" *ngIf="optionsUpload.showLeitorFacial && !showCropped">

      <div class="form-group col-md-12" >

        <button type="button" class="btn btn-secondary" [ladda]="loading[1]" data-style="expand-right"
          (click)="getDispositivosCadastradorFacial()">{{ 'upload.atualizar'
          | translate }}</button>
      </div>

      <div class="form-group col-md-12" *ngIf="state.dispositivos && state.dispositivos.length > 1">
        <h3 class="text-center">{{'upload.selecione_leitor' | translate}}</h3>

        <div class="text-center">
          <button *ngFor="let o of state.dispositivos"
            class="btn btn-lg btn-outline-secondary mr-2 d-inline-flex justify-content-center align-items-center my-2 mx-2"
            type="button" (click)="setLeitorFacial(o)">
            {{ o.dis_descricao }}
          </button>
        </div>

      </div>

      <!-- Images -->
      <div class="form-group col-md-12 form-row" #galleryContainer>

        <!-- Add this element to properly relayout grid -->
        <div class="gallery-sizer col-sm-6 col-md-6 col-xl-3 position-absolute"></div>

        <div *ngFor="let image of images" class="gallery-thumbnail col-sm-6 col-md-6 col-xl-3 mb-2">
          <a href="javascript:void(0)" (click)="triggerSnapshotLeitorFacial(image)"
            class="img-thumbnail img-thumbnail-zoom-in">
            <span class="img-thumbnail-overlay bg-dark opacity-25"></span>
            <span class="img-thumbnail-content display-4 text-white">
              <i class="ion ion-ios-search"></i>
            </span>
            <img [src]="image" class="img-fluid">
          </a>
        </div>

      </div>
      <!-- / Images -->

    </div>

    <!-- FIM - Leitor Facial -->

    <div class="form-row" [hidden]="!showCropped">

      <div class="col-md-6">
        <h4><small class="text-muted">{{ 'upload.centralize' | translate}}</small></h4>
        <image-cropper #cropper [maintainAspectRatio]="optionsUpload.maintainAspectRatio"
        resizeToWidth="1920" onlyScaleDown="true" resizeToHeight="1920" [imageQuality]="100"
          [aspectRatio]="optionsUpload.aspectRatio" format="jpeg" [alignImage]="'left'" [imageBase64]="imageBase64"
          (imageCropped)="imageCropped($event)"></image-cropper>

      </div>
      <div class="col-md-6">
        <h4><small class="text-muted">{{ 'upload.visualizacao' | translate}}</small></h4>
        <div *ngIf="croppedImage">
          <img [src]="croppedImage" style="max-width: 400px; max-height: 200px; margin-top: 5px;"
            [style.border]="croppedImage ? '1px solid black' : 'none'">
        </div>
      </div>

    </div>

    <div class="form-row" [hidden]="!showCropped">
      <div class="col-md-6">
        <button type="button" class="btn  btn-outline-danger" (click)="trocarFoto()">{{ 'upload.trocar_foto' | translate
          }}</button>
      </div>
    </div>

    <div class="form-row mt-4" *ngIf="optionsUpload.modeloPlanilha">
      <div class="form-group col-md-12">
        {{ 'upload.excel.se_preferir' | translate }}&nbsp;<a [href]="optionsUpload.modeloPlanilha" download>{{
          'upload.excel.modelo_planilha' | translate }}</a>

      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <ngb-alert [type]="'dark-danger'" *ngIf="this.form.invalid">{{ 'upload.invalido' | translate }}</ngb-alert>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalService.close(null)">{{ 'botao.cancelar' | translate
      }}</button>
    <button type="submit" class="btn btn-primary" [ladda]="loading[0]" data-style="expand-right">{{ 'upload.enviar' |
      translate }}</button>
  </div>

</form>
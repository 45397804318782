import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, Input, EventEmitter, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import { Dicionario } from 'src/app/model/dicionario';
import { Login } from 'src/app/model/login';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { AuditViewComponent } from 'src/app/audit/audit-view/audit-view.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CurrencyMaskModule } from "ngx-currency-mask";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VisualizarCampanhaPopupComponent } from '../visualizar-campanha-popup/visualizar-campanha-popup.component';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from 'ngx-clipboard';





@Component({
  selector: 'app-vender-campanha-popup',
  templateUrl: './vender-campanha-popup.component.html',
  styleUrls: [
    '../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
    '../../../vendor/libs/ng-select/ng-select.scss',
    '../../../vendor/libs/ngx-swiper-wrapper/ngx-swiper-wrapper.scss',
    './vender-campanha-popup.component.scss'

  ]
})
export class VenderCampanhaPopupComponent implements OnInit, AfterViewInit {

  // @ViewChild('regiao_campanha_input', { static: false }) // <--- changed
  // public myInput?: ElementRef;

  //#region VARIABLE
  // https://gist.github.com/ux-powered/c47614ec346517a6c17306733574ecdb
  isRTL: boolean;
  user: Login;
  id: number = 0;
  loading = [false];
  submitted = false;
  disabled = false;
  numberOfItemsFromEndBeforeFetchingMore = 5;

  @BlockUI() blockUIPage: NgBlockUI;
  @BlockUI('element') blockUIElement: NgBlockUI;

  campanha: any;
  urlCampanhaEmbed: any = '';

  plataforma: any;
  vendedor: any;
  nome_plataforma: any = '';
  nome_vendedor: any = '';
  cpf_vendedor: any = '';

  url_seridor: string;
  url_agreste: string;
  url_seridor_sertao: string;
  url_PME: string = 'https://portal2.planoclin.com.br/Vendas/SelecionarRegiao/';
  plataforma_validada: boolean = false;
  vendedor_validado: boolean = false;

  mostraNome: boolean = false

  qtdListaAtivas = 0;
  qtdListaDisponivel = 0;
  mostrarConteudo: boolean = false

  state: any = {
    list: null,
    list_ativas: null,
    list_disponiveis: null,
    is404: [false],
    qtd_por_pessoa: null,
    qtd_por_tipo_acesso: null,
    data_atual: this.getDataAtual()
  };

  emp_discursovenda: [null];
  plano_discursovenda: [null];
  carregouURl = false


  // resultSaque: any;
  // lotesComissao: any;
  // mensagem;
  // icone;
  typeaheadRegioes = new EventEmitter<string>();

  @BlockUI('elementRegiao') blockUIElementRegiao: NgBlockUI;


  // @Input() regiaoCampanhaView: any;
  // @Input() idCampanha: number = null;

  public form: FormGroup;

  get f() {
    return this.form.controls;
  }

  //#endregion

  //#region CONSTRUCTOR
  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, public translateService: TranslateService,
    private appService: AppService,
    private apiService: ApiService, private fb: FormBuilder, private cd: ChangeDetectorRef,
    private router: Router, private sanitizer: DomSanitizer, private _clipboardService: ClipboardService,
    private toastr: ToastrService) {

    this.isRTL = appService.isRTL;

  }




  ngAfterViewInit() {
    // Trigger resize event to redraw swiper
    setTimeout(() => {
      if (document.createEvent) {
        let event;

        if (typeof document['documentMode'] === 'number' && document['documentMode'] > 10) {
          event = document.createEvent('Event');
          event.initEvent('resize', false, true);
        } else {
          event = new Event('resize');
        }

        window.dispatchEvent(event);
      } else {
        window['fireEvent']('onresize', document['createEventObject']());
      }
    }, 50);
  }

  swiper3dCoverflowEffect = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true
    },
    pagination: {
      el: '.swiper-pagination'
    }
  };

  swiperWithPagination = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  };

  ngOnInit(): void {

    this.form = this.fb.group({
      cod_vendedor: [null, [Validators.required, Validators.maxLength(8)]],
      // ope_nome: [null],
      cod_plataforma: [null, [Validators.required, Validators.maxLength(8)]]
    });


    this.apiService.getUser().then((res: Login) => {
      this.user = res;
      setTimeout(() => {
        this.carregarMinhasCampanhasAtivas();

        this.form.patchValue({
          cod_vendedor: this.user.conta.con_cods4_corretor,
          cod_plataforma: this.user.conta.con_cods4_plataforma
        })



        let codEmpresa = this.form.value.cod_plataforma;
        if (codEmpresa.length > 3)
          this.loading[0] = true;
        this.CarregarInformacoesUsuario();

      }, 200);
    });
  }

  //#endregion


  async carregarMinhasCampanhasAtivas() {

    try {

     // this.state.is404[0] = false;
      this.loading[0] = true;

      let idConta = this.user.conta.pk_idconta;

      let data = await this.apiService.getCampanhasAtivasEDisponiveis();

      this.state.list_ativas = []
      this.state.list_disponiveis = []
      this.qtdListaAtivas = 0
      this.qtdListaDisponivel = 0

      data.forEach(o => {
        if (o.conta_campanhas_json != null) {
          let estaNaCampanha = o.conta_campanhas_json.find(x => x.fk_idconta == idConta)
          if (estaNaCampanha) {

            let imagePath = `/campanhas/${o.pk_idcampanha}.jpg`
            let ret = 'https://dest-clinfluencer-imagens.s3.amazonaws.com' + imagePath
            //o.img = ret

            o.img = this.sanitizer.bypassSecurityTrustStyle(`url("${ret}")`);

            this.state.list_ativas.push(o);
            this.qtdListaAtivas = this.state.list_ativas.length
          }
          else {
            this.state.list_disponiveis.push(o);
            this.qtdListaDisponivel = this.state.list_disponiveis.length
          }
        } else {
          this.state.list_disponiveis.push(o);
          this.qtdListaDisponivel = this.state.list_disponiveis.length
        }
      });

      this.loading[0] = false;
      this.state.paging = {
        total: data[0].full_count
      }

    } catch (error) {

      this.loading[0] = false;

      if (error && error.status != 401 && error.status != 404) {
        this.apiService.errorMessageShow(error);
      }

    //  this.state.is404[0] = error.status == 404;

    }
  }

  getDataAtual() {

    let retorno = ""
    let data = new Date()
    let locale = 'pt-BR';

    let day = data.toLocaleDateString(locale, { day: "numeric" });
    let month = data.toLocaleDateString(locale, { month: "numeric" });
    let year = data.toLocaleDateString(locale, { year: "numeric" });
    let hora = data.toLocaleTimeString(locale, { hour: "numeric" });
    let minuto = data.toLocaleTimeString(locale, { minute: "numeric" });

    let diaAtual = (data.getDay() + 1).toString()

    let dataAtual: any = data.toLocaleDateString('pt-BR', {}).toString()

    dataAtual = (diaAtual + '/' + dataAtual).split('/')

    retorno = this.translateService.instant('dias_da_semana.' + dataAtual[0]) + ', ' + day + this.translateService.instant('dashboard_2.of') + this.translateService.instant('meses_do_ano.' + month) + this.translateService.instant('dashboard_2.of') + year + " hora: " + hora + ":" + minuto

    return retorno
  }

  async onSubmit() {
    try {

      this.submitted = true;

      this.loading[2] = true;

      let codPlataforma = 0
      let codVendedor = 0

      if (this.form.value.cod_plataforma)
        codPlataforma = this.form.value.cod_plataforma
      else
        codPlataforma = 100006


      if (this.form.value.cod_vendedor)
        codVendedor = this.form.value.cod_vendedor

      //  this.urlCampanhaEmbed =


      if (this.form.invalid) {
        return;
      }

      let url = 'https://dev.vendas.agiben.com.br/#/campanha/' + this.campanha.pk_idcampanha + '/' + codPlataforma + '/' + codVendedor
      window.open(url, '_blank');
      //console.log(this.urlCampanhaEmbed)

      this.loading[2] = false;

      // this.carregouURl = true
    } catch (error) {

      this.apiService.errorMessageShow(error);

    } finally {
    }
  }



  delayBlockUI;
  toggleElementBlocking() {



    this.stopToggleElementBlocking();

    this.delayBlockUI = setTimeout(() => {

      this.blockUIElement.start();

    }, 1000);

  }

  stopToggleElementBlocking() {
    if (this.delayBlockUI) {
      clearTimeout(this.delayBlockUI);
      this.blockUIElement.stop();
      this.delayBlockUI = null;
      return;
    }
  }


  async carregarPlataforma(i) {

    try {


      let codEmpresa = this.form.value.cod_plataforma;
      this.loading[i] = true;
      this.toggleElementBlocking();

      if (!codEmpresa || codEmpresa.length < 3) {
        this.nome_plataforma = "Informe um código válido."
        this.mostraNome = true
      }
      else {
        let body = {
          query: `SELECT * FROM  FN_siteBuscarPlataforma (${codEmpresa}) `
        }

        this.plataforma = await this.apiService.execSQL_S4(body);

        if (this.plataforma && this.plataforma.length > 0) {
          this.plataforma.forEach(row => {

            this.nome_plataforma = row.nm_empregado;
            this.mostraNome = true
            this.loading[i] = false;
            this.plataforma_validada = true;

            this.url_seridor = 'https://portal2.planoclin.com.br/Vendas/CadastroPECONSIG.aspx?convenio=3&&IdVendedor=' + this.cpf_vendedor + '&CodPlataforma=' + this.form.value.cod_plataforma + '&p=9999&regiao=1&collapse=true'
            this.url_agreste = 'https://portal2.planoclin.com.br/Vendas/CadastroPECONSIG.aspx?convenio=3&&IdVendedor=' + this.cpf_vendedor + '&CodPlataforma=' + this.form.value.cod_plataforma + '&p=9999&regiao=3&collapse=true'
            this.url_seridor_sertao = 'https://portal2.planoclin.com.br/Vendas/CadastroPECONSIG.aspx?convenio=3&&IdVendedor=' + this.cpf_vendedor + '&CodPlataforma=' + this.form.value.cod_plataforma + '&regiao=2&p=9999&collapse=true'

          })

        } else {
          this.nome_plataforma = 'Plataforma não encontrada.';
          this.loading[i] = false;
          this.plataforma_validada = false;
          this.url_seridor = ''
          this.url_agreste = ''
          this.url_seridor_sertao = ''
        }
      }
    } catch (error) {
      this.loading[i] = false;
    } finally {
      this.stopToggleElementBlocking();
      this.loading[i] = false;
    }

  }

  async carregarVendedor(i) {

    try {


      let codVendedor = this.form.value.cod_vendedor;
      this.loading[i] = true;
      this.toggleElementBlocking();

      if (!codVendedor || codVendedor.length < 3) {
        this.nome_vendedor = "Informe um código válido."
        this.mostraNome = true
      }
      else {
        let body = {
          query: `SELECT * FROM  FN_siteBuscarVendedorCodFuncionario (${codVendedor}) `
        }

        this.vendedor = await this.apiService.execSQL_S4(body);

        if (this.vendedor && this.vendedor.length > 0) {
          this.vendedor.forEach(row => {

            this.nome_vendedor = row.nm_empregado;
            this.cpf_vendedor = row.nr_cpf;
            this.mostraNome = true
            this.vendedor_validado = true;
            this.loading[i] = false;

            this.url_seridor = 'https://portal2.planoclin.com.br/Vendas/CadastroPECONSIG.aspx?convenio=3&&IdVendedor=' + this.cpf_vendedor + '&CodPlataforma=' + this.form.value.cod_plataforma + '&regiao=1&p=9999&collapse=true'
            this.url_agreste = 'https://portal2.planoclin.com.br/Vendas/CadastroPECONSIG.aspx?convenio=3&&IdVendedor=' + this.cpf_vendedor + '&CodPlataforma=' + this.form.value.cod_plataforma + '&regiao=3&p=9999&collapse=true'
            this.url_seridor_sertao = 'https://portal2.planoclin.com.br/Vendas/CadastroPECONSIG.aspx?convenio=3&&IdVendedor=' + this.cpf_vendedor + '&CodPlataforma=' + this.form.value.cod_plataforma + '&regiao=2&p=9999&collapse=true'

          })

        } else {
          this.nome_vendedor = 'Usuário não encontrado.';
          this.loading[i] = false;
          this.cpf_vendedor = '';
          this.vendedor_validado = false;
          this.url_seridor = '';
          this.url_agreste = '';
          this.url_seridor_sertao = '';
        }
      }
    } catch (error) {
      this.loading[i] = false;
    } finally {
      this.stopToggleElementBlocking();
      this.loading[i] = false;
    }

  }


  async CarregarInformacoesUsuario() {



    let codEmpresa = this.form.value.cod_plataforma;
    let codVendedor = this.form.value.cod_vendedor;

    if (codEmpresa) {
      this.carregarPlataforma(4);
    }

    if (codVendedor) {
      this.carregarVendedor(3);
    }

  }

  copyLinkVenda(obj) {

    try {

      //Caso ele mostre o nome da plataforma quer dizer que a plataforma existe e valida. 
      if (!this.mostraNome) {
        throw 'Código da plaforma informado inválido'
      }

      this.campanha = obj


      this.loading[0] = true;

      let codPlataforma = 0
      let codVendedor = 0

      if (this.form.value.cod_plataforma)
        codPlataforma = this.form.value.cod_plataforma
      else
        codPlataforma = 100006

      if (this.form.value.cod_vendedor)
        codVendedor = this.form.value.cod_vendedor

      var url_atual = window.location.href;
      let url = "";


      if (this.form.invalid) {
        return;
      }


      if (!this.vendedor_validado || !this.plataforma_validada) {
        this.toastr.show(null, "Informe um código válido para plataforma e/ou vendedor.");
      } else {

        if (url_atual.indexOf("localhost") > 0) {
          url = 'https://agiben.com.br/redirect/index.html?url=' + 'https://dev.vendas.agiben.com.br/_/campanha/' + this.campanha.pk_idcampanha + '/' + codPlataforma + '/' + codVendedor + '&e=c'

        } else {
          url = 'https://agiben.com.br/redirect/index.html?url=' + 'https://vendas.agiben.com.br/_/campanha/' + this.campanha.pk_idcampanha + '/' + codPlataforma + '/' + codVendedor + '&e=c'
        }

        this._clipboardService.copy(url);
        this.toastr.show(null, "Link copiado para a area de transferência");
      }



      this.loading[0] = false;

    } catch (error) {

      this.loading[0] = false;
      this.apiService.errorMessageShow(error);

    }

  }


  async redirectTelaDeVendas(obj) {

    try {

      this.submitted = true;

      //Caso ele mostre o nome da plataforma quer dizer que a plataforma existe e valida. 
      if (!this.mostraNome) {
        throw 'Código da plaforma informado inválido'
      }

      this.loading[0] = true;

      if (obj) {

        this.campanha = obj

        let codPlataforma = 0
        let codVendedor = 0

        if (this.form.value.cod_plataforma)
          codPlataforma = this.form.value.cod_plataforma
        else
          codPlataforma = 100006

        if (this.form.value.cod_vendedor)
          codVendedor = this.form.value.cod_vendedor

        var url_atual = window.location.href;
        let url = "";

        if (!this.vendedor_validado || !this.plataforma_validada) {
          this.toastr.show(null, "Informe um código válido para plataforma e/ou vendedor.");
        } else {

          if (url_atual.indexOf("localhost") > 0 || url_atual.indexOf("dev.") > 0) {
            url = 'https://dev.vendas.agiben.com.br/#/campanha/' + this.campanha.pk_idcampanha + '/' + codPlataforma + '/' + codVendedor

          } else {
            url = 'https://vendas.agiben.com.br/#/campanha/' + this.campanha.pk_idcampanha + '/' + codPlataforma + '/' + codVendedor
          }

          window.open(url, '_blank');
        }
      }

      this.loading[0] = false;

    } catch (error) {

      this.apiService.errorMessageShow(error);
      this.loading[0] = false;

    } finally {
      this.loading[0] = false;

    }

  }



  // async view(obj) {

  //   

  //   try {

  //     let o = obj;

  //     let options = { windowClass: 'modal-lg animate nested-modal' };

  //     let modal = this.modalService.open(VisualizarCampanhaPopupComponent, options); // Render modal with modal component  

  //     (<VisualizarCampanhaPopupComponent>modal.componentInstance).campanha = o;

  //   } catch (error) {

  //     this.loading[0] = false;

  //     if (error && error.status != 401 && error.status != 404) {
  //       this.apiService.errorMessageShow(error);
  //     }

  //   } finally {
  //     this.loading[0] = false;
  //   }

  // }



  copyLinkVendaServidor(obj) {

    try {

      this.loading[0] = true;
      this._clipboardService.copy(this.url_seridor);
      this.loading[0] = false;

      this.toastr.show(null, "Link copiado para a area de transferência");
    } catch (error) {
      this.loading[0] = false;
      this.apiService.errorMessageShow(error);

    }

  }

  copyLinkVendaServidorSertao(obj) {

    try {

      this.loading[0] = true;
      this._clipboardService.copy(this.url_seridor_sertao);
      this.loading[0] = false;

      this.toastr.show(null, "Link copiado para a area de transferência");
    } catch (error) {
      this.loading[0] = false;
      this.apiService.errorMessageShow(error);

    }

  }

  copyLinkVendaServidorAgreste(obj) {

    try {

      this.loading[0] = true;
      this._clipboardService.copy(this.url_agreste);
      this.loading[0] = false;

      this.toastr.show(null, "Link copiado para a area de transferência");
    } catch (error) {
      this.loading[0] = false;
      this.apiService.errorMessageShow(error);

    }

  }


  copyLinkVendaPME(obj) {

    try {

      this.loading[0] = true;
      this._clipboardService.copy(this.url_PME);
      this.loading[0] = false;

      this.toastr.show(null, "Link copiado para a area de transferência");
    } catch (error) {
      this.loading[0] = false;
      this.apiService.errorMessageShow(error);

    }

  }



}

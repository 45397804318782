import {Component, Input} from '@angular/core'

@Component({
	selector: 'card-clube-beneficios',
	templateUrl: './card-clube-beneficios.component.html',
	styleUrls: ['card-clube-beneficios.component.scss'],
})
export class CardClubeBeneficiosComponent {
	@Input() tituloCard = 'Clube de benefícios exclusivo'
	@Input() textoCard =
		'Aproveite uma variedade de descontos especiais e ofertas únicas em farmácias, entretenimento, viagens e muito mais!'
	@Input() textoButton = 'Conhecer o Clube Agiben'
	@Input() imgSrcDesktop = '../../../../assets/img/banner-beneficios.jpg'
	@Input() imgSrcMobile = '../../../../assets/img/banner-beneficios-mobile.jpg'
	@Input() linkButton = 'https://clube.agiben.com.br'

	constructor() {}
}

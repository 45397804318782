<nav class="navbar navbar-expand-lg bg-light">
	<div class="container" style="padding-top: 10px; padding-bottom: 10px">
		<a class="navbar-brand" href="javascript:void(0)"><img src="../../../../assets/agiben-site/img/agiben.png"
				style="height: 40px" class="navbar-brand-img" alt="..." /></a>
	</div>
</nav>

<ng-template #defaultModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h5 class="modal-title">Selecione o sindicato desejado <br /></h5>
		
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<div class="list-group">
					<a href="javascript:void(0)" *ngFor="let item of arrayDeConteudos"
						(click)="selecionarSindicato(item.id, c)" class="list-group-item list-group-item-action">{{
						item.Nome }}</a>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<section id="sectionBeneficios" class="overlay overlay-dark overlay-10" style="
		background-image: url('./assets/agiben-site/img/sind/sintbref/banner.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	">
	<div class="container">
		<div class="row align-items-center justify-content-center min-vh-100 py-8 py-md-11">
			<div class="col-12 mt-auto"></div>
		</div>
	</div>
</section>

<!-- Todos os beneficios em um so lugar -->
<section class="py-8 py-md-12 sectionAgiben" id="sectionTwo">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-12 col-lg-8 text-center">
				<img style="width: 100px" src="assets/agiben-site/img/sind/sintbref/logo.png" alt="..." />
				<br />
				<h4 class="mt-4 mb-4">
					Há muitos anos o Sindicato dos empregados e trabalhadores nas instituições reliosas, beneficientes e
					filantrópicas do estado de Ceará reivindica a implantação
					de benefícios que possam refletir no bem estar, proteção e comodidade dos colaboradores.​
				</h4>

				<a class="btn btn-primary" href="javascript:void(0)" #modalLink (click)="
						open(defaultModal, {
							windowClass: 'modal-md animate',
							backdrop: 'static',
							keyboard: false
						})
					"><i class="fas fa-align-justify"></i> Selecionar sindicato</a>
			</div>
		</div>
	</div>
</section>


<section id="PAFInformacao" class="py-5 text-center" style="background: #fff7e0 !important">
	<div class="ui-bg-overlay bg-dark opacity-50"></div>
	<div class="container">
		<div class="row justify-content-center card_price">
			<div class="col-12 col-md-10 col-lg-4 col-xl-6">
				<div class="card" *ngIf="sindicatoSelecionado">
					<div class="card-header">Programa de assistência familiar - PAF</div>
					<div class="card-body">
						<!-- Preheading -->
						<div class="text-center mb-3">
							<span class="badge rounded-pill bg-primary-soft">
								<span class="text-uppercase">{{ sindicatoSelecionado?.Nome }}</span>
							</span>
						</div>

						<!-- Price -->
						<div class="d-flex justify-content-center">
							<span class="h2 mb-0 mt-2">R$</span>
							<span class="display-3 d-inline-block font-weight-bold align-middle">{{
								sindicatoSelecionado?.Valor_Titular | number: '1.0-0'
								}}</span>
							<span class="h2 align-self-end mb-1">,{{
								formatarCasasDecimais(sindicatoSelecionado?.Valor_Titular) }}</span>
						</div>

						<!-- Text -->
						<p class="text-center text-muted mb-5">Mensais por trabalhador</p>

						<div class="d-flex" *ngFor="let proc of sindicatoSelecionado?.Procedimentos">
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<p style="padding-left: 10px">{{ proc }}</p>
						</div>
					</div>
					<div class="card-footer text-muted">
						<a class="btn btn-md btn-primary" href="{{ sindicatoSelecionado?.Link }}" target="_blank"><i
								class="far fa-building"></i> Cadastrar empresa
						</a>
						<a class="btn btn-md btn-primary" href="{{ sindicatoSelecionado?.Link_CCT }}" target="_blank"><i
								class="fas fa-level-down-alt"></i> CCT
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Section platform -->
<section id="infoPlatatormaRH" style="background-color: #ffffff !important">
	<div class="container ">
		<div class="page-margin" style="padding-top: 50px; padding-bottom: 50px;">
			<div class="d-lg-flex d-none flex-row">
				<landing-page-info-card class="col-lg-6 col-sm-12" title="Plataforma de gestão para o RH"
					subtitle="&nbsp;Gerencie os benefícios<br>em um só lugar&nbsp;<br><br>" [backgroundSubtitle]="true"
					description="Simplifique a gestão de benefícios para sua empresa com nossa plataforma exclusiva, completa e eficiente.<br/><br/> Sem burocracia, oferecemos agilidade e facilidade para que sua empresa tenha uma melhor e mais rápida gestão no dia-a-dia."
					primaryButtonLabel="Cadastrar minha empresa"
					(primaryEventEmitter)="redirectTo(sindicatoSelecionado?.Link)"></landing-page-info-card>

				<card-imagem-landing-page imgUrl="../../assets/img/device-macbook.svg"
					posicaoEfeito="central"></card-imagem-landing-page>
			</div>

			<div class="d-flex d-lg-none flex-column">
				<landing-page-info-card class="col-lg-6 col-sm-12" title="Plataforma de gestão para o RH"
					subtitle="&nbsp;Gerencie os benefícios<br>em um só lugar&nbsp;<br>" [backgroundSubtitle]="true"
					description="Simplifique a gestão de benefícios para sua empresa com nossa plataforma exclusiva, completa e eficiente.<br/><br/> Sem burocracia, oferecemos agilidade e facilidade para que sua empresa tenha uma melhor e mais rápida gestão no dia-a-dia."
					primaryButtonLabel="Cadastrar minha empresa"
					(primaryEventEmitter)="redirectTo(sindicatoSelecionado?.Link)"></landing-page-info-card>

				<card-imagem-landing-page class="mt-5" imgUrl="../../assets/img/device-macbook.svg"
					posicaoEfeito="central"></card-imagem-landing-page>
			</div>
		</div>
	</div>
</section>



<!-- Section odontologia -->
<section class="pt-md-8 pb-8 pb-md-11 mt-10" id="sectionOdontologia" *ngIf="sindicatoSelecionado">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-10 col-lg-9 col-xl-8">
				<h2 class="fw-bold">Odontologia</h2>
				<!-- Fugure -->
				<figure class="figure">
					<!-- Image -->
					<img class="figure-img img-fluid rounded lift lift-lg"
						src="./assets/agiben-site/img/photos/Odontologia.jpg" alt="..." />
				</figure>
				<!-- Text -->
				<h3 class="fw-bold">Plano odontológico (Clin)</h3>
				<p class="mt-5">
					Aqui você não fica na mão! Com apenas alguns cliques, solicite a marcação de consultas no
					dia e horário que quiser, visualize a rede de clínicas credenciadas, acesse a carteira
					digital e inclua dependentes no seu plano.
				</p>

				<div class="row mb-2 mt-4">
					<div class="col-12 col-lg-6">
						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Consulta Inicial</p>
						</div>

						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Curativo em caso de hemorragia bucal</p>
						</div>

						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Curativo em caso de odontalgia aguda/pulpectomia/necrose</p>
						</div>

						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Imobilização dentária temporária</p>
						</div>

						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Recimentação de peça protética</p>
						</div>

						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Tratamento de alveolite</p>
						</div>

					</div>
					<div class="col-12 col-lg-6 mb-6 mb-md-0" style="">
						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Colagem de fragmentos</p>
						</div>

						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Incisão e drenagem de abscesso extra-oral</p>
						</div>

						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Incisão e drenagem de abscesso intra-oral</p>
						</div>

						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Reimplante de dente avulsionado</p>
						</div>

						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Orientação de higiene bucal</p>
						</div>

						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Evidenciação de placa bacteriana</p>
						</div>

						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>
							<!-- Text -->
							<p class="text-primary">Aplicação tópica de flúor</p>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- / .row -->
	</div>
	<!-- / .container -->
</section>

<!-- Section Telemedicina -->
<section class="pt-md-8 pb-8 pb-md-11 mt-10" id="sectionMedicina" *ngIf="sindicatoSelecionado">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-10 col-lg-9 col-xl-8">
				<h2 class="fw-bold">Medicina</h2>
				<!-- Fugure -->
				<figure class="figure">
					<!-- Image -->
					<img class="figure-img img-fluid rounded lift lift-lg"
						src="./assets/agiben-site/img/photos/telemedicina.jpg" alt="..." />
				</figure>
				<!-- Text -->
				<h3 class="fw-bold">Telemedicina (Api Saúde)</h3>
				<p class="mt-5">
					Tenha acesso à uma plataforma completa de saúde e conecte-se a profissionais para realizar
					suas consultas.
				</p>
				<p class="">
					Escolha o melhor dia e horário. Você precisa apenas de um aparelho com acesso à internet.
					As consultas são realizadas sem barreiras geográficas. A qualquer hora, em qualquer lugar.
				</p>

				<div class="row mb-2 mt-4" style="">
					<div class="col-lg-6">
						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<!-- Text -->
							<p class="text-primary">Clínica geral</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</div>

							<p class="text-primary">Cardiologia</p>
						</div>


					</div>
					<div class="col-lg-6">
						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>

							<!-- Text -->
							<p class="text-primary">Dermatologia</p>
						</div>

						<!-- Item -->
						<div class="d-flex">
							<!-- Check -->
							<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
								<i class="fas fa-check"></i>
							</span>

							<!-- Text -->
							<p class="text-primary">Endocrinologia</p>
						</div>
					</div>
				</div>
				<br>
				<h5>TELETERAPIA</h5>

				<ol>
					<li>O trabalhador terá direito a Consultas Psicológicas mensal online pela plataforma recebendo os
						laudos e outros documentos da terapia validado com certificação e assinatura digital na própria
						plataforma por e-mail, WhatsApp e link por SMS.</li>
					<li>Caso o Psicólogo entenda necessário, o trabalhador terá direito a até 04 (quatro) sessões de
						terapia mensais sem custo adicional ou qualquer pagamento extra.</li>
					<li>A consulta será realizada de forma on-line, via plataforma de teleatendimento, que devem ser
						previamente agendadas junto a Central de Atendimento, via telefone: <a
							href="tel:08009406333">0800-940-6333</a>, que será <a
							href="http://www3.mte.gov.br/sistemas/mediador/Resumo/ResumoVisualizar?NrSolicitacao=MR064420/2023">www3.mte.gov.br/sistemas/mediador/Resumo/ResumoVisualizar?NrSolicitacao=MR064420/2023</a>,
						exclusiva para o trabalhador da categoria, com horário de funcionamento de 09:00 às 18:00 de
						Segunda a Sexta-feira, exceto Sábado, Domingo e Feriado.</li>
					<li>Em caso de desistência ou impossibilidade de comparecimento deve-se informar em até 24 horas de
						antecedência a sua ausência, e caso não o faça, será considerado ausência injustificada do
						empregado. Havendo reincidência de ausência injustificada do empregado a gestora gerará, em nome
						do empregado, boleto específico no valor de R$20,00 (vinte reais) correspondente a consulta
						agendada e faltosa, que deve ser solicitado por meio de e-mail <a
							href="mailto:sac@agiben.com.br">sac@agiben.com.br</a>. Até que haja o devido pagamento o
						empregado representado ficará impedido de acessar a plataforma de teleatendimento, que, após o
						devido pagamento do referido boleto deve enviar o comprovante ao mesmo e-mail, para que a
						liberação seja feita.</li>
				</ol>

				<h5>ASSISTÊNCIA CHECKUP SAÚDE</h5>

				<ol>
					<li>Todos os empregados regularmente contratados e representados pelo SINTIBREF, terão direito ao um
						reembolso de até R$100,00 (cem reais) a cada período de 180 (cento e oitenta) dias, destinado
						exclusivamente à realização de exames de sangue, desde que previamente prescrito via
						teleatendimento, com objetivo de promover a saúde preventiva e o bem-estar destes.</li>
					<li>O procedimento para reembolso será feito mediante solicitação à Gestora, diretamente pelo
						empregado, por meio de e-mail <a href="mailto:sac@agiben.com.br">sac@agiben.com.br</a>, ou
						através do Departamento de Recursos Humanos das empresas empregadoras, dentro de um prazo de até
						05 (cinco) dias da data de realização dos exames, e mediante apresentação da solicitação médica
						do exame, nota fiscal do exame de sangue, e o formulário de solicitação de reembolso devidamente
						preenchido, conforme modelo fornecido pela gestora.</li>
				</ol>

				<h5> BENEFÍCIO MEDICAMENTO PARA TODOS - CEARÁ:</h5>

				<ol>
					<li>O benefício Medicamento para Todos oferecerá gratuitamente medicamentos (Genéricos ou
						Similares), quando receitados pelos profissionais no primeiro Atendimento Online realizado
						através da Telemedicina.</li>
					<li>O trabalhador solicitará seu reembolso à Central de Atendimento, via e-mail: <a
							href="mailto:sac@agiben.com.br">sac@agiben.com.br</a>, enviando os seguintes documentos: (i)
						nome completo do titular; (ii) prescrição do medicamento fornecida pelo médico do
						teleatendimento; (iii) nota fiscal ou CUPOM FISCAL de compra da medicação; (iv) chave PIX do
						titular, que deverá obrigatoriamente ser CPF.</li>
					<li>Após a validação dos requisitos exigidos para o reembolso, o pagamento será realizado em até
						05(cinco) dias úteis.</li>
				</ol>
			</div>
		</div>
		<!-- / .row -->
	</div>
	<!-- / .container -->
</section>

<!-- Section Seguros -->
<section class="pt-md-8 pb-8 pb-md-11 mt-10" *ngIf="sindicatoSelecionado && sindicatoSelecionado.Assistencia">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-10 col-lg-9 col-xl-8">
				<h2 class="fw-bold">Auxílio</h2>
				<!-- Fugure -->
				<figure class="figure">
					<!-- Image -->
					<img class="figure-img img-fluid rounded lift lift-lg"
						src="./assets/agiben-site/img/photos/seguros.jpg" alt="..." />
				</figure>
				<!-- Text -->
				<h3 class="fw-bold">Funeral e Natalidade</h3>
				<!-- <p class="mt-5">
					Proteção é sempre bem-vinda! Oferecemos um pacote completo para garantir a segurança da
					sua Organização.
				</p>
				<p class="">
					Reconhecemos que colaboradores satisfeitos e saudáveis são essenciais para o sucesso de
					nossa empresa. É por isso que estamos comprometidos em fornecer um abrangente Plano de
					Assistência ao Colaborador, visando o bem-estar e a tranquilidade de todos que fazem parte
					do nosso trabalho.
				</p>
				<p>Para maiores informações,</p> -->

				<div class="d-flex">
					<!-- Check -->
					<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
						<i class="fas fa-check"></i>
					</span>

					<!-- Text -->
					<p class="text-default">O auxílio funeral é disponibilizado em casos de mortes naturais ou acidentais para os
						trabalhadores associados.</p>
				</div>
				<div class="d-flex">
					<!-- Check -->
					<span class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
						<i class="fas fa-check"></i>
					</span>

					<!-- Text -->
					<p class="text-default">O auxílio natalidade é fornecido para os trabalhadores associados, após o nascimento do bebê. O
						titular deverá entrar em contato com a central de atendimento em até 60 dias e enviar a certidão de
						nascimento.</p>
				</div>

			
				<p>Um cartão magnético será entregue ao titular contendo o valor em crédito referente ao auxílio.</p>

				<p>Para mais informações entre em contato pelo 0800 006 2254 ou Baixe a CCT.</p>
				<a class="btn btn-primary mt-2" href="{{ sindicatoSelecionado?.Link_CCT }}" target="_blank"><i
						class="fas fa-level-down-alt"></i> Baixar CCT
				</a>
			</div>
		</div>
	</div>
</section>
<hr class="mt-10" />
<section class="pt-6 pt-md-8 pb-8 pb-md-11 mt-10">
	<div class="container" style="">
		<div class="row justify-content-center">
			<div class="col-12 col-md-10 col-lg-8 text-center">
				<!-- Heading -->
				<h2>Uma plataforma, <b>vários beneficios. </b></h2>
			</div>
		</div>
	</div>
</section>

<nav class="footer bg-light pt-4 mt-10">
	<div class="container" style="padding: 60px 60px">
		<div class="row">
			<div class="col-12 col-md-4 col-lg-3">
				<!-- Brand -->
				<img src="./assets/agiben-site/img/agiben.png" alt="..." style="height: 60px" class="" />

				<!-- Text -->
				<p class="text-gray-700 mb-2">Uma plataforma, vários benefícios.</p>

				<!-- Social -->
				<ul class="list-unstyled list-inline list-social mb-6 mb-md-0">
					<li class="list-inline-item list-social-item me-3">
						<a href="https://www.instagram.com/agibenbeneficios/" target="_blank"
							class="text-decoration-none">
							<i class="d-block fab fa-instagram" ng-reflect-klass="d-block"
								ng-reflect-ng-class="fab fa-instagram"></i>
						</a>
					</li>
				</ul>
			</div>
			<div class="col-6 col-md-4 col-lg-2" style="visibility: hidden">
				<!-- Heading -->
				<h6 class="fw-bold text-uppercase text-gray-700">Parceiros</h6>

				<!-- List -->
				<ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
					<li class="mb-3">
						<a href="https://planoclin.com.br/" target="_blank" class="text-reset"> Clin </a>
					</li>
					<li class="mb-3">
						<a href="https://institucional.melhor.ai/" target="_blank" class="text-reset">
							Melhor.ai
						</a>
					</li>

					<li class="mb-3">
						<a href="https://www.allianz.com.br/" target="_blank" class="text-reset"> Allianz </a>
					</li>
				</ul>
			</div>
			<div class="col-6 col-md-4 col-lg-2">
				<!-- Heading -->
				<h6 class="fw-bold text-uppercase text-gray-700" style="display: none">Contatos</h6>
			</div>
			<div class="col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0">
				<div>
					<!-- Heading -->
					<h6 class="fw-bold text-uppercase text-gray-700">Parceriros</h6>

					<!-- List -->
					<ul class="list-unstyled text-muted mb-0">
						<li class="mb-3">
							<a href="https://planoclin.com.br/" target="_blank" class="text-reset"> Clin </a>
						</li>
						<li class="mb-3">
							<a href="https://institucional.melhor.ai/" target="_blank" class="text-reset">
								Melhor.ai
							</a>
						</li>

						<li class="mb-3">
							<a href="https://www.allianz.com.br/" target="_blank" class="text-reset"> Allianz </a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-6 col-md-4 col-lg-2">
				<!-- Heading -->
				<h6 class="fw-bold text-uppercase text-gray-700">Produtos</h6>

				<!-- List -->
				<ul class="list-unstyled text-muted mb-0">
					<li class="mb-3">
						<a data-scroll='{"offset": 0.8}' href="javascript:void(0)"
							(click)="scrollToSection('sectionMedicina', 'Beneficios')" class="text-reset">
							Telemedicina
						</a>
					</li>
					<li class="mb-3">
						<a data-scroll='{"offset": 0.8}' href="javascript:void(0)"
							(click)="scrollToSection('sectionOdontologia', 'Beneficios')" class="text-reset">
							Odontologia
						</a>
					</li>
					<li class="mb-3">
						<a data-scroll='{"offset": 0.8}' href="javascript:void(0)"
							(click)="scrollToSection('sectionPsicologia', 'Beneficios')" class="text-reset">
							Psicologia
						</a>
					</li>
					<li class="mb-3">
						<a data-scroll='{"offset": 0.8}' (click)="scrollToSection('sectionSeguros', 'Beneficios')"
							class="text-reset">
							Seguros
						</a>
					</li>
					<li>
						<a data-scroll='{"offset": 0.8}' class="text-reset"> Soluções Financeiras </a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>
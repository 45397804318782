import { Client } from '../model/client'
import { SistemaOperacional } from '../model/sistema-operacional'
import { FormGroup } from '@angular/forms'
import { Meses, TipoValidoOuInvalido } from './enums'
import { ValueItemList } from '../cadastros/cct-edit/componentes/agiben-bill-preview/agiben-bill-preview.component'

/**
 * [Função para formatar "DateTime" em formato de horas "00:00"]
 * @param  date [data no formate "DateTime" para ser formatada em "00:00"]
 * @return [retorna a hora/minutos preenchidos com 0]
 */
export const converterDateStringParaHorasEMinutos = (date: string): string => {
	const [hora, minutos] = date.split('T')[1].split(':')
	const horaFormatada = `${hora.padStart(2, '0')}:${minutos.padStart(2, '0')}`
	return horaFormatada
}

/**
 * [Função para preencher as horas e minutos com zero quando necessário]
 * @param  horaParaFormatar [string no formato "xx:xx" para ser formatada com "0"]
 * @return [retorna a hora/minutos preenchidos com 0]
 */
export const retornarHoraMinutoPreenchidoCom0 = (horaParaFormatar: string): string => {
	const [hora, minutos] = horaParaFormatar.split(':')
	const horaFormatada = `${hora.padStart(2, '0')}:${minutos.padStart(2, '0')}`
	return horaFormatada
}

/**
 * [Função para tratamento de erros]
 * @param  error [obj de erro ocorrido na requisição]
 * @return [mensagem de erro retornada de acordo com o status do erro]
 */
export const retornarMsgErroPorStatus = (error: any): string => {
	console.log(error)
	let msgErro = 'Ocorreu um erro, tente novamente mais tarde.'
	if (error?.status) {
		switch (error?.status) {
			case 404:
				msgErro = 'Erro ao realizar procedimento.'
				break
			case 400:
			case 418:
				msgErro = error?.error?.message || error?.error?.message[0] || error?.message[0]
				break
		}
	}
	return msgErro
}

/**
 * [Função para verificar se o caracter é uppercase]
 * @param  string [string para ser verificada]
 * @return  [retorna booleano verdadeiro se for uppercase]
 */
export const verificarUpperCase = (str: string): boolean => {
	return /^[A-Z]*$/.test(str)
}

/**
 * [Função para verificar se o caracter especial está companhado de ]
 * @param  string [string para ser verificada]
 * @return  [retorna booleano verdadeiro se for especial]
 */
export const verificarCaracterEspecial = (str: string): boolean => {
	return /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/.test(str)
}

/**
 * [Função para renomear nome de arquivo ]
 * @param  fileOriginal [arquivo para ser modificado]
 * @param  newName [novo nome para ser alterado]
 * @return [retorna booleano verdadeiro se for especial]
 */
export const renomearNomeArquivo = (fileOriginal: File, newName: string) => {
	let formattedName: string = newName
	if (fileOriginal.name.includes('.')) {
		const fileOriginalNameParts = fileOriginal.name.split('.')
		formattedName = newName + '.' + fileOriginalNameParts[fileOriginalNameParts.length - 1]
	}

	const newFile = new File([fileOriginal], formattedName, {
		type: fileOriginal.type,
		lastModified: fileOriginal.lastModified,
	})
	return newFile
}

/**
 * [Função para converter um arrayBuffer em obj Json]
 * @param  arrayBuffer [arrayBuffer passado como parametro inicial]
 * @return [ArrayBuffer formatado]
 */
export const converterArrayBufferEmObj = (arrayBuffer: ArrayBuffer) => {
	return String.fromCharCode.apply(null, new Uint8Array(arrayBuffer))
}

/**
 * [Função para gerar um Date baseado em dois campos string Data e Hora]
 * @param  data [Data em string no formato dd-mm-yyyy]
 * @param  hora [Hora em string no formato Hh:m0]
 * @return tipo Date dos paramêtros passados]
 */
export const getDateFormatComHoraMinutoESegundo = (data, hora) => {
	return new Date(`${data}T${hora.padStart(5, '0')}`)
}

/**
 * [Função para validar um CNPJ]
 * @param  cnpj [CNPJ]
 * @return  [resultado da validação]
 */
export const validateCNPJ = cnpj => {
	cnpj = cnpj.trim().replace(/[^\d]+/g, '')
	let v1 = 0
	let v2 = 0
	let aux = false

	for (let i = 1; cnpj.length > i; i++) {
		if (cnpj[i - 1] !== cnpj[i]) {
			aux = true
		}
	}

	if (aux === false) {
		return false
	}

	for (let i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
		if (p1 >= 2) {
			v1 += cnpj[i] * p1
		} else {
			v1 += cnpj[i] * p2
		}
	}

	v1 = v1 % 11

	if (v1 < 2) {
		v1 = 0
	} else {
		v1 = 11 - v1
	}

	if (v1 !== cnpj[12]) {
		return false
	}

	for (let i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
		if (p1 >= 2) {
			v2 += cnpj[i] * p1
		} else {
			v2 += cnpj[i] * p2
		}
	}

	v2 = v2 % 11

	if (v2 < 2) {
		v2 = 0
	} else {
		v2 = 11 - v2
	}

	if (v2 !== cnpj[13]) {
		return false
	} else {
		return true
	}
}

/**
 * [Função para validar um CPF]
 * @param  cpf [CPF]
 * @return  [resultado da validação]
 */
export const validateCPF = cpf => {
	let Soma = 0
	let Resto

	const strCPF = String(cpf).replace(/[^\d]/g, '')

	if (strCPF.length !== 11) {
		return false
	}

	if (
		[
			'00000000000',
			'11111111111',
			'22222222222',
			'33333333333',
			'44444444444',
			'55555555555',
			'66666666666',
			'77777777777',
			'88888888888',
			'99999999999',
		].indexOf(strCPF) !== -1
	) {
		return false
	}

	for (let i = 1; i <= 9; i++) {
		Soma = Soma + parseInt(strCPF.substring(i - 1, i), 10) * (11 - i)
	}

	Resto = (Soma * 10) % 11

	if (Resto === 10 || Resto === 11) {
		Resto = 0
	}

	if (Resto !== parseInt(strCPF.substring(9, 10), 10)) {
		return false
	}

	Soma = 0

	for (let i = 1; i <= 10; i++) {
		Soma = Soma + parseInt(strCPF.substring(i - 1, i), 10) * (12 - i)
	}

	Resto = (Soma * 10) % 11

	if (Resto === 10 || Resto === 11) {
		Resto = 0
	}

	if (Resto !== parseInt(strCPF.substring(10, 11), 10)) {
		return false
	}

	return true
}

/**
 * [Função para gerar uma data no formato propricio para o NgbDatepicker baseado em um Date]
 * @param  data [Data no tipo Date]
 * @return [Data formatada]
 */
export const formatarDateParaDatePicker = (data: Date) => {
	const dateFormatada = {
		year: data.getFullYear(),
		month: data.getMonth() + 1,
		day: data.getDate(),
	}
	return dateFormatada
}

/**
 * [Função para gerar um Date baseado apenas no mês e no ano]
 * @param  mes [Mês no formato MM]
 * * @param  ano [Ano no formato YYYY]
 * @return [Date]
 */
export const gerarDateBaseadoEmMesAno = (mes: string, ano: string) => {
	const date = new Date()
	date.setFullYear(Number(ano), Number(mes) - 1)
	return date
}

interface PegarSistemaOperacional {
	client: Client
	sistemaOperacional: SistemaOperacional
}
export const pegarSistemaOperacional = (): PegarSistemaOperacional => {
	const userAgent = window.navigator.userAgent.toLowerCase()

	if (userAgent.indexOf('windows') !== -1) {
		return {
			client: 'WEB',
			sistemaOperacional: 'WINDOWS',
		}
	} else if (userAgent.indexOf('android') !== -1) {
		return {
			client: 'MOBILE',
			sistemaOperacional: 'ANDROID',
		}
	} else if (userAgent.indexOf('linux') !== -1) {
		return {
			client: 'WEB',
			sistemaOperacional: 'LINUX',
		}
	} else if (userAgent.indexOf('mac') !== -1) {
		return {
			client: 'WEB',
			sistemaOperacional: 'MAC',
		}
	} else if (
		userAgent.indexOf('iphone') !== -1 ||
		userAgent.indexOf('ipad') !== -1 ||
		userAgent.indexOf('ipod') !== -1
	) {
		return {
			client: 'MOBILE',
			sistemaOperacional: 'IOS',
		}
	} else {
		return {
			client: 'OUTRO',
			sistemaOperacional: 'OUTRO',
		}
	}
}

/**
 * [Função para retornar nome do arquivo sem extensão ]
 * @param  nomeArquivoOriginal [nome do arquivo original]
 * @return [nome do arquivo sem extensão]
 */
export const formatarNomeArquivoSemExtensao = (nomeArquivoOriginal: string) => {
	const arrNomeArquivoOriginal = nomeArquivoOriginal.split('.')
	return arrNomeArquivoOriginal[0]
}

/**
 * [ Função para retornar texto sem caracteres especiais ]
 * @param  texto [texto original]
 * @return [texto sem caracteres]
 */
export const substituirCaracteresEspeciais = (texto: string) => {
	return texto
		.normalize('NFD')
		.replace(/\\/g, '')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/[^\w\s.]/g, '')
}

/**
 * [ Função para retornar status de validade dos controles de um form, a depender da opção passada ]
 * @param  formGroup [Form passado]
 * @param  opcao [Opcao que pode ser invalido ou valido]
 * @return [lista de com as chaves(keys) dos controles]
 */
export const listarControlesInvalidosDeUmForm = (
	formGroup: FormGroup,
	opcao: TipoValidoOuInvalido,
) => {
	const controls = Object.keys(formGroup.controls).map(key => {
		switch (opcao) {
			case TipoValidoOuInvalido.INVALID:
				if (formGroup.get(key).invalid) {
					return key
				}
				break
			case TipoValidoOuInvalido.VALID:
				if (formGroup.get(key).valid) {
					return key
				}
				break
		}
	})
	return controls
}

/**
 * [Função para formatar valor em br]
 * @param  formatarValor [valor formatado ex: 11,20]
 * @return valor formatado em br
 */

export function formatarValor(valor: string | undefined): string | undefined {
	if (!valor) {
		return undefined
	}

	return valor?.toString().replace(/\./g, ',')
}

/**
 * [Função para calcular valores do componente de conta]
 * @param  [valores: {totalPafValue: number, agencyValue: number, agencyPercent: number, lifetimeValue: number, administrativeTaxValue: number, listaValorProdutos: number[]}]
 * @return [{availableCreditValue: number, totalUsedValue: number}]
 */
export function calcularSaldoEGasto(totalValue: number, listaValorProdutos: number[]) {
	const totalUsedValue = listaValorProdutos.reduce(
		(somaParcial, value) => somaParcial + Number(value),
		0,
	)
	const availableCreditValue = totalValue - totalUsedValue
	return { availableCreditValue, totalUsedValue }
}

/**
 * [Função para obter cálculo do valor de agenciamento]
 * @param  [totalPafValue: number, agencyPercent:number, months: number = 12]
 * @return [agencyValue: number]
 */
export function obterValorAbsolutoAgenciamento(
	totalPafValue: number,
	agencyPercent: number,
	months: number = 12,
) {
	return (totalPafValue / months) * (agencyPercent / 100)
}

/**
 * [Função para montar lista de valores de PAF para componente de conta]
 * @param  [allowAgency: boolean,
 * agencyValue: number,
 * lifetimeValue: number,
 * administrativeTaxValue: number,
 * products: ValueItemList[]]
 * @return [valueList: ValueItemList[]]
 */
export function montarListaValoresPAF(
	allowAgency: boolean,
	agencyValue: number,
	lifetimeValue: number,
	administrativeTaxValue: number,
	products: ValueItemList[],
) {
	const valueList: ValueItemList[] = []
	if (allowAgency) {
		valueList.push({
			descricao: 'Agenciamento',
			percentual: true,
			valor: agencyValue,
		})
	}

	valueList.push({
		descricao: 'Vitalício',
		percentual: false,
		valor: lifetimeValue,
	})

	valueList.push({
		descricao: 'Taxa administrativa',
		percentual: false,
		valor: administrativeTaxValue,
	})

	if (products.length) {
		for (const product of products) {
			valueList.push({
				id: product.id,
				valor: product.valor,
				descricao: product.descricao,
				percentual: false,
			})
		}
	}

	return valueList
}

export const calcularSaldoAgenciamentoEVitalicio = (
	valorPercentualAgenciamento: number,
	valorPaf: number,
	valorAgenciamentoSindicatos: number[],
	valorAgenciamentoIntermediadores: number[],
	valorVitalicioSindicatos: number[],
	valorVitalicioIntermediadores: number[],
	valorVitalicio: number,
) => {
	const totalMesesPaf = 12
	const valorAgenciamento = (valorPaf / totalMesesPaf) * (valorPercentualAgenciamento / 100)

	const agenciamentoSindicatos = valorAgenciamentoSindicatos.map(valor =>
		valor === undefined ? 0 : valor,
	)
	const agenciamentoIntermediadores = valorAgenciamentoIntermediadores.map(valor =>
		valor === undefined ? 0 : valor,
	)
	const vitalicioSindicatos = valorVitalicioSindicatos.map(valor =>
		valor === undefined ? 0 : valor,
	)
	const vitalicioIntermediadores = valorVitalicioIntermediadores.map(valor =>
		valor === undefined ? 0 : valor,
	)

	const totalAgenciamento =
		agenciamentoSindicatos.reduce(
			(somaParcial, percentual) => somaParcial + (valorAgenciamento * percentual) / 100,
			0,
		) +
		agenciamentoIntermediadores.reduce(
			(somaParcial, percentual) => somaParcial + (valorAgenciamento * percentual) / 100,
			0,
		)

	const creditoDisponivelAgenciamento = valorAgenciamento - totalAgenciamento

	const totalVitalicio =
		vitalicioSindicatos.reduce((somaParcial, valor) => somaParcial + Number(valor), 0) +
		vitalicioIntermediadores.reduce((somaParcial, valor) => somaParcial + Number(valor), 0)

	const creditoDisponivelVitalicio = valorVitalicio - totalVitalicio

	return {
		creditoDisponivelAgenciamento,
		creditoDisponivelVitalicio,
		valorAgenciamento,
		totalAgenciamento,
		totalVitalicio,
	}
}

export function formatarDataParaDiaMesAno(data: string): string {
	const [ano, mes, dia] = data.split('T')[0].split('-')

	return `${dia}/${mes}/${ano}`
}

export function formatarStringParaCamelCase(str: string): string {
	const words = str.toLowerCase().split(' ')
	const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
	return capitalizedWords.join(' ')
}

export function formatId(id?: string): string {
	if (!id) {
		return ''
	}
	if (id.length === 5) {
		return id.replace(/(\d{2})(\d{2})(\d{1})/, '$1.$2.$3')
	} else if (id.length === 7) {
		return id.replace(/(\d{2})(\d{2})(\d{3})/, '$1.$2.$3')
	}
	return id
}

/**
 * [Função para retornar lista de meses como options de select ]
 * @return [retorna lista de meses como options de select]
 */
export function listarMeses() {
	return Object.keys(Meses)
		.filter(key => isNaN(Number(key)))
		.map(key => {
			return { label: key, value: key }
		})
}

/**
 * [Função para conveter kilo bytes em mega bytes aredondando em duas casas decimais]
 * @return [retorna o valor convertido]
 */
export function kbConverToMB(value: number) {
	return value > 0 ? parseFloat((value / (1024 * 1024)).toFixed(2)) : 0;
}

/**
 * [Função para abrir um arquivo em uma nova janela]
 * @return [retorna o valor convertido]
 */
export function viewBrowserLocalFile(file: File) {
	const fileReader = new FileReader();
	fileReader.onload = () => {
		const blob = new Blob([fileReader.result as ArrayBuffer], { type: file.type });
		abrirNovaAba(URL.createObjectURL(blob))		
	};
	fileReader.readAsArrayBuffer(file);
}

export function abrirNovaAba(url: string) {
	window.open(url, '_blank')
}

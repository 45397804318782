import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
	name: 'numeroCompromisso',
	pure: true,
})
export class NumeroCompromisso implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		switch (typeof value) {
			case 'number':
				return value.toString().padStart(5, '0')
			case 'string':
				return value.padStart(5, '0')
			default:
				return value
		}
	}
}

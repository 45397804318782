import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[onlyNumber]'
})

export class OnlyNumberDirective {

  private specialKeys = [
    'Control', 'Enter', 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
  ];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const pattern = /[0-9]/;

    if (!pattern.test(event.key) && event.key != 'v') {
      console.log(event.key);
      // invalid character, prevent input
      event.preventDefault();
      return;
    }
  }
}
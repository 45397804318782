import { Injectable } from "@angular/core";
import {
  HttpClient, HttpHeaders,  
} from "@angular/common/http";
import { PlatformLocation } from "@angular/common";
import { AppService } from "../../app.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
// https://www.npmjs.com/package/@ngx-pwa/local-storage
import { StorageMap, LocalStorage } from "@ngx-pwa/local-storage";

// https://stackblitz.com/edit/ng-brazil?file=src%2Fapp%2Fapp.component.html
import * as textMaskAddons from "text-mask-addons/dist/textMaskAddons";
import { Login } from "../../model/login";

import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
  })
  export class AuthService {
    hostName: any;
    hostPort: any;
    urlServerAPI: any;
    urlServerAPINest: any;
    vernomecliente: boolean;
    login: Login;
    headers: any;
    headersNest: any;
    isRemoteServer: boolean = false;
    isDev: boolean = false;
    tipo: any;
  
    dateMaskOptions = {
      mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
      pipe: textMaskAddons.createAutoCorrectedDatePipe("dd/mm/yyyy"),
    };
  
    constructor(
      private router: Router,
      private translateService: TranslateService,
      private toastrService: ToastrService,
      private platformLocation: PlatformLocation,
      private appService: AppService,
      private http: HttpClient,
      private storage: StorageMap,
      protected localStorage: LocalStorage
    ) {
      // https://stackoverflow.com/questions/49297680/angular-5-get-host-name-and-app-name-from-url
      // Example: localhost:4200
      this.hostName = (platformLocation as any).location.origin;
      // Example: 4200
      this.hostPort = (platformLocation as any).location.port;
  
      this.urlServerAPI = environment.API_URL;
      this.urlServerAPINest = environment.API_URL_NEST;
  
      // this.urlServerAPI = 'http://localhost:80/v1/'
      // this.urlServerAPINest = 'http://localhost:3001/api/'
    }

    setLogin(login: Login) {
      this.login = login;
    }

    ngOnInit() { }

    public async getUser(): Promise<Login> {
        try {
          if (!this.login) {
            let data = await this.localStorage.getItem<Login>("login").toPromise();
    
            if (!data) {
              // usuario está sem sessão
              // Redirect to session expired
              this.router.navigate(["/login/authentication/login-v2"]);
              return null;
            }
    
            //let user = data as Login;    
            this.login = new Login(data);
    
            // //CARREGAR EMPRESAS
            // if (this.login.conta) {
            //   if (!this.login.conta.empresas) {
            //     this.login.conta.empresas = await this.getContaEmpresas(this.login.conta.conta.pk_idconta)
            //   }
            // }
    
            this.setHeaders();
            this.setHeadersNest();
          }
    
          return this.login;
        } catch (e) {
          console.log("getUser: ", e);
    
          // usuario está sem sessão
          // Redirect to session expired
          this.router.navigate(["/login/authentication/login-v2"]);
    
          return null;
        }
      }
    
      public async getHeaders(): Promise<any> {
        if (!this.login) {
          await this.getUser();
        }
    
        return this.headers;
      }
    
      public async getHeadersNest(): Promise<any> {
        if (!this.login) {
          await this.getUser();
        }
    
        return this.headersNest;
      }
    
      setHeaders() {
        this.headers = {
          "Content-Type": "application/json",
          "x-access-token": this.login.token,
        };
      }
    
      setHeadersNest() {
        this.headersNest = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.login.token,
        };
      }

      getHeadersNestNoContentType() {
        return {          
          Authorization: "Bearer " + this.login.token,
        };
      }
    
      tapToDismiss = true;
      closeButton = false;
      progressBar = true;
      preventDuplicates = false;
      newestOnTop = false;
      progressAnimation = "decreasing";
      positionClass = "toast-bottom-right";
    
      /**
       *
       * @param keyTranslate
       * @param type -> 'success', 'error', 'info'
       */
      showToast(
        keyTitleTranslate: string = null,
        keyMessageTranslate: any,
        type: string,
        positionClass = "toast-bottom-right"
      ) {
        const options = {
          tapToDismiss: this.tapToDismiss,
          closeButton: this.closeButton,
          progressBar: this.progressBar,
          progressAnimation: this.progressAnimation,
          positionClass: positionClass,
          rtl: this.appService.isRTL,
        };
    
        // `newestOnTop` and `preventDuplicates` options must be set on global config
        this.toastrService.toastrConfig.newestOnTop = this.newestOnTop;
        this.toastrService.toastrConfig.preventDuplicates = this.preventDuplicates;
    
        this.translateService
          .get(keyMessageTranslate)
          .subscribe((messageTranslated) => {
            if (!keyTitleTranslate) {
              this.toastrService[type](messageTranslated, null, options);
            } else {
              this.translateService
                .get(keyTitleTranslate)
                .subscribe((titleTranslated) => {
                  this.toastrService[type](
                    messageTranslated,
                    titleTranslated,
                    options
                  );
                });
            }
          });
      }
    
      errorMessageShow(data: any) {
        var codeError = null;
    
        if (typeof data === "string") {
          codeError = data;
        } else if (
          data &&
          (data.status == 0 ||
            (data.error &&
              data.error.response &&
              data.error.response.message == 1000))
        ) {
          // Não há conexao
          codeError = "1000";
        } else if (!data || !data.error || data.status == 500) {
          // Erro interno
          codeError = "500";
          console.error(data);
        } else if (data.error && data.error.code) {
          codeError = data.error.code.toString();
        } else if (data.error.errors) {
          // Multiplos erros encontrado na validação do form
          // Exibindo multiplas mensagens de erros
          for (let index = 0; index < data.error.errors.length; index++) {
            const element = data.error.errors[index];
    
            if (element.field) {
              this.showToast(
                "error.info",
                element.field.toString(),
                "info",
                "toast-top-right"
              );
            } else {
              this.showToast(
                "error.info",
                element.code.toString(),
                "info",
                "toast-top-right"
              );
            }
          }
        }
    
        if (codeError) {
          this.showToast(null, codeError, "error", "toast-top-right");
        }
      }

      get getRowLimit() {
        return 20;
      }

        //#region HANDLERS

  /**
   * Handle Http operation that failed.
   * https://angular.io/tutorial/toh-pt6
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError(error: any, operation: string): void {
    // TODO: send the error to remote logging infrastructure

    // TODO: better job of transforming error for user consumption

    console.log(`${operation} failed: ${error.message}`);

    if (error.error && error.error.response) {
      console.log(
        `${operation}, Code: ${error.error.response.code}, Details: ${error.error.response.message}`
      );
    }

    if (
      error.status == 401 &&
      error.error &&
      error.error.response &&
      error.error.response.code == 1171
    ) {
      // Registrando a saída do sistema para a conta do usuário logado atual
      this.logout(false);

      // get current url to return
      // Voltar para a tela anterior após login expirar
      let return_url =
        (this.platformLocation as any).location.pathname +
        (this.platformLocation as any).location.search;

      let href = (this.platformLocation as any).location.href;

      if (href.indexOf("login") < 0) {
        // TokenExpiredError
        this.errorMessageShow(error);

        // Redirect to session expired
        // https://jasonwatmore.com/post/2016/12/08/angular-2-redirect-to-previous-url-after-login-with-auth-guard
        this.router.navigate(["/login/authentication/lock-screen-v2"], {
          queryParams: {
            returnUrl: return_url,
          },
        });
      }
    }

    // return error
    throw error;
  }

  async logout(isClearSession: boolean = true) {
    try {
      var user = await this.getUser();

      user.sessionExpired = true;

      // Registrando a saída do usuario do sistema em banco de dados
      this.createLogAcesso(user);
    } catch (error) {
      console.log("logout: " + error);
    } finally {
      if (isClearSession == true) {
        // Limpando todos os dados salvos localmente
        this.clearStorageLocal();

        this.login = null;

        this.router.navigate(["/login/authentication/login-v2"]);
      }
    }
  }

  async createLogAcesso(user: Login): Promise<any> {
    var retorno = null;

    try {
      // O registro de log nao pode depender de token, pois a sessao pode ter sido expirada. Entao como fica o registro do log de saída de sistema sem token valido?

      const headers = await this.getHeaders();

      var body = {
        fk_idconta: user.conta.pk_idconta,
        log_entradasaida: "S",
      };

      const urlAPI = this.urlServerAPI + "logacessos";

      retorno = await this.http
        .post<any>(urlAPI, body, {
          headers,
        })
        .toPromise();
    } catch (error) { }

    return retorno;
   }

     //#region STORAGE_LOCAL
     async getStorageLocal(key: string): Promise<any> {
      var retorno = null;
  
      try {
        retorno = await this.storage.get(key).toPromise();
      } catch (error) {
        this.handleError(error, "getStorageLocal");
      }
  
      return retorno;
    }
  
    setStorageLocal(key: string, value: any) {
      this.storage.set(key, value).subscribe({
        next: () => { },
        error: (error) => {
          console.error("setStorageLocal: " + error);
        },
      });
    }
  
    clearStorageLocal() {
      this.storage.clear().subscribe(() => { });
    }
  
    //#endregion
  }

  
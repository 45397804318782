import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core'
import {Beneficio, Topico} from 'src/app/model/cct/beneficios/beneficio.model'
import {TipoProvedor} from 'src/app/utils/enums'

@Component({
	selector: 'card-beneficios-cct',
	templateUrl: './card-beneficios-cct.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./card-beneficios-cct.component.scss'],
})
export class CardBeneficiosCctComponent implements OnInit {
	@Input() beneficio: Beneficio = null
	@Input() linkDinamico = ``

	@Input() conteudoFooter = ''

	ilustracaoUrlMobile = ''

	enumTipoProvedor = TipoProvedor

	topicoSelecionado: Topico = null

	@Output() onStoreClickEvent: EventEmitter<any> = new EventEmitter()
	@Output() onTopicSelectedEvent: EventEmitter<any> = new EventEmitter()
	@Output() onDynamicLinkClickEvent: EventEmitter<any> = new EventEmitter()

	constructor(private cd: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.topicoSelecionado = this.beneficio?.topicos[0]
		this.ilustracaoUrlMobile = this.beneficio?.ilustracaoUrl?.split('.svg')[0] + '-mobile.svg'
	}

	ativarSelecao(topicoSelecionado: Topico) {
		const topicoEncontrado = this.beneficio.topicos.findIndex(item => topicoSelecionado === item)
		this.beneficio.topicos = this.beneficio.topicos.map(topico => {
			return {...topico, ativo: false}
		})
		this.topicoSelecionado = this.beneficio.topicos[topicoEncontrado]
		this.topicoSelecionado.ativo = true
		this.onTopicSelectedEvent.emit({
			topicoSelecionado,
			beneficio: this.beneficio,
		})
		this.cd.detectChanges()
	}

	@HostListener('click', ['$event.target']) clickEmIconeLoja(target: HTMLElement) {
		if (
			(target && target.id.includes('botao-play-store')) ||
			target.id.includes('botao-app-store')
		) {
			this.onStoreClickEvent.emit({
				linkAcesso: target.attributes['link-acesso'].value,
				loja: target.id.includes('botao-play-store') ? 'GOOGLE_PLAY' : 'APPLE_STORE',
				beneficio: this.beneficio,
			})
		}
	}

	clickEmLinkDinamico(topicoSelecionado: Topico) {
		this.onDynamicLinkClickEvent.emit({
			topicoSelecionado,
			beneficio: this.beneficio,
		})
	}
}

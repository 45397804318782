<div
	class="card"
	[ngClass]="{
		'borda-lateral-dir': tipoBorda === tipoBordaEnum.DIREITA,
		'borda-lateral-esq': tipoBorda === tipoBordaEnum.ESQUERDA,
		'borda-central': tipoBorda === tipoBordaEnum.CENTRAL
	}">
	<div class="card-header border-0">
		<div class="d-flex flex-row align-items-center">
			<img
				src="../../../../assets/img/logo/logo-black-{{ tipoLogo }}.svg"
				*ngIf="mostrarIcon"
				class="mr-2" />
			<span class="font-weight-bold m-0">{{ titulo }}</span>
		</div>
	</div>
	<div class="card-body py-0">
		<span [innerHTML]="conteudo"></span>
	</div>

	<div class="d-flex align-items-center card-footer border-0 py-3">
		<span class="font-weight-bold">{{ descricaoFooter }}</span>
	</div>
</div>

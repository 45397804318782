<div class="modal-header">
  <h5 class="modal-title">
    {{ 'conta.edit.nova' | translate }} <span
      class="font-weight-light">{{ 'conta.edit.senha' | translate }}</span><br>
    <small class="text-muted">{{'conta.edit.alterar_senha_antiga' | translate }}</small>
  </h5>
  <button type="button" class="close" (click)="modalService.dismiss('Cross click')">&times;</button>
</div>

<!-- Element blocking -->
<ng-template #elementBlockTemplate>
  <div class="block-ui-template">
    <div class="sk-wave sk-primary mx-auto">
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
    </div>
  </div>
</ng-template>

<!-- Form -->
<form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
  <fieldset [attr.disabled]="disabled ? 'disabled': null">
    <div class="modal-body block-ui-white" *blockUI="'elementNewPassword'; template: elementBlockTemplate">

      <div class="form-row">
        <div class="form-group col-md-12">
          <label class="form-label">{{ 'conta.edit.senha_antiga' | translate }}</label>
          <code>*</code>
          <input formControlName="senha_antiga" id="senha_antiga" type="password" class="form-control"
              [ngClass]="{ 'is-invalid': f.senha_antiga.touched && f.senha_antiga.invalid }">
            <div *ngIf="f.senha_antiga.touched && f.senha_antiga.invalid" class="text-danger">
              <div *ngIf="f.senha_antiga.errors.required">{{ 'validation_form.required' | translate }}</div>
              <div *ngIf="f.senha_antiga.errors.minlength">{{ 'validation_form.min_character' | translate:{'value':'4'} }}</div>
              <div *ngIf="f.senha_antiga.errors.maxlength">{{ 'validation_form.max_character' | translate:{'value':'15'} }}</div>
              <div *ngIf="f.senha_antiga.errors.mustMatch">{{ 'validation_form.senha_antiga_errada' | translate }}</div>
            </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label class="form-label">{{ 'conta.edit.nova_senha' | translate }}</label>
          <code>*</code>
          <input formControlName="nova_senha" id="nova_senha" type="password" class="form-control"
              [ngClass]="{ 'is-invalid': f.nova_senha.touched && f.nova_senha.invalid }">
            <div *ngIf="f.nova_senha.touched && f.nova_senha.invalid" class="text-danger">
              <div *ngIf="f.nova_senha.errors.required">{{ 'validation_form.required' | translate }}</div>
              <div *ngIf="f.nova_senha.errors.minlength">{{ 'validation_form.min_character' | translate:{'value':'4'} }}</div>
              <div *ngIf="f.nova_senha.errors.maxlength">{{ 'validation_form.max_character' | translate:{'value':'15'} }}</div>

            </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label class="form-label">{{ 'conta.edit.repetir_nova_senha' | translate }}</label>
          <code>*</code>
          <input formControlName="repetir_nova_senha" id="repetir_nova_senha" type="password" class="form-control"
              [ngClass]="{ 'is-invalid': f.repetir_nova_senha.touched && f.repetir_nova_senha.invalid }">
            <div *ngIf="f.repetir_nova_senha.touched && f.repetir_nova_senha.invalid" class="text-danger">
              <div *ngIf="f.repetir_nova_senha.errors.required">{{ 'validation_form.required' | translate }}</div>
              <div *ngIf="f.repetir_nova_senha.errors.minlength">{{ 'validation_form.min_character' | translate:{'value':'4'} }}</div>
              <div *ngIf="f.repetir_nova_senha.errors.maxlength">{{ 'validation_form.max_character' | translate:{'value':'15'} }}</div>
              <div *ngIf="f.repetir_nova_senha.errors.mustMatch">{{ 'validation_form.repetir_senha' | translate }}</div>

            </div>
        </div>
      </div>
      

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default"
        (click)="modalService.close(null)">{{ 'botao.cancelar' | translate }}</button>
      <button type="submit" class="btn btn-primary" [ladda]="loading[0]" data-style="zoom-in"
        [disabled]="!form.valid">{{ 'conta.edit.alterar_senha' | translate }}</button>
    </div>
  </fieldset>

</form>

import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {AgiStepHeaderComponent} from './agi-step-header/agi-step-header.component'
import {AgiStepBodyComponent} from './agi-step-body/agi-step-body.component'
import {AgiStepFooterComponent} from './agi-step-footer/agi-step-footer.component'
import {AgiStepperComponent} from './agi-stepper/agi-stepper.component'
import {AgiStepComponent} from './agi-step/agi-step.component'

@NgModule({
	declarations: [
		AgiStepperComponent,
		AgiStepComponent,
		AgiStepHeaderComponent,
		AgiStepBodyComponent,
		AgiStepFooterComponent,
	],
	imports: [CommonModule],
	exports: [
		AgiStepperComponent,
		AgiStepHeaderComponent,
		AgiStepBodyComponent,
		AgiStepFooterComponent,
		AgiStepComponent,
	],
})
export class AgiStepperModule {}

import {PlatformLocation} from '@angular/common'
import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {TranslateService} from '@ngx-translate/core'
import {ToastrService} from 'ngx-toastr'
import {AppService} from '../app.service'
// https://www.npmjs.com/package/@ngx-pwa/local-storage
import {LocalStorage} from '@ngx-pwa/local-storage'

// https://stackblitz.com/edit/ng-brazil?file=src%2Fapp%2Fapp.component.html
import * as textMaskAddons from 'text-mask-addons/dist/textMaskAddons'
import {Login} from '../model/login'

import {environment} from '../../environments/environment'
import {ArquivosDadosGeraisCCTDTO} from '../model/cct/arquivos-dados-gerais-cct'
import {CCTBeneficio} from '../model/cct/beneficios/beneficio.model'
import {CadastroCCTDTO} from '../model/cct/beneficios/cadastro-cct-dto'
import {DadosLandingPageUsuarioCCT} from '../model/cct/beneficios/dados-landing-page-usuario-cct.model'
import {CctActStatusRaw} from '../model/cct/cct-act-status.enum'
import {
	BeneficioDescricao,
	CriarOuAtualizarCCTProdutoEComissoesDTO,
	ListagemCCTBeneficio,
	ListagemCCTProduto,
} from '../model/cct/cct-produto-e-comissoes-dto'
import {ContratosCCTAssinadosDTO} from '../model/cct/contratos-cct-assinados-dto'
import {DadosCCT} from '../model/cct/dados-cct'
import {IDesqualificarCCTDTO} from '../model/cct/desqualificar-cct.dto'
import {EditarDadosGeraisCCTDTO} from '../model/cct/editar-dados-gerais-cct.dto'
import {
	IEnvioArquivosDadosGeraisDTO,
	IEnvioArquivosLinkDTO,
} from '../model/cct/envio-arquivos-dados-gerais-dto'
import {FiltroDTO} from '../model/filtro-dto'
import {AuthService} from './auth/auth.service'
import {IArquivoTabela} from '../model/cct/beneficios/landing-page/interfaces'

export class Sindicato {
	id: number
	cnpj: string
	tipoSindicato: string
	nomeFantasia: string
	razaoSocial: string
}

@Injectable({
	providedIn: 'root',
})
export class CctService {
	//#region variables
	hostName: any
	hostPort: any
	urlServerAPI: any
	urlServerAPINest: any
	verdescricaocliente: boolean
	login: Login
	headers: any
	headersNest: any
	isRemoteServer = false
	isDev = false
	tipo: any

	dateMaskOptions = {
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
		pipe: textMaskAddons.createAutoCorrectedDatePipe('dd/mm/yyyy'),
	}

	//#endregion

	constructor(
		private router: Router,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private platformLocation: PlatformLocation,
		private appService: AppService,
		private http: HttpClient,
		private authService: AuthService,
		protected localStorage: LocalStorage,
	) {
		// https://stackoverflow.com/questions/49297680/angular-5-get-host-name-and-app-name-from-url
		// Example: localhost:4200
		this.hostName = (platformLocation as any).location.origin
		// Example: 4200
		this.hostPort = (platformLocation as any).location.port

		this.urlServerAPI = environment.API_URL
		this.urlServerAPINest = environment.API_URL_NEST
	}

	//#region cct
	async listarSindicatos(cctId: number) {
		return await this.http
			.get<Sindicato[]>(`${this.urlServerAPINest}cct/${cctId}/listar-sindicatos`)
			.toPromise()
			.then(data => {
				return data
			})
			.catch(error => {
				this.authService.handleError(error, 'listarSindicatos')
				return []
			})
	}

	async buscarInformacoesCCTPorIdESindicatoId(cctId: number, sindicatoId) {
		return await this.http
			.get<DadosLandingPageUsuarioCCT>(
				`${this.urlServerAPINest}cct/${cctId}/sindicato/${sindicatoId}`,
			)
			.toPromise()
			.then(data => {
				return data
			})
			.catch(error => {
				this.authService.handleError(error, 'buscarInformacoesCCTPorIdESindicatoId')
				return []
			})
	}

	async listarSindicatosLaborais(filtroDTO: FiltroDTO) {
		return await this.http
			.post<any>(`${this.urlServerAPINest}cct/listar-sindicatos-laborais`, filtroDTO)
			.toPromise()
			.then(data => {
				return data
			})
			.catch(error => {
				this.authService.handleError(error, 'listarSindicatosLaborais')
			})
	}

	async listarSindicatosPatronais(filtroListagemSindPatronaisDTO: FiltroDTO) {
		return await this.http
			.post<any>(
				`${this.urlServerAPINest}cct/laboral/listar-sindicatos-patronais`,
				filtroListagemSindPatronaisDTO,
			)
			.toPromise()
			.then(data => {
				return data
			})
			.catch(error => {
				this.authService.handleError(error, 'listarSindicatosPatronais')
			})
	}

	async cadastrarCCT(cadastroCCTDTO: CadastroCCTDTO) {
		return await this.http
			.post<any>(`${this.urlServerAPINest}cct`, cadastroCCTDTO)
			.toPromise()
			.then(data => {
				return data
			})
			.catch(error => {
				this.authService.handleError(error, 'buscarInformacoesCCTPorIdESindicatoId')
				return []
			})
	}

	async buscarInfoCCTPorId(id: number) {
		return await this.http
			.get(`${this.urlServerAPINest}cct/${id}`)
			.toPromise()
			.then((data: DadosCCT) => {
				return data
			})
			.catch(error => {
				this.authService.handleError(error, 'buscarInfoCCTPorId')
				return []
			})
	}

	async atualizarInfoCCTPorId(id: number, body: EditarDadosGeraisCCTDTO) {
		return await this.http
			.put(`${this.urlServerAPINest}cct/${id}`, body)
			.toPromise()
			.catch(error => {
				this.authService.handleError(error, 'atualizarInfoCCTPorId')
				return []
			})
	}

	async listarAbrangenciaGeografica() {
		return await this.http
			.get(`${this.urlServerAPINest}cct/listar-abrangencia-geografica`)
			.toPromise()
			.then(data => {
				return data
			})
			.catch(error => {
				this.authService.handleError(error, 'listarAbrangenciaGeografica')
			})
	}

	async listarCCTs(filtroDTO: FiltroDTO) {
		return await this.http
			.post<any>(`${this.urlServerAPINest}cct/listar`, filtroDTO)
			.toPromise()
			.then(data => {
				return data
			})
			.catch(error => {
				this.authService.handleError(error, 'listarCCTs')
			})
	}

	async buscarCCTsParametrizacao(filtroDTO: FiltroDTO) {
		return await this.http
			.post<any>(`${this.urlServerAPINest}cct/listar/parametrizacao`, filtroDTO)
			.toPromise()
			.then(data => {
				return data
			})
			.catch(error => {
				this.authService.handleError(error, 'buscarCCTsParametrização')
			})
	}

	async desqualificarCCT(id: number, body: IDesqualificarCCTDTO): Promise<any> {
		const retorno = null
		try {
			const urlAPI = this.urlServerAPINest + `cct/${id}/desqualificar`
			await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'desqualificarCCT')
		}

		return retorno
	}

	async enviarArquivosDadosGerais(
		id: number,
		body: IEnvioArquivosDadosGeraisDTO,
	): Promise<boolean> {
		const formData = this.criarFormDataArquivosDadosGerais(body)
		try {
			const urlAPI = this.urlServerAPINest + `cct/dados-gerais/arquivos/${id}`
			return await this.http.post<boolean>(urlAPI, formData).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'enviarArquivosDadosGerais')
		}
	}

	async atualizarArquivosDadosGerais(
		id: number,
		body: IEnvioArquivosDadosGeraisDTO,
	): Promise<boolean> {
		const formData = this.criarFormDataArquivosDadosGerais(body)

		try {
			const urlAPI = this.urlServerAPINest + `cct/dados-gerais/arquivos/${id}`
			return await this.http.put<boolean>(urlAPI, formData).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'atualizarArquivosDadosGerais')
		}
	}

	async listarArquivosCCTDadosGerais(id: number): Promise<ArquivosDadosGeraisCCTDTO[]> {
		try {
			const urlAPI = this.urlServerAPINest + `cct/${id}/listar-contrato-e-acordo`
			return await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'listarArquivosCCTDadosGerais')
		}
	}

	async visualizarArquivoDadosGerais(idArquivo: number): Promise<any> {
		try {
			const urlAPI = this.urlServerAPINest + `cct/buscar/dados-gerais/arquivos/${idArquivo}`
			return await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'visualizarArquivoDadosGerais')
		}
	}

	async downloadArquivoDadosGerais(idArquivo: number): Promise<any> {
		try {
			const urlAPI = this.urlServerAPINest + `cct/download/dados-gerais/arquivos/${idArquivo}`
			return await this.http
				.get<any>(urlAPI, {
					observe: 'response',
					responseType: 'blob' as 'json',
				})
				.toPromise()
		} catch (error) {
			this.authService.handleError(error, 'downloadArquivoDadosGerais')
		}
	}

	private criarFormDataArquivosDadosGerais(body: any) {
		const formData: FormData = new FormData()
		body.arquivos.map(arq => formData.append('arquivos', arq, arq.name))
		return formData
	}

	async enviarArquivosLinkLP(id: number, body: IEnvioArquivosLinkDTO): Promise<any> {
		try {
			const formData = this.criarFormDadaEnvioArquivosLPeLink(body)
			const urlAPI = this.urlServerAPINest + `cct/${id}/arquivos-gerais`
			return await this.http.post<any>(urlAPI, formData).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'enviarArquivosLinkLP')
		}
	}

	private criarFormDadaEnvioArquivosLPeLink(body: IEnvioArquivosLinkDTO) {
		const {bannerSindicatoPrincipal, cctActVigente, logoSindicatoPrincipal, link, sindicatoIds} =
			body
		const [bannerPrincipal] = bannerSindicatoPrincipal
		const [logoPrincipal] = logoSindicatoPrincipal

		const formData: FormData = new FormData()
		if (bannerPrincipal) {
			formData.append(
				'bannerSindicatoPrincipal',
				bannerPrincipal.arquivo,
				bannerPrincipal.nomeArquivo.replace('/', '-'),
			)
		}

		if (logoPrincipal) {
			formData.append(
				'logoSindicatoPrincipal',
				logoPrincipal.arquivo,
				logoPrincipal.nomeArquivo.replace('/', '-'),
			)
		}
		cctActVigente.forEach(cctAct => {
			formData.append('cctActVigente', cctAct.arquivo, cctAct.nomeArquivo.replace('/', '-'))
		})

		formData.append('link', link)

		formData.append('idSindicato', '')
		for (const id of sindicatoIds) {
			formData.append('idSindicato', id.toString())
		}
		return formData
	}

	async visualizarArquivosInformacoes(id: number): Promise<any> {
		try {
			const urlAPI = this.urlServerAPINest + `cct/${id}/arquivos-e-informacoes`
			return await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'visualizarArquivosInformacoes')
		}
	}

	async downloadArquivosCCT(idCCT: number, idArquivo: number): Promise<any> {
		try {
			const urlAPI = this.urlServerAPINest + `cct/${idCCT}/arquivos-gerais/${idArquivo}`
			return await this.http
				.get<any>(urlAPI, {
					observe: 'response',
					responseType: 'blob' as 'json',
				})
				.toPromise()
		} catch (error) {
			this.authService.handleError(error, 'downloadArquivosCCT')
		}
	}

	async alterarArquivoCCT(idCCT: number, idArquivo: number, novoArquivo): Promise<any> {
		const formData: FormData = new FormData()
		formData.append('arquivos', novoArquivo.arquivo, novoArquivo.nomeArquivo)

		try {
			const urlAPI = this.urlServerAPINest + `cct/${idCCT}/arquivos-gerais/${idArquivo}`
			return await this.http.put<any>(urlAPI, formData).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'alterarArquivoCCT')
		}
	}

	async alterarLinkLpCCT(idCCT: number, novoLink: string) {
		try {
			const urlAPI = this.urlServerAPINest + `cct/${idCCT}/arquivos-gerais/link`
			return await this.http.put(urlAPI, {link: novoLink}).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'alterarLinkLpCCT')
		}
	}

	//#endregion

	private criarFormDataContratoAssinado(body: ContratosCCTAssinadosDTO) {
		const formData: FormData = new FormData()

		body.arquivos.map(arq => formData.append('arquivos', arq, arq.name))

		return formData
	}

	async postEnviarContratoAssinado(idCCT: number, body: ContratosCCTAssinadosDTO) {
		const formData = this.criarFormDataContratoAssinado(body)
		const urlAPI = this.urlServerAPINest + `cct/${idCCT}/arquivos-assinados`

		let retorno

		try {
			retorno = await this.http.post<any>(urlAPI, formData).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'postEnviarContratoAssinadoCCT')
		}

		return retorno
	}

	async getVisualizarArquivoAssinado(idCCT: number, idContrato: number): Promise<any> {
		const urlAPI = this.urlServerAPINest + `cct/${idCCT}/arquivos-assinados/${idContrato}`
		try {
			return await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'getVisualizarArquivoContratoCCT')
		}
	}

	async listagemArquivosAssinados(idCCT: number): Promise<any> {
		const urlAPI = this.urlServerAPINest + `cct/${idCCT}/arquivos-assinados/`
		try {
			return await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'listagemArquivosAssinados')
		}
	}

	async removerArquivoAssinado(idCCT: number, idContrato: number): Promise<any> {
		const urlAPI = this.urlServerAPINest + `cct/${idCCT}/arquivos-assinados/${idContrato}`
		try {
			return await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'removerArquivoAssinado')
		}
	}

	async implantarCCT(id: number): Promise<any> {
		try {
			const urlAPI = this.urlServerAPINest + `cct/${id}/implantacao`
			return await this.http.post<any>(urlAPI, {}).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'implantarCCT')
		}
	}

	async patchCCTAlterarStatus(cctId: number, status: CctActStatusRaw): Promise<any> {
		try {
			const urlAPI = this.urlServerAPINest + `cct/${cctId}/status`
			return await this.http.patch(urlAPI, {status}).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'patchCCTAlterarStatus')
			return null
		}
	}

	async getCCTByPaf(paf: string): Promise<any> {
		const retorno = null
		try {
			const urlAPI = this.urlServerAPINest + `cct/paf/${paf}`
			await this.http.post<any>(urlAPI, {}).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'getCCTByPaf')
		}

		return retorno
	}

	async postCCTProdutosEComissoes(
		idCCT: number,
		body: CriarOuAtualizarCCTProdutoEComissoesDTO,
	): Promise<any> {
		const retorno = null
		const formData = this.criarFormDataProdutosEComissoes(body)

		try {
			const urlAPI = this.urlServerAPINest + `cct/${idCCT}/produtos-comissoes`
			await this.http.post<any>(urlAPI, formData).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'postCCTProdutosEComissoes')
		}

		return retorno
	}

	private criarFormDataProdutosEComissoes(body: CriarOuAtualizarCCTProdutoEComissoesDTO) {
		const formData: FormData = new FormData()

		const {
			permiteInclusaoDependente,
			dependenteMesmasCondicoes,
			permiteAgenciamentoTitular,
			permiteAgenciamentoDependente,
			valorPafTitular,
			valorPafDependente,
			beneficiosNegociadosTitular,
			beneficiosNegociadosDependente,
			produtosTitular,
			produtosDependente,
			observacoesGerais,
			valorAgenciamentoTitular,
			valorAgenciamentoDependente,
			taxaAdministrativaTitular,
			taxaAdministrativaDependente,
			valorVitalicioTitular,
			valorVitalicioDependente,
			intermediadores,
			arquivoConvencaoColetiva,

			percentualAgenciamentoLaboralTitular,
			percentualAgenciamentoLaboralDependente,
			valorVitalicioLaboralTitular,
			valorVitalicioLaboralDependente,
			percentualAgenciamentoPatronalTitular,
			percentualAgenciamentoPatronalDependente,
			valorVitalicioPatronalTitular,
			valorVitalicioPatronalDependente,
		} = body

		formData.append('beneficiosNegociadosTitular', JSON.stringify(beneficiosNegociadosTitular))
		formData.append(
			'beneficiosNegociadosDependente',
			JSON.stringify(beneficiosNegociadosDependente),
		)

		formData.append('permiteInclusaoDependente', JSON.stringify(permiteInclusaoDependente))
		formData.append('dependenteMesmasCondicoes', JSON.stringify(dependenteMesmasCondicoes))
		formData.append('permiteAgenciamentoTitular', JSON.stringify(permiteAgenciamentoTitular))
		formData.append('permiteAgenciamentoDependente', JSON.stringify(permiteAgenciamentoDependente))
		formData.append('valorPafTitular', valorPafTitular ? valorPafTitular.toString() : '')
		formData.append('valorPafDependente', valorPafDependente ? valorPafDependente.toString() : '')
		formData.append(
			'valorVitalicioTitular',
			valorVitalicioTitular ? valorVitalicioTitular.toString() : '',
		)
		formData.append(
			'valorVitalicioDependente',
			valorVitalicioDependente ? valorVitalicioDependente.toString() : '',
		)
		formData.append(
			'valorAgenciamentoTitular',
			valorAgenciamentoTitular ? valorAgenciamentoTitular.toString() : '',
		)
		formData.append(
			'valorAgenciamentoDependente',
			valorAgenciamentoDependente ? valorAgenciamentoDependente.toString() : '',
		)

		formData.append(
			'taxaAdministrativaTitular',
			taxaAdministrativaTitular ? taxaAdministrativaTitular.toString() : '',
		)
		formData.append(
			'taxaAdministrativaDependente',
			taxaAdministrativaDependente ? taxaAdministrativaDependente.toString() : '',
		)
		formData.append(
			'percentualAgenciamentoLaboralTitular',
			percentualAgenciamentoLaboralTitular ? percentualAgenciamentoLaboralTitular.toString() : '',
		)
		formData.append(
			'percentualAgenciamentoLaboralDependente',
			percentualAgenciamentoLaboralDependente
				? percentualAgenciamentoLaboralDependente.toString()
				: '',
		)
		formData.append(
			'valorVitalicioLaboralTitular',
			valorVitalicioLaboralTitular ? valorVitalicioLaboralTitular.toString() : '',
		)
		formData.append(
			'valorVitalicioLaboralDependente',
			valorVitalicioLaboralDependente ? valorVitalicioLaboralDependente.toString() : '',
		)
		formData.append(
			'percentualAgenciamentoPatronalTitular',
			percentualAgenciamentoPatronalTitular ? percentualAgenciamentoPatronalTitular.toString() : '',
		)
		formData.append(
			'percentualAgenciamentoPatronalDependente',
			percentualAgenciamentoPatronalDependente
				? percentualAgenciamentoPatronalDependente.toString()
				: '',
		)
		formData.append(
			'valorVitalicioPatronalTitular',
			valorVitalicioPatronalTitular ? valorVitalicioPatronalTitular.toString() : '',
		)
		formData.append(
			'valorVitalicioPatronalDependente',
			valorVitalicioPatronalDependente ? valorVitalicioPatronalDependente.toString() : '',
		)

		if (!observacoesGerais) {
			formData.append('observacoesGerais', 'Sem observações')
		} else {
			formData.append('observacoesGerais', observacoesGerais)
		}

		if (produtosTitular) {
			formData.append('produtosTitular', JSON.stringify(produtosTitular))
		}
		if (produtosDependente) {
			formData.append('produtosDependente', JSON.stringify(produtosDependente))
		}

		arquivoConvencaoColetiva
			.filter(arquivo => !!arquivo.arquivo)
			.map(arq => formData.append('arquivoConvencaoColetiva', arq.arquivo, arq.arquivo.name))

		formData.append('intermediadores', JSON.stringify(intermediadores))

		return formData
	}

	async getDadosProdutosEComissoes(id: number): Promise<any> {
		try {
			const urlAPI = this.urlServerAPINest + `cct/${id}/produtos-comissoes`
			return await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'getDadosProdutosEComissoes')
		}
	}

	async downloadArquivoProdutosEComissoes(id: number): Promise<any> {
		try {
			const urlAPI = this.urlServerAPINest + `cct/download/produtos-comissoes/arquivos/${id}`
			return await this.http
				.get<any>(urlAPI, {
					observe: 'response',
					responseType: 'blob' as 'json',
				})
				.toPromise()
		} catch (error) {
			this.authService.handleError(error, 'downloadArquivoProdutosEComissoes')
		}
	}

	async atualizarArquivoConvencaoColetiva(id: number, body: IArquivoTabela): Promise<boolean> {
		const formData = new FormData()
		formData.append('cctId', id && id.toString())
		formData.append('idArquivo', body.idArquivo && body.idArquivo.toString())
		formData.append('nome', body.nomeArquivo)
		formData.append('arquivoConvencaoColetiva', body.arquivo)

		try {
			const urlAPI = this.urlServerAPINest + `cct/${id}/produtos-comissoes-arquivos`
			return await this.http.put<any>(urlAPI, formData).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'atualizarArquivoConvencaoColetiva')
		}
	}

	async listarBeneficios() {
		return await this.http
			.get<ListagemCCTBeneficio>(`${this.urlServerAPINest}cct/listar-beneficios`)
			.toPromise()
			.then(data => {
				const {titular, dependente} = data
				const beneficiosTitularOptions = titular.map(
					(beneficio: CCTBeneficio): BeneficioDescricao => {
						return {
							id: beneficio.id,
							descricao: beneficio.descricao,
						}
					},
				)
				const beneficiosDependentesOptions = dependente.map(
					(beneficio: CCTBeneficio): BeneficioDescricao => {
						return {
							id: beneficio.id,
							descricao: beneficio.descricao,
						}
					},
				)
				const beneficios: ListagemCCTBeneficio = {
					titular: beneficiosTitularOptions,
					dependente: beneficiosDependentesOptions,
				}
				return beneficios
			})
	}

	async listarProdutos() {
		return await this.http
			.get<ListagemCCTProduto>(`${this.urlServerAPINest}cct/produtos`)
			.toPromise()
			.then(data => {
				const planosClin = data.CLIN.map(produto => {
					return {
						value: produto.id,
						label: produto.nomePlano,
					}
				})

				const planosMelhora = data.MELHORAI.map(produto => {
					return {
						value: produto.id,
						label: produto.nomePlano,
					}
				})

				const planosAgiben = data.AGIBEN.map(produto => {
					return {
						id: produto.id,
						descricao: produto.nomePlano,
					}
				})

				return {planosClin, planosMelhora, planosAgiben}
			})
	}

	async postCCTCadastrarParametrizacao(idCCT: number, body: any): Promise<any> {
		const retorno = null

		try {
			const urlAPI = this.urlServerAPINest + `cct/${idCCT}/parametrizacao`
			await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'postCCTCadastrarParametrizacao')
		}

		return retorno
	}

	async postCCTFinalizarParametrizacao(idCCT: number): Promise<any> {
		const retorno = null

		try {
			const urlAPI = this.urlServerAPINest + `cct/${idCCT}/finalizacao`
			await this.http.post<any>(urlAPI, {}).toPromise()
		} catch (error) {
			this.authService.handleError(error, 'postCCTFinalizarParametrizacao')
		}

		return retorno
	}
}

<section
	id="clube-beneficios"
	class="d-lg-flex flex-row justify-content-between d-none align-items-center page-margin">
	<div class="flex-column px-5">
		<h3>{{ tituloCard }}</h3>
		<p class="my-5">{{ textoCard }}</p>
		<a class="btn btn-white text-left font-weight-bold" [href]="linkButton" target="_blank">
			{{ textoButton }}
		</a>
	</div>
	<div class="img-clube">
		<img [src]="imgSrcDesktop" alt="Imagem " />
	</div>
</section>

<section
	id="clube-beneficios-mobile"
	class="my-5 d-lg-none d-block flex-column align-items-center m-4">
	<div class="img-clube-mobile">
		<img [src]="imgSrcMobile" alt="Imagem " />
	</div>

	<div class="flex-column p-5">
		<h3 class="clube-title-mobile">{{ tituloCard }}</h3>
		<p class="my-5">{{ textoCard }}</p>
		<a
			class="btn btn-white text-center font-weight-bold d-block"
			[href]="linkButton"
			target="_blank">
			{{ textoButton }}
		</a>
	</div>
</section>

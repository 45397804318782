<div>
	<nav class="navbar navbar-expand-lg align-items-lg-center">
		<div class="d-flex flex-row w-100 align-items-center justify-content-between border-bottom-1">
			<a class="navbar-brand app-brand demo py-0" (click)="onLogoClick()">
				<svg
					width="98"
					height="48"
					viewBox="0 0 98 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip0_5690_269)">
						<path
							d="M42.0039 7.72131V5.78841C42.0039 5.6604 42.0294 5.53495 42.078 5.41719C42.1266 5.29942 42.1982 5.19445 42.2877 5.10229C42.3797 5.01012 42.4845 4.941 42.6021 4.89236C42.7197 4.84371 42.8449 4.81812 42.9727 4.81812H45.0178C45.1456 4.81812 45.2709 4.84371 45.3885 4.89236C45.5061 4.941 45.6109 5.01268 45.7029 5.10229C45.7949 5.19445 45.8639 5.29942 45.9125 5.41719C45.9611 5.53495 45.9866 5.6604 45.9866 5.78841V7.72131C45.9866 7.84932 45.9611 7.97476 45.9125 8.09253C45.8639 8.21029 45.7924 8.31526 45.7029 8.40742C45.6109 8.49959 45.5061 8.56871 45.3885 8.61736C45.2709 8.666 45.1456 8.6916 45.0178 8.6916H42.9727C42.8449 8.6916 42.7197 8.666 42.6021 8.61736C42.4845 8.56871 42.3797 8.49703 42.2877 8.40742C42.1956 8.31782 42.1266 8.21029 42.078 8.09253C42.0294 7.97476 42.0039 7.84932 42.0039 7.72131Z"
							fill="#F2AB27" />
						<path
							d="M42.0039 29.0114V11.2747C42.0039 11.1467 42.0294 11.0213 42.078 10.9035C42.1266 10.7857 42.1982 10.6808 42.2877 10.5886C42.3797 10.4965 42.4845 10.4273 42.6021 10.3787C42.7197 10.33 42.8449 10.3044 42.9727 10.3044H45.0178C45.1456 10.3044 45.2709 10.33 45.3885 10.3787C45.5061 10.4273 45.6109 10.499 45.7029 10.5886C45.7949 10.6808 45.8639 10.7857 45.9125 10.9035C45.9611 11.0213 45.9866 11.1467 45.9866 11.2747V29.0114C45.9866 29.1394 45.9611 29.2648 45.9125 29.3826C45.8639 29.5003 45.7924 29.6053 45.7029 29.6975C45.6134 29.7896 45.5061 29.8588 45.3885 29.9074C45.2709 29.956 45.1456 29.9816 45.0178 29.9816H42.9727C42.8449 29.9816 42.7197 29.956 42.6021 29.9074C42.4845 29.8588 42.3797 29.7871 42.2877 29.6975C42.1956 29.6079 42.1266 29.5003 42.078 29.3826C42.0294 29.2648 42.0039 29.1419 42.0039 29.0114Z"
							fill="#222222" />
						<path
							d="M60.4959 20.412C60.4959 18.3025 60.1354 16.6819 59.4171 15.5555C58.7934 14.5493 57.9549 14.045 56.9017 14.045C56.3751 14.045 55.7105 14.2319 54.9078 14.6031C54.2534 14.9052 53.6833 15.2611 53.1976 15.6656C52.9931 15.8371 52.878 16.0931 52.878 16.3593V24.6516C52.878 24.9153 52.9905 25.1662 53.1874 25.3377C54.2943 26.3029 55.3398 26.7842 56.3265 26.7842C57.4513 26.7842 58.3971 26.2799 59.164 25.2737C60.0536 24.1191 60.4959 22.4986 60.4959 20.412ZM64.2332 20.4837C64.2332 23.2896 63.635 25.5758 62.4361 27.3398C61.2372 29.1037 59.5858 29.9844 57.4769 29.9844C56.2549 29.9844 55.2963 29.8513 54.6036 29.5876C53.9082 29.3239 53.1183 28.7478 52.2313 27.8595L51.1755 29.2906C51.0043 29.5236 50.7333 29.6593 50.4444 29.6593H49.2992C48.7189 29.6593 48.2869 29.1242 48.4096 28.5558L49.0001 25.8088V5.74246C49.0001 5.23812 49.4066 4.83105 49.9102 4.83105H51.8632C52.3642 4.83105 52.7732 5.23812 52.7732 5.74246V12.9646C53.4685 12.5089 54.1511 12.0532 54.8208 11.5975C55.6823 11.0931 56.4492 10.8423 57.1215 10.8423C59.2279 10.8423 60.9407 11.7537 62.2597 13.5765C63.5737 15.3993 64.2332 17.7009 64.2332 20.4837Z"
							fill="#222222" />
						<path
							d="M76.5891 16.6614C77.1822 16.6614 77.6142 16.1033 77.4711 15.5247C76.9649 13.4766 75.7481 12.45 73.8232 12.45C72.793 12.45 71.8881 12.8263 71.111 13.5841C70.5972 14.0834 70.206 14.7004 69.9376 15.4351C69.7203 16.0291 70.16 16.6589 70.7914 16.6589H76.5891V16.6614ZM80.531 19.7925H69.4749C69.6667 21.5922 70.3134 22.9952 71.4152 24.0013C72.3968 24.9127 73.5701 25.3684 74.9352 25.3684C76.4741 25.3684 77.9696 24.8026 79.419 23.6685C79.9482 23.2563 80.7253 23.5405 80.8659 24.1984L81.1598 25.5732C81.2416 25.9598 81.0678 26.3566 80.7253 26.5512C78.5038 27.8262 76.3361 28.4636 74.2169 28.4636C71.773 28.4636 69.7612 27.6137 68.1814 25.9137C66.5045 24.0935 65.666 21.687 65.666 18.6942C65.666 15.7961 66.5275 13.474 68.253 11.7255C69.8328 10.1689 71.7373 9.39062 73.9664 9.39062C76.0012 9.39062 77.7267 10.0614 79.1403 11.4055C80.6716 12.8929 81.4385 14.8591 81.4385 17.3066V18.8785C81.441 19.3854 81.0346 19.7925 80.531 19.7925Z"
							fill="#222222" />
						<path
							d="M97.0907 28.4636H95.1377C94.6341 28.4636 94.2276 28.0565 94.2276 27.5522V17.3449C94.2276 14.2984 93.4607 12.7751 91.9295 12.7751C90.3241 12.7751 88.5628 13.4228 86.6481 14.7182V27.5522C86.6481 28.0565 86.2417 28.4636 85.7381 28.4636H83.7851C83.2815 28.4636 82.875 28.0565 82.875 27.5522V13.6379V11.2211C82.875 10.5478 83.4195 10.0025 84.0918 10.0025C84.4369 10.0025 84.7692 10.151 84.9993 10.4095L86.1445 11.6947C89.2351 10.3276 91.4259 9.64404 92.7194 9.64404C96.2394 9.64404 98.0007 12.1991 98.0007 17.3091V27.5522C98.0007 28.0565 97.5943 28.4636 97.0907 28.4636Z"
							fill="#222222" />
						<path
							d="M13.4533 16.8329V13.6737C13.4533 13.1617 13.1005 12.7162 12.6046 12.601C11.5846 12.3706 10.6107 12.2528 9.68016 12.2528C8.03133 12.2528 6.75062 12.5575 5.84057 13.1642C4.93052 13.7735 4.47295 14.5851 4.47295 15.5989C4.47295 16.6127 4.90751 17.432 5.7741 18.0541C6.64325 18.6787 7.72715 18.9885 9.02832 18.9885C10.7206 18.9885 12.0729 18.5456 13.0826 17.6547C13.3204 17.4499 13.4533 17.1478 13.4533 16.8329ZM17.6047 22.7263H16.3854C16.0428 22.7263 15.7182 22.565 15.5111 22.2911L14.1921 20.5528C12.3975 21.5947 11.3137 22.1913 10.9379 22.3346C9.75175 22.8569 8.52214 23.1155 7.2491 23.1155C5.10691 23.1155 3.38652 22.4498 2.08536 21.116C0.697277 19.7258 0.00195312 17.8416 0.00195312 15.4658C0.00195312 13.41 0.796956 11.7792 2.38698 10.5785C3.97701 9.37522 6.07574 8.77614 8.67807 8.77614C10.24 8.77614 11.83 8.9656 13.4507 9.34194V8.81967C13.4507 5.51709 12.1777 3.86581 9.6316 3.86581C8.76757 3.86581 6.92956 4.22166 4.12273 4.93338C3.59613 5.06651 3.05163 4.79513 2.84202 4.29591L2.15694 2.67534C1.89108 2.04555 2.25919 1.32615 2.92383 1.1751C6.33907 0.3917 8.93628 0 10.718 0C13.5811 0 15.5623 0.824363 16.664 2.47565C17.5332 3.77876 17.9652 5.88063 17.9652 8.77614V18.0746L18.6784 21.3925C18.8241 22.0812 18.3026 22.7263 17.6047 22.7263Z"
							fill="#222222" />
						<path
							d="M33.5802 17.8441V21.0033C33.5802 21.5153 33.2275 21.9608 32.7316 22.076C31.7116 22.3064 30.7376 22.4242 29.8071 22.4242C28.1583 22.4242 26.8776 22.1195 25.9675 21.5102C25.0575 20.9009 24.5999 20.0919 24.5999 19.0755C24.5999 18.0617 25.0345 17.2425 25.9011 16.6204C26.7702 15.9957 27.8541 15.6859 29.1553 15.6859C30.8475 15.6859 32.1998 16.1288 33.2096 17.0197C33.4448 17.2271 33.5802 17.5292 33.5802 17.8441ZM37.7317 11.9507H36.5123C36.1698 11.9507 35.8451 12.112 35.6381 12.3859L34.319 14.1242C32.5245 13.0822 31.4406 12.4857 31.0648 12.3424C29.8787 11.8201 28.6491 11.5615 27.3761 11.5615C25.2339 11.5615 23.5135 12.2272 22.2123 13.561C20.8242 14.9511 20.1289 16.8354 20.1289 19.2112C20.1289 21.267 20.9239 22.8978 22.5165 24.1011C24.1065 25.3018 26.2053 25.9034 28.8076 25.9034C30.3695 25.9034 31.9595 25.714 33.5802 25.3376V25.8599C33.5802 29.1625 32.3072 30.8137 29.7611 30.8137C28.8971 30.8137 27.0591 30.4579 24.2522 29.7462C23.7256 29.613 23.1811 29.8844 22.9715 30.3836L22.2865 32.0042C22.0206 32.634 22.3887 33.3534 23.0533 33.5044C26.4686 34.2878 29.0658 34.6795 30.8475 34.6795C33.7106 34.6795 35.6918 33.8552 36.7935 32.2039C37.6601 30.9008 38.0947 28.7989 38.0947 25.9034V16.605L38.8079 13.2871C38.9511 12.5958 38.4296 11.9507 37.7317 11.9507Z"
							fill="#222222" />
						<path
							d="M1.46842 37.1502C1.96179 36.7406 2.68266 36.7637 3.15302 37.1989C7.09997 40.8573 12.4734 42.6878 19.2783 42.6878C22.9159 42.6878 25.9962 42.2833 28.5219 41.4743C31.1983 40.632 33.609 39.2496 35.7486 37.3295C36.2011 36.925 36.8759 36.8942 37.3616 37.2552L38.3126 37.9618C38.9287 38.4201 39.0079 39.3136 38.4813 39.8691C35.925 42.5777 32.7066 44.6642 28.8261 46.1286C25.4927 47.378 22.3101 48.0001 19.2783 48.0001C16.1136 48.0001 12.8287 47.3089 9.42879 45.9264C5.87806 44.462 2.85907 42.4446 0.364103 39.8691C-0.157386 39.3315 -0.113924 38.4636 0.463803 37.9848L1.46842 37.1502Z"
							fill="#222222" />
					</g>
					<defs>
						<clipPath id="clip0_5690_269">
							<rect width="98" height="48" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</a>

			<div
				*ngIf="typeSelectedLabel"
				class="navbar-selector d-flex d flex-row align-items-center justify-space-between">
				<div class="d-flex flex-row align-items-center" (click)="onSelectType()">
					<span class="mr-2 font-weight-bold">{{ typeSelectedLabel }}</span>
					<i class="fa fa-chevron-down"></i>
				</div>

				<button *ngIf="bottaoRoteamentoLabel" class="link-button" (click)="onBottaoRoteamento()">
					{{ bottaoRoteamentoLabel }}
					<i class="fa fa-external-link-alt"></i>
				</button>

				<div class="navbar-buttons d-lg-flex d-none flex-row justify-content-end">
					<button
						*ngIf="secondaryButtonLabel"
						class="btn btn-outline-dark font-weight-bold mr-4"
						(click)="onSecondaryButtonClick()">
						{{ secondaryButtonLabel }}
					</button>
					<button
						*ngIf="primaryButtonLabel"
						class="btn btn-primary font-weight-bold"
						(click)="onPrimaryButtonClick()">
						{{ primaryButtonLabel }}
					</button>
				</div>
			</div>

			<div
				*ngIf="!typeSelectedLabel"
				class="navbar-options d-lg-flex d-none flex-row w-100 justify-content-end align-items-center">
				<div
					class="navbar-option font-weight-bold"
					[ngClass]="{'ml-5': index != 0}"
					*ngFor="let option of navbarOptions; let i = index">
					<span (click)="onScrollTo(option?.id)">{{ option?.label }}</span>
				</div>

				<button *ngIf="bottaoRoteamentoLabel" class="link-button" (click)="onBottaoRoteamento()">
					{{ bottaoRoteamentoLabel }}
					<i class="fa fa-external-link-alt"></i>
				</button>

				<div class="navbar-buttons d-lg-flex d-none flex-row justify-content-end ml-5">
					<button
						*ngIf="secondaryButtonLabel"
						class="btn btn-outline-dark font-weight-bold mr-4"
						(click)="onSecondaryButtonClick()">
						{{ secondaryButtonLabel }}
					</button>
					<button
						*ngIf="primaryButtonLabel"
						class="btn btn-primary font-weight-bold"
						(click)="onPrimaryButtonClick()">
						{{ primaryButtonLabel }}
					</button>
				</div>
			</div>
		</div>
	</nav>
</div>

import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'
import {Sindicato} from 'src/app/service/cct.service'

@Component({
	selector: 'modal-with-one-button',
	templateUrl: './modal-with-one-button.component.html',
	styleUrls: ['./modal-with-one-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class ModalWithOneButtonComponent implements OnInit {
	@Input() title = 'Modal title'
	@Input() description: string
	@Input() icon: string
	@Input() buttonLabel = 'Voltar'
	public formSindicato: FormGroup

	@Input() sindicatos: Sindicato[]

	constructor(
		private activeModal: NgbActiveModal,
		private fb: FormBuilder,
	) {}

	async ngOnInit() {
		this.formSindicato = this.fb.group({
			sindicato: [null, [Validators.required]],
		})
	}

	get getFormSindicato() {
		return this.formSindicato.controls
	}

	closeModal() {
		this.activeModal.close(this.formSindicato.get('sindicato').value)
	}
}

import {Injectable} from '@angular/core'
import {Subject} from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class PopoverService {
	private dataTransferSubject = new Subject<any>()
	dataTransferObservable = this.dataTransferSubject.asObservable()

	send(data: any): void {
		this.dataTransferSubject.next(data)
	}
}

<button
	[type]="type"
	[disabled]="disabled"
	(click)="click()"
	class="btn btn-anim ladda-button font-weight-semibold"
	[ladda]="loading"
	data-style="zoom-in"
	[ngClass]="{'btn-primary': cssClass === 'PRIMARY', 'btn-outline-dark': cssClass === 'OUTLINE', 'btn-dark': cssClass === 'DARK'}">
	{{ label }}
</button>

import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { NgbActiveModal, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { ApiService } from "src/app/service/api.service";
import { Login } from "src/app/model/login";
import { BlockUI, NgBlockUI } from "ng-block-ui";
// import { InclusaoVidasSelecaoPopup } from "../inclusao-vidas-selecao-popup/inclusao-vidas-selecao-popup.component";

import { ValidateBrService } from "angular-validate-br";
import { ActivatedRoute } from "@angular/router";
import { AuditViewComponent } from "src/app/audit/audit-view/audit-view.component";
import { Dicionario } from "src/app/model/dicionario";

@Component({
  selector: "inclusao-vidas-popup",
  templateUrl: "./inclusao-vidas-popup.component.html",
  styleUrls: [
    "../../../vendor/libs/angular2-ladda/angular2-ladda.scss",
    "../../../vendor/libs/ng-select/ng-select.scss",
    "./inclusao-vidas-popup.component.scss",
    "../../../vendor/libs/spinkit/spinkit.scss",
    "../../../vendor/libs/ngb-datepicker/ngb-datepicker.scss",
  ],
})
export class InclusaoVidasPopup implements OnInit {
  user: Login;
  id: number = 0;
  idEmpresa: number = 0;
  idEmpresaS4E: number = 0;
  nomeEmpresa: string = "";
  tipoVida: string = "";
  loading = [false];
  submitted = false;
  disabled = false;
  numberOfItemsFromEndBeforeFetchingMore = 5;
  existente: any;
  compromisso: any;
  infoTitular: any;
  exigeMatricula: boolean = false

  typeaheadConta = new EventEmitter<string>();
  typeaheadEmpresa = new EventEmitter<string>();

  form_inclusao_vida: any;
  isReturn: boolean = false;
  fk_idempresa: any;
  dadosCompromisso: any;
  remover_vida: boolean = false;

  state: any = {
    list: null,
    paging: null,
    list_produtos: [],
    empresa: null,
    is404: [false],
  };

  @BlockUI("elementBody") blockUIElementBody: NgBlockUI;
  @BlockUI() blockUIPage: NgBlockUI;
  @BlockUI("element") blockUIElement: NgBlockUI;
  @Input() atividadeView: any;

  public form: FormGroup;

  get f() {
    return this.form.controls;
  }

  //#endregion

  //#region CONSTRUCTOR
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public translateService: TranslateService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private validateBrService: ValidateBrService,
    private activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {

    this.apiService.getUser().then((res: Login) => {
      this.user = res;

      if (this.user.temPermissao(Dicionario.SALES_CLIENTE, "D")) {
        this.remover_vida = true;
      }

      setTimeout(() => {
      }, 200);

      if (this.state.empresa && this.state.empresa.empresa && this.state.empresa.empresa.empresa && this.state.empresa.empresa.empresa.exigeMatricula) {
        this.exigeMatricula = true
      }
    });

    this.form = this.fb.group({
      vida_tipo: [null, [Validators.required]],
      vida_empresa: [null, [Validators.required]],
      vida_nome: [null, [Validators.required, Validators.minLength(10)]],
      vida_cpf: [
        null,
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
          this.validateBrService.cpf,
        ],
      ],
      vida_nomemae: [null, [Validators.required]],
      vida_nascimento: [null, [Validators.required]],
      vida_sexo: [null, [Validators.required]],
      vida_matricula: [null],
      vida_telefone: [null, [Validators.required]],
      vida_email: [null, [Validators.minLength(4)]],
      cpf_titular: [null],
      vida_idcompromisso: [null, [Validators.required]],
      fk_idtitular: [null],
      cco_id_produto_s4: [null],
      cli_codigoassociados4e: [null],
      cli_grauparentesco: [null, [Validators.required]],
      cco_status: [null],
      pk_idcliente: [null],
      cli_cod_empresa_s4E: [null],
    });

    //FLUXO DE QUEM ESTA ADICIONANDO UM DEPENDENTE PELO GRID DE COMRPOMISSO
    if (this.tipoVida != '') {
      this.form.patchValue({
        vida_tipo: this.tipoVida,
        vida_empresa: this.nomeEmpresa,
        cpf_titular: this.infoTitular.cli_cpf,
        vida_idcompromisso: this.compromisso,
        cli_codigoassociados4e: this.infoTitular.cli_codigoassociados4e,
        fk_idtitular: this.infoTitular.pk_idcliente
      })
    }
    else if (this.id > 0) {
      this.form.patchValue({
        cco_datahora: this.existente.cco_datahora,
        vida_nome: this.existente.cliente.cli_nome,
        vida_cpf: this.existente.cliente.cli_cpf,
        vida_nomemae: this.existente.cliente.cli_nomemae,
        vida_nascimento: this.existente.cliente.cli_datanascimento,
        vida_sexo: this.existente.cliente.cli_sexo,
        vida_matricula: this.existente.cliente.cli_matricula,
        vida_telefone: this.existente.cliente.cli_telefonecelular,
        vida_email: this.existente.cliente.cli_email,
        vida_tipo: this.existente.cliente.cli_tipo.toString(),
        cli_grauparentesco:
          this.existente.cliente.cli_grauparentesco.toString(),
        vida_empresa: this.nomeEmpresa,
        fk_idempresa: this.existente.compromisso.fk_idempresa,
        vida_idcompromisso: this.compromisso,
        cli_codigoassociados4e: this.existente.cliente.cli_codigoassociados4e,
        cco_id_produto_s4: this.existente.cco_id_produto_s4 + " - " + this.state.list_produtos.find(x => x.idPlano == this.existente.cco_id_produto_s4).plano.nome,
        cpf_titular: this.existente.cliente?.titular?.cli_nome,
        cco_status: this.existente.cco_status.toString(),
      });
    } else if (this.isReturn) {
      this.form.patchValue({
        vida_tipo: this.form_inclusao_vida.vida_tipo.value,
        vida_empresa: this.form_inclusao_vida.vida_empresa.value,
        vida_nome: this.form_inclusao_vida.vida_nome.value,
        vida_cpf: this.form_inclusao_vida.vida_cpf.value,
        vida_nomemae: this.form_inclusao_vida.vida_nomemae.value,
        vida_nascimento: this.form_inclusao_vida.vida_nascimento.value,
        vida_sexo: this.form_inclusao_vida.vida_sexo.value,
        vida_matricula: this.form_inclusao_vida.vida_matricula.value,
        vida_telefone: this.form_inclusao_vida.vida_telefone.value,
        vida_email: this.form_inclusao_vida.vida_email.value,
        cpf_titular: this.form_inclusao_vida.cpf_titular.value,
        vida_idcompromisso: this.form_inclusao_vida.vida_idcompromisso.value,
        fk_idtitular: this.form_inclusao_vida.fk_idtitular.value,
        cco_id_produto_s4: this.form_inclusao_vida.cco_id_produto_s4.value,
        cli_codigoassociados4e: this.form_inclusao_vida.cli_codigoassociados4e.value,
        cli_grauparentesco: this.form_inclusao_vida.cli_grauparentesco.value,
        cco_status: this.form_inclusao_vida.cco_status.value,
        pk_idcliente: this.form_inclusao_vida.pk_idcliente.value,
        cli_cod_empresa_s4E: this.form_inclusao_vida.cli_cod_empresa_s4E.value
      })
    }

  }

  //#endregion

  delayBlockUI;
  toggleElementBlocking() {
    this.stopToggleElementBlocking();

    this.delayBlockUI = setTimeout(() => {
      this.blockUIElement.start();
    }, 1000);
  }

  stopToggleElementBlocking() {
    if (this.delayBlockUI) {
      clearTimeout(this.delayBlockUI);
      this.blockUIElement.stop();
      this.delayBlockUI = null;
      return;
    }
  }

  async onSubmit() {
    try {

      if (this.id > 0) {
        if (this.form.invalid) {
          return;
        }

        const update = await this.apiService.updateVidaNest(this.f, this.id);

        this.activeModal.close(null);
        return update;
      }

      if (this.exigeMatricula && !this.f.vida_matricula.value) {
        this.apiService.showToast(
          null,
          "Para essa empresa o campo matrícula é obrigatório.",
          "warning"
        );
      } else {

        // let options = { windowClass: "modal-md animate nested-modal" };
        let options: NgbModalOptions = {
          backdrop: 'static',
          keyboard: false,
          windowClass: "modal-md animate nested-modal"
        };


        // let modal = this.modalService.open(InclusaoVidasSelecaoPopup, options);

        // (<InclusaoVidasSelecaoPopup>modal.componentInstance).form_inclusao_vida =
        //   this.f;

        // (<InclusaoVidasSelecaoPopup>modal.componentInstance).state.list_produtos =
        //   this.state.list_produtos;

        // (<InclusaoVidasSelecaoPopup>modal.componentInstance).state.empresa =
        //   this.state.empresa;

        // (<InclusaoVidasSelecaoPopup>modal.componentInstance).idCompromisso =
        //   this.compromisso;

        this.activeModal.close(null);

      }

    } catch (error) {
      if (error.status == 404) {
        this.apiService.showToast(
          null,
          "Erro ao realizar procedimento.",
          "error"
        );
      } else if (error.status == 400) {
        this.apiService.showToast(null, error.error.errors, "error");
      } else {
        this.apiService.errorMessageShow(error.errors);
      }
    } finally {
      this.loading[0] = false;
    }
  }

  async getClientesPorIdCompromissoNest() {
    try {

      this.toggleElementBlocking();

      var data = await this.apiService.getClientesPorIdCompromissoNest(this.id);
      if (!data) return;

      // GET Response
      const o = data;

      this.form.patchValue(o);

      if (!data) return;

      this.state.clientesCompromissos = data;

      let full_count = data[0].full_count;

      this.state.pagingCompromisso = {
        total: full_count,
      };
    } catch (error) {
      if (error) {
        if (error.status == 404) {
          this.state.is404[10] = true;
        } else if (error.status != 401) {
          this.apiService.errorMessageShow(error);
        } else {
          // erro desconhecido
          console.log(error);
        }
      }
    } finally {
      this.loading[0] = false;
    }
  }

  clienteSincronizado(): boolean {
    let retorno: boolean = false;

    let cli_codigoassociados4e = this.f.cli_codigoassociados4e.value;

    if (this.id === 0) {
      retorno = true;
    } else if (cli_codigoassociados4e > 0 && this.id > 0) {
      retorno = true;
    }
    // this.sanitizer.bypassSecurityTrustResourceUrl(this.url_google_maps_incio + this.f.emp_logradouro.value + " , " + this.f.emp_numero.value + " , " + this.f.emp_cidade.value + " , " + this.f.emp_uf.value + this.url_google_maps_fim)
    return retorno;
  }



  async sincronizarVidaS4E() {
    try {
      //
      this.loading[3] = true;
      this.submitted = true;

      // stop here if form is invalid
      if (this.form.invalid) {
        return;
      }


      if (this.existente && this.id > 0 && this.state.empresa.empresa) {
        this.form.patchValue({
          vida_empresa: this.nomeEmpresa,
          fk_idempresa: this.idEmpresa,
          cli_codigoassociados4e:
            this.existente?.cliente?.titular?.cli_codigoassociados4e,
          cli_cod_empresa_s4E: this.state.empresa?.empresa?.emp_codempresa_s4,
          cpf_titular: this.existente?.cliente?.titular?.cli_nome,
          fk_idtitular: this.existente?.cliente?.titular?.pk_idcliente,
          pk_idcliente: this.id,
        });
      } else {
        this.apiService.showToast(
          null,
          "Não foi possivel sincronizar esse benefiário. Entre em contato com o suporte.",
          "warning"
        );
        return;
      }

      var body = this.form.value;

      if (this.id > 0) {
        const data = await this.apiService.sincronizarVidaNest(body);
        this.apiService.showToast(
          null,
          "Beneficiário sincronizado com sucesso",
          "success"
        );
      }

      // close this popup and pass result value
      this.activeModal.close(this.form.value);

      this.submitted = false;
      this.disabled = true;
    } catch (error) {
      if (error) {
        if (error.status == 404) {
          this.state.is404[10] = true;
        } else if (error.error.status == 422 || error.error.status == 406) {
          this.apiService.showToast(
            null,
            "Beneficiário já cadastrado",
            "error"
          );
        }
        else if (error.status != 401) {
          this.apiService.showToast(
            null,
            "Erro ao sincronizar beneficiário.",
            "error"
          );
        } else {
          // erro desconhecido
          this.apiService.showToast(null, error.error.errors, "error");
          console.log(error);
        }
      }
    } finally {
      this.loading[3] = false;
    }
  }
  async removeVidaNest(id: any) {
    try {

      const remove = await this.apiService.removerVidaNest(id);
      this.activeModal.close(null);

      return remove;
    } catch (error) {

      if (error) {
        if (error.status == 404) {
          this.state.is404[10] = true;
        } else if (error.status != 401) {
          this.apiService.errorMessageShow(error);
        } else {
          // erro desconhecido
          console.log(error);
        }
      }
    } finally {
      this.apiService.showToast(
        null,
        "Beneficiário removido com sucesso",
        "success"
      );
    }
  }
  //#region AUDITORIA
  async openAuditPopup() {
    let options = { windowClass: "modal-xl nested-modal" };

    let modal = this.modalService.open(AuditViewComponent, options);

    // let title = `${this.regiaoCampanhaView.cam_nome}`;

    (<AuditViewComponent>modal.componentInstance).setDicionario(
      "title",
      Dicionario.CLIENTE,
      this.id
    );

    modal.result.then(
      (result) => { },
      (reason) => {
        // modal foi cancelado/fechado
        console.log(`Dismissed ${reason}`);
      }
    );
  }
  //#endregion

  validarForm() {

    if (this.form.invalid) {
      return;
    }
  }


  onChange(event: any) {

    if (this.state.empresa.empresa.pk_idempresa) {
      this.idEmpresa = this.state.empresa.empresa.pk_idempresa
    }
    if (event == 1) {
      this.form.patchValue({
        vida_empresa: this.nomeEmpresa,
        fk_idempresa: this.idEmpresa,
        vida_tipo: "1",
        cli_grauparentesco: "1",
        vida_idcompromisso: this.compromisso,
      });
    }
    if (event == 2) {
      this.form.patchValue({
        vida_tipo: "2",
        cli_grauparentesco: null,
        cpf_titular: this.infoTitular?.cli_nome,
        fk_idtitular: this.infoTitular?.pk_idcliente,
        vida_empresa: this.nomeEmpresa,
        vida_idcompromisso: this.compromisso,
      });
    }
  }


  async buscarTitular(event: Event) {
    try {
      this.loading[0] = true;
      this.state.is404[0] = false;

      this.idEmpresa = this.state.empresa.empresa.pk_idempresa
      this.idEmpresaS4E = this.state.empresa.empresa.emp_codempresa_s4

      //VERIFICA SE O BENEFICIARIO JA ESTA CADASTRADO NO POSTGRES

      let beneficiarioPostgresAgiben = await this.verificarSeJaEstaNoPostgresAgiben(this.idEmpresa)

      let dataS4E = await this.apiService.getClientePorIDEmpresaCPFS4E(this.idEmpresaS4E, this.f.cpf_titular.value);

      if (dataS4E) {
        if (dataS4E && dataS4E.situacao === 'Ativo') {
          let params = {
            cli_nome: dataS4E.nome,
            cli_cpf: dataS4E.cpf,
            cli_telefonecelular: dataS4E.celular != null ? dataS4E.celular : '00900000000',
            cli_email: dataS4E.email,
            cli_grauparentesco: 1,
            cli_nomemae: dataS4E.nomeMae,
            cli_datanascimento: dataS4E.dataNascimento,
            cli_matricula: dataS4E.matricula,
            cli_status: 'A',
            cli_sexo: dataS4E.sexo === 'Masculino' ? 1 : 0,
            fk_idempresa: this.idEmpresa,
            cli_codigoassociados4e: dataS4E.codAssociado
          };

          //VERIFICAR SE O BENEFICIARIO JA ESTA NA BASE DO POSTGRES E APONTANDO PARA MESMA EMPRESA
          if (beneficiarioPostgresAgiben &&
            beneficiarioPostgresAgiben.cli_codigoassociados4e &&
            beneficiarioPostgresAgiben.cli_codigoassociados4e === dataS4E.codAssociado) {

            this.form.patchValue({
              cpf_titular: beneficiarioPostgresAgiben.cli_cpf,
              fk_idtitular: beneficiarioPostgresAgiben.pk_idcliente,
              cli_codigoassociados4e: beneficiarioPostgresAgiben.cli_codigoassociados4e,
            });

          } else {
            //USUARIO AINDA NAO TA NA BASE DO POSTGRES, PRECISA INSERIR
            const create = await this.apiService.inserirVidaPostgresNest(params);
            this.form.patchValue({
              cpf_titular: create.cli_cpf,
              fk_idtitular: create.pk_idcliente,
              cli_codigoassociados4e: create.cli_codigoassociados4e,
            });
          }

          this.apiService.showToast(
            null,
            dataS4E.nome + " - Relacionado com sucesso.",
            "success"
          );
        }
      } else {
        this.beneficiarioNaoEncontrado();
      }

      // if (this.state.empresa.empresa.associado) {

      //   this.idEmpresa = this.state.empresa.empresa.pk_idempresa
      //   this.idEmpresaS4E = this.state.empresa.empresa.emp_codempresa_s4
      //   let titular = this.state.empresa.empresa.associado.find(x => x.cpf == this.f.cpf_titular.value)

      //   //CASO O USUÁRIO JÁ ESTEJA CADASTRADO NO S4E
      //   if (titular) {

      //     //BUSCAR POR titular?.codigoAssociado

      //     let data = await this.apiService.getClientePorIDEmpresaCodAssociadoPostgres(this.idEmpresa, titular?.codigoAssociado).then((data: any) => {

      //       this.form.patchValue({
      //         cpf_titular: data.cli_cpf,
      //         fk_idtitular: data.pk_idcliente,
      //         cli_codigoassociados4e: data.cli_codigoassociados4e,
      //       });

      //       this.apiService.showToast(
      //         null,
      //         data.cli_nome + " - Relacionado com sucesso.",
      //         "success"
      //       );

      //     }).catch(async (err: any) => {

      //       // Erro diferente do esperado
      //       if(!(err?.error?.status === 404)){
      //         throw err;
      //       }

      //       if (this.idEmpresaS4E > 0) {
      //         // 1- BUSCAR NA BASE DO S4E PARA PEGAR AS INFORMACOES DO BENEFICIARIO
      //         // 2- CADASTRAR NO POSTGRES

      //         let dataS4E = await this.apiService.getClientePorIDEmpresaCPFS4E(this.idEmpresaS4E, this.f.cpf_titular.value);

      //         if (dataS4E && dataS4E.situacao === 'Ativo') {
      //           let params = {
      //             cli_nome: dataS4E.nome,
      //             cli_cpf: dataS4E.cpf,
      //             cli_telefonecelular: dataS4E.celular != null ? dataS4E.celular : '00900000000',
      //             cli_email: dataS4E.email,
      //             cli_grauparentesco: 1,
      //             cli_nomemae: dataS4E.nomeMae,
      //             cli_datanascimento: dataS4E.dataNascimento,
      //             cli_matricula: dataS4E.matricula,
      //             cli_status: 'A',
      //             cli_sexo: dataS4E.sexo === 'Masculino' ? 1 : 0,
      //             fk_idempresa: this.idEmpresa,
      //             cli_codigoassociados4e: titular.codigoAssociado
      //           };

      //           //INSERE A VIDA NO POSTGRES PARA VINCULAR O DEPENDENTE
      //           const create = await this.apiService.inserirVidaPostgresNest(params);

      //           if (!create) return;

      //           let idCliente = create.pk_idcliente;

      //           //ATUALIZA O FORM COM OS DADOS TRAZIDO DO BANCO POSTGTRES
      //           this.form.patchValue({
      //             cpf_titular: create.cli_cpf,
      //             fk_idtitular: create.pk_idcliente,
      //             cli_codigoassociados4e: create.cli_codigoassociados4e,
      //           });


      //           this.apiService.showToast(
      //             null,
      //             create.cli_nome + " - Inserido com sucesso.",
      //             "success"
      //           );

      //         } else {
      //           this.beneficiarioNaoEncontrado();
      //           return
      //         }

      //       } else {
      //         this.beneficiarioNaoEncontrado();
      //         return
      //       }

      //     });


      //   } else {
      //     this.beneficiarioNaoEncontrado();
      //   }
      // }

    } catch (error) {

      this.loading[0] = false;
      if (error) {
        if (error.status == 404) {
          this.state.is404[10] = true;
        } else if (error.status != 401) {
          this.apiService.errorMessageShow(error);
        } else {
          // erro desconhecido
          console.log(error);
        }
      }
    } finally {
      this.loading[0] = false;
    }

  }
  async verificarSeJaEstaNoPostgresAgiben(idEmpresa: number) {

    try {

      let beneficiarioPostgresAgiben_list = await this.apiService.getClientePorIDEmpresaCPFPostgres(idEmpresa, this.f.cpf_titular.value)
      let resultado = null;

      for (const element of beneficiarioPostgresAgiben_list) {
        if (element.cli_codigoassociados4e != null) {
          resultado = element;
          break; // Para o loop quando a condição for atendida
        }
      }
      return resultado

    } catch (error) {
      console.log("Beneficiario não encontrato na Plataforma de Vendas")
    }
  }


  beneficiarioNaoEncontrado() {
    this.apiService.showToast(
      null,
      "Beneficiário não encontrado.",
      "error"
    );

    //LIMPA O FORM
    this.form.patchValue({
      fk_idtitular: '',
      cli_codigoassociados4e: '',
    });
  }
}

<!-- Element blocking -->
<ng-template #elementBlockTemplateCampanha>
  <div class="block-ui-template">
    <div class="sk-wave sk-primary mx-auto">
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
    </div>
  </div>
</ng-template>

<div class="modal-header">
  <h5 class="modal-title">
    Incluir vida <span class="text-muted">#{{ this.id }}</span>
    <div *ngIf="id > 0">
      <a href="javascript:void(0)" class="font-weight-normal small" (click)="openAuditPopup()">{{
        'auditoria.view.titulo' | translate}}</a>
      &nbsp;|&nbsp;
      <small class="font-weight-normal">{{ 'auditoria.view.cadastrado_em' | translate}}:
        {{ existente.cco_datahora | date: 'short' : '' : translateService.currentLang }}</small>
    </div>
  </h5>

  <button type="button" class="close" (click)="activeModal.close(null)">
    &times;
  </button>
</div>
<form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="modal-body">
    <!-- <a>f.cli_codigoassociados4e</a>
    <a>{{f.cli_codigoassociados4e.value}}</a> -->
    <div *ngIf="!clienteSincronizado()" class="alert alert-warning">
      Esse beneficiário ainda <b>não foi sincronizado</b> com o S4E.
      <button type="button" [disabled]="!form.valid" (click)="sincronizarVidaS4E()" [ladda]="loading[3]"
        data-style="zoom-in" class="btn btn-xs btn-primary">
        Sincronizar
      </button>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12 mb-2">
        <label class="form-label">Qual tipo de vida deseja incluir</label>
        <code>*</code>
        <ng-select formControlName="vida_tipo" (focus)="select2.open()" #select2 [multiple]="false"
          [closeOnSelect]="true" placeholder="Selecione o tipo de vida" id="vida_tipo" [hideSelected]="true"
          [clearable]="false" [ngClass]="{ 'is-invalid': submitted }" [readonly]="this.id > 0 || tipoVida != ''"
          (change)="onChange($event)">
          <ng-option value="1">Titular</ng-option>
          <ng-option value="2">Dependente</ng-option>
        </ng-select>
      </div>
    </div>
    <div class="form-row" *ngIf="f.vida_tipo.value === '1'">
      <div class="form-group col-md-12 mb-2">
        <label class="form-label">Empresa</label>
        <input [disabled]="true" formControlName="vida_empresa" disabled id="vida_empresa"
          placeholder="Pesquise pela empresa do beneficiário" type="text" class="form-control" />
        <div *ngIf="(submitted || f.vida_empresa.touched) && f.vida_empresa.invalid" class="text-danger">
          <div *ngIf="f.vida_empresa.errors.required">{{ 'validation_form.required' | translate}}</div>
        </div>
      </div>
    </div>



    <div class="form-row" *ngIf="f.vida_tipo.value === '2'">
      <div class="form-group col-md-12 mb-2">
        <label class="form-label">Empresa do titular</label>
        <input [disabled]="true" formControlName="vida_empresa" disabled id="vida_empresa"
          placeholder="Pesquise pela empresa do beneficiário" upperCase type="text" class="form-control" />

      </div>
    </div>

    <div class="form-row" *ngIf="f.vida_tipo.value === '2' && id === 0">
      <div class="form-group col-md-12 mb-2">
        <label class="form-label">CPF do titular</label>
        <code>*</code>
        <div class="input-group">
          <input formControlName="cpf_titular" [readonly]="tipoVida != '' " id="cpf_titular" mask="000.000.000-00"
            placeholder="Não informado" type="text" class="form-control" />
          <div class="input-group-append">
            <button class="input-group-text" [ladda]="loading[0]" data-style="zoom-in" (click)="buscarTitular($event)"
              type="button">
              <span class="ion ion-md-search"></span>
            </button>
          </div>
        </div>
        <div *ngIf="(submitted || f.cpf_titular.touched) && f.cpf_titular.invalid" class="text-danger">
          <div *ngIf="f.cpf_titular.errors.required">
            {{ "validation_form.required" | translate }}
          </div>
          <div *ngIf="f.cpf_titular.errors.minlength">
            {{ "validation_form.min_character" | translate : { value: "11" } }}
          </div>
          <div *ngIf="f.cpf_titular.errors.maxlength">
            {{ "validation_form.max_character" | translate : { value: "11" } }}
          </div>
          <div *ngIf="f.cpf_titular.errors.cpfvalidator">CPF inválido</div>
        </div>
      </div>
    </div>

    <div *ngIf="f.vida_tipo.value  ==='1' || f.fk_idtitular.value ||  f.cli_codigoassociados4e.value || id > 0">
      <div class="form-row" *ngIf="f.vida_tipo.value">
        <div class="form-group col-md-12 mb-2">
          <label class="form-label">Nome</label>
          <code>*</code>
          <div class="input-group">
            <input formControlName="vida_nome" id="vida_nome" upperCase
              placeholder="Insira a nome completo do beneficiário" type="text" class="form-control" />
          </div>
          <div *ngIf="(submitted || f.vida_nome.touched) && f.vida_nome.invalid" class="text-danger">
            <div *ngIf="f.vida_nome.errors.required">{{ 'validation_form.required' | translate}}</div>
            <div *ngIf="f.vida_nome.errors.minlength">O campo nome deve ter ao menos 10 caracteres</div>
          </div>
        </div>
      </div>

      <div class="form-row" *ngIf="f.vida_tipo.value">
        <div class="form-group col-md-6 mb-2">
          <label class="form-label">CPF</label>
          <code>*</code>
          <div class="input-group">
            <input formControlName="vida_cpf" id="vida_cpf" mask="000.000.000-00" type="text"
              placeholder="Insira o CPF do contato" class="form-control" />
          </div>
          <div *ngIf="(submitted || f.vida_cpf.touched) && f.vida_cpf.invalid" class="text-danger">
            <div *ngIf="f.vida_cpf.errors.required">
              {{ "validation_form.required" | translate }}
            </div>
            <div *ngIf="f.vida_cpf.errors.minlength">
              {{ "validation_form.min_character" | translate : { value: "11" } }}
            </div>
            <div *ngIf="f.vida_cpf.errors.maxlength">
              {{ "validation_form.max_character" | translate : { value: "11" } }}
            </div>
            <div *ngIf="f.vida_cpf.errors.cpfvalidator">CPF inválido</div>
          </div>
        </div>

        <div class="form-group col-md-6 mb-6" *ngIf="f.vida_tipo.value">
          <label class="form-label">Nome da mãe</label>
          <code>*</code>
          <div class="input-group">
            <input formControlName="vida_nomemae" upperCase placeholder="Insira o nome da mãe do beneficiário"
              id="vida_nomemae" type="text" class="form-control" />
          </div>
          <div *ngIf="(submitted || f.vida_nomemae.touched) && f.vida_nomemae.invalid" class="text-danger">
            <div *ngIf="f.vida_nomemae.errors.required">{{ 'validation_form.required' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="form-row" *ngIf="f.vida_tipo.value">
        <div class="form-group col-md-6">
          <label class="form-label">Data de nascimento</label>
          <code>*</code>
          <div class="input-group">
            <input id="vida_nascimento" formControlName="vida_nascimento" class="form-control"
              placeholder="Ex: 01/01/2000" ngbDatepicker #d="ngbDatepicker" dateMask autocomplete="off"
              [attr.maxlength]="10" />
            <div class="input-group-append">
              <button class="input-group-text" (click)="d.toggle()" type="button">
                <span class="ion ion-md-calendar" style="cursor: pointer"></span>
              </button>
            </div>
          </div>
          <div *ngIf="(submitted || f.vida_nascimento.touched) && f.vida_nascimento.invalid" class="text-danger">
            <div *ngIf="f.vida_nascimento.errors.required">{{ 'validation_form.required' | translate}}</div>
          </div>
        </div>

        <div class="form-group col-md-6">
          <label class="form-label">Sexo</label>
          <code>*</code>
          <ng-select formControlName="vida_sexo" (focus)="select3.open()" #select3 [multiple]="false"
            [closeOnSelect]="true" placeholder="Selecione o sexo do beneficiário" id="vida_sexo" [hideSelected]="true"
            [clearable]="false">
            <ng-option value="1">Masculino</ng-option>
            <ng-option value="0">Feminino</ng-option>
          </ng-select>
          <div *ngIf="(submitted || f.vida_sexo.touched) && f.vida_sexo.invalid" class="text-danger">
            <div *ngIf="f.vida_sexo.errors.required">{{ 'validation_form.required' | translate}}</div>
          </div>
        </div>

      </div>

      <div class="form-row" *ngIf="f.vida_tipo.value">
        <div class="form-group col-md-6 mb-2">
          <label class="form-label">Matrícula</label>
          <code *ngIf="exigeMatricula">*</code>
          <div class="input-group">
            <input formControlName="vida_matricula" placeholder="Insira a matrícula do beneficiário" id="vida_matricula"
              type="text" class="form-control" />
          </div>
        </div>
        <div class="form-group col-md-6">
          <label class="form-label">Grau de parentesco</label>
          <code>*</code>
          <ng-select [multiple]="false" [closeOnSelect]="true" placeholder="Selecione o grau de parentesco"
            formControlName="cli_grauparentesco" id="cli_grauparentesco" [readonly]="f.vida_tipo.value === '1'"
            [hideSelected]="true" [ngClass]="{
            'is-invalid':
              (submitted || f.cli_grauparentesco.touched) &&
              f.cli_grauparentesco.invalid
          }">
            <ng-option *ngIf="f.vida_tipo.value === '1'" value="1">TITULAR</ng-option>
            <ng-option value="3">CONJUGE/COMPANHEIRO</ng-option>
            <ng-option value="4">FILHO</ng-option>
            <ng-option value="6">ENTEADO</ng-option>
            <ng-option value="8">PAI/MAE</ng-option>
            <ng-option value="10">AGREGADOS/OUTROS</ng-option>
          </ng-select>
          <div *ngIf="(submitted || f.cli_grauparentesco.touched) && f.cli_grauparentesco.invalid" class="text-danger">
            <div *ngIf="f.cli_grauparentesco.errors.required">{{ 'validation_form.required' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="form-row" *ngIf="f.vida_tipo.value">
        <div class="form-group col-md-6 mb-2">
          <label class="form-label">Telefone</label>
          <code>*</code>
          <div class="input-group">
            <input formControlName="vida_telefone" placeholder="Insira o telefone do beneficiário" mask="(00)0000-0000||(00)00000-0000"
              id="vida_telefone" type="tel" class="form-control" />
          </div>
          <div *ngIf="(submitted || f.vida_telefone.touched) && f.vida_telefone.invalid" class="text-danger">
            <div *ngIf="f.vida_telefone.errors.required">{{ 'validation_form.required' | translate}}</div>
          </div>
        </div>
        <div class="form-group col-md-6 mb-2">
          <label class="form-label">Email</label>
          <div class="input-group">
            <input formControlName="vida_email" placeholder="Insira o email do beneficiário" id="vida_email" type="text"
              class="form-control" />
          </div>
          <div *ngIf="(submitted || f.vida_email.touched) && f.vida_email.invalid" class="text-danger">
            <div *ngIf="f.vida_email.errors.required">{{ 'validation_form.required' | translate}}</div>
            <div *ngIf="f.vida_email.errors.minlength">
              {{ 'validation_form.min_character' | translate:{'value':'4'} }}
            </div>
            <div *ngIf="f.vida_email.errors.maxlength">
              {{ 'validation_form.max_character' | translate:{'value':'255'} }}
            </div>
          </div>

        </div>
      </div>
    </div>


    <div *ngIf="f.vida_tipo.value">
      <span><code>*</code>Campos obrigatórios</span>
    </div>
    <hr *ngIf="id != 0" />
    <span *ngIf="id != 0" class="badge font-weight-normal badge-secondary">Informações do S4E</span>

    <div class="form-row bg-gray mt-2" *ngIf="id != 0">
      <div class="form-group col-md-3 mb-2">
        <label class="form-label">Cod. Beneficiário</label>
        <div class="input-group">
          <input formControlName="cli_codigoassociados4e" disabled id="cli_codigoassociados4e" type="text"
            class="form-control" />
        </div>
      </div>
      <div class="form-group col-md-6 mb-2">
        <label class="form-label">Cod. Produto</label>
        <div class="input-group">
          <input formControlName="cco_id_produto_s4" disabled id="cco_id_produto_s4" type="text" class="form-control" />
        </div>
      </div>

      <div class="form-group col-md-3" *ngIf="existente">
        <label class="form-label">Status *</label>
        <ng-select [multiple]="false" [closeOnSelect]="true" [readonly]="true" placeholder="Selecione o status"
          formControlName="cco_status" id="cco_status" [hideSelected]="true" [ngClass]="{
            'is-invalid':
              (submitted || f.cco_status.touched) && f.cco_status.invalid
          }">
          <ng-option value="C">Em cadastro</ng-option>
          <ng-option value="I">Implantada</ng-option>
          <ng-option value="R">Rejeitada</ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex ">
    <div class="d-flex justify-content-start ">
      <div *ngIf="id > 0 && !f.cli_codigoassociados4e.value">
        <button type="button" class="btn btn-outline-danger icon-btn borderless" [ladda]="loading[0]"
          data-style="zoom-in" [swal]="{
          title: ('delete_dialog.title' | translate),
          text: ('delete_dialog.message' | translate),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: ('delete_dialog.confirm' | translate),
          cancelButtonText: ('delete_dialog.cancel' | translate),
          showCloseButton: true,
          customClass: {
            confirmButton: 'btn btn-lg btn-warning',
            cancelButton: 'btn btn-lg btn-default'
          }
        }" (confirm)="removeVidaNest(this.id)" (cancel)="declineDialog.fire()" [disabled]="!remover_vida">
          <span class="ion ion-md-trash"></span>
        </button>
      </div>
    </div>
    <div class="d-flex   ml-md-auto justify-content-end">
      <div class="mr-2">
        <button class="btn btn-outline-dark" [ladda]="loading[0]" data-style="zoom-in"
          (click)="activeModal.close(null)">
          <span>Voltar</span>
        </button>
      </div>
      <button type="submit" *ngIf="id === 0" class="btn btn-primary" [ladda]="loading[0]" data-style="zoom-in"
        [disabled]="!form.valid">
        <span>Avançar</span>
      </button>
    </div>
  </div>
</form>
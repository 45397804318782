import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core'
import {PosicaoEfeito} from 'src/app/utils/enums'

@Component({
	selector: 'landing-page-info-card',
	templateUrl: './landing-page-info-card.component.html',
	styleUrls: ['landing-page-info-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class LandingPageInfoCardComponent {
	@Input() title: string
	@Input() showIconPrimary = false
	@Input() imageUrl: string
	@Input() imagePos: PosicaoEfeito

	@Input() iconUrl: string

	@Input() subtitle: string
	@Input() secondSubtitle: string
	@Input() backgroundSubtitle = false
	@Input() backgroundTitle = false
	@Input() description: string
	@Input() secondDescription: string
	@Input() primaryButtonLabel: string
	@Input() secondaryButtonLabel: string

	@Output() primaryEventEmitter: EventEmitter<any> = new EventEmitter<any>()
	@Output() secondaryEventEmitter: EventEmitter<any> = new EventEmitter<any>()

	emitEvent(eventEmitter: EventEmitter<any>): void {
		eventEmitter.emit()
	}
}

<ng-template #modal>
	<div class="generic-modal">
		<div class="modal-header">
			<h5 class="modal-title">{{ modalConfig.modalTitle }}</h5>
			<button
				*ngIf="
					modalConfig.hideCloseHeaderButton === undefined || !modalConfig.hideCloseHeaderButton()
				"
				type="button"
				class="close"
				(click)="dismiss()">
				&times;
			</button>
		</div>
		<div [ngClass]="{'custom-modal': modalConfig.hasCustomModalClass}">
			<div
				[ngClass]="{
					'overflow-modal-body modal-body': modalConfig.hasOverflowClass,
					'modal-body': !modalConfig.hasOverlayClass
				}">
				<ng-content></ng-content>
			</div>
		</div>
		<div
			*ngIf="!modalConfig.steps?.length; else stepperTemplate"
			class="modal-footer"
			[ngClass]="{
				'd-none': modalConfig.hideFooterSection !== undefined && modalConfig.hideFooterSection()
			}">
			<button
				type="button"
				class="btn btn-outline-dark font-weight-semibold"
				(click)="dismiss()"
				*ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
				[disabled]="
					modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()
				">
				{{ modalConfig.cancelButtonLabel }}
			</button>
			<button
				type="button"
				class="btn btn-primary font-weight-semibold"
				(click)="close()"
				[ladda]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()"
				data-style="zoom-in"
				*ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
				[disabled]="
					modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()
				">
				{{ modalConfig.confirmButtonLabel }}
			</button>
		</div>

		<ng-template #stepperTemplate>
			<div class="modal-footer">
				<button
					*ngIf="
						modalConfig.selectedStep.getValue() !== 0 &&
						(modalConfig?.hideBackStepButton === undefined || !modalConfig?.hideBackStepButton())
					"
					type="button"
					class="btn btn-outline-dark font-weight-semibold"
					(click)="backstep()">
					{{ modalConfig.cancelButtonLabel }}
				</button>

				<button
					*ngIf="modalConfig.selectedStep.getValue() !== modalConfig.steps.length - 1"
					type="button"
					class="btn btn-primary font-weight-semibold"
					[disabled]="
						modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()
					"
					(click)="advance()">
					{{ modalConfig.confirmButtonLabel }}
				</button>

				<button
					*ngIf="modalConfig.selectedStep.getValue() === modalConfig.steps.length - 1"
					type="button"
					class="btn btn-primary font-weight-semibold"
					[ladda]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()"
					data-style="zoom-in"
					[disabled]="
						modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()
					"
					(click)="close()">
					{{ modalConfig.finishButtonLabel }}
				</button>
			</div>
		</ng-template>
	</div>
</ng-template>

export enum ProdutosComissoes {
	BeneficiosNegociados = 0,
	ProdutosTitular = 1,
	ProdutosTitularEDependente = 2,
	ProdutosDependente = 3,
	DefinirPAFTitularEDependenteDiferentes = 4,
	DefinirPAFTitularEDependentesIguais = 5,
	DefinirPAFTitular = 6,
	ComissoesTitularOuTitularDependenteDiferentes = 7,
	ComissoesDependente = 8,
	EnvioClausulaConvencaoColetiva = 9,
}

export enum IS_ADM {
	IS_NOT_ADM = 0,
	IS_ADM = 1,
}

export enum EnumTipoOperacao {
	CREATE = 'C',
	READ = 'R',
	UPDATE = 'U',
	DELETE = 'D',
}

export enum TipoProvedor {
	CLIN = 'CLIN',
	AGIBEN = 'AGIBEN',
	MELHORAI = 'MELHORAI',
}

export enum PosicaoEfeito {
	DIREITA = 'dir',
	ESQUERDA = 'esq',
	CENTRAL = 'central',
}

export enum TipoAcordoCCTEnum {
	CCT = 'CCT',
	ACT = 'ACT',
}

export enum StepCCTContratosAssinados {
	INITIAL_STEP,
	FINAL_STEP,
}

export enum Titularidade {
	Titular = 'Titular',
	Dependente = 'Dependente',
}

export enum TipoIntermediador {
	TITULAR = 'TITULAR',
	DEPENDENTE = 'DEPENDENTE',
}

export enum TipoValidoOuInvalido {
	VALID = 'VALID',
	INVALID = 'INVALID',
}

export enum EnumStatusFiltroCCTACT {
	ATIVO = 'ATIVO',
	INATIVO = 'INATIVO',
	AGUARDANDO_PARAMETRIZACAO = 'AGUARDANDO_PARAMETRIZACAO',
	IMPLANTADO = 'IMPLANTADO',
}

export enum ModoAtivoParametrizacaoCCT {
	CRIACAO = 'CRIACAO',
	EDICAO = 'EDICAO',
	VISUALIZACAO = 'VISUALIZACAO',
}

export enum AbasGestaoCarteira {
	EMPRESA = 0,
	USUARIO = 1,
}

export enum TipoTitularInclusaoVidas {
	TITULAR = '1',
	DEPENDENTE = '2',
}

export enum GrauParentesco {
	TITULAR = '1',
	CONJUGE_OU_COMPANHEIRO = '3',
	FILHO = '4',
	ENTEADO = '6',
	PAI_OU_MAE = '8',
	AGREGADOS_OU_OUTROS = '10',
}

export enum Sexo {
	MASCULINO = '1',
	FEMININO = '0',
}

export enum ClienteCompromissoStatus {
	'I' = 'Implantada',
	'C' = 'Em cadastro',
	'M' = 'Em migração',
}

export enum EnumBeneficios {
	ODONTO = 1,
	TELEMEDICINA = 2,
	ASSISTENCIA = 3,
	SEGURO_DE_VIDA = 4,
}

export enum ProdutoCCTEnum {
	CLIN_CLASS = 1,
	CLIN_SMILE = 2,
	CLIN_SMART = 3,
	ORTOCLIN_PLUS = 4,
	MELHORAI_CONFORT = 6,
	MELHORAI_EXPRESS = 5,
	MELHORAI_QUALITY = 7,
	AUXILIO_NATALIDADE = 9,
	AUXILIO_FUNERAL = 10,
	SEGURO_DE_VIDA = 8,
}

export enum SimOuNao {
	SIM = 'Sim',
	NAO = 'Não',
}

export enum Meses {
	'JANEIRO',
	'FEVEREIRO',
	'MARÇO',
	'ABRIL',
	'MAIO',
	'JUNHO',
	'JULHO',
	'AGOSTO',
	'SETEMBRO',
	'OUTUBRO',
	'NOVEMBRO',
	'DEZEMBRO',
}

import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {PublicoService} from '../service/publico.service'
import {ApiService} from '../service/api.service'
import {CONTATO_ESPECIALISTA_PADRAO} from '../utils/constants'

@Component({
	selector: 'app-manutencao',
	templateUrl: './manutencao.component.html',
	styleUrls: ['./manutencao.component.scss'],
})
export class ManutencaoComponent implements OnInit {
	numeroEspecialistaCCT = ''

	constructor(
		private route: ActivatedRoute,
		private publicService: PublicoService,
		private apiService: ApiService,
		private router: Router,
	) {}

	ngOnInit() {
		this.carregarDados(this.route.snapshot.paramMap.get('paf'))
	}

	falarComEspecialista() {
		window.open(
			`https://api.whatsapp.com/send?phone=55${this.numeroEspecialistaCCT}&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20a%20Agiben`,
			'_blank',
		)
	}

	async carregarDados(pafCode: string) {
		this.publicService
			.getInfoByPaf(pafCode)
			.then((res: any) => {
				this.numeroEspecialistaCCT = res.contatoEspecialista || CONTATO_ESPECIALISTA_PADRAO
			})
			.catch(async (err: any) => {
				if (err?.error?.message) {
					this.apiService.showToast(null, err?.error?.message, 'error')
				} else {
					this.apiService.showToast(null, 'Nenhuma CCT encontrada com este link.', 'error')
				}
				await new Promise(f => setTimeout(f, 2500))
				this.router.navigate(['/'])
			})
	}
}

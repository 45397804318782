<!-- Element blocking -->
<ng-template #elementBlockTemplateCampanha>
    <div class="block-ui-template">
      <div class="sk-wave sk-primary mx-auto">
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
      </div>
    </div>
  </ng-template>
  
  <div class="modal-header">
    <h5 class="modal-title">Enviar arquivos</h5>
  
    <!-- <button type="button" class="close" (click)="activeModal.close(null)">
      &times;
    </button> -->
  </div>
  <form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="modal-body">
      <div class="form-column mb-2">
        <div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <div class="mt-4 d-flex flex-column justify-content-center align-items-center">
            <p class="custom-font-size d-flex flex-column justify-content-center align-items-center">Envie a foto da ficha de<br><strong>{{ form_inclusao_vida.vida_nome.value }}</strong></p>
          </div>
          <div class="media-body ml-3">
            <div ngbDropdown class="d-inline-flex">
              <button type="button" class="btn btn-dark btn-lg" ngbDropdownToggle>Enviar Foto</button>
              <div ngbDropdownMenu>
                <a class="dropdown-item" href="javascript:void(0)" (click)="openWebcam()">Tirar foto</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="openDragdrop()">Enviar foto</a>
              </div>
            </div>
  
          </div>
        </div>
        
      </div>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end w-100 w-lg-auto mt-3 mt-lg-0">
        <div class="mr-2">
          <!-- <button
            class="btn btn-outline-dark"
            [ladda]="loading[0]"
            data-style="zoom-in"
            (click)="activeModal.close(null)"
          >
            <span>Voltar</span>
          </button> -->
        </div>
  
        <button
          type="submit"
          class="btn btn-primary"
          [ladda]="loading[0]"
          data-style="zoom-in"
          [disabled]="!linkGerado"
        >
          <span>Salvar e incluir vidas</span>
        </button>
      </div>
    </div>
  </form>
  
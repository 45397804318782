import {Component, Injectable, Input, OnInit, TemplateRef, ViewChild} from '@angular/core'
import {ModalConfig} from 'src/app/model/cct/beneficios/landing-page/interfaces'
import {NgbModal, NgbModalOptions, NgbModalRef, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'modal-generico',
	templateUrl: './modal-generico.component.html',
	styleUrls: [
		'./modal-generico.component.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
	],
})
@Injectable()
export class ModalGenericoComponent implements OnInit {
	@Input() public modalConfig: ModalConfig<any>
	@ViewChild('modal') private modalContent: TemplateRef<ModalGenericoComponent>
	private modalRef: NgbModalRef

	constructor(
		private modalService: NgbModal,
		private activeModal: NgbActiveModal,
	) {}

	ngOnInit(): void {}

	open(ngbModalOptions?: NgbModalOptions): Promise<any> {
		return new Promise<any>(resolve => {
			this.modalRef = this.modalService.open(
				this.modalContent,
				ngbModalOptions || {...this.modalConfig.options},
			)
			this.modalRef.result.then(resolve, resolve)
		})
	}

	async close(): Promise<void> {
		if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
			const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
			this.modalRef.close(result)
		}
	}

	async dismiss(): Promise<void> {
		if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
			const result =
				this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
			this.modalRef.dismiss(result)
		}
	}

	defaultDismiss() {
		this.modalRef?.dismiss()
		this.activeModal?.dismiss()
	}

	advance(): void {
		if (!this.modalConfig.onNextStep) {
			const actualStep = this.modalConfig.selectedStep.getValue()
			this.modalConfig.selectedStep.next(actualStep + 1)
		} else {
			this.modalConfig.onNextStep()
		}
	}

	backstep(): void {
		if (!this.modalConfig.onBackStep) {
			const actualStep = this.modalConfig.selectedStep.getValue()
			this.modalConfig.selectedStep.next(actualStep - 1)
		} else {
			this.modalConfig.onBackStep()
		}
	}
}
